import React, { useState, useRef } from "react"
import { makeStyles } from "@material-ui/styles"
import { TextField, Grid, IconButton, Box, Divider } from "@material-ui/core"
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined"
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined"
import {
  ACTION_PRIORITY,
  ACTION_STATUS,
  useMutationCreateJobAction,
  useMutationCreateLocationAction,
  useMutationUpdateAction,
} from "../../data"
import {
  MentionTextInput,
  UsersGroupsAssigner,
  DraftBlockquote,
  CreatorActions,
  InfoSection,
  PaperBox,
  usersGroupsEveryoneItem,
  LocationsAssigner,
  ActionStatusOutlinedSelect,
} from ".."
import { toId, mapToIds, useMountEffect, useFormUtils, useDraft, useAnalytics } from "../../utils"
import { CreatorMaster } from "../Creators"
import { Uploader, UploadingList, UploadingImagesList } from "../Uploader"
import { useAuth } from "../../services"
import Checkbox from "../Checkbox"
import { ActionPriorityOutlinedSelect } from "../OutlinedSelect/ActionPriorityOutlinedSelect"
import { PresetOptionsDateOutlinedSelect } from "../OutlinedSelect/PresetOptionsDateOutlinedSelect"
import TagsField from "../TextField/TagsField"

const useStyles = makeStyles((theme) => ({
  settings: {
    minHeight: 68,
  },
  scheduledText: {
    color: theme.palette.scheduled.main,
  },
  dividerSpacer: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    paddingTop: theme.spacing(1),
    fontSize: 14,
  },
  buttonText: {
    fontWeight: "600",
    fontSize: 14,
  },
  postOptions: {
    padding: theme.spacing(3),
  },
  visibleFrom: {},
}))

const initialState = {
  title: "",
  content: null,
  priority: ACTION_PRIORITY.NORMAL,
  status: ACTION_STATUS.UNRESOLVED,
  dueAt: null,
  users: [],
  groups: [],
  uploads: [],
  tags: [],
}

const ActionCreator = ({ open, job, edit, step, onClose, isInline }) => {
  const classes = useStyles()
  const [createJobAction, { loading: createJobLoading }] = useMutationCreateJobAction()
  const [createLocationAction, { loading: createLocationLoading }] = useMutationCreateLocationAction()
  const [updateAction, { loading: updateLoading }] = useMutationUpdateAction()
  const { isValid, touched } = useFormUtils()
  const analytics = useAnalytics()
  const {
    settings: { locations: userLocations },
    location: userLocation,
  } = useAuth()

  const [location, setLocation] = useState(null)
  const [title, setTitle] = useState(initialState.title)
  const [content, setContent] = useState(initialState.content)
  const [priority, setPriority] = useState(initialState.priority)
  const [status, setStatus] = useState(initialState.status)
  const [dueAt, setDueAt] = useState(initialState.dueAt)
  const [hasEveryone, setHasEveryone] = useState(false)
  const [users, setUsers] = useState(mapToIds(job?.actionSettings?.users) || initialState.users)
  const [groups, setGroups] = useState(mapToIds(job?.actionSettings?.groups) || initialState.groups)
  const [uploads, setUploads] = useState(initialState.uploads)
  const [uploading, setUploading] = useState([])
  const [tags, setTags] = useState(job?.tags ? [...job.tags] : initialState.tags)
  const [isUploading, setIsUploading] = useState(false)
  const contentRef = useRef(null)

  const [initialPicked, setInitialPicked] = useState(null)
  const [disableInitialPicked, setDisableInitialPicked] = useState(null)
  const [locationsInitialPicked, setLocationsInitialPicked] = useState(null)

  const [draft, setDraft, removeDraft, draftUntouched] = useDraft(
    `action_creator_${job ? toId(job) : "new"}_${step ? `_${toId(step)}` : ""}`,
    null
  )
  const [resendNotifications, setResendNotifications] = useState(true)

  const hasJobAndStep = Boolean(job) && Boolean(step)

  useMountEffect(() => {
    if (edit) {
      const noUsersOrGroups = !edit.users.length && !edit.groups.length

      setTitle(edit.title)
      setContent(edit.content)
      setPriority(edit.priority)
      setStatus(edit.status)
      setDueAt(edit.dueAt)
      setUsers(mapToIds(edit.users))
      setGroups(mapToIds(edit.groups))
      setLocation(toId(edit.location))
      setUploads(edit.uploads)
      setTags(edit.tags)
      setHasEveryone(!edit.users.length && !edit.groups.length)

      setInitialPicked(noUsersOrGroups ? [usersGroupsEveryoneItem] : [...edit.users, ...edit.groups])
    } else {
      const initialPickedFromSettings = []
      if (job?.actionSettings?.users?.length > 0) {
        initialPickedFromSettings.push(...job.actionSettings.users)
      }
      if (job?.actionSettings?.groups?.length > 0) {
        initialPickedFromSettings.push(...job.actionSettings.groups)
      }

      setInitialPicked(initialPickedFromSettings)

      // this state to user cannot be removed from default action assignees
      setDisableInitialPicked(initialPickedFromSettings)

      if (!job) {
        setLocationsInitialPicked(userLocation)
        setLocation(userLocation)
      }

      if (draft) {
        draft.title && setTitle(draft.title)
        draft.content && setContent(draft.content)
        draft.uploads && setUploads(draft.uploads)
      }
    }
  }, [])

  const handleClose = (event) => {
    onClose && onClose(event)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (formValid()) {
      const usersGroups = { users: hasEveryone ? [] : users, groups: hasEveryone ? [] : groups }
      const uploadIds = uploads.map((upload) => upload.id)
      const variables = {
        input: {
          title,
          content,
          priority,
          status,
          dueAt,
          tags: mapToIds(tags),
          ...usersGroups,
          uploads: uploadIds,
        },
      }

      if (edit) {
        await updateAction({
          variables: {
            id: toId(edit),
            input: {
              ...variables.input,
              resendNotifications,
            },
          },
        })
        analytics.track("Updated Action")
      } else {
        if (hasJobAndStep) {
          // add job & step
          variables.input.job = toId(job)
          variables.input.jobStep = toId(step)

          await createJobAction({
            variables,
          })
        } else {
          // add location
          variables.input.location = toId(location)

          await createLocationAction({
            variables,
          })
        }
        removeDraft()
        analytics.track("Created Action")
      }
      handleClose()
    }
  }

  const handleChange = (set, name, newValue) => {
    set(newValue)
    if (!edit) {
      setDraftProperty(name, newValue)
    }
  }

  const setDraftProperty = (prop, value) => {
    setDraft((prev) => ({
      ...prev,
      [prop]: value,
    }))
  }

  const handleDiscardDraft = () => {
    removeDraft()
    setTitle(initialState.title)
    setContent(initialState.content)
    setUploads(initialState.uploads)
  }

  const handleUsersGroupsPickedChanged = (picked) => {
    const newHasEveryone = picked.find((p) => p.__typename === "Everyone")
    setHasEveryone(newHasEveryone)
    setUsers(newHasEveryone ? [] : [...mapToIds(picked.filter((p) => p.__typename === "User"))])
    setGroups(newHasEveryone ? [] : [...mapToIds(picked.filter((p) => p.__typename === "Group"))])
  }

  const handleAddMention = (id) => {
    const parts = id.split(":")
    if (parts.length >= 2) {
      const newId = parts[1]
      switch (parts[0]) {
        case "User":
          if (!users.includes(newId)) setUsers([...users, newId])
          break
        case "Group":
          if (!groups.includes(newId)) setGroups([...groups, newId])
          break
        default:
      }
    }
  }

  const handleAtClick = () => {
    setContent(content ? `${content.trimEnd()} @` : "@")
    contentRef.current.focus()
  }

  const handleBeforeUpload = (items) => {
    setIsUploading(true)
    setUploading(() => items)
  }
  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }
  const handleItemUploaded = (item) => {
    setUploads((state) => {
      const result = [...state, item]
      if (!edit) {
        setDraftProperty("uploads", [...result])
      }
      return result
    })
  }
  const handleRemoveUpload = (id) => {
    setUploads((state) => {
      const result = [...state.filter((upload) => upload.id !== id)]
      if (!edit) {
        setDraftProperty("uploads", [...result])
      }
      return result
    })
  }
  const handleNotUploading = () => {
    setIsUploading(false)
  }

  const handleLocationPickedChanged = (picked) => {
    setLocation(picked)
    handleUsersGroupsPickedChanged([])
  }

  const handleDueChange = (date) => {
    setDueAt(date)
  }

  const handleTagChanged = (options) => {
    setTags(options)
  }

  const formValid = () =>
    isValid(title, content) &&
    (hasJobAndStep || isValid(location)) &&
    (hasEveryone || users.length || groups.length) &&
    !isUploading

  const isError = (val) => (val != null ? val.length === 0 : false)
  const errorMessage = (val, message) => isError(val) && message

  const isFormValid = formValid()

  const showDraft = !edit && !!draft && draftUntouched

  const jobLocations = job?.location ? [job.location] : [...userLocations]
  const jobLocation = job?.location ? userLocations.find((l) => toId(l) === toId(job.location)) : null

  const locations = hasJobAndStep ? jobLocations : location ? [toId(location)] : []
  const locationItem = location ? userLocations.find((l) => toId(l) === toId(location)) : null
  const timeZone = locationItem ? locationItem.timeZone : userLocation?.timeZone

  const loading = createJobLoading || createLocationLoading || updateLoading

  const form = (
    <>
      <DraftBlockquote show={showDraft} subject="Action" onDiscard={handleDiscardDraft} />

      {hasJobAndStep && (
        <PaperBox fullWidth mb={3}>
          <InfoSection title="Location" value={jobLocation?.name || "Unknown"} compact />
          <Divider light />
          <InfoSection title="Job" value={job.displayName} compact />
          <Divider light />
          <InfoSection title="Step" value={step.name} compact />
        </PaperBox>
      )}

      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          id="title"
          label="Give the action a short title"
          name="title"
          value={title}
          required
          onChange={(event) => handleChange(setTitle, "title", event.target.value)}
          inputProps={{ "data-cy": "ActionCreator-title" }}
          autoFocus
        />
      </Box>
      <MentionTextInput
        inputRef={contentRef}
        label="Describe what needs to be done"
        value={content}
        locations={locations}
        height={92}
        onChange={(event) => handleChange(setContent, "content", event.target.value)}
        onAddMention={handleAddMention}
        fullWidth
        multiline
        required
        onBlur={() => touched(content, setContent)}
        error={isError(content)}
        helperText={errorMessage(content, "Please enter content")}
        cy="ActionCreator-content"
      />
      {!!uploads?.length && (
        <Box my={1}>
          <UploadingImagesList
            uploaded={uploads}
            uploading={uploading}
            onRemoveUpload={handleRemoveUpload}
            height={100}
            gridItemProps={{
              xs: 3,
            }}
          />
          <UploadingList uploaded={uploads} uploading={uploading} images={false} onRemoveUpload={handleRemoveUpload} />
        </Box>
      )}
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.settings}>
        <Grid item sm={3} xs={12}>
          <IconButton onClick={handleAtClick} title="Link this jobs, articles or people to this post">
            <AlternateEmailOutlinedIcon />
          </IconButton>
          <Uploader
            onBeforeUpload={handleBeforeUpload}
            onProgress={handleUploadProgress}
            onItemUploaded={handleItemUploaded}
            onAfterUpload={handleNotUploading}
            onError={handleNotUploading}
            images={false}
            disabled={isUploading}
            title="Attach files to this post"
          />
          <Uploader
            onBeforeUpload={handleBeforeUpload}
            onProgress={handleUploadProgress}
            onItemUploaded={handleItemUploaded}
            onAfterUpload={handleNotUploading}
            onError={handleNotUploading}
            icon={<AddPhotoAlternateOutlinedIcon />}
            documents={false}
            disabled={isUploading}
            title="Attach photos / images to this post"
          />
        </Grid>
      </Grid>

      <Box mt={1} mb={2}>
        <TagsField
          disabled={loading}
          value={tags ?? []}
          label="Tags"
          onChange={(_, options) => handleTagChanged(options)}
        />
      </Box>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <ActionPriorityOutlinedSelect
              label="Priority"
              value={priority}
              onChange={(event) => handleChange(setPriority, "priority", event.target.value)}
              cy="ActionCreator-priority"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ActionStatusOutlinedSelect
              label="Status"
              value={status}
              onChange={(event) => handleChange(setStatus, "status", event.target.value)}
              cy="ActionCreator-status"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PresetOptionsDateOutlinedSelect label="Due" value={dueAt} onChange={handleDueChange} timeZone={timeZone} />
          </Grid>
        </Grid>
      </Box>

      {!hasJobAndStep && locationsInitialPicked != null && (
        <LocationsAssigner
          title="Location"
          initialPicked={locationsInitialPicked}
          onPickedChanged={handleLocationPickedChanged}
          multiple={false}
          cy="ActionCreator-location"
          bottomBorder={false}
        />
      )}

      {initialPicked && (
        <Box mb={2}>
          <UsersGroupsAssigner
            disabled={isUploading}
            title="Assign to"
            placeholder="Nobody selected"
            allowAll={false}
            allowSelf
            allowGroups
            allStaffLabel="All staff"
            locations={mapToIds(locations)}
            initialPicked={initialPicked}
            disableInitialPicked={disableInitialPicked}
            onPickedChanged={handleUsersGroupsPickedChanged}
            cy="ActionCreator-to"
          />
        </Box>
      )}

      {edit && (
        <Box mb={2}>
          <Checkbox
            color="primary"
            type="label"
            label="Resend notifications to users"
            checked={resendNotifications}
            onChange={() => setResendNotifications(!resendNotifications)}
          />
        </Box>
      )}

      <CreatorActions
        id="ActionCreator-CreatorActions"
        submitLabel={edit ? "Update action" : "Raise action"}
        submitLoading={loading}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid}
      />
    </>
  )

  return (
    <CreatorMaster
      id="ActionCreator-dialog"
      open={open}
      title={edit ? "Update action" : "Raise action"}
      form={form}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { ActionCreator }
