import React, { useEffect, useRef, useState } from "react"
import { Badge, Box, IconButton, Tooltip } from "@material-ui/core"
import AnnounceKit from "announcekit-react"
import Config from "react-global-configuration"
import { withStyles } from "@material-ui/styles"
import { Icon } from "../Icon"
import { useAuth } from "../../services"

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 2,
    top: 4,
    fontWeight: "600",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge)

const AnnouncementButton = ({ className, buttonClassName }) => {
  const ref = useRef(AnnounceKit)
  const { widget } = Config.get("announceKit")
  const [unread, setUnread] = useState(0)
  const {
    principal: { userID, firstName },
  } = useAuth()

  useEffect(() => {
    ref.current?.unread().then((next) => setUnread(next))
  }, [ref])

  const handleClick = () => {
    ref.current.open()
    setUnread(0)
  }

  return (
    <AnnounceKit ref={ref} widget={widget} user={{ id: userID, name: firstName }}>
      <Box className={className}>
        <Tooltip title="What's new">
          <IconButton
            aria-label="What's new"
            className={buttonClassName}
            data-cy="IconButton-announcements"
            onClick={handleClick}
          >
            <StyledBadge badgeContent={unread} color="primary" overlap="circular">
              <Icon name="announcement" />
            </StyledBadge>
          </IconButton>
        </Tooltip>
      </Box>
    </AnnounceKit>
  )
}

export { AnnouncementButton }
