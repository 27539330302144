import React, { useEffect, useMemo, useState } from "react"
import { Box } from "@material-ui/core"
import { formatters } from "json2csv"
import fileDownload from "js-file-download"
import { RowBox, ReportSectionActionCard, CircularStepCompletionChart } from ".."
import { useLazyQueryReportGroupsOverview } from "../../data"
import { mapToIds } from "../../utils"
import { useAuth } from "../../services"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.rank > b.rank ? -1 : 1)

const GroupsStepCompletionReport = ({ gt, lt, locations, groups, users, onGroupClick }) => {
  const [sort, setSort] = useState("alpha")
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const [loadReportGroupsOverview, { data, loading }] = useLazyQueryReportGroupsOverview()

  useEffect(() => {
    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterGroups = groups.includes("all") ? null : groups

    loadReportGroupsOverview({
      variables: {
        users: users ? mapToIds(users) : null,
        locations: filterLocations,
        groups: filterGroups,
        gt,
        lt,
      },
    })
  }, [groups, gt, loadReportGroupsOverview, locations, lt, userLocations, users])

  const handleGroupClick = ({ id }) => {
    onGroupClick && onGroupClick(id)
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const chartData = useMemo(
    () =>
      data
        ? data?.reportGroupsOverview
            .map(({ id, name, jobs }) => ({
              id,
              name,
              jobs,
              rank:
                jobs.total > 0
                  ? (jobs.completed + jobs.overdue + jobs.skipped) /
                    (jobs.completed + jobs.overdue + jobs.uncompleted + jobs.skipped)
                  : -1,
            }))
            .sort((a, b) => sortData(sort, a, b))
        : [],
    [data, sort]
  )

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = ["Name", "On-time", "Late", "Skipped", "Uncompleted"]
    const rows = [...data.reportGroupsOverview].map(({ name, jobs: { completed, overdue, skipped, uncompleted } }) =>
      [stringFormatter(name), completed, overdue, skipped, uncompleted].join(",")
    )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "groups-step-completion.csv", "text/csv")
  }

  return (
    <ReportSectionActionCard
      title="Areas of Work"
      detail="Step completion %"
      loading={loading}
      style={{ width: "50%" }}
      tools={
        <RowBox>
          <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
          <Box ml={1}>
            <ReportToolsIconButton
              title={sort === "alpha" ? "Sort by completions %" : "Sort alphabetically"}
              icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
              onClick={handleSort}
              disabled={loading}
            />
          </Box>
        </RowBox>
      }
    >
      <RowBox flexWrap="wrap" alignItems="flex-start" justifyContent="center">
        {chartData.map(({ id, name, jobs }) => (
          <CircularStepCompletionChart key={id} text={name} data={jobs} onClick={() => handleGroupClick({ id })} />
        ))}
      </RowBox>
    </ReportSectionActionCard>
  )
}

export default GroupsStepCompletionReport
