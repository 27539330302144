import React from "react"
import { MenuItem } from "@material-ui/core"
import { OutlinedSelect } from "../OutlinedSelect"
import { TRIGGER_TARGET_TYPE, TRIGGER_TARGET_TYPE_LABELS } from "../../data"

const { NOTIFY_USERS, NOTIFY_EMAILS, NOTIFY_AUTHOR } = TRIGGER_TARGET_TYPE

const TriggerTargetTypeOutlinedSelect = ({
  label = "What should the trigger do?",
  showNotifyAuthor = false,
  ...props
}) => (
  <OutlinedSelect label={label} native={false} {...props}>
    <MenuItem value={NOTIFY_USERS}>{TRIGGER_TARGET_TYPE_LABELS[NOTIFY_USERS]}</MenuItem>
    <MenuItem value={NOTIFY_EMAILS}>{TRIGGER_TARGET_TYPE_LABELS[NOTIFY_EMAILS]}</MenuItem>
    {showNotifyAuthor && <MenuItem value={NOTIFY_AUTHOR}>{TRIGGER_TARGET_TYPE_LABELS[NOTIFY_AUTHOR]}</MenuItem>}
    {/* <MenuItem value={INTEGRATION} disabled>
      {TRIGGER_TARGET_TYPE_LABELS[INTEGRATION]}
    </MenuItem> */}
  </OutlinedSelect>
)

export default TriggerTargetTypeOutlinedSelect
