import { makeStyles } from "@material-ui/core"
import { FlexBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "50%",
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.white,
    fontSize: 16,
    fontWeight: 700,
    width: 40,
    height: 40,
  },
  innerCircle: {
    borderRadius: "50%",
    border: ({ color }) => `2px solid ${theme.palette[color].main}`,
    width: 32,
    height: 32,
    lineHeight: "32px",
  },
}))

const RankingNumber = ({ value, color = "primary" }) => {
  const classes = useStyles({ color })

  return (
    <FlexBox alignItems="center" justifyContent="center" width={30} height={30} className={classes.root}>
      <FlexBox className={classes.innerCircle} alignItems="center" justifyContent="center">
        {value}
      </FlexBox>
    </FlexBox>
  )
}
export { RankingNumber }
