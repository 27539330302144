import React from "react"
import { useTheme } from "@material-ui/styles"
import { Box, Button, Link, Collapse, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { ColumnBox } from "../Boxes"
import { FieldSectionHeading } from "../Headings"
import { IconMessage } from "../Lists"
import { useJobUtils } from "../../utils"
import { DownloadExportJobButton } from "../Buttons"
import { ReadOnlyOutlinedInput } from "../TextField"

const useStyles = makeStyles((theme) => ({
  actions: {
    maxWidth: 500,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  link: {
    backgroundColor: theme.palette.background.default,
  },
}))

const AuditSubmittedScreen = ({ show, job, onClose, onCloseMessage, boxProps = {} }) => {
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { day } = useParams()
  const { getFullNavigateToLink } = useJobUtils()

  const handleClose = () => {
    onClose && onClose()
  }

  const handleCloseMessage = () => {
    onCloseMessage && onCloseMessage()
  }

  return (
    <Collapse in={show}>
      <Box {...boxProps}>
        <IconMessage name="audit-submitted" maxWidth={400} />

        <ColumnBox alignItems="center" mt={1.5}>
          <FieldSectionHeading size="large">Audit submitted</FieldSectionHeading>
          <Box mb={3}>
            <Typography align="center">
              <strong>{job.displayName}</strong> has been submitted and we've notified any users with{" "}
              <Link href="https://operandio.com/redirect/triggers" target="_blank" rel="noopener" underline="none">
                notifications set up
              </Link>{" "}
              for this audit. You can copy a link to this audit on Operandio and download a PDF below.
            </Typography>
          </Box>

          <Box className={classes.actions}>
            <Box mb={2}>
              <ReadOnlyOutlinedInput
                label="Audit link"
                hasCopy
                className={classes.link}
                value={getFullNavigateToLink(job, day)}
                labelWidth={135}
                fullWidth
              />
            </Box>
            <DownloadExportJobButton job={job} variant="file" />
          </Box>
        </ColumnBox>

        <ColumnBox alignItems="center" mt={2} mb={2}>
          <Grid justifyContent="center" container spacing={xs ? 3 : 1}>
            <Grid item xs={6} sm="auto">
              <Button variant="contained" fullWidth={xs} onClick={handleClose}>
                Close
              </Button>
            </Grid>
            <Grid item xs={6} sm="auto">
              <Button variant="contained" fullWidth={xs} color="primary" onClick={handleCloseMessage}>
                View audit
              </Button>
            </Grid>
          </Grid>
        </ColumnBox>
      </Box>
    </Collapse>
  )
}

export { AuditSubmittedScreen }
