import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: theme.palette.success.main,
  },
}))

const ActiveChip = ({ className, label = "Active" }) => {
  const classes = useStyles()

  return <Chip label={label} classes={classes} className={className} size="small" />
}

export { ActiveChip }
