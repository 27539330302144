import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_ACTIONS_PROCESS_STEPS_DETAIL = gql`
  fragment ReportActionsProcessStepsDetailFields on ReportActionProcessStepDetail {
    processName
    processStepName
    actions {
      id
      title
      timeZone
      location
      locationName
      priority
      dueAt
      resolvedAt
      resolvedBy {
        id
        fullName
      }
      status
      statusAt
      statusBy {
        id
        fullName
      }
      note
      createdAt
      createdBy {
        id
        fullName
      }
    }
  }
`

const REPORT_ACTIONS_PROCESS_STEPS_DETAIL_QUERY = gql`
  query ReportActionsProcessStepsDetail($input: ReportActionsProcessStepsDetailQueryInput!) {
    report {
      actions {
        steps {
          detail(input: $input) {
            ...ReportActionsProcessStepsDetailFields
          }
        }
      }
    }
  }
  ${REPORT_ACTIONS_PROCESS_STEPS_DETAIL}
`

const useQueryReportActionsProcessStepsDetail = (options) =>
  useQuery(REPORT_ACTIONS_PROCESS_STEPS_DETAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportActionsProcessStepsDetail = (options) =>
  useLazyQuery(REPORT_ACTIONS_PROCESS_STEPS_DETAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportActionsProcessStepsDetail, useLazyQueryReportActionsProcessStepsDetail }
