import { gql, useMutation } from "@apollo/client"
import { SCHEDULE_TRIGGER_FIELDS } from "./useQueryTriggers"

const CREATE_SCHEDULE_TRIGGER = gql`
  mutation CreateScheduleTrigger($input: ScheduleTriggerInput!) {
    createScheduleTrigger(input: $input) {
      ...ScheduleTriggerFields
    }
  }
  ${SCHEDULE_TRIGGER_FIELDS}
`

const UPDATE_SCHEDULE_TRIGGER = gql`
  mutation UpdateScheduleTrigger($id: ID!, $input: ScheduleTriggerInput!) {
    scheduleTrigger(id: $id) {
      update(input: $input) {
        ...ScheduleTriggerFields
      }
    }
  }
  ${SCHEDULE_TRIGGER_FIELDS}
`

const useMutationCreateScheduleTrigger = (options) => useMutation(CREATE_SCHEDULE_TRIGGER, options)

const useMutationUpdateScheduleTrigger = (options) => useMutation(UPDATE_SCHEDULE_TRIGGER, options)

export { useMutationCreateScheduleTrigger, useMutationUpdateScheduleTrigger }
