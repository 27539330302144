import React from "react"
import { PaperBox } from "../Boxes"
import { StatisticDisplay } from "../Charts/StatisticDisplay"
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"
import { useQueryReportLocationsTrainingChartData } from "../../data"

const TrainedStatistic = ({ gt, lt, locations, groups, tags, link }) => {
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { reportData, loading } = useQueryReportLocationsTrainingChartData({
    variables: {
      gt,
      lt,
      locations: locations.includes("all") ? mapToIds(userLocations) : locations,
      groups,
      tags,
    },
    skip: !gt || !lt || !locations || !groups || !tags,
  })

  return (
    <PaperBox>
      <StatisticDisplay
        icon="people-dark"
        label="Trained staff"
        breakdown={`Across all ${reportData?.userCount?.toLocaleString() || 0} staff`}
        value={`${Math.round(reportData?.percentComplete || 0)}%`}
        link={link}
        loading={loading}
      />
    </PaperBox>
  )
}

export { TrainedStatistic }
