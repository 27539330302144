import React from "react"
import { Box, Badge, makeStyles, Divider } from "@material-ui/core"
import moment from "moment"
import Config from "react-global-configuration"
import { RowBox, ActionCard, Icon, NumberDisplay, TimeAgo } from ".."
import { SENSOR_SAMPLE_TYPE } from "../../data"

const useStyles = makeStyles(() => ({
  humidity: {
    fontWeight: "normal",
  },
  scale: {
    opacity: 0.3,
    fontSize: 18,
  },
  at: {},
  indicators: {
    color: "#000000",
    opacity: 0.87,
  },
  warning: {
    color: "red",
    fontWeight: "600",
  },
}))

export const sentryIgnoreBatteryProducts = ["Guardian Sensor", "Guardian Sensor FMU130"]

const SensorIndicator = ({ value, unit = "%", iconBase = "sensors", iconStep = 10, ml = 0, ...rest }) => (
  <Box ml={ml} title={`${value}${unit}`} justifyContent="center" {...rest}>
    <Icon name={`${iconBase}${Math.floor(value / iconStep) * iconStep}`} />
    <Box>
      <small>{value}%</small>
    </Box>
  </Box>
)

const SensorLocationIndicator = ({ ...props }) => (
  <Box {...props} title="Last location reported">
    <Icon name="location-pin" />
  </Box>
)

const SensorActionCard = ({ index, sensor, loading, onClick, selected, ...rest }) => {
  const classes = useStyles()
  const config = Config.get("sensors")
  const { name, serial, product, sample } = sensor

  if (!sample) return null
  const { value, battery, signal, at, lbs } = sample

  const humidity = sensor.samples.find((item) => item.type === SENSOR_SAMPLE_TYPE.HUMIDITY)

  const handleSensorClick = (event) => {
    onClick && onClick(event)
  }

  const atWarning = moment().diff(moment(at), "minutes") > config.at.warnAgoMaxMin
  const hasBattery = !sentryIgnoreBatteryProducts.includes(product)
  const batteryWarning = battery < config.battery.warnLowPercent && hasBattery
  const hasWarning = (atWarning || batteryWarning) && !loading
  const hasLocation = Boolean(lbs?.length)

  return (
    <ActionCard
      title={name}
      detail={
        <RowBox>
          {product}{" "}
          <Box ml="auto" title={serial}>
            <strong>#</strong>
          </Box>
        </RowBox>
      }
      selected={selected}
      overdue={hasWarning && !selected}
      cardProps={{ onClick: handleSensorClick }}
      {...rest}
    >
      <Box style={{ marginLeft: -18, marginRight: -18 }} my={2}>
        <Divider />
      </Box>
      <RowBox alignItems="flex-start">
        <NumberDisplay
          alignItems="flex-start"
          justifyContent="flex-end"
          value={
            <RowBox>
              {value}
              <small className={classes.scale}>{"\u2103"}</small>
              {humidity && (
                <RowBox ml={1.5} className={classes.humidity}>
                  {humidity.value}
                  <small className={classes.scale}>%RH</small>
                </RowBox>
              )}
            </RowBox>
          }
          subject={
            <RowBox ml={0.5}>
              <Badge variant="dot" color={atWarning ? "error" : "primary"} overlap="circular" />
              <Box ml={1.25} className={`${classes.at} ${atWarning ? classes.warning : ""}`}>
                <small>
                  <TimeAgo date={at} />
                </small>
              </Box>
            </RowBox>
          }
        />
        {hasLocation && <SensorLocationIndicator ml="auto" className={classes.indicators} />}
        <SensorIndicator
          value={signal}
          iconBase="signal"
          iconStep={10}
          ml={hasLocation ? 1 : "auto"}
          className={classes.indicators}
        />
        {hasBattery && (
          <SensorIndicator
            value={battery}
            iconBase="battery"
            iconStep={5}
            ml={1}
            className={`${classes.indicators} ${batteryWarning ? classes.warning : ""}`}
          />
        )}
      </RowBox>
    </ActionCard>
  )
}

export { SensorActionCard, SensorIndicator }
