import { Box, Collapse, IconButton, ListItemSecondaryAction, ListItemText } from "@material-ui/core"
import { KeyboardArrowDown } from "@material-ui/icons"
import { useState } from "react"
import { ChipMenuItem } from "../ChipMenu/ChipMenuItem"
import { useMountEffect } from "../../utils"

const FiltersChipMenuItem = ({ menuItemRef, name, description, onExpanded, children }) => {
  const [expanded, setExpanded] = useState(false)

  useMountEffect(() => {
    if (!menuItemRef) {
      return
    }

    menuItemRef.current = {
      open: () => setExpanded(true),
      close: () => setExpanded(false),
    }
  })

  const handleClick = () => {
    const newOpen = !expanded
    setExpanded(newOpen)
    if (newOpen) {
      onExpanded && onExpanded()
    }
  }

  const hasChildren = Boolean(children)

  return (
    <>
      <ChipMenuItem onClick={handleClick}>
        <ListItemText primary={name} secondary={description} />
        {hasChildren && (
          <ListItemSecondaryAction>
            <IconButton size="small" onClick={handleClick}>
              <KeyboardArrowDown style={{ transform: expanded ? "rotate(180deg)" : undefined }} />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ChipMenuItem>
      {hasChildren && (
        <Collapse in={expanded}>
          <Box mb={1}>{children}</Box>
        </Collapse>
      )}
    </>
  )
}

export { FiltersChipMenuItem }
