import React, { useState, useEffect } from "react"
import { TextField, Box, Divider } from "@material-ui/core"
import { useApolloClient } from "@apollo/client"
import { useFormUtils } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import { useAuth } from "../../services"
import { useSnackbar } from "../SnackbarProvider"
import { CreateLocationBillingNoticeBlockquote } from "../Blockquotes/CreateLocationBillingNoticeBlockquote"
import { useMutationCreateRegion } from "../../data/locations/useMutationCreateRegion"
import { useMutationUpdateRegion } from "../../data/locations/useMutationUpdateRegion"

const RegionCreator = ({ open, onClose, edit, isInline, cy = "RegionCreator" }) => {
  const { isValid } = useFormUtils()
  const client = useApolloClient()
  const { refresh } = useAuth(client)
  const snackbar = useSnackbar()
  const [create] = useMutationCreateRegion()
  const [update] = useMutationUpdateRegion()

  const [name, setName] = useState("")

  const isEdit = edit != null

  useEffect(() => {
    if (edit) {
      setName(edit.name)
    }
  }, [edit, setName])

  const handleClose = () => {
    handleResetState()
    if (onClose) onClose(true)
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleSubmit = async (event) => {
    if (formValid()) {
      try {
        const input = { name }
        if (isEdit) {
          await update({ variables: { id: edit.id, input } })
        } else {
          await create({ variables: { input } })
        }
        refresh(client, false)
        handleClose(event)
      } catch (error) {
        snackbar.showMessage({ message: error.message })
      }
    }
  }

  const handleResetState = () => {
    setName("")
  }

  const formValid = () => isValid(name)

  const valid = formValid()

  const form = (
    <>
      {!edit && <CreateLocationBillingNoticeBlockquote />}

      <Box mb={3}>
        <Box flexGrow={1} mb={2}>
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            label="Region name"
            name="name"
            value={name}
            onChange={handleNameChange}
            autoFocus
            required
            inputProps={{ "data-cy": `${cy}-TextField-name` }}
          />
        </Box>
      </Box>

      <Box mb={1}>
        <Divider />
      </Box>

      <CreatorActions
        id={`${cy}-CreatorActions`}
        subject="Region"
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!valid}
      />
    </>
  )

  return (
    <CreatorMaster open={open} subject="Region" form={form} isEdit={isEdit} isInline={isInline} onClose={handleClose} />
  )
}

export default RegionCreator
