import React, { useState } from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Visibility } from "@material-ui/icons"
import { DisplayImage } from "../../ImagesViewer/DisplayImage"
import { LinkButton } from "../../LinkButton"

const imageMaxHeight = 800
const imageWidth = 924

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    height: ({ expanded, dimensions }) =>
      expanded || (dimensions && dimensions.height <= imageMaxHeight) ? "auto" : imageMaxHeight,
    overflow: ({ expanded }) => (expanded ? "visible" : "hidden"),
  },
  containerSmall: {
    textAlign: "center",
    backgroundColor: theme.palette.background.shaded,
  },
  image: {
    borderRadius: 4,
    maxWidth: "100%",
  },
  expand: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: theme.palette.background.shaded,
    fontWeight: "normal",
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
  },
}))

const KnowledgeArticleContentImage = ({ upload, onImageClick }) => {
  const [dimensions, setDimensions] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles({ expanded, dimensions })

  const handleImageLoad = ({ target: img }) => {
    setDimensions({
      height: img.offsetHeight,
      width: img.offsetWidth,
    })
  }

  const handleImageClick = () => {
    onImageClick && onImageClick(upload)
  }

  const handleExpand = () => {
    setExpanded(true)
  }

  const small = dimensions && dimensions.width < imageWidth
  const tall = dimensions && dimensions.height > imageMaxHeight

  return (
    <>
      <Box
        key={upload.id}
        className={small ? classes.containerSmall : classes.container}
        py={small ? 2 : 0}
        onClick={() => handleImageClick()}
      >
        <DisplayImage
          upload={upload}
          width={imageWidth}
          fit="contain"
          enlarge={false}
          alt={upload.fileName}
          className={classes.image}
          onLoad={(event) => handleImageLoad(event, upload)}
        />
      </Box>
      {tall && !expanded && (
        <LinkButton icon="view" onClick={handleExpand} className={classes.expand}>
          <Visibility />
          <Box ml={1}>View full image</Box>
        </LinkButton>
      )}
    </>
  )
}

export { KnowledgeArticleContentImage }
