import React from "react"
import { Box, Tooltip, makeStyles } from "@material-ui/core"
import { PaperBox, FieldSectionHeading, Caption, FormatDateCompact } from ".."

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "Transparent",
    maxWidth: "none",
    fontSize: 12,
  },
}))

const UserAssessmentTooltip = ({ user, module, userTrainingAssessment, children }) => {
  const classes = useStyles()
  const { trainedAt, trainedBy } = userTrainingAssessment || {}

  return (
    <Tooltip
      title={
        <PaperBox p={2}>
          <FieldSectionHeading mb={0}>{user.fullName}</FieldSectionHeading>
          <Caption mb={0}>{module.name}</Caption>
          {(trainedAt || trainedBy) && (
            <Box mt={1}>
              <>Trained </>
              {Boolean(trainedAt) && <FormatDateCompact value={trainedAt} />}
              {Boolean(trainedAt && trainedBy) && <> by </>}
              {Boolean(trainedBy) && trainedBy.fullName}
            </Box>
          )}
        </PaperBox>
      }
      classes={{ tooltip: classes.tooltip }}
    >
      <div>{children}</div>
    </Tooltip>
  )
}

export { UserAssessmentTooltip }
