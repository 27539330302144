import React from "react"
import { UsersGroupsAssigner } from ".."
import { useMutationUpdateJob } from "../../data/jobs/useMutationUpdateJob"
import { toId } from "../../utils"

const ProcessAssignees = ({ job: { id, users, groups, location }, disabled }) => {
  const [updateJob] = useMutationUpdateJob(id)

  const handleUsersGroupsPickedChanged = (picked) => {
    const hasEveryone = picked.find((p) => p.__typename === "Everyone")
    updateJob({
      variables: {
        id,
        users: hasEveryone ? [] : picked.filter((p) => p.__typename === "User").map((p) => p.id),
        groups: hasEveryone ? [] : picked.filter((p) => p.__typename === "Group").map((p) => p.id),
      },
    })
  }

  return (
    <UsersGroupsAssigner
      title="Assignees"
      placeholder="All staff"
      allowSelf
      initialPicked={[...users, ...groups]}
      onPickedChanged={handleUsersGroupsPickedChanged}
      locations={[toId(location)]}
      disabled={disabled}
    />
  )
}

export { ProcessAssignees }
