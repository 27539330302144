import React from "react"
import { Box } from "@material-ui/core"
import { useMountAwareReactiveVar } from "../../utils"
import { hubFiltersVar } from "../../data/hubFiltersVar"

const filterAll = (item) => item !== "all"

const HubFiltersCountBox = (props) => {
  const { groups, locations, tags } = useMountAwareReactiveVar(hubFiltersVar)

  const count = groups.filter(filterAll).length + locations.filter(filterAll).length + tags.length

  if (!count) {
    return null
  }

  return <Box {...props}>({count})</Box>
}

export { HubFiltersCountBox }
