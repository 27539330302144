import React from "react"
import { Content, AreaHeader, NotificationList } from "../../components"
import { useQueryNotifications } from "../../data"

const Notifications = () => {
  const result = useQueryNotifications()

  return (
    <>
      <AreaHeader title="Notifications" titleIcon="notifications" />

      <Content full start>
        <NotificationList {...result} limit={null} spinnerSize={60} />
      </Content>
    </>
  )
}

export default Notifications
