import { useMutation, gql } from "@apollo/client"
import { REGION_FIELDS } from "./useQueryRegions"

const CREATE_REGION_MUTATION = gql`
  mutation CreateRegion($input: RegionCreateMutationInput!) {
    createRegion(input: $input) {
      ...RegionFields
    }
  }
  ${REGION_FIELDS}
`

const useMutationCreateRegion = () => useMutation(CREATE_REGION_MUTATION)

export { useMutationCreateRegion }
