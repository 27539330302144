import React, { useEffect, useMemo } from "react"
import { Divider, ListItemIcon, makeStyles, MenuItem } from "@material-ui/core"
import { GROUP_VISIBILITY, useQueryGroups } from "../../data"
import { toId } from "../../utils"
import { OutlinedSelect } from "./OutlinedSelect"
import { useAuth } from "../../services"
import { Icon } from ".."

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 125,
  },
}))

const GroupOutlinedSelect = ({
  onDataLoaded,
  allLabel = "All areas of work",
  hasAll = true,
  usersGroupsOnly = false,
  disabled: disabledInput = false,
  multiple = false,
  ...rest
}) => {
  const { data, loading } = useQueryGroups()
  const {
    principal: { groups: usersGroups },
  } = useAuth()
  const classes = useStyles()

  useEffect(() => {
    if (onDataLoaded && data) onDataLoaded(data)
  }, [data, onDataLoaded])

  const groups = useMemo(
    () => data?.groups.filter((group) => !usersGroupsOnly || usersGroups.includes(toId(group))) || [],
    [data, usersGroupsOnly, usersGroups]
  )

  const disabled = disabledInput || loading || !data?.groups.length

  return (
    <OutlinedSelect
      label="Areas of work"
      native={false}
      value="all"
      classes={classes}
      disabled={disabled}
      multiple={multiple}
      selectProps={{
        renderValue: (option) => {
          if (multiple) {
            return option
              .map((item) => {
                if (item === "all") {
                  return allLabel
                }

                return groups.find((group) => group.id === item)?.name || item
              })
              .join(", ")
          }

          if (option === "all") {
            return allLabel
          }

          const selected = groups.find((item) => (option.value ? item.id === option.value : item.id === option))
          return selected?.name || option
        },
      }}
      {...rest}
    >
      {hasAll && <MenuItem value="all">{allLabel}</MenuItem>}
      {hasAll && <Divider />}
      {groups.map((group) => (
        <MenuItem key={toId(group)} value={toId(group)}>
          {group.visibility === GROUP_VISIBILITY.PRIVATE && (
            <ListItemIcon>
              <Icon name="group-visibility-private" />
            </ListItemIcon>
          )}
          {group.name}
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { GroupOutlinedSelect }
