import React, { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { Box, makeStyles, Input } from "@material-ui/core"
import { CloudUpload } from "@material-ui/icons"
import Config from "react-global-configuration"
import Axios from "axios"
import { useMutationRegisterUpload } from "../../data"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: `2px dashed ${theme.palette.shaded.border}`,
    borderRadius: 4,
    backgroundColor: theme.palette.background.shaded,
    padding: theme.spacing(4),
    outline: "none",
    cursor: "pointer",
  },
}))

const UploaderDropZone = ({
  onBeforeUpload,
  onProgress,
  onItemUploaded,
  onAfterUpload,
  onError,
  documents = true,
  images = true,
  prompt = `Drag & drop ${documents ? "files" : "images"} here or click to select`,
  className = "",
}) => {
  const classes = useStyles()
  const [registerUpload] = useMutationRegisterUpload()
  const [uploading, setUploading] = useState(false)
  const { acceptImages, acceptDocuments } = Config.get("uploader")

  const uploadsToProgress = (uploads) => uploads.filter((u) => u).map((u) => ({ ...u.upload, order: u.order }))

  const onDrop = useCallback(
    async (files) => {
      if (files && files.length > 0) {
        try {
          setUploading(true)
          const uploads = [
            ...files.map((file, index) => ({
              fileName: file.name,
              fileGroup: "upload",
              loaded: 0,
              total: file.size,
              order: index,
            })),
          ]
          onBeforeUpload([...uploads])

          await Promise.all(
            files.map(async (file, index) => {
              const { data } = await registerUpload({ variables: { fileName: file.name, fileSize: file.size } })
              if (!data) {
                throw Error("Request for upload failed")
              } else {
                uploads[index] = { ...data.registerUpload, order: uploads[index].order }
                onProgress(uploadsToProgress(uploads))
              }
            })
          )

          await Promise.all(
            uploads.map(async (upload, index) => {
              try {
                await Axios.put(upload.signedUploadUrl, files[index], {
                  headers: {
                    "Content-Type": upload.upload.contentType,
                  },
                  onUploadProgress: ({ loaded, total }) => {
                    upload.upload.loaded = loaded
                    upload.upload.total = total
                    if (onProgress) onProgress(uploadsToProgress(uploads))
                  },
                })
                if (onItemUploaded) onItemUploaded({ ...upload.upload, order: upload.order })
              } catch (errUpload) {
                console.log(errUpload)
                throw errUpload
              }
            })
          )

          if (onAfterUpload) onAfterUpload([...uploads])
        } catch (err) {
          if (onError) onError(err)
        } finally {
          setUploading(false)
        }
      }
    },
    [onAfterUpload, onBeforeUpload, onError, onItemUploaded, onProgress, registerUpload]
  )

  const accept = []
  if (images) accept.push(acceptImages)
  if (documents) accept.push(acceptDocuments)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: accept.join(","), onDrop })

  return (
    <Box
      className={[classes.root, className].join(" ")}
      display="flex"
      flexDirection="column"
      alignItems="center"
      {...getRootProps()}
    >
      <Box>{isDragActive ? <>Drop the files here...</> : <>{prompt}</>}</Box>
      <CloudUpload />

      <Input
        type="file"
        className={classes.fileInput}
        inputProps={{
          multiple: true,
          ...getInputProps(),
          disabled: uploading,
        }}
      />
    </Box>
  )
}

export { UploaderDropZone }
