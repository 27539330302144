import React, { useState, useEffect } from "react"
import { TextField, Box, Typography } from "@material-ui/core"
import { GROUP_VISIBILITY, useMutationCreateGroup, useMutationUpdateGroup } from "../../data"
import { useFormUtils } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import { ColumnBox } from "../Boxes"
import Checkbox from "../Checkbox"
import { useLazyQueryAdminableGroup } from "../../data/groups/useQueryAdminableGroup"
import { useAuth } from "../../services"

const initialState = {
  visibility: GROUP_VISIBILITY.GENERAL,
  name: "",
  description: "",
  hideFromKiosk: false,
}

const GroupCreator = ({ open, onClose, id, isInline, cy = "GroupCreator" }) => {
  const { isValid } = useFormUtils()
  const { hasPermission } = useAuth()
  const [load, { data, loading }] = useLazyQueryAdminableGroup()
  const [createGroup, { loading: createGroupLoading }] = useMutationCreateGroup()
  const [updateGroup, { loading: updateGroupLoading }] = useMutationUpdateGroup()

  const [visibility, setVisibility] = useState(initialState.visibility)
  const [name, setName] = useState(initialState.name)
  const [description, setDescription] = useState(initialState.description)
  const [hideFromKiosk, setHiddenFromKiosk] = useState(initialState.hideFromKiosk)

  const isEdit = id && id !== "new"

  useEffect(() => {
    if (id && id !== "new") {
      load({ variables: { id } })
    }
  }, [id, load])

  useEffect(() => {
    if (data) {
      const { group: edit } = data

      setVisibility(edit.visibility)
      setName(edit.name)
      setDescription(edit.description)
      setHiddenFromKiosk(edit.hideFromKiosk)
    }
  }, [id, data])

  const handleCancel = () => {
    handleClose(false)
  }

  const handleClose = (updated) => {
    handleResetState()
    if (onClose) onClose(updated)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (formValid()) {
      if (isEdit) {
        await updateGroup({ variables: { id, input: { visibility, name, description, hideFromKiosk } } })
      } else {
        await createGroup({ variables: { input: { visibility, name, description, hideFromKiosk } } })
      }
      handleClose(true)
    }
  }

  const handleChangeAccessType = (event) => {
    setVisibility(event.target.checked ? GROUP_VISIBILITY.PRIVATE : GROUP_VISIBILITY.GENERAL)
  }

  const handleResetState = () => {
    setName(initialState.name)
    setDescription(initialState.description)
  }

  const formValid = () => isValid(name)

  const isFormValid = formValid()

  const submitLoading = createGroupLoading || updateGroupLoading

  const hasUpdateAllPermission = hasPermission("group_update_all")

  const form = (
    <>
      <Box mb={1}>
        <TextField
          variant="outlined"
          fullWidth
          id="name"
          label="Name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          inputProps={{ "data-cy": `${cy}-TextField-name` }}
        />
      </Box>

      {hasUpdateAllPermission && (
        <>
          <ColumnBox pt={1} pb={2}>
            <Checkbox
              type="label"
              color="primary"
              label="Make private"
              checked={visibility === GROUP_VISIBILITY.PRIVATE}
              onChange={handleChangeAccessType}
            />
            <Typography variant="caption">
              Only existing members or full administrators will be able to add other users to this area of work.
            </Typography>
          </ColumnBox>

          <ColumnBox pt={1} pb={2}>
            <Checkbox
              type="label"
              color="primary"
              label="Hide from kiosk devices"
              checked={hideFromKiosk}
              onChange={() => setHiddenFromKiosk(!hideFromKiosk)}
            />
            <Typography variant="caption">
              Members of this area of work will not show on the login screen of Operandio kiosk devices.
            </Typography>
          </ColumnBox>
        </>
      )}

      <CreatorActions
        id="GroupCreator-CreatorActions"
        subject="Area of Work"
        submitLoading={submitLoading}
        onClose={handleCancel}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid}
      />
    </>
  )

  return (
    <CreatorMaster
      open={open}
      loading={loading}
      subject="Area of Work"
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleCancel}
    />
  )
}

export { GroupCreator }
