import React, { useState } from "react"
import { useTheme } from "@material-ui/styles"
import { Box, useMediaQuery } from "@material-ui/core"
import { useHistory, useParams, Redirect } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import pluralize from "pluralize"
import { ProcessTemplateActionCard, LoadingSpinner, NoItemsMessage, SearchInput, ScrollToTop } from "../../components"
import { TemplateViewer } from "../../components/JobViewer/TemplateViewer"
import { useQueryTemplateCategories, useMutationCopyProcess, useQueryLibraryTemplates } from "../../data"
import { toId } from "../../utils"
import { useAuth } from "../../services"

const Library = () => {
  const theme = useTheme()
  const displayInline = useMediaQuery(theme.breakpoints.down("xs"))
  const { set, id, action, category } = useParams()
  const history = useHistory()
  const [searchText, setSearchText] = useState(null)
  const { data: templateCategoriesData } = useQueryTemplateCategories()
  const {
    data: libraryData,
    loading: libraryLoading,
    loadMore,
  } = useQueryLibraryTemplates({
    variables: {
      filter: { category, searchText },
    },
  })
  const [copyProcess] = useMutationCopyProcess()
  const { hasFeature } = useAuth()

  const handleCopy = async (process) => {
    const item = await copyProcess({ variables: { id: toId(process) } })
    const inactiveSet = set.startsWith("active") ? `in${set}` : set
    if (item.data) history.push(`/templates/${inactiveSet}/${item.data.copyProcess.id}/edit`)
  }

  const handleUsed = async (item) => {
    const inactiveSet = !set ? "inactive-oneoff" : set.startsWith("active") ? `in${set}` : set
    history.push(`/templates/${inactiveSet}/${item.id}/edit`)
  }

  const handleClose = async () => {
    history.push(`/templates/library/${category}`)
  }

  const handleChangeSearchText = (text) => {
    setSearchText(text || null)
  }

  const handleNavigateCategory = (navigateCategory) => {
    history.push(`/templates/library/${navigateCategory}`)
  }

  let heading = "Category"
  if (category && templateCategoriesData) {
    const templateCategory = templateCategoriesData.templateCategories.find((item) => item.id === category)
    if (templateCategory) heading = templateCategory.name
  }

  const scrollToTop = displayInline

  const loading = libraryLoading

  const data = libraryData?.libraryTemplates.list || []
  const count = libraryData?.libraryTemplates.count

  if (!hasFeature("process_library")) {
    return <Redirect to="/templates/active" />
  }

  return (
    <>
      {scrollToTop && <ScrollToTop />}

      <TemplateViewer
        process={action === "view" && id ? id : null}
        onCategoryClick={handleNavigateCategory}
        onUsed={handleUsed}
        onClose={handleClose}
      />

      {(!displayInline || (displayInline && !action)) && (
        <>
          <SearchInput
            placeholder={`Search ${heading.toLowerCase()}`}
            debounce={300}
            onChange={handleChangeSearchText}
            loading={loading}
            boxProps={{ pl: 0, pr: 0, mb: displayInline ? 2 : 1 }}
          />

          {loading && !libraryData && !data.length && (
            <Box display="flex" justifyContent="center">
              <LoadingSpinner size={60} />
            </Box>
          )}
          <InfiniteScroll
            dataLength={data.length}
            next={loadMore}
            hasMore={count > data.length}
            loader={
              <Box display="flex" justifyContent="center">
                <LoadingSpinner size={60} />
              </Box>
            }
            endMessage={
              !loading &&
              count > 0 && (
                <NoItemsMessage>
                  {count || "No"} matching {pluralize("item", data.length)}
                </NoItemsMessage>
              )
            }
            style={{ overflow: "visible" }}
          >
            <Box data-cy="Box-templates">
              {data.map((template) => (
                <Box key={toId(template)}>
                  <ProcessTemplateActionCard
                    process={template}
                    set={set || "library"}
                    category={category}
                    onCopy={handleCopy}
                    onCategoryClick={handleNavigateCategory}
                  />
                </Box>
              ))}
            </Box>
          </InfiniteScroll>
          {!loading && !data.length && <NoItemsMessage>No items match your search</NoItemsMessage>}
        </>
      )}
    </>
  )
}

export default Library
