import { useQuery, gql } from "@apollo/client"

const sharedTextFields = `
  fontHeight
  align
  upperCase
  textWrap
`

// TODO Need to add fields into query
const LABEL_TEMPLATE_FIELDS = `
    id
    title
    width
    height
    padding
    numberOfLabels
    items { count }
    fields { 
      id 
      key 
      x 
      y 
      width 
      height 
      content {
        ... on LabelTemplateNameField { 
          type
          ${sharedTextFields}
        } 
        ... on LabelTemplateUserField {
          type
          ${sharedTextFields}
          userFormat
        }
        ... on LabelTemplateLocationField {
          type
          ${sharedTextFields}
        }
        ... on LabelTemplateCurrentDatetimeField {
          type
          ${sharedTextFields}
          dateFormat
        }
        ... on LabelTemplatePrepDatetimeField {
          type
          ${sharedTextFields}
          dateFormat
        }
        ... on LabelTemplateReadyDatetimeField {
          type
          ${sharedTextFields}
          dateFormat
        }
        ... on LabelTemplateDiscardDatetimeField {
          type
          ${sharedTextFields}
          dateFormat
        }
        ... on LabelTemplateDynamicTextOncreateField {
          type
          ${sharedTextFields}
          dynamicName
          dynamicText
        }
        ... on LabelTemplateDynamicTextOnprintField {
          type
          ${sharedTextFields}
          dynamicName
          dynamicText
        }
        ... on LabelTemplateStaticTextField {
          type
          ${sharedTextFields}
          staticText
        }
      }
    }
    locations { id }
    author { id }
    createdAt
    updatedAt
`

const LABEL_TEMPLATES_QUERY = gql`
  query LabelTemplates($filter: LabelTemplatesFilterInput) {
    labelTemplates(filter: $filter) {
      ${LABEL_TEMPLATE_FIELDS}
    }
  }
`

const LABEL_TEMPLATE_QUERY = gql`
  query LabelTemplate($id: ID!) {
    labelTemplate(id: $id) {
      ${LABEL_TEMPLATE_FIELDS}
    }
  }
`

const useQueryLabelTemplates = (options) => {
  const result = useQuery(LABEL_TEMPLATES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
  }
}

const useQueryLabelTemplate = (options) => {
  const result = useQuery(LABEL_TEMPLATE_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
  }
}

export { useQueryLabelTemplates, useQueryLabelTemplate, LABEL_TEMPLATE_FIELDS, LABEL_TEMPLATES_QUERY }
