import { Box, Typography } from "@material-ui/core"
import { RowBox } from "../../Boxes"
import { FormatDateTimeCompact } from "../../Format"
import { CommonChip } from "../../Chips/CommonChip"

const CompletionCellContent = ({ job }) => {
  if (job.hasSubmit && !job.status.submitted && job.isOverdue) {
    return (
      <RowBox>
        <Box mr={1}>
          <Typography color="error" element="span" variant="body2" noWrap>
            Not submitted
          </Typography>
        </Box>
        <CommonChip label="Overdue" color="error" size="small" />
      </RowBox>
    )
  }

  if (job.hasSubmit && !job.status.submitted) {
    return (
      <RowBox>
        <Box mr={1}>Not submitted</Box>
        <CommonChip label="Todo" size="small" />
      </RowBox>
    )
  }

  if (job.hasSubmit && job.status.submitted && job.isOverdue) {
    return (
      <RowBox>
        <Box mr={1}>
          <FormatDateTimeCompact value={job.status.submittedAt} />
        </Box>
        <CommonChip label="Late" color="warning" size="small" />
      </RowBox>
    )
  }

  if (job.hasSubmit && job.status.submitted) {
    return (
      <RowBox>
        <Box mr={1}>
          <FormatDateTimeCompact value={job.status.submittedAt} />
        </Box>
        <CommonChip label="On time" color="success" size="small" />
      </RowBox>
    )
  }

  if (!job.hasSubmit && !job.status.completed && job.isOverdue) {
    return (
      <RowBox>
        <Box mr={1}>
          <Typography color="error" element="span" variant="body2" noWrap>
            Not completed
          </Typography>
        </Box>
        <CommonChip label="Overdue" color="error" size="small" />
      </RowBox>
    )
  }

  if (!job.hasSubmit && !job.status.completed) {
    return (
      <RowBox>
        <Box mr={1}>Not completed</Box>
        <CommonChip label="Todo" size="small" />
      </RowBox>
    )
  }

  if (!job.hasSubmit && job.status.completed && job.isOverdue) {
    return (
      <RowBox>
        <Box mr={1}>
          <FormatDateTimeCompact value={job.status.completedAt} />
        </Box>
        <CommonChip label="Late" color="warning" size="small" />
      </RowBox>
    )
  }

  if (!job.hasSubmit && job.status.completed) {
    return (
      <RowBox>
        <Box mr={1}>
          <FormatDateTimeCompact value={job.status.completedAt} />
        </Box>
        <CommonChip label="On time" color="success" size="small" />
      </RowBox>
    )
  }

  return null
}

export { CompletionCellContent }
