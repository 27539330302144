import { makeVar } from "@apollo/client"

const makeUserStateVar = () => ({
  usernameChange: {},
  openEmailChangeDialog: false,
  isPendingUsernameChange: false,
})

const userStateVar = makeVar({
  ...makeUserStateVar(),
})

const resetUserStateVar = () => {
  userStateVar({ ...makeUserStateVar() })
}

export { userStateVar, resetUserStateVar }
