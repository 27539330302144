import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, Grid, MenuItem, Popover } from "@material-ui/core"
import { FilterList } from "@material-ui/icons"
import PopupState, { anchorRef, bindMenu, bindTrigger } from "material-ui-popup-state"
import { OutlinedSelect, FiltersChips, ScheduleOutlinedSelect, LocationOutlinedSelect } from "../../components"
import { PROCESS_TYPE, useQueryGroups, useQueryLocations } from "../../data"
import { useQueryTags } from "../../data/tags/useQueryTags"

const useFiltersPanelStyles = makeStyles((theme) => ({
  fullName: {
    fontSize: 16,
    color: theme.palette.text.primary,
    lineHeight: "20px",
  },
  roles: {
    color: theme.palette.text.secondary,
  },
  filter: {
    backgroundColor: theme.palette.shaded.filter,
  },
  menu: {
    marginTop: 5,
  },
  clearAllLink: {
    whiteSpace: "nowrap",
  },
  infiniteScrollWrapper: {
    "&&>div": {
      display: "contents",
    },
  },
  infiniteScroll: {
    display: "contents",
  },
}))

const FiltersPanel = ({ filters, hasSchedules, hasAreas, onChange, onClear, onDelete }) => {
  const classes = useFiltersPanelStyles()
  const { data: groupsData } = useQueryGroups()
  const { data: locationsData } = useQueryLocations()
  const { data: tagsData } = useQueryTags()

  const data = {
    groupsData,
    locationsData,
    tagsData,
  }

  return (
    <Box display="flex" flexDirection="row">
      <Box px={3} mb={3} display="flex" flexGrow={1} className={classes.filter}>
        <Box py={3} pr={2}>
          <FiltersButton
            data={data}
            filters={filters}
            hasSchedules={hasSchedules}
            hasAreas={hasAreas}
            onFilterChange={onChange}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <FiltersChips data={data} filters={filters} hasSchedules={hasSchedules} onDelete={onDelete} />
        </Box>
        {filters.length > 0 && (
          <Box ml="auto" pl={2} display="flex" alignItems="center">
            <Button className={classes.clearAllLink} color="primary" onClick={onClear}>
              Clear all
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const useFiltersPopoverStyles = makeStyles((theme) => ({
  root: {
    width: 512,
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const FiltersButton = ({ data, filters, onFilterChange, hasSchedules, hasAreas }) => {
  const filtersPopoverClasses = useFiltersPopoverStyles()
  const { data: groupsData } = useQueryGroups()

  const handleFilterChange = (type, eventOrValues) => {
    let values = eventOrValues.target?.value || eventOrValues
    if (values.includes("all")) {
      values = []
    }
    if (onFilterChange) onFilterChange(type, values)
  }

  return (
    <PopupState variant="popover" popupId="bulk-actions">
      {(popupState) => (
        <>
          <Button variant="contained" color="primary" {...bindTrigger(popupState)} ref={anchorRef(popupState)}>
            <FilterList />
            <Box ml={1}>Filters</Box>
          </Button>
          <Popover
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={filtersPopoverClasses}
          >
            <Box p={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <OutlinedSelect
                    label="Areas of work"
                    native={false}
                    value={filters.filter((filter) => filter.type === "groups").map((filter) => filter.value)}
                    onChange={(event) => handleFilterChange("groups", event)}
                    multiple
                  >
                    {groupsData &&
                      groupsData.groups.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </OutlinedSelect>
                </Grid>
                {hasSchedules && (
                  <Grid item xs={6}>
                    <ScheduleOutlinedSelect
                      label="Schedules"
                      value={filters.filter((filter) => filter.type === "schedules").map((filter) => filter.value)}
                      multiple
                      hasAll={false}
                      hasNonRecurring={false}
                      onChange={(event) => handleFilterChange("schedules", event)}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <LocationOutlinedSelect
                    label="Locations"
                    value={filters.filter((filter) => filter.type === "locations").map((filter) => filter.value)}
                    onChange={(event) => handleFilterChange("locations", event)}
                    onRegionChange={(event) => handleFilterChange("locations", event)}
                    multiple
                  />
                </Grid>
                <Grid item xs={hasSchedules || hasAreas ? 6 : 12}>
                  <OutlinedSelect
                    label="Type"
                    native={false}
                    value={filters.filter((filter) => filter.type === "types").map((filter) => filter.value)}
                    onChange={(event) => handleFilterChange("types", event)}
                    multiple
                  >
                    <MenuItem value={PROCESS_TYPE.PROCESS}>Process</MenuItem>
                    <MenuItem value={PROCESS_TYPE.AUDIT}>Audit</MenuItem>
                  </OutlinedSelect>
                </Grid>
                {hasAreas && (
                  <Grid item xs={hasSchedules ? 12 : 6}>
                    <OutlinedSelect
                      label="Tags"
                      native={false}
                      value={filters.filter((filter) => filter.type === "tags").map((filter) => filter.value)}
                      onChange={(event) => handleFilterChange("tags", event)}
                      multiple
                    >
                      {data?.tagsData &&
                        data?.tagsData?.tags.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </OutlinedSelect>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  )
}

export { FiltersPanel }
