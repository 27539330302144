import React from "react"
import { PinDropOutlined } from "@material-ui/icons"
import { ListItemText, makeStyles } from "@material-ui/core"
import { Assigner } from "./Assigner"
import { useAuth } from "../../services"
import { CheckboxPickerListItem, RadioPickerListItem } from "../PickerListItems"
import { mapToIds, toId } from "../../utils"
import RegionPickerListItem from "./RegionPickerListItem"
import { TruncateNames } from "../DataDisplay/TruncateNames"

const LocationPickerListItem = ({ multiple, showTimeZone = true, checked, ...props }) =>
  multiple ? (
    <CheckboxPickerListItem
      checked={checked}
      {...props}
      cy={`LocationPickerListItem-${checked ? "checked" : "unchecked"}`}
    >
      <ListItemText primary={props.item.name} secondary={showTimeZone ? props.item.timeZone : ""} />
    </CheckboxPickerListItem>
  ) : (
    <RadioPickerListItem
      checked={checked}
      {...props}
      cy={`LocationPickerListItem-${checked ? "checked" : "unchecked"}`}
    >
      <ListItemText primary={props.item.name} secondary={showTimeZone ? props.item.timeZone : ""} />
    </RadioPickerListItem>
  )

const useStyles = makeStyles(() => ({
  dialogContent: {
    paddingTop: 0,
  },
}))

const LocationsAssigner = ({
  title = "Locations",
  initialPicked,
  onPickedChanged,
  bottomBorder = true,
  requireTimeZoneMatch = false,
  multiple = true,
  cy = "LocationsAssigner",
  ...rest
}) => {
  const classes = useStyles()
  const {
    settings: { locations },
    hasFeature,
  } = useAuth()

  const handlePickedChanged = ({ picked }) => {
    onPickedChanged(picked)
  }

  const isTimeZoneValid = (item, picked) =>
    !requireTimeZoneMatch || !picked.length || picked.every((n) => n.timeZone === item.timeZone)

  const handleRegionSelect = (region, picked, configOnPickedChanged, onShouldUpdateDialogState) => {
    const newPicked = [...locations.filter((location) => location.region && toId(location.region) === toId(region))]

    newPicked.push(...picked.filter((item) => !newPicked.find((n) => toId(n) === toId(item))))

    const pickedData = { picked: newPicked }
    configOnPickedChanged(pickedData)
    onShouldUpdateDialogState(pickedData)
  }

  const handleRegionClear = (region, picked, configOnPickedChanged, onShouldUpdateDialogState) => {
    const regionLocations = mapToIds(
      locations.filter((location) => location.region && toId(location.region) === toId(region))
    )
    const pickedData = { picked: picked.filter((item) => !regionLocations.includes(toId(item))) }

    configOnPickedChanged(pickedData)
    onShouldUpdateDialogState(pickedData)
  }

  const hasRegions = hasFeature("regions")

  const data = hasRegions ? [...locations.filter((location) => !location.region)] : locations

  if (hasRegions) {
    const regions = locations.reduce((acc, location) => {
      if (!location.region) {
        return acc
      }
      acc[toId(location.region)] = {
        region: location.region,
        locations: locations.filter((l) => l.region && toId(l.region) === toId(location.region)),
      }
      return acc
    }, {})

    for (const region of Object.values(regions).sort((a, b) => a.region.name.localeCompare(b.region.name))) {
      data.push(region.region)
      data.push(...region.locations)
    }
  }

  return (
    <Assigner
      {...{
        type: multiple ? "multiple" : "single",
        title,
        description: requireTimeZoneMatch ? "Timezones must match across all locations." : null,
        icon: <PinDropOutlined />,
        pickerConfig: {
          type: multiple ? "multiple" : "single",
          closeText: "Close",
          initialPicked: multiple ? [...initialPicked] : initialPicked,
          data,
          labelPropName: "name",
          // eslint-disable-next-line react/display-name
          getListItem: (
            item,
            index,
            checked,
            onChange,
            { picked, onPickedChanged: configOnPickedChanged, onShouldUpdateDialogState }
          ) =>
            item.__typename === "Region" ? (
              <RegionPickerListItem
                key={index}
                item={item}
                picked={picked}
                onPickedChanged={configOnPickedChanged}
                onShouldUpdateDialogState={onShouldUpdateDialogState}
                onRegionSelect={handleRegionSelect}
                onRegionClear={handleRegionClear}
                hasButtons={multiple}
              />
            ) : (
              <LocationPickerListItem
                key={index}
                color="primary"
                showTimeZone
                multiple={multiple}
                {...{ item, checked, onChange, disabled: !isTimeZoneValid(item, picked) }}
              />
            ),
          onPickedChanged: handlePickedChanged,
          mapPickedToText: ({ picked }) =>
            multiple ? <TruncateNames names={picked.map((p) => p.name)} max={20} /> : picked.name,
          hasClear: true,
          classes: {
            dialogContent: classes.dialogContent,
          },
        },
        bottomBorder,
        ...rest,
      }}
      cy={cy}
    />
  )
}

export { LocationsAssigner }
