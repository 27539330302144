import moment from "moment-timezone"

const hasTime = (date) => date && moment(date).format("HH:mm") !== "00:00"
const formatTime = (value, timeZone) => (timeZone ? moment(value).tz(timeZone) : moment(value)).format("h:mm A")
const formatShortDate = (value, timeZone) => (timeZone ? moment(value).tz(timeZone) : moment(value)).format("MMM D")
const formatShortTime = (value, timeZone) => (timeZone ? moment(value).tz(timeZone) : moment(value)).format("h:mmA")
const isBeforeToday = (value) => moment(value).isBefore(moment().startOf("day"))
const scheduleDateToMoment = (value) =>
  value && value.year
    ? moment(`${value.year}-${value.month}-${value.day} ${value.hour}:${value.minute}Z`, "YYYY-M-D H:m")
    : null
const scheduleDatesMatch = (date1, date2) =>
  ["year", "month", "day", "hour", "minute"].every((part) => date1[part] === date2[part])

const formatScheduleDate = (value) => (value ? scheduleDateToMoment(value).format("D MMM YY") : "")
const formatScheduleTime = (value) => (value ? scheduleDateToMoment(value).format("h:mm A") : "")
const momentToScheduleDate = (value) =>
  value
    ? {
        year: value.year(),
        month: value.month() + 1,
        day: value.date(),
        hour: value.hour(),
        minute: value.minute(),
      }
    : null
const hasScheduleDate = (value) => !!value && value.year && value.month && value.day

const hasTimeOffsetFromUser = (timeZone) => moment().utcOffset() !== moment.tz(moment(), timeZone).utcOffset()

const hasTimeOffsetFromMoment = (dateMoment, timeZone) =>
  moment(dateMoment).utcOffset() !== moment.tz(moment(), timeZone).utcOffset()

const useDateUtils = () => ({
  hasTime,
  formatTime,
  formatShortDate,
  formatShortTime,
  formatScheduleTime,
  formatScheduleDate,
  isBeforeToday,
  scheduleDateToMoment,
  momentToScheduleDate,
  hasScheduleDate,
  hasTimeOffsetFromUser,
  hasTimeOffsetFromMoment,
})

export {
  useDateUtils,
  hasTime,
  formatShortDate,
  formatTime,
  formatScheduleTime,
  formatScheduleDate,
  isBeforeToday,
  scheduleDateToMoment,
  scheduleDatesMatch,
  momentToScheduleDate,
  hasScheduleDate,
  hasTimeOffsetFromUser,
  hasTimeOffsetFromMoment,
}
