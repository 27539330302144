import React from "react"
import { Box, Divider, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("xs")]: {
      bottom: 60,
    },
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const SettingsFooter = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} pb={2} mx={-1} px={1}>
      <Divider />
      <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
        {children}
      </Box>
    </Box>
  )
}

export default SettingsFooter
