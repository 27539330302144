import React, { useEffect, useMemo, useState } from "react"
import { Box, Divider, Tab, Tabs } from "@material-ui/core"
import moment from "moment"
import { useHistory, useParams } from "react-router"
import { FormatDateCompact, ModuleHeading, RowBox } from "../../../components"
import {
  mapToIds,
  multipleSelectChange,
  scheduleDateToMoment,
  toId,
  useDateUtils,
  useReportUtils,
} from "../../../utils"
import { useAuth } from "../../../services"
import { DateRangeChipMenu } from "../../../components/ChipMenu/DateRangeChipMenu"
import { LocationChipMenu } from "../../../components/ChipMenu/LocationChipMenu"
import { TagChipMenu } from "../../../components/ChipMenu/TagChipMenu"
import { ProcessChipMenu } from "../../../components/ChipMenu/ProcessChipMenu"
import { ReportsFilters } from "../../../components/Reports/ReportsFilters"
import ExportJobAttachmentsButton from "../../../components/Reports/ExportJobAttachmentsButton"
import { JobsTableReport } from "../../../components/Charts/JobsTableReport/JobsTableReport"
import JobStepTableReport from "../../../components/Reports/JobStepTableReport"
import { ProcessesTableReport } from "../../../components/Charts/ProcessesTableReport/ProcessesTableReport"

const Jobs = () => {
  const { days, locations, groups, tags, process } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const { getNavigateToJobsLink, getNavigateToLink } = useReportUtils()
  const [gt, setGt] = useState(null)
  const [lt, setLt] = useState(null)
  const [tab, setTab] = useState("jobs")
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()

  const daysValue = days || 7
  const groupIds = groups?.split("-") || ["all"]
  const locationIds = useMemo(() => locations?.split("-") || ["all"], [locations])
  const tagIds = tags?.split("-") || ["all"]
  const processValue = process || "all"

  useEffect(() => {
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      setGt(momentToScheduleDate(moment(daysParts[0])))
      setLt(momentToScheduleDate(moment(daysParts[1])))
    } else {
      setGt(momentToScheduleDate(moment().subtract(days, "days")))

      let newLt = null
      // If not "Today"
      if (days !== "1") {
        // Set end of yesterday (23h59)
        newLt = momentToScheduleDate(moment().subtract(1, "days").endOf("day"))
      }

      setLt(newLt)
    }
  }, [days, locations, momentToScheduleDate, process, userLocations])

  const handleFiltersMenuApply = (values) => {
    const { days: newDays, locations: newLocations, groups: newGroups, process: newProcess, tags: newTags } = values
    history.push(getNavigateToJobsLink(newDays, newLocations, newGroups, newProcess, newTags))
  }

  const handleDaysChange = (newDays) => {
    history.push(getNavigateToJobsLink(newDays, locationIds, groupIds, processValue, tagIds))
  }

  const handleLocationsChange = (event) => {
    history.push(
      getNavigateToJobsLink(daysValue, multipleSelectChange(locationIds, event), groupIds, processValue, tagIds)
    )
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToJobsLink(daysValue, mapToIds(regionLocations), groupIds, processValue, tagIds))
  }

  const handleTagsChange = (value) => {
    history.push(getNavigateToJobsLink(days, locationIds, groupIds, processValue, multipleSelectChange(tagIds, value)))
  }

  const handleJobChange = (value) => {
    setTab("jobs")
    history.push(getNavigateToJobsLink(daysValue, locationIds, groupIds, value, tagIds))
  }

  const handleTemplatesProcessClick = (newProcess) => {
    setTab("jobs")
    handleJobChange(toId(newProcess))
  }

  const handleClear = () => {
    history.push(getNavigateToLink("jobs", 7, ["all"], ["all"]))
  }

  const handleChangeTab = (newTab) => {
    setTab(newTab)
  }

  const hasProcess = processValue !== "all"
  const hasSingleLocation = locationIds.length === 1 && locationIds[0] !== "all"
  const singleLocation = hasSingleLocation ? userLocations.find((l) => l.id === locationIds[0]) : null

  return (
    <>
      <ReportsFilters
        values={{
          days,
          locations: locationIds,
          groups: groupIds,
          process: processValue,
          tags: tagIds,
        }}
        menu={{
          days: true,
          locations: true,
          process: true,
          tags: true,
        }}
        onApply={handleFiltersMenuApply}
        onClear={handleClear}
      >
        <RowBox flexGrow={1}>
          <Box ml={0.5}>
            <DateRangeChipMenu value={days} onChange={handleDaysChange} />
          </Box>
          <Box ml={0.5}>
            <LocationChipMenu
              value={locationIds}
              onChange={handleLocationsChange}
              onRegionChange={handleRegionChange}
              multiple
            />
          </Box>
          <Box ml={0.5}>
            <ProcessChipMenu value={processValue} onChange={handleJobChange} />
          </Box>
          <Box ml={0.5}>
            <TagChipMenu value={tagIds} onChange={handleTagsChange} multiple />
          </Box>
        </RowBox>
        {hasProcess && (
          <Box mr={1}>
            <ExportJobAttachmentsButton gt={gt} lt={lt} process={process} locations={locationIds} />
          </Box>
        )}
      </ReportsFilters>

      <Box mb={2}>
        <Tabs
          width="100%"
          variant="scrollable"
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Reporting type tabs"
        >
          <Tab label="Jobs" value="jobs" onClick={() => handleChangeTab("jobs")} />
          {!hasProcess && <Tab label="Templates" value="templates" onClick={() => handleChangeTab("templates")} />}
          {hasProcess && hasSingleLocation && (
            <Tab label="Step responses" value="steps" onClick={() => handleChangeTab("steps")} />
          )}
        </Tabs>
        <Divider />
      </Box>

      {tab === "jobs" && (
        <JobsTableReport
          title={
            <ModuleHeading noMargin>
              Jobs from <FormatDateCompact value={scheduleDateToMoment(gt)} /> -{" "}
              <FormatDateCompact value={scheduleDateToMoment(lt)} />
            </ModuleHeading>
          }
          gt={gt}
          lt={lt}
          processes={processValue === "all" ? null : [processValue]}
          locations={locationIds}
          groups={groupIds}
          tags={tagIds}
        />
      )}

      {tab === "templates" && (
        <ProcessesTableReport
          title={
            <ModuleHeading noMargin>
              Jobs from <FormatDateCompact value={scheduleDateToMoment(gt)} /> -{" "}
              <FormatDateCompact value={scheduleDateToMoment(lt)} />
            </ModuleHeading>
          }
          gt={gt}
          lt={lt}
          locations={locationIds}
          groups={groupIds}
          tags={tagIds}
          onClickProcess={handleTemplatesProcessClick}
        />
      )}

      {tab === "steps" && hasSingleLocation && (
        <JobStepTableReport gt={gt} lt={lt} process={process} location={singleLocation} />
      )}
    </>
  )
}

export default Jobs
