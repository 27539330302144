import React, { useEffect, useState, useRef } from "react"
import { Box, IconButton, Divider, CircularProgress } from "@material-ui/core"
import { Done as DoneIcon, Edit as EditIcon } from "@material-ui/icons"
import pluralize from "pluralize"
import { Icon, Uploader, RowBox, UploadEditList } from ".."
import { PROCESS_STEP_RESPONSE_TYPE } from "../../data"
import { preventDefault, toId } from "../../utils"
import { useOffline } from "../Offline/useOffline"
import { ProcessCollapse } from "./ProcessCollapse"

const { FILES } = PROCESS_STEP_RESPONSE_TYPE

const debounceRemoveMs = 1500
let debounceRemove

const AddFileStep = ({
  reference,
  expand,
  complete,
  responseUploads,
  classes,
  onChange,
  onRemove,
  onEdit,
  disabled,
  collapseTimeout = "auto",
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const { offline } = useOffline()
  const doneRef = useRef(null)
  const [uploads, setUploads] = useState([])
  const [uploading, setUploading] = useState([])

  useEffect(() => {
    setUploads(responseUploads ? [...responseUploads] : [])
  }, [responseUploads])

  const handleChange = (event) => {
    clearTimeout(debounceRemove)
    onChange && onChange(event, uploads)
  }

  const handleEdit = (event) => {
    onEdit && onEdit(event)
  }

  const handleBeforeUpload = (items) => {
    setIsUploading(true)
    setUploading(() => items)
  }
  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }
  const handleItemUploaded = (item) => {
    setUploads((state) => [...state, item])
    setUploading([...uploading.filter((upload) => toId(upload) !== toId(item))])
  }
  const handleRemoveUpload = (id) => {
    clearTimeout(debounceRemove)
    const removing = uploads.find((upload) => toId(upload) === id)
    const newUploads = uploads.filter((upload) => toId(upload) !== id)
    setUploads([...newUploads])
    setUploading([...uploading.filter((upload) => toId(upload) !== id)])
    if (!removing.__upload && onRemove) {
      debounceRemove = setTimeout(() => {
        onRemove(newUploads)
      }, debounceRemoveMs)
    }
  }

  const handleNotUploading = () => {
    setIsUploading(false)
    setTimeout(() => {
      doneRef.current.click()
    }, 100)
  }

  const handleDeviceUploaded = (items) => {
    setUploads((prev) => [...prev, ...items])
    setTimeout(() => {
      doneRef.current.click()
    }, 100)
  }

  const hasUploads = Boolean(uploads.length || uploading.length)

  return (
    <ProcessCollapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />
      <Box py={1} px={2}>
        {hasUploads && (
          <Box mb={complete ? 0 : 1}>
            <UploadEditList documents images uploads={uploads} onRemove={handleRemoveUpload} editMode={!complete} />
          </Box>
        )}
        <ProcessCollapse in={!complete} timeout={collapseTimeout}>
          <Uploader
            variant="button"
            reference={reference}
            buttonText={
              <RowBox alignItems="center" justifyItems="center">
                <Icon name="attach-file" />
                <Box ml={1}>Add file</Box>
              </RowBox>
            }
            buttonProps={{
              variant: "contained",
              fullWidth: true,
              className: classes.button,
            }}
            documents
            images
            onBeforeUpload={handleBeforeUpload}
            onProgress={handleUploadProgress}
            onItemUploaded={handleItemUploaded}
            onAfterUpload={handleNotUploading}
            onDeviceUploaded={handleDeviceUploaded}
            onError={handleNotUploading}
            disabled={disabled || offline}
          />
        </ProcessCollapse>
      </Box>
      <ProcessCollapse in={hasUploads} timeout={collapseTimeout}>
        <Divider className={classes.divider} />

        <RowBox py={1} px={2} className={classes.promptArea}>
          <Box>
            {isUploading && <strong>Uploading...</strong>}
            {!isUploading && !complete && <strong>Submit {pluralize("file", uploads.length, true)}?</strong>}
            {!isUploading && complete && <strong>{pluralize("file", uploads.length, true)} uploaded</strong>}
          </Box>
          <Box onClick={preventDefault} ml="auto">
            {isUploading && (
              <RowBox alignItems="center" mr={1}>
                <CircularProgress size={24} />
              </RowBox>
            )}
            {!isUploading && !complete && (
              <IconButton
                ref={doneRef}
                onClick={handleChange}
                className={classes.incompletedButton}
                data-cy={`ProcessStep-field-${FILES}-complete`}
                disabled={disabled}
              >
                <DoneIcon />
              </IconButton>
            )}
            {!isUploading && complete && (
              <IconButton
                onClick={handleEdit}
                className={classes.completedButton}
                data-cy={`ProcessStep-field-${FILES}-uncomplete`}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </RowBox>
      </ProcessCollapse>
    </ProcessCollapse>
  )
}

export { AddFileStep }
