import React from "react"
import { Box, makeStyles, useTheme } from "@material-ui/core"
import { Chart, PieSeries, Tooltip } from "@devexpress/dx-react-chart-material-ui"
import { EventTracker, HoverState, Palette } from "@devexpress/dx-react-chart"
import pluralize from "pluralize"
import { RowBox, ColumnBox } from ".."

const size = 108

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    textAlign: "center",
    minWidth: size,
    cursor: ({ clickable }) => (clickable ? "pointer" : "default"),
  },
  text: {
    maxWidth: size,
    fontSize: 12,
  },
  value: {
    height: 1,
    position: "absolute",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: `${size}px`,
    zIndex: 1,
  },
  noData: {
    color: theme.palette.chart.background,
  },
}))

const CircularJobCompletionChart = ({ text, data, mr = 2, mb = 1, ...props }) => {
  const { completedOnTime, completedLate, inProgress, uncompleted, uncompletedOverdue, total } = data
  const classes = useStyles({ clickable: !!props.onClick })
  const theme = useTheme()

  const colours = [
    theme.palette.chart.background,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.warning.main,
    theme.palette.success.main,
  ]

  const chartData = [
    { argument: "Uncompleted", value: total ? uncompleted : 1 },
    { argument: "In Progress", value: inProgress },
    { argument: "Overdue", value: uncompletedOverdue },
    { argument: "Completed (Late)", value: completedLate },
    { argument: "Completed (On time)", value: completedOnTime },
  ]

  const totalUncompleted = uncompleted + inProgress + uncompletedOverdue

  const overall = total ? Math.floor(((total - totalUncompleted) / total) * 100) : 0

  const TooltipContent = ({ targetItem: { point } }) => {
    const { argument, value } = chartData[point]
    const percentage = Math.floor((value / total) * 100)

    return (
      <Box>
        <Box>
          <strong>{argument}</strong>
        </Box>
        <Box>
          {percentage}% ({pluralize("job", value, true)})
        </Box>
      </Box>
    )
  }

  return (
    <>
      <ColumnBox alignItems="center" justifyContent="flex-start" mr={mr} mb={mb} className={classes.root} {...props}>
        <ColumnBox>
          <Chart width={size} height={size} data={chartData}>
            <Palette scheme={colours} />
            <PieSeries valueField="value" argumentField="argument" innerRadius={0.78} />
            <EventTracker />
            {total > 0 && <Tooltip contentComponent={TooltipContent} />}
            <HoverState />
          </Chart>
        </ColumnBox>
        <Box className={classes.value}>
          {!!total && <>{overall}%</>}
          {!total && <Box className={classes.noData}>None</Box>}
        </Box>
        <RowBox alignItems="center" justifyContent="center">
          <Box className={classes.text}>{text}</Box>
        </RowBox>
      </ColumnBox>
    </>
  )
}
export { CircularJobCompletionChart }
