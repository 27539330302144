import React from "react"
import { Box, Hidden } from "@material-ui/core"
import { ActionCardInfo } from "."
import { RowBox } from "../Boxes"
import { AdornmentIcon } from "../Icon"

const ActionCardFooterInfo = ({ title, value, icon, mr, valueTooltip = true }) => (
  <RowBox mr={mr}>
    <Hidden mdUp>
      <ActionCardInfo>
        <AdornmentIcon
          name={icon}
          tooltip={
            <>
              {title}
              {valueTooltip ? `: ${value}` : null}
            </>
          }
        />
      </ActionCardInfo>
    </Hidden>
    <Hidden smDown>
      <AdornmentIcon name={icon} tooltip={title} />
      <Box ml={1} mr={0}>
        {value}
      </Box>
    </Hidden>
  </RowBox>
)

export { ActionCardFooterInfo }
