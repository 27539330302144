import { Box, Grid, makeStyles } from "@material-ui/core"
import React from "react"
import { useHistory } from "react-router-dom"
import { Skeleton } from "@material-ui/lab"
import { jobFiltersStateVar } from "../../data"
import { isArrayOddLength, isLastInArray, toId, useMountAwareReactiveVar } from "../../utils"
import { ChartCard } from "./ChartCard"
import { CircularProgress } from "../CircularProgress"
import { useAuth } from "../../services"

const useStyles = makeStyles(() => ({
  gridItem: {
    width: "100%",
    cursor: "pointer",
  },
}))

const JobsGroupsCompletionChartCards = ({ items, loading }) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    principal: { groups },
  } = useAuth()
  const jobsFiltersStore = useMountAwareReactiveVar(jobFiltersStateVar)

  const handleClick = (groupId) => {
    jobFiltersStateVar({
      ...jobsFiltersStore,
      tab: "others",
      toGroups: [groupId],
    })
    history.push("/jobs")
  }

  const oddLength = isArrayOddLength(groups)

  return (
    <Grid container spacing={2}>
      {groups.map((group, index) => {
        const last = isLastInArray(groups, index)
        const evenAndSecondLast = !oddLength && index === groups.length - 2
        const full = last && oddLength
        const item = items.find((i) => toId(i) === toId(group))
        const jobs = item?.jobs || null

        const percentComplete = jobs
          ? jobs.total === 0
            ? 0
            : Math.round(((jobs.completed + jobs.overdue + jobs.skipped) / jobs.total) * 100)
          : 0

        return (
          <Grid key={toId(group)} item lg={full ? 12 : 6} sm={12} className={classes.gridItem}>
            <Box mb={last || evenAndSecondLast ? 0 : -1} onClick={() => handleClick(toId(group))}>
              <ChartCard
                title={
                  <>
                    {loading && (
                      <>
                        <Skeleton variant="text" width={110} />
                        <Skeleton variant="text" width={110} />
                      </>
                    )}
                    {!loading && !!item && (
                      <>
                        <strong>{item.name}</strong> completion rate for today
                      </>
                    )}
                    {!loading && !item && <>Unable to load</>}
                  </>
                }
                chart={
                  <>
                    {loading && <Skeleton variant="circle" width={120} height={120} />}
                    {!loading && (
                      <CircularProgress value={loading ? 0 : percentComplete} showValue={!loading} size={120} />
                    )}
                  </>
                }
              />
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

export { JobsGroupsCompletionChartCards }
