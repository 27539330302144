import { GroupChipMenuItem, GroupValueText } from "../ChipMenu/GroupChipMenu"
import { FiltersChipMenuItem } from "./FiltersChipMenuItem"

const GroupsFiltersChipMenuItem = ({ menuItemRef, value, onChange, onExpanded, ...props }) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue)
  }

  return (
    <FiltersChipMenuItem
      menuItemRef={menuItemRef}
      name="Areas of Work"
      description={<GroupValueText value={value} />}
      onExpanded={onExpanded}
    >
      <GroupChipMenuItem value={value} onChange={handleChange} {...props} />
    </FiltersChipMenuItem>
  )
}

export { GroupsFiltersChipMenuItem }
