import { useLazyQuery, useQuery, gql } from "@apollo/client"
import { offsetLimitPagination } from "@apollo/client/utilities"
import * as Sentry from "@sentry/browser"
import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const ADMINABLE_USERS_LIMIT = 10

const ADMINABLE_USERS_QUERY = gql`
  query AdminableUsers(
    $searchText: String
    $locations: [ID!]
    $roles: [ID!]
    $groups: [ID!]
    $status: [String!]
    $trainingStatus: [String!]
    $accreditationStatus: [String!]
    $offset: Int
    $limit: Int
  ) {
    adminableUsers: adminableUsersV2(
      searchText: $searchText
      locations: $locations
      roles: $roles
      groups: $groups
      status: $status
      trainingStatus: $trainingStatus
      accreditationStatus: $accreditationStatus
      limit: $limit
      offset: $offset
    ) {
      ...AdminableUsersFields
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const ADMINABLE_USERS_AND_COUNT_QUERY = gql`
  query AdminableUsersAndCount($filter: AdminableUsersFilterInput!, $offset: Int, $limit: Int) {
    adminableUsers {
      list(filter: $filter, limit: $limit, offset: $offset) {
        ...AdminableUsersFields
      }
      count(filter: $filter)
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const adminableUsersQueryTypePolicies = {
  root: {
    adminableUsersV2: offsetLimitPagination([
      "searchText",
      "locations",
      "roles",
      "groups",
      "status",
      "trainingStatus",
      "accreditationStatus",
    ]),
  },
  scoped: {
    AdminableUsersQuery: {
      merge(_, next) {
        return { ...next }
      },
      fields: {
        list: offsetLimitPagination(["filter"]),
        count: {
          keyArgs: ["filter"],
        },
      },
    },
  },
}

const loadMore = (result) => async () => {
  try {
    await result.fetchMore({
      variables: {
        ...result.variables,
        offset: result.data.adminableUsers.length,
        limit: ADMINABLE_USERS_LIMIT,
      },
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

const useQueryAdminableUsers = (options) => {
  const { variables, ...otherOptions } = options || {}
  const result = useQuery(ADMINABLE_USERS_QUERY, {
    variables: {
      offset: 0,
      limit: ADMINABLE_USERS_LIMIT,
      ...variables,
    },
    fetchPolicy: "cache-and-network",
    ...otherOptions,
  })

  return {
    ...result,
    loadMore: loadMore(result),
  }
}

const useLazyQueryAdminableUsers = (options) => {
  const { variables, ...otherOptions } = options || {}
  const [loadItems, result] = useLazyQuery(ADMINABLE_USERS_QUERY, {
    variables: {
      offset: 0,
      limit: ADMINABLE_USERS_LIMIT,
      ...variables,
    },
    fetchPolicy: "cache-and-network",
    ...otherOptions,
  })

  return [
    loadItems,
    {
      ...result,
      loadMore: loadMore(result),
    },
  ]
}

const loadMoreForScoped = (result) => async () => {
  try {
    await result.fetchMore({
      variables: {
        ...result.variables,
        offset: result.data.adminableUsers.list.length,
        limit: ADMINABLE_USERS_LIMIT,
      },
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

const useLazyQueryAdminableUsersAndCount = (options) => {
  const { variables, ...otherOptions } = options || {}
  const [loadItems, result] = useLazyQuery(ADMINABLE_USERS_AND_COUNT_QUERY, {
    variables: {
      offset: 0,
      limit: ADMINABLE_USERS_LIMIT,
      ...variables,
    },
    fetchPolicy: "cache-and-network",
    ...otherOptions,
  })

  return [
    loadItems,
    {
      ...result,
      loadMore: loadMoreForScoped(result),
    },
  ]
}

export {
  useQueryAdminableUsers,
  useLazyQueryAdminableUsers,
  useLazyQueryAdminableUsersAndCount,
  adminableUsersQueryTypePolicies,
  ADMINABLE_USERS_LIMIT,
}
