import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import { DialogTitleCloser } from "../Creators"
import { TrainingCurriculum } from "../Training/TrainingCurriculum"

const PersonTrainingViewer = ({ userTraining, onClose }) => {
  const handleClose = () => {
    onClose && onClose()
  }

  if (!userTraining) {
    return null
  }

  const { name } = userTraining

  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle id="form-dialog-title">
        {name}
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <TrainingCurriculum training={userTraining} showAnswers showUserScore />
      </DialogContent>
    </Dialog>
  )
}

export { PersonTrainingViewer }
