import { Box } from "@material-ui/core"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import React, { useEffect, useMemo, useState } from "react"
import { ReportSectionActionCard, RowBox } from ".."
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"
import { useLazyQueryReportActionsProcessStepsOverview } from "../../data/report/useQueryReportActionsProcessStepsOverview"
import { ProcessStepActionsChart } from "../Charts/ProcessStepActionsChart"
import ProcessStepActionsTableReport from "./ProcessStepActionsTableReport"

const sortData = (sort, a, b) =>
  sort === "alpha" ? b.processStepName.localeCompare(a.processStepName) : a.rank - b.rank

const ProcessStepActionsOverviewReport = ({
  gt,
  lt,
  locations,
  process,
  groups,
  tags,
  statuses,
  priorities,
  containerHeight = 300,
}) => {
  const [viewActions, setViewActions] = useState(null)
  const [sort, setSort] = useState("rank")
  const [load, { data, loading }] = useLazyQueryReportActionsProcessStepsOverview()
  const [fullscreen, setFullscreen] = useState(false)

  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    if (!gt) {
      return
    }

    const filterProcess = process === "all" ? null : process
    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterTags = tags.includes("all") ? null : tags
    const filterStatuses = statuses.includes("all") ? null : statuses
    const filterPriorities = priorities.includes("all") ? null : priorities

    load({
      variables: {
        input: {
          locations: filterLocations,
          process: filterProcess,
          statuses: filterStatuses,
          tags: filterTags,
          priorities: filterPriorities,
          gt,
          lt,
        },
      },
    })
  }, [gt, load, locations, lt, priorities, process, tags, userLocations, statuses])

  const handleFullscreen = (newFullscreen) => {
    setFullscreen(newFullscreen)
  }

  const handleViewStepsClick = (stepData) => {
    setViewActions(stepData)
  }

  const handleViewStepsClose = () => {
    setViewActions(null)
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const chartData = useMemo(
    () =>
      data
        ? data.report.actions.steps.overview
            .map(({ id, total, ...step }) => ({
              argument: id,
              total,
              rank: total,
              ...step,
            }))
            .reverse()
            .sort((a, b) => sortData(sort, a, b))
        : [],
    [data, sort]
  )

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = [
      "Process",
      "Step",
      "Unresolved",
      "InProgress",
      "Blocked",
      "Resolved",
      "Cancelled",
      "Total",
      "ProcessID",
      "ProcessStepID",
    ]
    const rows = [...chartData]
      .reverse()
      .sort((a, b) => sortData(sort, a, b))
      .map(
        ({
          process: processId,
          processStep: processStepId,
          processName,
          processStepName,
          unresolved,
          inProgress,
          blocked,
          resolved,
          cancelled,
          total,
        }) =>
          [
            stringFormatter(processName),
            stringFormatter(processStepName),
            unresolved,
            inProgress,
            blocked,
            resolved,
            cancelled,
            total,
            processId,
            processStepId,
          ].join(",")
      )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "process-step-actions.csv", "text/csv")
  }

  const rotated = true

  return (
    <>
      {viewActions && (
        <ProcessStepActionsTableReport
          gt={gt}
          lt={lt}
          process={viewActions.process}
          step={viewActions.processStep}
          locations={locations}
          groups={groups}
          tags={tags}
          statuses={statuses}
          priorities={priorities}
          defaultFullscreen
          onFullscreen={handleViewStepsClose}
        />
      )}

      <ReportSectionActionCard
        title="Top actions by source"
        detail="Number raised and status"
        loading={loading}
        onFullscreen={handleFullscreen}
        tools={
          <RowBox>
            <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
            <Box ml={1}>
              <ReportToolsIconButton
                title={sort === "alpha" ? "Sort by rank #" : "Sort alphabetically"}
                icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
                onClick={handleSort}
                disabled={loading}
              />
            </Box>
          </RowBox>
        }
      >
        <ProcessStepActionsChart
          data={chartData}
          barWidth={rotated ? 0.75 : 0.5}
          argumentLabels
          rotated={rotated}
          truncateLabelAt={fullscreen ? 60 : rotated ? 30 : 10}
          height={rotated ? chartData.length * 32 : containerHeight}
          containerHeight={fullscreen ? null : containerHeight}
          variant="stacked-bar"
          onClick={handleViewStepsClick}
        />
      </ReportSectionActionCard>
    </>
  )
}

export default ProcessStepActionsOverviewReport
