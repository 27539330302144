import React, { useMemo } from "react"

import PropTypes from "prop-types"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { NavLink } from "react-router-dom"

import { PROCESS_STEP_SELECTION_SOURCES, useQueryAsset, useQuerySupplier } from "../../data"
import { DialogTitleCloser, FlexBox, Icon, SupplierViewer, LoadingSpinner, RowBox, AssetViewer } from ".."
import Forbidden from "../../areas/knowledge/Forbidden"
import { titleCase } from "../../utils/string"

const SelectionPeekDialog = ({ id, source, open, onClose }) => {
  const isFromSuppliersSource = source === PROCESS_STEP_SELECTION_SOURCES.SUPPLIERS
  const isFromAssetsSource = source === PROCESS_STEP_SELECTION_SOURCES.ASSETS
  const {
    data: supplierData,
    loading: supplierLoading,
    error: supplierError,
  } = useQuerySupplier({
    skip: !(id && isFromSuppliersSource),
    variables: { id },
    errorPolicy: "all",
  })
  const {
    data: assetData,
    loading: assetLoading,
    error: assetError,
  } = useQueryAsset({
    skip: !(id && isFromAssetsSource),
    variables: { id },
    errorPolicy: "all",
  })

  const forbidden = useMemo(() => {
    if (
      (isFromSuppliersSource && !supplierError?.graphQLErrors?.length) ||
      (isFromAssetsSource && !assetError?.graphQLErrors?.length)
    ) {
      return false
    }

    return (
      (isFromSuppliersSource && supplierError.graphQLErrors[0].extensions?.code === "PERMISSION_DENIED") ||
      (isFromAssetsSource && assetError.graphQLErrors[0].extensions?.code === "PERMISSION_DENIED")
    )
  }, [isFromSuppliersSource, isFromAssetsSource, supplierError, assetError])

  const handleClose = () => {
    onClose && onClose()
  }

  const getTitle = () => {
    switch (source) {
      case PROCESS_STEP_SELECTION_SOURCES.SUPPLIERS:
        return supplierData?.supplier?.name
      case PROCESS_STEP_SELECTION_SOURCES.ASSETS:
        return assetData?.asset?.name
      default:
        throw new Error("Source does not support")
    }
  }

  const getActionTextBySource = () => {
    return titleCase(source)
  }

  const getNavigateLink = () => {
    switch (source) {
      case PROCESS_STEP_SELECTION_SOURCES.SUPPLIERS:
        return `/suppliers/${id}`
      case PROCESS_STEP_SELECTION_SOURCES.ASSETS:
        return `/assets/${id}`
      default:
        throw new Error("Source does not support")
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle id="customized-dialog-title">
        <RowBox mr={2}>
          <FlexBox flexGrow={1}>{getTitle()}</FlexBox>
          <DialogTitleCloser onClose={handleClose} />
        </RowBox>
      </DialogTitle>
      <DialogContent>
        {(supplierLoading || assetLoading) && !(supplierData || assetData) && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {!forbidden && supplierData && <SupplierViewer variant="peek" supplier={supplierData.supplier} />}
        {!forbidden && assetData && <AssetViewer variant="peek" asset={assetData.asset} />}
        {forbidden && <Forbidden />}
      </DialogContent>
      <DialogActions>
        {!forbidden && (
          <Button component={NavLink} to={getNavigateLink(id)}>
            <Box mr={0.5}>View in {getActionTextBySource()}</Box>
            <Icon name="forward" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

SelectionPeekDialog.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.oneOf([PROCESS_STEP_SELECTION_SOURCES.SUPPLIERS, PROCESS_STEP_SELECTION_SOURCES.ASSETS]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default SelectionPeekDialog
