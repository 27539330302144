import React, { useState, useEffect } from "react"
import { TextField, useTheme } from "@material-ui/core"
import { MentionsInput, Mention } from "@impelsys/react-mentions"
import { useQueryUsers, useQueryGroups, useQueryKnowledges, useQueryMentionableJobs } from "../../data"
import { UserMentionListItem } from ".."
import { MentionListItem } from "./MentionListItem"
import { useAuth } from "../../services"
import { MentionProcessListItem } from "./MentionProcessListItem"
import { mapToIds } from "../../utils"

const getStyles = (theme, { height, minHeight, readOnly, multiline, showingSubMenu }) => ({
  control: {},
  input: multiline
    ? {
        height: readOnly ? "100%" : height || "auto",
        minHeight,
        lineHeight: "20px",
        resize: "none",
        overflow: "auto",
        width: "100%",
        border: "none",
        outline: "none",
        padding: 0,
        margin: 0,
      }
    : {
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
        lineHeight: "20px",
        border: "none",
        outline: "none",
        margin: 0,
      },
  suggestions: {
    list: {
      zIndex: 999,
      backgroundColor: "white",
      border: "1px solid #aaa",
      padding: showingSubMenu ? 0 : 8,
      fontSize: 13,
      marginTop: 10,
      borderRadius: 4,
      maxHeight: 200,
      overflow: "auto",
      minWidth: 320,
    },
    item: {
      padding: showingSubMenu ? 0 : "5px 10px",
      borderRadius: 4,
      fontSize: 13,
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
  highlighter: multiline
    ? {
        overflow: "hidden",
        width: "100%",
        border: "none",
        outline: "none",
        padding: 0,
      }
    : {
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
        padding: "0 -14px",
      },
})

const getMentionStyles = (theme, { fontSize, lineHeight }) => ({
  fontSize: fontSize || 16,
  lineHeight: lineHeight || "20px",
  position: "relative",
  marginTop: 1,
  zIndex: 1,
  color: theme.palette.primary.main,
  textShadow: "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white -1px -1px 1px white",
  backgroundColor: "white",
  pointerEvents: "none",
})

const markup = "@[__display__](__id__)"

const triggerRegex = /(@([\w]+))$/

const getMentionChildren = (data, style, onAdd, toggleSubMenu) => (
  <Mention
    key="root"
    trigger={triggerRegex}
    data={data}
    style={style}
    markup={markup}
    appendSpaceOnAdd
    onAdd={onAdd}
    renderSuggestion={(
      { display, hidden, showSubMenu, subMenuFocusIndex, source },
      search,
      highlightedDisplay,
      index,
      focused,
      addManually,
      stateMutator
    ) => {
      const { __typename: type } = source
      if (hidden && !showSubMenu) return null

      switch (type) {
        case "User":
          return <UserMentionListItem item={source} />
        case "Group":
          return <MentionListItem text={source.name} secondaryText="Group of users" iconName="group" />
        case "Knowledge":
        case "KnowledgeV2":
          return <MentionListItem text={source.title} secondaryText="Knowledge base article" iconName="knowledge" />
        // case "Category":
        //   return <MentionListItem text={source.name} secondaryText="Knowledge base category" iconName="custom" customIconName={source.icon} />
        case "MentionableProcess":
          return (
            <MentionProcessListItem
              text={source.name}
              data={source}
              secondaryText="Process"
              {...{ showSubMenu, toggleSubMenu, addManually, stateMutator, subMenuFocusIndex }}
            />
          )
        case "MentionableTask":
          return <MentionListItem text={`${source.name}`} secondaryText={`Task - ${source.location}`} iconName="task" />

        default:
          return display
      }
    }}
  />
)

const convertProcessJobToMention = ({ id, name, locationDay, location, __typename }) => ({
  id: `${__typename}:${id}:${locationDay}:${location.id}:${location.name}`,
  display: `${name}`,
})

const KEY = { TAB: 9, RETURN: 13, ESC: 27, UP: 38, DOWN: 40, RIGHT: 39, LEFT: 37 }

const MentionTextInput = ({
  value,
  onChange,
  onAddMention,
  label,
  height,
  minHeight = null,
  fontSize,
  lineHeight,
  inputRef,
  multiline,
  locations,
  cy,
  ...rest
}) => {
  const { location } = useAuth()
  const { data: users } = useQueryUsers()
  const { data: groups } = useQueryGroups()
  const { data: articles } = useQueryKnowledges()
  const { data: jobs } = useQueryMentionableJobs({
    variables: {
      locations: mapToIds(locations?.length > 0 ? locations : location ? [location] : []),
    },
  })
  const [data, setData] = useState([])

  const theme = useTheme()
  const [showingSubMenu, setShowingSubMenu] = useState(false)

  const style = getStyles(theme, { height, minHeight, multiline, showingSubMenu })
  const mentionStyle = getMentionStyles(theme, { fontSize, lineHeight })

  useEffect(() => {
    const newData = []
    if (jobs) {
      newData.push(
        ...jobs.mentionableJobs.processes.map((d) => ({
          display: `${d.name}`,
          id: `${d.__typename}:${d.id}`,
          hidden: false,
          hasSubMenu: true,
          showSubMenu: false,
          source: { name: d.name, jobs: d.jobs, __typename: d.__typename },
        }))
      )
      newData.push(
        ...jobs.mentionableJobs.tasks.map((d) => ({
          display: `${d.name}`,
          id: `${d.__typename}:${d.id}:${d.locationDay}`,
          source: { name: d.name, location: d.location?.name, __typename: d.__typename },
        }))
      )
    }
    if (articles)
      newData.push(
        ...articles.knowledges.map((d) => ({
          display: `${d.title}`,
          id: `${d.__typename}:${d.id}`,
          source: { title: d.title, __typename: d.__typename },
        }))
      )
    if (users)
      newData.push(
        ...users.users.map((d) => ({
          display: `${d.fullName}`,
          id: `${d.__typename}:${d.id}`,
          source: { fullName: d.fullName, avatar: d.avatar, __typename: d.__typename },
        }))
      )
    if (groups)
      newData.push(
        ...groups.groups.map((d) => ({
          display: `${d.name}`,
          id: `${d.__typename}:${d.id}`,
          source: { name: d.name, __typename: d.__typename },
        }))
      )
    setData(newData)
  }, [articles, groups, jobs, users])

  const inputLabelProps = value ? { shrink: true } : {}

  const handleAddMention = (id, display) => {
    if (onAddMention) onAddMention(id, display)
  }

  const handleToggleSubMenu = ({ mutateState }) => {
    mutateState(updateSubMenuShowState)
  }

  const getSuggestionStateInfo = (state) => {
    const suggestions = state.suggestions[0]
    const { results } = suggestions
    const suggestion = results[state.focusIndex]
    return [results, suggestion, suggestions.queryInfo]
  }

  const updateSubMenuShowState = (state) => {
    const [results, suggestion] = getSuggestionStateInfo(state)
    if (suggestion.hasSubMenu) {
      const showing = suggestion.showSubMenu
      results.forEach((result) => {
        if (result.id === suggestion.id) return
        result.hidden = !showing
      })
      suggestion.subMenuFocusIndex = 0
      suggestion.showSubMenu = !showing
      setShowingSubMenu(!showing)
    }
    return { ...state }
  }

  const updateSubMenuFocus = (state, delta) => {
    const [, suggestion] = getSuggestionStateInfo(state)
    if (suggestion.hasSubMenu) {
      const jobsCount = suggestion.source.jobs.length
      suggestion.subMenuFocusIndex = (jobsCount + suggestion.subMenuFocusIndex + delta) % jobsCount
    }
    return { ...state }
  }

  const addSelectedSubMenuItem = (state, addMention) => {
    const [, suggestion, queryInfo] = getSuggestionStateInfo(state)
    const job = suggestion.source.jobs[suggestion.subMenuFocusIndex]
    addMention(convertProcessJobToMention(job), queryInfo)
  }

  const handleKeyDownOverride = (
    ev,
    { clearSuggestions, shiftFocus, selectFocused, mutateState, state, addMention }
  ) => {
    switch (ev.keyCode) {
      case KEY.DOWN: {
        if (!showingSubMenu) shiftFocus(+1)
        else mutateState((prev) => updateSubMenuFocus(prev, +1))
        return
      }
      case KEY.UP: {
        if (!showingSubMenu) shiftFocus(-1)
        else mutateState((prev) => updateSubMenuFocus(prev, -1))
        return
      }
      case KEY.RIGHT: {
        mutateState(updateSubMenuShowState)
        return
      }
      case KEY.LEFT: {
        if (showingSubMenu) mutateState(updateSubMenuShowState)
        else clearSuggestions()
        return
      }
      case KEY.RETURN: {
        if (showingSubMenu) addSelectedSubMenuItem(state, addMention)
        else if (state.suggestions[0].results[state.focusIndex].hasSubMenu) mutateState(updateSubMenuShowState)
        else selectFocused()
        return
      }
      case KEY.TAB: {
        selectFocused()
        break
      }
      default:
    }
  }

  const handleClearSuggestions = () => {
    setShowingSubMenu(false)
  }

  return (
    <TextField
      {...rest}
      multiline
      variant="outlined"
      label={label}
      InputProps={{
        inputComponent: MentionsInput,
        inputProps: {
          value: value || "",
          singleLine: !multiline,
          onChange,
          inputRef,
          onKeyDownOverride: handleKeyDownOverride,
          onClearSuggestions: handleClearSuggestions,
          onBlur: handleClearSuggestions,
          // mentionHintTrigger: "@",
          // mentionHint: "Mention a team mate, job or knowledge base article",
          keyDownOverrideTriggerCodes: KEY,
          children: getMentionChildren(data, mentionStyle, handleAddMention, handleToggleSubMenu),
          markup,
          style,
          "data-cy": cy,
        },
      }}
      InputLabelProps={inputLabelProps}
    />
  )
}

export { MentionTextInput, getMentionChildren, convertProcessJobToMention }
