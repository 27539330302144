import { ActionStatusChipMenuItem, ActionStatusValueText } from "../ChipMenu/ActionStatusChipMenu"
import { FiltersChipMenuItem } from "./FiltersChipMenuItem"

const ActionStatusFiltersChipMenuItem = ({ menuItemRef, value, onChange, onExpanded, ...props }) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue)
  }

  return (
    <FiltersChipMenuItem
      menuItemRef={menuItemRef}
      name="Status"
      description={<ActionStatusValueText value={value} />}
      onExpanded={onExpanded}
    >
      <ActionStatusChipMenuItem value={value} onChange={handleChange} {...props} />
    </FiltersChipMenuItem>
  )
}

export { ActionStatusFiltersChipMenuItem }
