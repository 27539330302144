import React, { useEffect, useMemo, useState } from "react"
import { Box, makeStyles, Grid, Button } from "@material-ui/core"
import moment from "moment"
import { useHistory, useParams } from "react-router"
import InfiniteScroll from "react-infinite-scroll-component"
import {
  LocationOutlinedSelect,
  TemplateOutlinedSelect,
  DateRangeOutlinedSelect,
  NoItemsMessage,
  RowBox,
} from "../../components"
import { mapToIds, toId, useDateUtils, useReportUtils } from "../../utils"
import { useAuth } from "../../services"
import JobsDaysOverviewReport from "../../components/Reports/JobStepsDaysOverviewReport"
import JobsStepsOverviewReport from "../../components/Reports/JobStepsOverviewReport"
import JobReport from "../../components/Reports/JobReport"
import JobsStepsNotesReport from "../../components/Reports/JobsStepsNotesReport"
import ExportJobAttachmentsButton from "../../components/Reports/ExportJobAttachmentsButton"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
    width: 300,
    flexShrink: 0,
  },
  filterSelect: {
    backgroundColor: "white",
    maxWidth: 300,
  },
  reports: {
    width: 932 - theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  infiniteScrollWrapper: {
    "&&>div": {
      display: "contents",
    },
  },
  infiniteScroll: {
    display: "contents",
  },
}))

const initialLimit = 8
const incrementLimit = 4

const Jobs = () => {
  const classes = useStyles()
  const { days, locations, groups, process } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const { getNavigateToJobsLink, getNavigateToLink, filterChange } = useReportUtils()
  const [limit, setLimit] = useState(initialLimit)
  const [gt, setGt] = useState(null)
  const [lt, setLt] = useState(null)
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()

  const daysValue = days || 7
  const groupIds = groups?.split("-") || ["all"]
  const locationIds = useMemo(() => locations?.split("-") || ["all"], [locations])
  const processValue = process || "all"

  useEffect(() => {
    setLimit(initialLimit)
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      setGt(momentToScheduleDate(moment(daysParts[0])))
      setLt(momentToScheduleDate(moment(daysParts[1])))
    } else {
      setGt(momentToScheduleDate(moment().subtract(days, "days")))

      let newLt = null
      // If not "Today"
      if (days !== "1") {
        // Set end of yesterday (23h59)
        newLt = momentToScheduleDate(moment().subtract(1, "days").endOf("day"))
      }

      setLt(newLt)
    }
  }, [days, locations, momentToScheduleDate, process, userLocations])

  const handleDaysChange = (event) => {
    history.push(getNavigateToJobsLink(event.target.value, locationIds, groupIds, processValue))
  }

  const handleLocationsChange = (event) => {
    history.push(getNavigateToJobsLink(daysValue, filterChange(locationIds, event), groupIds, processValue))
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToJobsLink(daysValue, mapToIds(regionLocations), groupIds, processValue))
  }

  const handleJobChange = (event) => {
    history.push(getNavigateToJobsLink(daysValue, locationIds, groupIds, event.target.value))
  }

  const handleDayClick = (day) => {
    history.push(
      getNavigateToJobsLink(`${day}-to-${moment(day).format("YYYY-MM-DD")}`, locationIds, groupIds, processValue)
    )
  }

  const handleJobClick = (newProcess) => {
    history.push(getNavigateToJobsLink(days, locationIds, groupIds, newProcess))
  }

  const handleReset = () => {
    history.push(getNavigateToLink("jobs", 7, ["all"], ["all"]))
  }

  const showLocations = userLocations.filter(
    (location) => locationIds.includes("all") || locationIds.some((l) => toId(l) === toId(location))
  )
  const visibleLocations = showLocations.slice(0, limit)

  const report = processValue !== "all"

  return (
    <RowBox alignItems="flex-start">
      <Box p={3} className={classes.filter} mt={3} mb={3}>
        <Box mb={2}>
          <DateRangeOutlinedSelect value={daysValue} onChange={handleDaysChange} className={classes.filterSelect} />
        </Box>
        <Box mb={2}>
          <LocationOutlinedSelect
            value={locationIds}
            className={classes.filterSelect}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        <Box>
          <TemplateOutlinedSelect
            label="Job"
            allLabel="All jobs"
            value={processValue}
            className={classes.filterSelect}
            hasAll
            onChange={handleJobChange}
          />
        </Box>
        <RowBox mt={2} justifyContent="flex-end">
          {report && (
            <Box mr="auto">
              <ExportJobAttachmentsButton gt={gt} lt={lt} process={process} locations={locationIds} />
            </Box>
          )}

          <Button variant="contained" onClick={handleReset}>
            Reset
          </Button>
        </RowBox>
      </Box>
      <Box flexGrow={1}>
        {!report && (
          <Grid container spacing={1} className={classes.reports}>
            <Grid item xs={12}>
              <JobsDaysOverviewReport
                gt={gt}
                lt={lt}
                locations={locationIds}
                groups={groupIds}
                onDayClick={handleDayClick}
              />
            </Grid>
            <Grid item xs={7}>
              <JobsStepsOverviewReport gt={gt} lt={lt} locations={locationIds} onJobClick={handleJobClick} />
            </Grid>
            <Grid item xs={5}>
              <JobsStepsNotesReport gt={gt} lt={lt} locations={locationIds} />
            </Grid>
          </Grid>
        )}
        {report && (
          <>
            {showLocations.length > 0 && (
              <InfiniteScroll
                dataLength={visibleLocations.length}
                next={() => setLimit(limit + incrementLimit)}
                hasMore={visibleLocations.length < showLocations.length}
                className={classes.infiniteScroll}
              >
                <Grid container spacing={1} className={classes.reports}>
                  {visibleLocations.map((location) => (
                    <React.Fragment key={toId(location)}>
                      <Grid item xs={12}>
                        <JobReport
                          gt={gt}
                          lt={lt}
                          process={processValue}
                          location={location}
                          onDayClick={handleDayClick}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </InfiniteScroll>
            )}

            {!showLocations.length && (
              <Grid container spacing={1} className={classes.reports}>
                <Grid item>
                  <NoItemsMessage>No data</NoItemsMessage>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </RowBox>
  )
}

export default Jobs
