import React from "react"
import { Box, makeStyles } from "@material-ui/core"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"

const useStyles = makeStyles((theme) => ({
  areaHeaderToggle: {
    backgroundColor: "white",
    height: 40,
  },
  areaToggleText: {
    textTransform: "none",
    fontSize: 14,
    fontWeight: "normal",
    color: theme.palette.text.primary,
  },
}))

const CalendarViewToggleButton = ({ view, onViewChange, size = "small", ml = 1, ...rest }) => {
  const classes = useStyles()

  const handleViewChange = (...args) => {
    onViewChange && onViewChange(...args)
  }

  return (
    <Box ml={ml} {...rest}>
      <ToggleButtonGroup
        value={view}
        exclusive
        className={classes.areaHeaderToggle}
        size={size}
        onChange={handleViewChange}
      >
        <ToggleButton value="week">
          <Box mx={1} className={classes.areaToggleText}>
            Week
          </Box>
        </ToggleButton>
        <ToggleButton value="month">
          <Box mx={1} className={classes.areaToggleText}>
            Month
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export { CalendarViewToggleButton }
