import React, { useState } from "react"
import { Button, Tooltip, useTheme } from "@material-ui/core"
import { Icon } from "../Icon"
import downloadUserTrainingAssessmentExport from "../../data/training/downloadUserTrainingAssessmentExport"
import { useSnackbar } from "../SnackbarProvider"
import { LoadingSpinner } from "../LoadingSpinner"

const TrainingAssessmentDownload = ({ filters, disabled }) => {
  const theme = useTheme()
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const handleDownloadData = async () => {
    setLoading(true)
    snackbar.showMessage({
      message: "Preparing your download",
      icon: <Icon name="document" />,
      chipProps: { style: { backgroundColor: theme.palette.scheduled.main } },
      autoHideDuration: 30000,
    })

    try {
      await downloadUserTrainingAssessmentExport(filters)

      snackbar.showMessage({ message: "Download ready", icon: <Icon name="download" /> })
    } catch {
      snackbar.showMessage({ message: "Download failed", icon: <Icon name="download" />, color: "secondary" })
    }

    setLoading(false)
  }

  return (
    <Tooltip title="Download training assessment">
      <div>
        <Button variant="contained" color="primary" onClick={handleDownloadData} disabled={disabled || loading}>
          {!loading && <Icon name="download" />}
          {loading && <LoadingSpinner size="24px" delay={false} />}
        </Button>
      </div>
    </Tooltip>
  )
}

export default TrainingAssessmentDownload
