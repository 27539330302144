import { useMutation, gql } from "@apollo/client"

const CONFIRM_USERNAME_MUTATION = gql`
  mutation ConfirmUsernameChange($input: MeConfirmUsernameChangeInput!) {
    me {
      confirmUsernameChange(input: $input) {
        firstName
      }
    }
  }
`

const useMutationConfirmUsername = () =>
  useMutation(CONFIRM_USERNAME_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationConfirmUsername }
