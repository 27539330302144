import { Box, Button, Modal, Grid, makeStyles, Tooltip } from "@material-ui/core"
import React, { useEffect, useMemo, useState } from "react"
import useAsyncEffect from "use-async-effect"
import { ColumnBox, FlexBox, Icon, NoItemsMessage, RowBox, SearchInput, TrainingFiltersPanel } from "../../components"
import TrainingAssessmentDownload from "../../components/Training/TrainingAssessmentDownload"
import { useLazyQueryTrainingModules, useQueryTrainingCourses, USER_STATUS } from "../../data"
import { mapToIds, multipleSelectChange, toId, useMountEffect } from "../../utils"
import { compileAdminableUsersFilters as makeUserFilters } from "../people"
import { TrainingCoursesOutlinedSelect } from "../../components/OutlinedSelect/TrainingCoursesOutlinedSelect"
import { TrainingAssessmentTable } from "../../components/Training/TrainingAssessmentTable"

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: "white",
  },
  filterPanel: {
    backgroundColor: theme.palette.shaded.filter,
    paddingLeft: theme.spacing(2),
  },
  searchInput: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  overlay: {
    height: 52,
    width: 400,
    position: "absolute",
    left: "50%",
    right: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  },
  prompt: {
    borderRadius: 8,
    backgroundColor: "#f0f0f0",
    opacity: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  fullscreen: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    backgroundColor: "white",
  },
}))

const initialState = {
  filters: [],
  moduleFilters: [],
}

const statusFilter = [USER_STATUS.INVITED, USER_STATUS.ACTIVE]

const Assessment = () => {
  const classes = useStyles()
  const { data: coursesData } = useQueryTrainingCourses()
  const [loadTrainingModules, { data: modulesData }] = useLazyQueryTrainingModules()
  const [fullscreen, setFullscreen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [searchTextLoading, setSearchTextLoading] = useState(false)
  const [filters, setFilters] = useState(initialState.filters)
  const [moduleFilters, setModuleFilters] = useState(initialState.moduleFilters)
  const [courses, setCourses] = useState(["all"])
  const [userCount, setUserCount] = useState(0)
  const [hasDownload, setHasDownload] = useState(false)

  useMountEffect(() => {
    loadTrainingModules()
  })

  useAsyncEffect(async () => {
    await loadTrainingModules({
      variables: {
        ...moduleFilters.reduce((acc, filter) => {
          acc[filter.type] = [...(acc[filter.type] || []), filter.value]
          return acc
        }, {}),
      },
    })
  }, [moduleFilters, loadTrainingModules])

  useEffect(() => {
    setTimeout(() => setSearchTextLoading(false), 1000)
  }, [fullscreen])

  const handleChangeSearchText = (text) => {
    setSearchTextLoading(true)
    setSearchText(text)
  }

  const handleCoursesChange = (event) => {
    setCourses([...multipleSelectChange(courses, event)])
  }

  const handleFilterChange = (type, event) => {
    const values = event.target.value
    if (type === "trainingCourses") {
      setModuleFilters((prev) => {
        const next = prev.filter((filter) => filter.type !== type)
        next.push(...values.map((value) => ({ type, value })))
        return next
      })
      return
    }

    setFilters((prev) => {
      const next = prev.filter((filter) => filter.type !== type)
      next.push(...values.map((value) => ({ type, value })))
      return next
    })
  }

  const handleClearFilters = () => {
    filters.length && setFilters([])
    moduleFilters.length && setModuleFilters([])
  }

  const handleFilterDelete = ({ type, value }) => {
    if (type === "trainingCourses") {
      setModuleFilters((prev) => prev.filter((filter) => !(filter.type === type && filter.value === value)))
      return
    }
    setFilters((prev) => prev.filter((filter) => !(filter.type === type && filter.value === value)))
  }

  const makeDownloadFilters = () => ({
    adminableUsers: { ...makeUserFilters(searchText, filters, statusFilter) },
    trainingModules: {
      trainingCourses: mapToIds(courses),
    },
  })

  const modules = useMemo(() => {
    if (!modulesData || courses.length === 0 || (courses.length === 1 && courses[0] === "all")) {
      return []
    }

    // return single course in correct module order
    if (courses.length === 1) {
      const course = coursesData?.trainingCourses.find((item) => toId(item) === courses[0])
      if (!course) {
        return []
      }

      return [
        ...course.modules.map((courseModule) =>
          modulesData.trainingModules.find((item) => toId(item) === toId(courseModule))
        ),
      ]
    }

    // try to return in order of courses
    return coursesData?.trainingCourses
      .filter((course) => courses.includes(toId(course)))
      .reduce((acc, course) => {
        const courseModules = course.modules.map((courseModule) =>
          modulesData.trainingModules.find((item) => toId(item) === toId(courseModule))
        )
        return [...acc, ...courseModules]
      }, [])
  }, [courses, coursesData, modulesData])

  useEffect(() => {
    setHasDownload(userCount > 0 && modules.length > 0)
  }, [modules, userCount])

  const handleFullscreen = () => {
    const newFullscreen = !fullscreen
    setFullscreen(newFullscreen)
  }

  const handleEscape = (event) => {
    if (event.key === "Escape") {
      handleFullscreen()
    }
  }

  const handleExitFullscreen = () => {
    if (fullscreen) {
      handleFullscreen()
    }
  }

  const handleUsersLoadComplete = (newUserCount) => {
    setSearchTextLoading(false)
    setUserCount(newUserCount)
  }

  const hasSelectedCourses = courses.length > 0 && courses[0] !== "all"

  const display = (
    <>
      <Box mb={3}>
        <Grid container alignItems="center" className={classes.filterPanel}>
          <Grid item xs={3}>
            <TrainingCoursesOutlinedSelect
              placeholder="Training course"
              value={courses}
              onChange={handleCoursesChange}
              size="small"
              className={classes.select}
              hasAll
              allLabel="Select training course..."
              multiple
            />
          </Grid>
          <Grid item xs={5}>
            <TrainingFiltersPanel
              buttonText=""
              filters={[...filters, ...moduleFilters]}
              filtersOptions={{ hasCourses: false, hasGroups: true, hasLocations: true }}
              onChange={handleFilterChange}
              onClear={handleClearFilters}
              onDelete={handleFilterDelete}
            />
          </Grid>
          <Grid item xs={4}>
            <RowBox alignItems="center">
              <FlexBox flexGrow={1}>
                <SearchInput
                  placeholder="Filter people"
                  initialValue={searchText}
                  debounce={300}
                  onChange={handleChangeSearchText}
                  loading={searchTextLoading}
                  inputProps={{ className: classes.searchInput }}
                  boxProps={{ pr: 0, mb: 0, flexGrow: 1 }}
                />
              </FlexBox>
              <Box ml={2}>
                <TrainingAssessmentDownload filters={makeDownloadFilters()} disabled={!hasDownload} />
              </Box>
              <Box ml={1} mr={2}>
                <Tooltip title="Toggle fullscreen">
                  <div>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={fullscreen ? handleExitFullscreen : handleFullscreen}
                    >
                      <Icon name={fullscreen ? "fullscreen-exit" : "fullscreen"} />
                    </Button>
                  </div>
                </Tooltip>
              </Box>
            </RowBox>
          </Grid>
        </Grid>
      </Box>

      {!hasSelectedCourses && (
        <ColumnBox mt={11} alignItems="center" className={classes.overlay}>
          <NoItemsMessage className={classes.prompt}>Please select one or more training courses</NoItemsMessage>
        </ColumnBox>
      )}

      <TrainingAssessmentTable
        fullscreen={fullscreen}
        filters={filters}
        modules={modules}
        moduleFilters={moduleFilters}
        searchText={searchText}
        searchTextLoading={searchTextLoading}
        onUsersLoadComplete={handleUsersLoadComplete}
      />
    </>
  )

  if (fullscreen) {
    return (
      <Modal open className={classes.backdrop} onKeyDown={handleEscape}>
        <Box className={classes.fullscreen}>{display}</Box>
      </Modal>
    )
  }

  return display
}

export { Assessment }
