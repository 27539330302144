import React, { useState } from "react"
import { Chip, MenuList, Popover } from "@material-ui/core"
import { KeyboardArrowDown } from "@material-ui/icons"
import { RowBox } from ".."
import { useMountEffect } from "../../utils"

const ChipMenu = ({ children, menuRef, chipProps = {}, text }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  useMountEffect(() => {
    if (menuRef) {
      menuRef.current = {
        close: () => setAnchorEl(null),
      }
    }
  })

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)

  return (
    <>
      <Chip
        label={
          <RowBox>
            {text}
            <KeyboardArrowDown style={{ transform: isOpen ? "rotate(180deg)" : undefined }} />
          </RowBox>
        }
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
        }}
        {...chipProps}
      />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: 320,
          },
        }}
      >
        <MenuList>{children}</MenuList>
      </Popover>
    </>
  )
}

export { ChipMenu }
