import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_ACTIONS_PROCESS_STEPS_OVERVIEW = gql`
  fragment ReportActionsProcessStepsOverviewFields on ReportActionProcessStepOverview {
    id
    processStep
    processStepName
    process
    processName
    total
    unresolved
    inProgress
    blocked
    resolved
    cancelled
  }
`

const REPORT_ACTIONS_PROCESS_STEPS_OVERVIEW_QUERY = gql`
  query ReportActionsProcessStepsOverview($input: ReportActionsProcessStepsOverviewQueryInput!) {
    report {
      actions {
        steps {
          overview(input: $input) {
            ...ReportActionsProcessStepsOverviewFields
          }
        }
      }
    }
  }
  ${REPORT_ACTIONS_PROCESS_STEPS_OVERVIEW}
`

const useQueryReportActionsProcessStepsOverview = (options) =>
  useQuery(REPORT_ACTIONS_PROCESS_STEPS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportActionsProcessStepsOverview = (options) =>
  useLazyQuery(REPORT_ACTIONS_PROCESS_STEPS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportActionsProcessStepsOverview, useLazyQueryReportActionsProcessStepsOverview }
