import React, { useState } from "react"
import { Chip, Popover, MenuList, MenuItem, ListItemIcon, Typography, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { KeyboardArrowDown } from "@material-ui/icons"
import { CompletedByAvatar, Icon, RowBox } from ".."
import {
  ACTION_STATUS,
  ACTION_STATUS_LABELS,
  makeUpdateActionStatusOptimisticResponse,
  useMutationUpdateStatusAction,
} from "../../data"
import { useAuth } from "../../services"
import { toId } from "../../utils"
import DialogActionStatus from "./DialogActionStatus"

const useStyles = makeStyles((theme) => {
  const styles = {
    root: {
      minHeight: 67,
    },
    infoTitle: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: "600",
      color: theme.palette.text.primary,
      whiteSpace: "nowrap",
    },
    note: {
      textWrap: "pretty",
      fontSize: 14,
    },
    chip: {
      fontWeight: "600",
      color: "white",
      cursor: "pointer",
    },
  }

  Object.values(ACTION_STATUS).forEach((value) => {
    styles[`chip_${value}`] = {
      backgroundColor: theme.palette.status[value] || "inherit",
    }
    styles[`icon_${value}`] = {
      color: theme.palette.status[value] || "inherit",
    }
  })

  return styles
})

const ActionStatus = ({ action, readOnly = false, onChange }) => {
  const { principal } = useAuth()

  const [updateStatusAction] = useMutationUpdateStatusAction()
  const [openDialog, setOpenDialog] = useState(false)
  const [statusState, setStatusState] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (status) => {
    if ([ACTION_STATUS.CANCELLED, ACTION_STATUS.BLOCKED, ACTION_STATUS.RESOLVED].includes(status)) {
      return handleOpenDialog(status)
    }

    handleUpdateStatus(status)
  }

  const handleOpenDialog = (status) => {
    setOpenDialog(true)
    setStatusState(status)
  }

  const handleUpdateStatus = async (status, note) => {
    handleClose()
    const variables = { id: toId(action), input: { status, note: note ?? "" } }
    await updateStatusAction({
      variables,
      optimisticResponse: makeUpdateActionStatusOptimisticResponse({
        variables,
        action,
        principal,
      }),
    })
    onChange && onChange()
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        className={classes.root}
      >
        <Box mr={3}>
          <Typography className={classes.infoTitle}>Status</Typography>
          <Typography className={classes.note}>{action?.note}</Typography>
        </Box>
        <RowBox>
          {action?.statusAt && action?.statusBy && (
            <CompletedByAvatar at={action.statusAt} by={action.statusBy} mr={1} />
          )}

          <Chip
            label={
              <RowBox>
                {ACTION_STATUS_LABELS[action.status]}
                {!readOnly && <KeyboardArrowDown />}
              </RowBox>
            }
            className={[classes.chip, classes[`chip_${action.status}`]].join(" ")}
            onClick={(event) => {
              if (readOnly) {
                return
              }
              setAnchorEl(event.currentTarget)
            }}
            clickable={false}
          />
        </RowBox>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuList>
          {[
            ACTION_STATUS.CANCELLED,
            ACTION_STATUS.UNRESOLVED,
            ACTION_STATUS.IN_PROGRESS,
            ACTION_STATUS.BLOCKED,
            ACTION_STATUS.RESOLVED,
          ].map((status) => (
            <MenuItem key={status} onClick={() => handleMenuItemClick(status)}>
              <ListItemIcon style={{ minWidth: 30 }}>
                <Icon name="action-status" className={classes[`icon_${status}`]} />
              </ListItemIcon>
              <Typography>{ACTION_STATUS_LABELS[status]}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>

      {openDialog && (
        <DialogActionStatus
          open={openDialog}
          loading={false}
          onClose={() => setOpenDialog(false)}
          status={statusState}
          onSubmit={({ note }) => {
            handleUpdateStatus(statusState, note)
          }}
        />
      )}
    </>
  )
}

export { ActionStatus }
