import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { ErrorBoundary } from "../../ErrorBoundary"
import { mapToIds, toId } from "../../../utils"
import { useAuth } from "../../../services"
import { ColumnBox, RowBox } from "../../Boxes"
import { LoadingSpinner } from "../../LoadingSpinner"
import { NoItemsMessage } from "../../Lists"
import { TablePagination } from "../../Table/TablePagination"
import {
  useQueryReportProcessesJobsCompletionsList,
  useQueryReportProcessesJobsCompletionsCount,
} from "../../../data/report/useQueryReportProcessesJobsCompletions"
import { TablePageIndicator } from "../../Table/TablePageIndicator"
import { MultiSeriesLinearProgress } from "../../LinearProgress/MultiSeriesLinearProgress"
import { TagNames } from "../../Tags/TagNames"
import { TooltipIconButton } from "../../Buttons/TooltipIconButton"
import { Icon } from "../../Icon"

const ProcessesTableReport = ({
  title,
  gt,
  lt,
  processes,
  locations,
  groups,
  tags,
  defaultLimit = 50,
  showTags = true,
  onClickProcess,
}) => {
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const [{ offset, limit }, setOffsetLimit] = useState({ offset: 0, limit: defaultLimit })
  const baseQueryVariables = {
    filter: {
      locations: !locations || locations.includes("all") ? mapToIds(userLocations) : locations,
      dateRange: {
        start: gt,
        end: lt,
      },
      processes: !processes || processes.includes("all") ? undefined : mapToIds(processes),
      tags: !tags || tags.includes("all") ? undefined : mapToIds(tags),
    },
  }
  const { data: listData, loading: listLoading } = useQueryReportProcessesJobsCompletionsList({
    variables: {
      ...baseQueryVariables,
      offset,
      limit,
    },
  })
  const { data: countData, loading: countLoading } = useQueryReportProcessesJobsCompletionsCount({
    variables: baseQueryVariables,
  })

  useEffect(() => {
    setOffsetLimit({ offset: 0, limit: defaultLimit })
  }, [gt, lt, locations, groups, processes, defaultLimit])

  const handlePageChange = (_, page) => {
    setOffsetLimit({
      offset: page * limit,
      limit,
    })
  }

  const handleClickProcess = (process) => {
    onClickProcess && onClickProcess(process)
  }

  const count = countData?.report.processes.jobs.completions.count || 0
  const items = listData?.report.processes.jobs.completions.list || []

  return (
    <>
      <Box>{title}</Box>

      <Box mb={2}>
        <Typography variant="body2" color="textSecondary" component="div">
          <TablePageIndicator subject="templates" loading={countLoading} total={count} limit={limit} offset={offset} />
        </Typography>
      </Box>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Template Name</TableCell>
            <TableCell>Completions</TableCell>
            {showTags && <TableCell>Tags</TableCell>}
            <TableCell>Job Completion</TableCell>
            <TableCell align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((value) => {
            return (
              <ErrorBoundary key={toId(value)}>
                <TableRow>
                  <TableCell>{value.name}</TableCell>
                  <TableCell>
                    {(value.completions.completedOnTime + value.completions.completedLate).toLocaleString()} /{" "}
                    {value.completions.total.toLocaleString()}
                  </TableCell>
                  {showTags && (
                    <TableCell>
                      <TagNames ids={value.tags} />
                    </TableCell>
                  )}
                  <TableCell>
                    <MultiSeriesLinearProgress
                      values={[
                        {
                          value: (value.completions.completedOnTime / value.completions.total) * 100,
                          color: "success",
                        },
                        {
                          value: (value.completions.completedLate / value.completions.total) * 100,
                          color: "warning",
                        },
                        {
                          value: (value.completions.inProgress / value.completions.total) * 100,
                          color: "primary",
                        },
                        {
                          value: (value.completions.uncompletedOverdue / value.completions.total) * 100,
                          color: "secondary",
                        },
                        {
                          value: (value.completions.uncompleted / value.completions.total) * 100,
                          color: "grey",
                        },
                      ]}
                    />
                  </TableCell>
                  <TableCell>
                    <RowBox justifyContent="flex-end">
                      <Box mr={0}>
                        <TooltipIconButton tooltip="View jobs" size="small" onClick={() => handleClickProcess(value)}>
                          <Icon name="forward" />
                        </TooltipIconButton>
                      </Box>
                      {/* <DownloadExportJobButton variant="icon" iconSize="small" iconName="download" job={value} /> */}
                    </RowBox>
                  </TableCell>
                </TableRow>
              </ErrorBoundary>
            )
          })}
        </TableBody>
      </Table>
      {listLoading && items.length === 0 && (
        <ColumnBox alignItems="center" justifyContent="center" py={2}>
          <LoadingSpinner size={60} />
        </ColumnBox>
      )}
      {!listLoading && items.length === 0 && <NoItemsMessage>No matching templates</NoItemsMessage>}
      <TablePagination
        count={count}
        rowsPerPage={limit}
        page={offset / limit}
        boxProps={{
          mt: 2,
        }}
        onPageChange={handlePageChange}
        disabled={listLoading}
      >
        <TablePageIndicator
          subject="templates"
          loading={countLoading}
          total={count}
          limit={limit}
          offset={offset}
          hideWhenNone
        />
      </TablePagination>
    </>
  )
}

export { ProcessesTableReport }
