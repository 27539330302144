import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"
import { ACTION_STATUS } from "../../data"

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: ({ status }) => {
      switch (status) {
        case "sending":
          return theme.palette.primary.main
        default:
          return theme.palette.status[status] || "inherit"
      }
    },
  },
}))

const ActionStatusChip = ({ className, size = "small", label = "", status = "none", ...props }) => {
  const classes = useStyles({ status })

  let displayLabel = label
  if (!displayLabel) {
    switch (status) {
      case "sending":
        displayLabel = "Sending..."
        break
      case ACTION_STATUS.UNRESOLVED:
        displayLabel = "Unresolved"
        break
      case ACTION_STATUS.RESOLVED:
        displayLabel = "Resolved"
        break
      case ACTION_STATUS.CANCELLED:
        displayLabel = "Cancelled"
        break
      case ACTION_STATUS.BLOCKED:
        displayLabel = "Blocked"
        break
      case ACTION_STATUS.IN_PROGRESS:
        displayLabel = "In progress"
        break
      default:
        displayLabel = "Unknown"
    }
  }

  return <Chip classes={classes} size={size} label={displayLabel} {...props} />
}

export { ActionStatusChip }
