import { JobStatusChipMenuItem, JobStatusValueText } from "../ChipMenu/JobStatusChipMenu"
import { FiltersChipMenuItem } from "./FiltersChipMenuItem"

const JobStatusFiltersChipMenuItem = ({ menuItemRef, value, onChange, onExpanded, ...props }) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue)
  }

  return (
    <FiltersChipMenuItem
      menuItemRef={menuItemRef}
      name="Status"
      description={<JobStatusValueText value={value} />}
      onExpanded={onExpanded}
    >
      <JobStatusChipMenuItem value={value} onChange={handleChange} {...props} />
    </FiltersChipMenuItem>
  )
}

export { JobStatusFiltersChipMenuItem }
