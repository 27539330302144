import { Box, makeStyles } from "@material-ui/core"
import React from "react"
import { NavLink } from "react-router-dom"
import { Skeleton } from "@material-ui/lab"
import { ColumnBox, Icon, RowBox } from ".."

const useStyles = makeStyles((theme) => ({
  icon: {
    marginBottom: theme.spacing(0.5),
  },
  statistic: {
    fontSize: 36,
    lineHeight: "36px",
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  label: {
    fontSize: 16,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  breakdown: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  link: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginTop: theme.spacing(1),
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  linkArrow: {
    fontSize: 16,
    marginLeft: theme.spacing(1),
  },
}))

const StatisticDisplay = ({
  icon,
  label,
  value,
  breakdown,
  total,
  link = { text: "Navigate" },
  alignItems = "center",
  justifyContent = "center",
  loading,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <ColumnBox
      component={link?.to ? NavLink : "div"}
      to={link?.to}
      alignItems={alignItems}
      justifyContent={justifyContent}
      className={classes.root}
      py={3}
      {...rest}
    >
      {icon && <Icon name={icon} className={classes.icon} />}
      {!!total && (
        <>
          <Box className={classes.statistic}>
            {loading && <Skeleton variant="text" width={50} />}
            {!loading && <>{Math.floor(Number(parseFloat((value / total) * 100)))}%</>}
          </Box>
          <Box className={classes.label}>{label}</Box>
          <Box className={classes.breakdown}>
            {breakdown}
            {!breakdown && (
              <>
                {value ? value.toLocaleString() : "None"} out of {total.toLocaleString()}
              </>
            )}
          </Box>
        </>
      )}
      {!total && (
        <>
          <Box className={classes.statistic}>
            {loading && <Skeleton variant="text" width={50} />}
            {!loading && <>{value || 0}</>}
          </Box>
          <Box className={classes.label}>{label}</Box>
          <Box className={classes.breakdown}>
            {breakdown}
            {!breakdown && <>{value ? value.toLocaleString() : "No"} items</>}
          </Box>
        </>
      )}
      {link?.to && (
        <RowBox className={classes.link}>
          <Box>{link.text || link.to}</Box>
          <Icon name="forward" className={classes.linkArrow} />
        </RowBox>
      )}
    </ColumnBox>
  )
}

export { StatisticDisplay }
