import { useQuery, gql, useLazyQuery } from "@apollo/client"

const INTEGRATION_LOCATION_ATTRIBUTE_VALUES_QUERY = gql`
  query IntegrationLocationAttributeValues($id: ID!, $key: String!, $searchText: String) {
    integration(id: $id) {
      locationAttributeValues(key: $key, searchText: $searchText) {
        id
        key
        value
      }
    }
  }
`

const INTEGRATION_LOCATION_GROUP_ATTRIBUTE_VALUES_QUERY = gql`
  query IntegrationLocationGroupAttributeValues($id: ID!, $location: ID!, $key: String!, $searchText: String) {
    integration(id: $id) {
      locationGroupAttributeValues(location: $location, key: $key, searchText: $searchText) {
        id
        key
        value
      }
    }
  }
`

const INTEGRATION_GROUP_ATTRIBUTE_VALUES_QUERY = gql`
  query IntegrationGroupAttributeValues($id: ID!, $key: String!, $searchText: String) {
    integration(id: $id) {
      groupAttributeValues(key: $key, searchText: $searchText) {
        id
        key
        value
      }
    }
  }
`

const INTEGRATION_USER_ATTRIBUTE_VALUES_QUERY = gql`
  query IntegrationUserAttributeValues($id: ID!, $key: String!, $searchText: String) {
    integration(id: $id) {
      userAttributeValues(key: $key, searchText: $searchText) {
        id
        key
        value
      }
    }
  }
`

const useQueryIntegrationLocationAttributeValues = (options) =>
  useQuery(INTEGRATION_LOCATION_ATTRIBUTE_VALUES_QUERY, options)

const useLazyQueryIntegrationLocationAttributeValues = () => useLazyQuery(INTEGRATION_LOCATION_ATTRIBUTE_VALUES_QUERY)

const useQueryIntegrationLocationGroupAttributeValues = (options) =>
  useQuery(INTEGRATION_LOCATION_GROUP_ATTRIBUTE_VALUES_QUERY, options)

const useLazyQueryIntegrationLocationGroupAttributeValues = () =>
  useLazyQuery(INTEGRATION_LOCATION_GROUP_ATTRIBUTE_VALUES_QUERY)

const useQueryIntegrationGroupAttributeValues = (options) => useQuery(INTEGRATION_GROUP_ATTRIBUTE_VALUES_QUERY, options)

const useLazyQueryIntegrationGroupAttributeValues = () => useLazyQuery(INTEGRATION_GROUP_ATTRIBUTE_VALUES_QUERY)

const useQueryIntegrationUserAttributeValues = (options) => useQuery(INTEGRATION_USER_ATTRIBUTE_VALUES_QUERY, options)

const useLazyQueryIntegrationUserAttributeValues = () => useLazyQuery(INTEGRATION_USER_ATTRIBUTE_VALUES_QUERY)

export {
  useQueryIntegrationLocationAttributeValues,
  useLazyQueryIntegrationLocationAttributeValues,
  useQueryIntegrationLocationGroupAttributeValues,
  useLazyQueryIntegrationLocationGroupAttributeValues,
  useQueryIntegrationGroupAttributeValues,
  useLazyQueryIntegrationGroupAttributeValues,
  useQueryIntegrationUserAttributeValues,
  useLazyQueryIntegrationUserAttributeValues,
}
