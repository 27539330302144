import { useQuery, useLazyQuery, gql } from "@apollo/client"

const SENSOR_LBS_FIELDS = `
  mobileCountryCode
  mobileNetworkCode
  locationAreaCode
  cellId
`

const SENSOR_FIELDS = `
    id
    serial
    name
    type
    product
    location { id }
    sample { 
      id
      value
      battery
      signal
      at
      type 
      lbs { 
        ${SENSOR_LBS_FIELDS}
      }      
    }
    samples {
      id
      value
      battery
      signal
      at
      type
      lbs { 
        ${SENSOR_LBS_FIELDS}
      }
    }
`

const SENSORS_QUERY = gql`
  query Sensors($location: ID!) {
    sensors(location: $location) {
      ${SENSOR_FIELDS}
    }
  }
`

const useQuerySensors = (options) => useQuery(SENSORS_QUERY, options)

const useLazyQuerySensors = (options) => useLazyQuery(SENSORS_QUERY, options)

export { useQuerySensors, useLazyQuerySensors, SENSOR_FIELDS }
