import { Box } from "@material-ui/core"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import React, { useEffect, useMemo, useState } from "react"
import { JobStepCompletionChart, ReportSectionActionCard, RowBox } from ".."
import { useLazyQueryReportUsersJobsOverview } from "../../data"
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.rank - b.rank)

const UsersJobsOverviewReport = ({ gt, lt, locations, groups, users, containerHeight = 300, onJobClick }) => {
  const [sort, setSort] = useState("alpha")
  const [loadReportUserJobsOverview, { data, loading }] = useLazyQueryReportUsersJobsOverview()
  const [fullscreen, setFullscreen] = useState(false)

  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    if (!gt) {
      return
    }

    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterGroups = groups.includes("all") ? null : groups

    loadReportUserJobsOverview({
      variables: { users: users ? mapToIds(users) : [], locations: filterLocations, groups: filterGroups, gt, lt },
    })
  }, [groups, gt, loadReportUserJobsOverview, locations, lt, userLocations, users])

  const handleFullscreen = (newFullscreen) => {
    setFullscreen(newFullscreen)
  }

  const handleJobClick = ({ process }) => {
    onJobClick && onJobClick(process)
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const chartData = useMemo(
    () =>
      data && data.reportUsersJobsOverview[0]
        ? data.reportUsersJobsOverview[0].jobs
            .map(
              ({
                process,
                name,
                stepsCompleted: completed,
                stepsOverdue: late,
                stepsSkipped: skipped,
                stepsUncompleted: uncompleted,
              }) => ({
                argument: process,
                name,
                process,
                completed,
                late,
                uncompleted,
                skipped,
                rank: (completed + late + skipped) / (completed + late + uncompleted + skipped),
              })
            )
            .reverse()
            .sort((a, b) => sortData(sort, a, b))
        : [],
    [data, sort]
  )

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = ["Name", "On-time", "Late", "Skipped", "Uncompleted"]
    const rows = [...chartData]
      .reverse()
      .sort((a, b) => sortData(sort, a, b))
      .map(({ name, completed, late, skipped, uncompleted }) =>
        [stringFormatter(name), completed, late, skipped, uncompleted].join(",")
      )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "overview-jobs-step-completion.csv", "text/csv")
  }

  return (
    <ReportSectionActionCard
      title="Jobs"
      detail="Step completion % by job"
      loading={loading}
      onFullscreen={handleFullscreen}
      tools={
        <RowBox>
          <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
          <Box ml={1}>
            <ReportToolsIconButton
              title={sort === "alpha" ? "Sort by completions %" : "Sort alphabetically"}
              icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
              onClick={handleSort}
              disabled={loading}
            />
          </Box>
        </RowBox>
      }
    >
      {chartData && (
        <JobStepCompletionChart
          data={chartData}
          rotated
          barWidth={0.75}
          argumentLabels
          height={chartData.length * 32}
          containerHeight={fullscreen ? null : containerHeight}
          variant="full-stacked-bar"
          onClick={handleJobClick}
          truncateLabelAt={25}
        />
      )}
    </ReportSectionActionCard>
  )
}

export default UsersJobsOverviewReport
