import { actionsTypePolicies } from "./actions"
import { areasTypePolicies } from "./areas"
import { assetsTypePolicies } from "./assets"
import { integrationQueryTypePolicies } from "./integrations"
import { jobsTypePolicies } from "./jobs"
import { notificationPostTypePolicies } from "./notifications"
import { postsTypePolicies, postTypePolicies } from "./posts"
import { processesQueryTypePolicies, processTypePolicies } from "./processes"
import { sensorTypePolicies } from "./sensors"
import { suppliersTypePolicies } from "./suppliers"
import { trainingCourseTypePolicies } from "./training"
import { adminableUsersQueryTypePolicies, userAccreditationTypePolicies, userTrainingsQueryTypePolicies } from "./users"
import { reportTrainingUsersQueryTypePolicies } from "./report"
import { loginResultTypePolicies } from "./users/fragments/loginFieldsFragment"

const typePolicies = {
  Query: {
    fields: {
      ...adminableUsersQueryTypePolicies.root,
      ...userTrainingsQueryTypePolicies.root,
      ...jobsTypePolicies.root,
      ...loginResultTypePolicies.root,
      ...reportTrainingUsersQueryTypePolicies.root,
    },
  },
  ...actionsTypePolicies.scoped,
  ...areasTypePolicies.scoped,
  ...assetsTypePolicies.scoped,
  ...adminableUsersQueryTypePolicies.scoped,
  ...processesQueryTypePolicies,
  ...integrationQueryTypePolicies,
  ...jobsTypePolicies.scoped,
  ...loginResultTypePolicies.scoped,
  ...postTypePolicies,
  ...postsTypePolicies.scoped,
  ...processTypePolicies,
  ...notificationPostTypePolicies,
  ...sensorTypePolicies,
  ...suppliersTypePolicies.scoped,
  ...trainingCourseTypePolicies,
  ...userAccreditationTypePolicies,
  ...userTrainingsQueryTypePolicies.scoped,
  ...reportTrainingUsersQueryTypePolicies.scoped,
}

export { typePolicies }
