import React from "react"
import { PaperBox } from "../Boxes"
import { StatisticDisplay } from "../Charts/StatisticDisplay"
import { useQueryReportActionsOverallCount } from "../../data/report/useQueryReportActionsOverall"
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"

const ActionsStatistic = ({ gt, lt, locations, groups, tags, link }) => {
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data, loading } = useQueryReportActionsOverallCount({
    variables: {
      input: {
        gt,
        lt,
        locations: locations.includes("all") ? mapToIds(userLocations) : locations,
        groups,
        tags,
      },
    },
  })

  return (
    <PaperBox>
      <StatisticDisplay
        icon="actions-dark"
        label="Actions raised"
        breakdown="In this period"
        value={data?.report?.actions?.overall?.count?.toLocaleString() || 0}
        link={link}
        loading={loading}
      />
    </PaperBox>
  )
}

export { ActionsStatistic }
