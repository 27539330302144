import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, Popover, Grid } from "@material-ui/core"
import { FilterList as FilterListIcon } from "@material-ui/icons"
import PopupState, { anchorRef, bindTrigger, bindMenu } from "material-ui-popup-state"
import { GroupOutlinedSelect, LocationOutlinedSelect } from ".."
import { useAuth } from "../../services"
import { TagOutlinedSelect } from "../OutlinedSelect/TagOutlinedSelect"

const useFiltersPopoverStyles = makeStyles((theme) => ({
  root: {
    width: 512,
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const filterAll = (item) => item !== "all"

const KnowledgeFiltersButton = ({
  groups,
  locations,
  tags = [],
  onGroupsChange,
  onLocationsChange,
  onRegionChange,
  onTagsChange,
}) => {
  const filtersPopoverClasses = useFiltersPopoverStyles()
  const { hasPermission, hasFeature } = useAuth()

  const count = useMemo(
    () => groups.filter(filterAll).length + locations.filter(filterAll).length + tags.filter(filterAll).length,
    [groups, locations, tags]
  )

  const handleGroupsChanged = (event) => {
    onGroupsChange && onGroupsChange(event)
  }

  const handleLocationsChanged = (event) => {
    onLocationsChange && onLocationsChange(event)
  }

  const handleRegionChange = (regionLocations) => {
    onRegionChange && onRegionChange(regionLocations)
  }

  const handleFilterChange = (event) => {
    if (onTagsChange) onTagsChange(event)
  }

  const usersGroupsOnly = !hasPermission("knowledge_update_all")

  const hasAreas = hasFeature("areas")

  return (
    <PopupState variant="popover" popupId="bulk-actions">
      {(popupState) => (
        <>
          <Button variant="contained" color="primary" {...bindTrigger(popupState)} ref={anchorRef(popupState)}>
            <FilterListIcon />
            {Boolean(count) && <Box ml={0.5}>({count})</Box>}
          </Button>
          <Popover
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={filtersPopoverClasses}
          >
            <Box p={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <GroupOutlinedSelect
                    value={groups}
                    onChange={handleGroupsChanged}
                    multiple
                    usersGroupsOnly={usersGroupsOnly}
                    data-cy="KnowledgeFiltersButton-groups"
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationOutlinedSelect
                    value={locations}
                    onChange={handleLocationsChanged}
                    onRegionChange={handleRegionChange}
                    multiple
                    data-cy="KnowledgeFiltersButton-locations"
                  />
                </Grid>
                {hasAreas && (
                  <Grid item xs={12}>
                    <TagOutlinedSelect
                      value={tags}
                      onChange={handleFilterChange}
                      multiple
                      data-cy="KnowledgeFiltersButton-tags"
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  )
}

export { KnowledgeFiltersButton }
