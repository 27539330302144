import React, { memo } from "react"
import { makeStyles } from "@material-ui/core"
import { TooltipIcon, LinearProgress } from ".."
import { USER_TRAINING_STATUS } from "../../data"

const useStyles = makeStyles((theme) => ({
  iconRef: {
    display: "flex",
    justifyContent: "center",
  },
  none: {
    fill: theme.palette.text.faint,
    cursor: "pointer",
  },
  progress: {
    cursor: "pointer",
  },
  expired: {
    fill: theme.palette.error.main,
    cursor: "pointer",
  },
  complete: {
    fill: theme.palette.success.main,
    cursor: "pointer",
  },
}))

const PersonTrainingStatus = memo(function PersonTrainingStatus({ user, onClick }) {
  const classes = useStyles()
  const {
    training: { percentComplete, status },
  } = user

  const handleClick = () => {
    onClick && onClick(user)
  }

  if (percentComplete === null) {
    return (
      <TooltipIcon
        tooltip="No training assigned"
        name="none"
        className={classes.none}
        refClassName={classes.iconRef}
        onClick={handleClick}
      />
    )
  }

  if (status === USER_TRAINING_STATUS.OVERDUE) {
    return (
      <TooltipIcon
        tooltip="Overdue"
        name="warning"
        className={classes.expired}
        refClassName={classes.iconRef}
        onClick={handleClick}
      />
    )
  }

  if (percentComplete < 100) {
    return (
      <LinearProgress
        value={percentComplete}
        title={`${percentComplete}%`}
        className={classes.progress}
        onClick={handleClick}
      />
    )
  }

  return (
    <TooltipIcon
      tooltip="All training completed"
      name="training-module-complete"
      className={classes.complete}
      refClassName={classes.iconRef}
      onClick={handleClick}
    />
  )
})

export { PersonTrainingStatus }
