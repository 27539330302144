import React from "react"
import { ChevronRight } from "@material-ui/icons"
import { NavLink } from "react-router-dom"
import { ActionCard } from "."
import { useKnowledgeUtils } from "../../utils"

const KnowledgeActionCard = ({ knowledge: { id, title } }) => {
  const { getNavigateToArticleLinkForID } = useKnowledgeUtils()

  return (
    <ActionCard
      key={id}
      title={title}
      detail="Knowledge base article"
      cardProps={{ component: NavLink, to: { pathname: getNavigateToArticleLinkForID(id) } }}
      rightChildren={<ChevronRight />}
    />
  )
}

export { KnowledgeActionCard }
