import { Grid, useTheme } from "@material-ui/core"
import React, { useEffect, useMemo } from "react"
import { CircularStepCompletionChart, NumberDisplay, ReportSectionActionCard } from ".."
import { useLazyQueryReportUsersJobsOverview } from "../../data"
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"

const JobsStepCompletionStatisticsReport = ({ gt, lt, locations, groups, users }) => {
  const theme = useTheme()

  const [loadReportUserJobsOverview, { data: jobsOverviewData, loading: jobsOverviewLoading }] =
    useLazyQueryReportUsersJobsOverview()

  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    if (!gt) {
      return
    }

    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterGroups = groups.includes("all") ? null : groups

    loadReportUserJobsOverview({
      variables: { users: users ? mapToIds(users) : [], locations: filterLocations, groups: filterGroups, gt, lt },
    })
  }, [groups, gt, loadReportUserJobsOverview, locations, lt, userLocations, users])

  const jobsStepStatisticsData = useMemo(() => {
    if (!jobsOverviewData?.reportUsersJobsOverview.length) {
      return {
        stepsCompleted: 0,
        stepsOverdue: 0,
        stepsSkipped: 0,
        stepsUncompleted: 0,
        stepsTotal: 0,
      }
    }

    let result = jobsOverviewData?.reportUsersJobsOverview.map((user) => {
      if (!user.jobs.length) {
        return {
          stepsCompleted: 0,
          stepsOverdue: 0,
          stepsSkipped: 0,
          stepsUncompleted: 0,
        }
      }

      return user.jobs
        .map((job) => ({ ...job }))
        .reduce((acc, { stepsCompleted, stepsOverdue, stepsSkipped, stepsUncompleted }) => {
          acc.stepsCompleted += stepsCompleted
          acc.stepsOverdue += stepsOverdue
          acc.stepsSkipped += stepsSkipped
          acc.stepsUncompleted += stepsUncompleted

          acc.stepsTotal = acc.stepsCompleted + acc.stepsOverdue + acc.stepsSkipped + acc.stepsUncompleted
          return acc
        })
    })

    result = result.reduce(
      (acc, { stepsCompleted, stepsOverdue, stepsSkipped, stepsUncompleted }) => {
        acc.stepsCompleted += stepsCompleted
        acc.stepsOverdue += stepsOverdue
        acc.stepsSkipped += stepsSkipped
        acc.stepsUncompleted += stepsUncompleted

        return acc
      },
      {
        stepsCompleted: 0,
        stepsOverdue: 0,
        stepsSkipped: 0,
        stepsUncompleted: 0,
      }
    )

    result.stepsTotal = result.stepsCompleted + result.stepsOverdue + result.stepsSkipped + result.stepsUncompleted

    return result
  }, [jobsOverviewData])

  const layout = users?.length ? "user" : "row"

  const cardLayout = layout === "user" ? "column" : "row"

  const overallChart = (
    <ReportSectionActionCard
      p={0}
      loading={jobsOverviewLoading}
      cardProps={{ style: { height: jobsOverviewLoading ? "100%" : "auto" } }}
      fullHeight
      allowFullscreen={false}
    >
      <CircularStepCompletionChart
        mr={0}
        mb={0}
        text="Jobs overall"
        data={{
          completed: jobsStepStatisticsData.stepsCompleted || 0,
          overdue: jobsStepStatisticsData.stepsOverdue || 0,
          skipped: jobsStepStatisticsData.stepsSkipped || 0,
          uncompleted: jobsStepStatisticsData.stepsUncompleted || 0,
          total: jobsStepStatisticsData.stepsTotal || 0,
        }}
      />
    </ReportSectionActionCard>
  )

  const stepsCompletedChart = (
    <ReportSectionActionCard
      title="Jobs"
      detail="Steps completed on time"
      loading={jobsOverviewLoading}
      allowFullscreen={false}
      layout={cardLayout}
    >
      <NumberDisplay
        value={jobsStepStatisticsData.stepsCompleted}
        total={jobsStepStatisticsData.stepsTotal}
        color={theme.palette.success.main}
        subject="steps"
      />
    </ReportSectionActionCard>
  )

  const stepsLateChart = (
    <ReportSectionActionCard
      title="Jobs"
      detail="Steps completed late"
      loading={jobsOverviewLoading}
      allowFullscreen={false}
      layout={cardLayout}
    >
      <NumberDisplay
        value={jobsStepStatisticsData.stepsOverdue}
        total={jobsStepStatisticsData.stepsTotal}
        color={theme.palette.error.light}
        subject="steps"
      />
    </ReportSectionActionCard>
  )

  const stepsSkippedChart = (
    <ReportSectionActionCard
      title="Jobs"
      detail="Steps skipped"
      loading={jobsOverviewLoading}
      allowFullscreen={false}
      layout={cardLayout}
    >
      <NumberDisplay
        value={jobsStepStatisticsData.stepsSkipped}
        total={jobsStepStatisticsData.stepsTotal}
        color={theme.palette.primary.main}
        subject="steps"
      />
    </ReportSectionActionCard>
  )

  const stepsUncompletedChart = (
    <ReportSectionActionCard
      title="Jobs"
      detail="Steps uncompleted"
      loading={jobsOverviewLoading}
      allowFullscreen={false}
      layout={cardLayout}
    >
      <NumberDisplay
        value={jobsStepStatisticsData.stepsUncompleted}
        total={jobsStepStatisticsData.stepsTotal}
        color={theme.palette.text.faint}
        subject="steps"
      />
    </ReportSectionActionCard>
  )

  if (layout === "row") {
    return (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {stepsCompletedChart}
            </Grid>
            <Grid item xs={6}>
              {stepsLateChart}
            </Grid>
            <Grid item xs={6}>
              {stepsSkippedChart}
            </Grid>
            <Grid item xs={6}>
              {stepsUncompletedChart}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {overallChart}
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Grid item xs={2}>
        {overallChart}
      </Grid>
      <Grid item xs={3}>
        {stepsCompletedChart}
      </Grid>
      <Grid item xs={3}>
        {stepsLateChart}
      </Grid>
      <Grid item xs={3}>
        {stepsSkippedChart}
      </Grid>
      <Grid item xs={3}>
        {stepsUncompletedChart}
      </Grid>
    </>
  )
}

export default JobsStepCompletionStatisticsReport
