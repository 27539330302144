import React from "react"
import ReactPlayer from "react-player/lazy"

const ReactLoomPlayer = ({ src }) => {
  return (
    <div style={{ width: 640, height: 360 }}>
      <div style={{ width: "100%", height: "100%" }}>
        <iframe allowFullScreen title="Loom iframe" width="100%" height="100%" src={src} style={{ border: "none" }} />
      </div>
    </div>
  )
}

const tryGetUrl = (value) => {
  try {
    return new URL(value)
  } catch {
    return null
  }
}

const KnowledgeArticleContentVideo = ({ value, controls = true, ...rest }) => {
  const url = tryGetUrl(value)

  if (!url) {
    return <>Unable to display video player, media URL is invalid.</>
  }
  if (url.host.endsWith("loom.com")) {
    const embedUrl = value.replace("share", "embed")
    return <ReactLoomPlayer src={embedUrl} />
  }
  return <ReactPlayer url={value} controls={controls} {...rest} />
}

export { KnowledgeArticleContentVideo }
