import { useMutation, gql } from "@apollo/client"

const CANCEL_USERNAME_CHANGE_MUTATION = gql`
  mutation CancelUsernameChange {
    me {
      cancelUsernameChange
    }
  }
`

const useMutationCancelUsernameChange = () =>
  useMutation(CANCEL_USERNAME_CHANGE_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationCancelUsernameChange }
