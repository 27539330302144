import React, { useEffect, useState } from "react"
import { Box, Button, Divider, Tab, Tabs } from "@material-ui/core"
import { useParams, useHistory } from "react-router-dom"
import { RequirePermissions, NoItemsMessage, LoadingSpinner, SearchInput, Icon } from "../../components"
import { useQueryAdminableRoles } from "../../data"
import { toId } from "../../utils"
import SettingsFooter from "../../components/Settings/SettingsFooter"
import { RoleActionCard } from "../../components/Roles/RoleActionCard"
import { ListWindowVirtualizer } from "../../components/Lists/ListWindowVirtualizer"
import { useMutationDeleteRole } from "../../data/roles/useMutationRole"
import { RoleViewer } from "../../components/Roles/RoleViewer"
import { RoleCreator } from "../../components/Roles/RoleCreator"

const baseUri = "/account/roles"

const Roles = () => {
  const { scope, id, action } = useParams()
  const history = useHistory()
  const { data, refetch, loading } = useQueryAdminableRoles()
  const [deleteRole] = useMutationDeleteRole()
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    if (!scope) {
      history.push(`${baseUri}/organisation`)
    }
  }, [scope, history])

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleCreate = () => {
    history.push(`${baseUri}/organisation/new`)
  }
  const handleClose = (closingScope) => {
    history.push(`${baseUri}/${closingScope}`)
  }

  const handleTabClick = (newScope) => {
    history.push(`${baseUri}/${newScope}`)
  }

  const handleEdit = (editRole) => {
    history.push(`${baseUri}/${scope}/${toId(editRole)}/edit`)
  }

  const handleDelete = async (role) => {
    await deleteRole({
      variables: {
        id: toId(role),
      },
    })
    refetch()
  }

  const hasFilter = data?.roles.length > 5

  const items =
    data?.roles.filter(
      (item) =>
        (scope === "organisation" ? item.isCustom : !item.isCustom) &&
        (!searchText || item.name.toLowerCase().includes(searchText))
    ) || []

  const edit = (Boolean(id) && action === "edit") || id === "new"

  const view = Boolean(id) && action === "view"

  return (
    <>
      <RoleCreator key={`${id}-creator`} open={edit} id={id} onClose={handleClose} />

      <RoleViewer key={`${id}-viewer`} open={view} id={id} onClose={handleClose} />

      <Tabs
        variant="standard"
        value={scope}
        indicatorColor="primary"
        textColor="primary"
        aria-label="Select role scope"
      >
        <Tab label="Custom" value="organisation" onClick={() => handleTabClick("organisation")} />
        <Tab label="Built-in" value="common" onClick={() => handleTabClick("common")} />
      </Tabs>

      <Divider />

      <Box mt={3} pb={5}>
        {hasFilter && <SearchInput placeholder="Filter" boxProps={{ pl: 0, pr: 0 }} onChange={handleFilter} />}

        <Box mb={5} data-cy="Roles-list">
          <ListWindowVirtualizer
            items={items}
            itemContent={(_, role) => (
              <RoleActionCard key={toId(role)} role={role} onEdit={handleEdit} onDelete={handleDelete} />
            )}
            components={{
              Footer: () => <Box my={10}>&nbsp;</Box>,
            }}
          />

          {!data && loading && (
            <Box display="flex" justifyContent="center">
              <LoadingSpinner size={60} />
            </Box>
          )}

          {data && items.length === 0 && <NoItemsMessage>No custom permission levels found</NoItemsMessage>}
        </Box>
      </Box>

      {scope === "organisation" && (
        <RequirePermissions requires="role_create">
          <SettingsFooter>
            <Button variant="contained" color="primary" onClick={handleCreate} data-cy="Button-create-role">
              <Icon name="add" />
              <Box ml={1}>Add custom permission level</Box>
            </Button>
          </SettingsFooter>
        </RequirePermissions>
      )}
    </>
  )
}

export default Roles
