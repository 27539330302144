import { useQuery, gql } from "@apollo/client"

const REPORT_ACTIONS_OVERALL_COUNT_QUERY = gql`
  query ReportActionsOverallCount($input: ReportActionsOverallInput!) {
    report {
      actions {
        overall {
          count(input: $input)
        }
      }
    }
  }
`

const useQueryReportActionsOverallCount = (options) =>
  useQuery(REPORT_ACTIONS_OVERALL_COUNT_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportActionsOverallCount }
