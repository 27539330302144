import { Box, makeStyles, TableCell, TableRow } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  cell: {
    borderBottom: "none",
  },
  bar: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 8,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
  },
}))

const SeparatorBarTableRow = ({ colSpan, children }) => {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell colSpan={colSpan} padding="none" className={classes.cell}>
        <Box className={classes.bar}>{children}</Box>
      </TableCell>
    </TableRow>
  )
}

export { SeparatorBarTableRow }
