import React, { useState, useEffect } from "react"
import { Box, makeStyles, Modal, IconButton, useTheme, useMediaQuery } from "@material-ui/core"
import { Close, ChevronLeft, ChevronRight, ErrorOutline } from "@material-ui/icons"
import { FormatBytes } from "../Format"
import { LoadingSpinner, ColumnBox } from ".."
import { DisplayImage } from "./DisplayImage"
import { preventDefault, useStringUtils } from "../../utils"
import { RowBox } from "../Boxes"

const useStyle = makeStyles((theme) => ({
  count: {
    position: "absolute",
    margin: "0 auto",
    top: 20,
    fontSize: 16,
    color: "#ffffff",
    filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, .7))",
    zIndex: 999,
  },
  full: {
    outline: "none",
    position: "relative",
  },
  progress: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  error: {
    position: "absolute",
    width: "100%",
    height: "100%",
    fontSize: 18,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  close: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 999,
  },
  buttonIcon: {
    color: "#ffffff",
    fontSize: 42,
    filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, .7))",
  },
  prev: {
    position: "absolute",
    left: 0,
    marginTop: -30,
    top: "50%",
    zIndex: 999,
  },
  next: {
    position: "absolute",
    right: 0,
    marginTop: -30,
    top: "50%",
    zIndex: 999,
  },
  imageArea: {
    minWidth: ({ fullWidth }) => fullWidth,
    minHeight: 100,
  },
  image: {
    objectFit: "contain",
    height: "100%",
    maxHeight: "90vh",
    width: "100%",
    maxWidth: "95vw",
    filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, .7))",
  },
  light: {
    color: theme.palette.text.secondary,
  },
  infoContainer: {
    position: "absolute",
    margin: "0 auto",
    bottom: 20,
    fontSize: 16,
    color: "#ffffff",
    filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, .7))",
    zIndex: 999,
  },
  info: {
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: ({ fullWidth }) => fullWidth * 0.8,
  },
  size: {
    color: "#ccc",
  },
}))

const KEY = { RIGHT: "ArrowRight", LEFT: "ArrowLeft", ESC: "Escape", ENTER: "Enter" }

const ImagesViewerModal = ({ uploads, startIndex, onClose }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const sm = useMediaQuery(theme.breakpoints.only("sm"))
  const { truncateInMiddle } = useStringUtils()
  const fullWidth = xs ? 310 : sm ? 800 : 1100
  const classes = useStyle({ fullWidth })

  const [index, setIndex] = useState(startIndex || 0)
  const [controls, setControls] = useState(true)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const handleNavigate = (delta) => {
    const newIndex = index + delta
    if (!uploads || newIndex < 0 || newIndex > uploads.length - 1) return
    setLoading(true)
    setError(false)
    setIndex(newIndex)
  }

  const handleLoaded = () => {
    setLoading(false)
  }

  const handleError = () => {
    setLoading(false)
    setError(true)
  }

  const handleImageClick = () => {
    setControls(!controls)
  }

  const handleClose = () => {
    onClose && onClose()
  }

  useEffect(() => {
    const handleKeyNavigate = (delta) => {
      const newIndex = index + delta
      if (!uploads || newIndex < 0 || newIndex > uploads.length - 1) return
      setLoading(true)
      setError(false)
      setIndex(newIndex)
    }
    const downHandler = ({ key }) => {
      if (key === KEY.LEFT) handleKeyNavigate(-1)
      if (key === KEY.RIGHT) handleKeyNavigate(1)
      if (key === KEY.ENTER) setControls(!controls)
      if (key === KEY.ESC) {
        onClose && onClose()
      }
    }

    window.addEventListener("keydown", downHandler)
    return () => {
      window.removeEventListener("keydown", downHandler)
    }
  }, [controls, index, onClose, uploads])

  const openItem = uploads[index]

  return (
    <Box onClick={preventDefault}>
      <Modal
        open
        onClose={handleClose}
        className={classes.modal}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.9)" } }}
      >
        <>
          {controls && (
            <>
              <Box className={classes.count}>
                {index + 1} of {uploads.length}
              </Box>
              <Box className={classes.infoContainer}>
                <RowBox>
                  <Box alignSelf="center" className={classes.info} mr={1} title={openItem.fileName}>
                    {truncateInMiddle(openItem.fileName, 50)}
                  </Box>
                  <Box alignSelf="center" className={classes.size}>
                    <FormatBytes bytes={openItem.fileSize} />
                  </Box>
                </RowBox>
              </Box>
              <IconButton onClick={handleClose} className={classes.close}>
                <Close fontSize="large" className={classes.buttonIcon} />
              </IconButton>
              {index > 0 && (
                <IconButton onClick={() => handleNavigate(-1)} className={classes.prev}>
                  <ChevronLeft fontSize="large" className={classes.buttonIcon} />
                </IconButton>
              )}
              {index < uploads.length - 1 && (
                <IconButton onClick={() => handleNavigate(1)} className={classes.next}>
                  <ChevronRight fontSize="large" className={classes.buttonIcon} />
                </IconButton>
              )}
            </>
          )}
          <Box className={classes.full}>
            {loading && (
              <ColumnBox className={classes.progress} alignItems="center" justifyContent="center">
                <LoadingSpinner size={90} delay={false} />
              </ColumnBox>
            )}
            {error && (
              <ColumnBox className={classes.error} alignItems="center" justifyContent="center">
                <ErrorOutline fontSize="large" /> Error loading image
              </ColumnBox>
            )}
            <Box className={classes.imageArea} onClick={handleImageClick}>
              {!error && (
                <DisplayImage
                  upload={openItem}
                  width={fullWidth}
                  alt={openItem.fileName}
                  onLoad={handleLoaded}
                  onError={handleError}
                  className={classes.image}
                />
              )}
            </Box>
          </Box>
        </>
      </Modal>
    </Box>
  )
}

export { ImagesViewerModal }
