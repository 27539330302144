import React from "react"
import { Box, makeStyles } from "@material-ui/core"
import { Caption, FieldSectionHeading } from ".."
import { RowBox } from "../Boxes"
import { SubmitJobButton } from "./SubmitJobButton"
import { SubmittedAuditStatus } from "./SubmittedAuditStatus"

const useStyles = makeStyles((theme) => ({
  caption: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "inline-block",
  },
}))

const AuditFooterActions = ({ job, onResetMessage, disabled }) => {
  const classes = useStyles()
  const {
    status: { completed, submitted, steps },
  } = job

  const hasUncompletedMandatorySteps = steps.some((step) => step.responseMandatory && !step.completedAt)

  return (
    <RowBox flexGrow={1} px={2} py={1}>
      {!submitted && (
        <>
          {!completed && (
            <Box>
              <FieldSectionHeading mb={0.75}>Complete this audit</FieldSectionHeading>
              <Caption mb={0} className={classes.caption}>
                {hasUncompletedMandatorySteps && <>Please complete all required steps in this audit.</>}
                {!hasUncompletedMandatorySteps && <>When finished, press submit to finalise this audit.</>}
              </Caption>
            </Box>
          )}

          {completed && (
            <Box>
              <FieldSectionHeading mb={0.75}>Complete this audit</FieldSectionHeading>
              <Caption mb={0} className={classes.caption}>
                When finished, press submit to finalise this audit.
              </Caption>
            </Box>
          )}
        </>
      )}

      {submitted && (
        <Box>
          <FieldSectionHeading mb={0.75}>Audit submitted</FieldSectionHeading>
          <SubmittedAuditStatus job={job} onResetMessage={onResetMessage} />
        </Box>
      )}

      <Box ml="auto">
        <SubmitJobButton job={job} disabled={disabled} />
      </Box>
    </RowBox>
  )
}

export { AuditFooterActions }
