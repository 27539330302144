import { useMutation, gql } from "@apollo/client"
import { REGION_FIELDS } from "./useQueryRegions"

const UPDATE_REGION_MUTATION = gql`
  mutation UpdateRegion($id: ID!, $input: RegionUpdateMutationInput!) {
    region(id: $id) {
      update(input: $input) {
        ...RegionFields
      }
    }
  }
  ${REGION_FIELDS}
`

const useMutationUpdateRegion = () => useMutation(UPDATE_REGION_MUTATION)

export { useMutationUpdateRegion }
