import React, { useEffect, useRef, useState } from "react"
import pluralize from "pluralize"
import { ActionStatusOutlinedSelect } from ".."
import { ChipMenu } from "./ChipMenu"
import { ChipMenuItem } from "./ChipMenuItem"
import { ChipMenuActions } from "./ChipMenuActions"
import { multipleSelectChange } from "../../utils"

const ActionStatusValueText = ({ value }) => {
  if (!value) {
    return "Status"
  }

  if (value === "all" || (Array.isArray(value) && value.includes("all"))) {
    return "All statuses"
  }

  const count = Array.isArray(value) ? value.length : 1

  return `${pluralize("status", count, true)}`
}

const ActionStatusChipMenuItem = ({ value, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange && onChange(multipleSelectChange(value, event.target.value))
  }

  return (
    <ChipMenuItem>
      <ActionStatusOutlinedSelect value={value} onChange={handleChange} {...props} hasAll multiple />
    </ChipMenuItem>
  )
}

const ActionStatusChipMenu = ({ value, onChange, ...props }) => {
  const [nextValue, setNextValue] = useState(value)
  const menuRef = useRef()

  useEffect(() => {
    setNextValue(value)
  }, [value])

  const handleChange = (newValue) => {
    setNextValue(newValue)
  }

  const handleApply = () => {
    menuRef.current?.close()
    onChange && onChange(nextValue)
  }

  return (
    <ChipMenu menuRef={menuRef} text={<ActionStatusValueText value={value} />}>
      <ActionStatusChipMenuItem value={nextValue} onChange={handleChange} {...props} />
      <ChipMenuActions onApply={handleApply} />
    </ChipMenu>
  )
}

export { ActionStatusChipMenu, ActionStatusChipMenuItem, ActionStatusValueText }
