import React from "react"
import { MenuItem } from "@material-ui/core"
import { OutlinedSelect } from "../OutlinedSelect"
import { TRIGGER_NOTIFY_METHOD, TRIGGER_NOTIFY_METHOD_LABELS } from "../../data"

const { IN_APP, EMAIL, PUSH } = TRIGGER_NOTIFY_METHOD

const TriggerNotifyMethodOutlinedSelect = ({
  label = "Which communication methods?",
  allowInApp = true,
  allowPush = true,
  ...props
}) => (
  <OutlinedSelect label={label} native={false} {...props}>
    {allowInApp && <MenuItem value={IN_APP}>{TRIGGER_NOTIFY_METHOD_LABELS[IN_APP]}</MenuItem>}
    <MenuItem value={EMAIL}>{TRIGGER_NOTIFY_METHOD_LABELS[EMAIL]}</MenuItem>
    {allowPush && <MenuItem value={PUSH}>{TRIGGER_NOTIFY_METHOD_LABELS[PUSH]}</MenuItem>}
  </OutlinedSelect>
)

export default TriggerNotifyMethodOutlinedSelect
