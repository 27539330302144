import { useRef } from "react"
import { useMountEffect } from "./useMountEffect"

const useUnmount = (fn) => {
  const fnRef = useRef(fn)

  fnRef.current = fn

  useMountEffect(() => () => fnRef.current())
}

export default useUnmount
