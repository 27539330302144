function makeSubscribeToMore({ document, ...rest }) {
  return {
    document,
    onError: (error) => {
      console.log("[subscribeToMore]", document, error)
    },
    ...rest,
  }
}

export { makeSubscribeToMore }
