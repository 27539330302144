import React from "react"
import { Box, Divider, Button } from "@material-ui/core"
import { Receipt } from "@material-ui/icons"
import { useQuerySubscription } from "../../data"
import {
  FieldSectionHeading,
  LoadingSpinner,
  InfoSection,
  FormatDate,
  PaperBox,
  FormatDateTime,
} from "../../components"
import { useLazyQueryBillingPortalSession } from "../../data/organisations/useQueryBillingPortalSession"
import { useAuth } from "../../services"

const getInTrial = (subscription) => subscription.status === "in_trial"

const getTitle = (plan, subscription) => {
  if (getInTrial(subscription)) return `${plan.name} (${plan.trial_period} ${plan.trial_period_unit} trial)`
  return plan.name
}

const Billing = () => {
  const { data, error } = useQuerySubscription()
  const [loadPortal, portalResult] = useLazyQueryBillingPortalSession()
  const { hasPermission } = useAuth()
  const subscription = data && data.subscription.subscription
  const plan = data && data.subscription.plan
  const customer = data && data.subscription.customer
  const inTrial = data && getInTrial(subscription)
  const portalLoading = (portalResult.loading || portalResult.data) && !portalResult.error

  const handlePortal = () => {
    loadPortal()
  }

  if (portalResult && portalResult.data) {
    window.location = portalResult.data.billingPortalSession.access_url
  }

  return (
    <>
      <Box mb={5}>
        {!data && !error && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {data && (
          <>
            <FieldSectionHeading size="large">Overview</FieldSectionHeading>
            <PaperBox mb={4}>
              <InfoSection title="Current plan" value={getTitle(plan, subscription)} />
              <Divider />
              {inTrial && <InfoSection title="Trial ends" value={<FormatDateTime value={subscription.trial_end} />} />}
              {inTrial && <Divider />}
              {/* {plan.free_quantity && <InfoSection title="Users" value={`X of ${plan.free_quantity}`} />} */}
              <InfoSection title="Account created" value={<FormatDate value={customer.created_at} />} />
              {!inTrial && <Divider />}
              {!inTrial && (
                <InfoSection
                  title="Status"
                  value={
                    <Box style={{ textTransform: "capitalize" }}>
                      {subscription.status ? subscription.status.replaceAll("_", " ") : ""}
                    </Box>
                  }
                />
              )}
              <Divider />
              <InfoSection title="Next billing" value={<FormatDate value={subscription.next_billing_at} />} />
            </PaperBox>

            <FieldSectionHeading size="large">Billing portal</FieldSectionHeading>

            <p>
              Visit the billing portal to manage payment methods, download invoices and other billing related tasks.
            </p>

            <Button
              variant="contained"
              onClick={handlePortal}
              disabled={portalLoading || !hasPermission("billing_update")}
            >
              <Receipt />{" "}
              <Box ml={1} mr={portalLoading ? 1 : 0}>
                Open the billing portal
              </Box>
              {portalLoading && <LoadingSpinner size="24px" delay={false} />}
            </Button>
          </>
        )}
        {error && (
          <>
            <FieldSectionHeading size="large">Problem occurred</FieldSectionHeading>
            <p>
              A problem occurred while trying to load your billing information. The billing service may be temporarily
              unavailable.
            </p>
            <p>Please try again or check again shortly.</p>
          </>
        )}
      </Box>
      <Divider />
    </>
  )
}

export default Billing
