import React from "react"

import { Box, Typography, makeStyles } from "@material-ui/core"
import PropTypes from "prop-types"

import { LinkButton, RowBox } from "../../../components"

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    color: theme.palette.error.main,
  },
}))

const EmailChangeButton = ({
  isPendingUsernameChange = false,
  isLoading,
  onCancelEmailAddressChange,
  onChangeEmailAddress,
  onResendVerification,
  alignChangeText = "flex-start",
}) => {
  const classes = useStyles()

  return (
    <>
      {isPendingUsernameChange ? (
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "center", sm: "center" }}
        >
          <Box>
            <Typography variant="body2">Email address change pending </Typography>
          </Box>
          <RowBox>
            <Box mr={1}>
              <LinkButton onClick={onResendVerification} disabled={isLoading} mr={1}>
                Resend verification
              </LinkButton>
            </Box>
            <Box>
              <LinkButton onClick={onCancelEmailAddressChange} disabled={isLoading} className={classes.cancelButton}>
                Cancel
              </LinkButton>
            </Box>
          </RowBox>
        </Box>
      ) : (
        <RowBox justifyContent={alignChangeText}>
          <LinkButton onClick={onChangeEmailAddress}>Change email address</LinkButton>
        </RowBox>
      )}
    </>
  )
}

EmailChangeButton.propTypes = {
  isPendingUsernameChange: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancelEmailAddressChange: PropTypes.func,
  onChangeEmailAddress: PropTypes.func,
  onResendVerification: PropTypes.func,
  alignChangeText: PropTypes.oneOf(["flex-start", "flex-end"]),
}

export default EmailChangeButton
