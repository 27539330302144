import React, { useEffect, useMemo, useState } from "react"
import { Box } from "@material-ui/core"
import moment from "moment"
import { JobStepCompletionChart, ReportSectionActionCard, RowBox, DownloadExportJobButton } from ".."
import { useLazyQueryReportJob } from "../../data"
import { toId } from "../../utils"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"
import JobStepTableReport from "./JobStepTableReport"

const JobReport = ({ gt, lt, process, location, onDayClick }) => {
  const [viewSteps, setViewSteps] = useState(false)
  const [loadReportJob, { data, loading }] = useLazyQueryReportJob()

  useEffect(() => {
    loadReportJob({ variables: { process, locations: [toId(location)], gt, lt } })
  }, [gt, loadReportJob, location, lt, process])

  const handleDaysClick = ({ day }, raiseExitFullscreen) => {
    onDayClick && onDayClick(day)
    if (raiseExitFullscreen) {
      raiseExitFullscreen()
    }
    // history.push(getNavigateToJobsLink(`${day}-to-${moment(day).format("YYYY-MM-DD")}`, [id], groupIds, processValue))
  }

  const handleViewStepsClick = () => {
    setViewSteps(true)
  }

  const handleViewStepsClose = () => {
    setViewSteps(false)
  }

  const multiplePerDay = useMemo(
    () => data?.reportJob.jobs.length && data.reportJob.jobs.some((d) => d.title !== data.reportJob.process?.name),
    [data]
  )

  const chartData = useMemo(
    () =>
      data
        ? {
            process: data.reportJob.process,
            jobs: data.reportJob.jobs
              .filter((item) => toId(location) === toId(item.location))
              .sort(({ availableFrom: a }, { availableFrom: b }) => (moment(a).isSameOrBefore(moment(b)) ? -1 : 1))
              .map(
                ({
                  availableFrom: argument,
                  stepsCompleted: completed,
                  stepsOverdue: late,
                  stepsUncompleted: uncompleted,
                  stepsSkipped: skipped,
                  title,
                  id,
                }) => {
                  const titlePart = title ? ` - ${title}` : ""
                  const itemArgument = `${moment(argument).format("D MMM YY HHmmss")}${multiplePerDay ? titlePart : ""}`
                  const itemName = `${moment(argument).format("D MMM YY")}${multiplePerDay ? titlePart : ""}`
                  return {
                    id,
                    argument: itemArgument,
                    name: itemName,
                    completed,
                    late,
                    uncompleted,
                    skipped,
                    title,
                    day: moment(argument).format("YYYY-MM-DD"),
                  }
                }
              ),
          }
        : { process: { name: "" }, jobs: [] },
    [data, location, multiplePerDay]
  )

  return (
    <>
      {viewSteps && (
        <JobStepTableReport
          gt={gt}
          lt={lt}
          process={process}
          location={location}
          defaultFullscreen
          onFullscreen={handleViewStepsClose}
          multiplePerDay={multiplePerDay}
        />
      )}

      <ReportSectionActionCard
        title={location.name}
        detail={`${!loading ? `${chartData.process?.name || "Unknown process"} - ` : ""}Job completion %`}
        loading={loading}
        tools={
          (loading || Boolean(chartData?.jobs.length)) && (
            <RowBox>
              <DownloadExportJobButton variant="list" iconSize="small" jobs={chartData.jobs} disabled={loading} />
              <Box ml={1}>
                <ReportToolsIconButton
                  title="View step responses"
                  icon="steps"
                  onClick={handleViewStepsClick}
                  disabled={loading}
                />
              </Box>
            </RowBox>
          )
        }
      >
        {({ fullscreen, raiseExitFullscreen }) => {
          if (!chartData) {
            return null
          }

          return (
            <JobStepCompletionChart
              data={chartData.jobs}
              barWidth={0.75}
              argumentLabels
              rotated
              height={chartData.jobs.length * 32}
              containerHeight={fullscreen ? null : 300}
              onClick={(point) => handleDaysClick(point, raiseExitFullscreen)}
            />
          )
        }}
      </ReportSectionActionCard>
    </>
  )
}

export default JobReport
