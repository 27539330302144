import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Hidden } from "@material-ui/core"
import { Icon, RowBox, SubNavigation } from ".."
import { ShowAtScrollTop } from "./ShowAtScrollTop"
import { useMountEffect } from "../../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}))

const BackToTop = ({ from = 0, offset = 20 }) => {
  const [top, setTop] = useState(null)
  const classes = useStyles()

  useMountEffect(() => {
    setTop(document.querySelector("header").clientHeight + offset)
  })

  const handleClick = () => window.scrollTo({ top: 0, behavior: "smooth" })

  if (!top) {
    return null
  }

  return (
    <Hidden smDown>
      <ShowAtScrollTop from={from} top={top}>
        <SubNavigation className={classes.root} onClick={handleClick} noPadding>
          <RowBox py={2}>
            <Icon name="up" />
            <Box ml={1}>Back to top</Box>
          </RowBox>
        </SubNavigation>
      </ShowAtScrollTop>
    </Hidden>
  )
}

export { BackToTop }
