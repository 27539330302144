import { useMutation, gql } from "@apollo/client"
import moment from "moment"
import { PROCESS_STEP_RESPONSE_TYPE, useMakeOptimistic } from ".."
import serializationKeys from "../serializationKeys"
import { JOB_FIELDS } from "./fragments/jobFieldsFragment"

const JOB_SKIP_MUTATION = gql`
  mutation JobSkip($job: ID!) {
    job(id: $job) {
      skip {
        ...JobFields
      }
    }
  }
  ${JOB_FIELDS}
`

const JOB_UPDATE_TITLE_MUTATION = gql`
  mutation JobUpdateTitle($job: ID!, $title: String!) {
    job(id: $job) {
      updateTitle(title: $title) {
        ...JobFields
      }
    }
  }
  ${JOB_FIELDS}
`

const JOB_SUBMIT_MUTATION = gql`
  mutation JobSubmit($job: ID!) {
    job(id: $job) {
      submit {
        ...JobFields
      }
    }
  }
  ${JOB_FIELDS}
`

const JOB_UNDO_SUBMIT_MUTATION = gql`
  mutation JobUndoSubmit($job: ID!) {
    job(id: $job) {
      undoSubmit {
        ...JobFields
      }
    }
  }
  ${JOB_FIELDS}
`

const JOB_DELETE_MUTATION = gql`
  mutation JobDelete($job: ID!) {
    job(id: $job) {
      delete
    }
  }
`

const makeOptimisticResponse = (type, variables, completedBy) => {
  const { job } = variables

  const result = {
    __typename: "Mutation",
    job: {
      [type]: {
        ...JSON.parse(JSON.stringify(job)),
      },
    },
  }

  const resultJob = result.job[type]

  if (["skip", "submit"].includes(type)) {
    resultJob.status.steps.forEach((step) => {
      // only skip if not completed, and if it's a process step, only skip if the nested job is not completed
      step.skip =
        !step.completedAt &&
        (step.responseType !== PROCESS_STEP_RESPONSE_TYPE.PROCESS ||
          (step.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && !step.job?.completed))

      step.completedAt = moment().format()
      step.completedBy = completedBy
    })
  }

  if (type === "submit") {
    resultJob.status.submitted = true
    resultJob.status.submittedAt = moment().format()
    resultJob.status.submittedBy = completedBy
  }

  if (type === "undoSubmit") {
    resultJob.status.submitted = false
    resultJob.status.submittedAt = null
    resultJob.status.submittedBy = null
  }

  resultJob.percentComplete = 100
  resultJob.status.completed = true
  resultJob.status.completedAt = moment().format()

  return result
}

const useMakeJobOptimistic = () => {
  const { currentUser } = useMakeOptimistic()

  return {
    makeJobSkipOptimisticResponse: (variables) => makeOptimisticResponse("skip", variables, currentUser()),
    makeJobSubmitOptimisticResponse: (variables) => makeOptimisticResponse("submit", variables, currentUser()),
    makeJobUndoSubmitOptimisticResponse: (variables) => makeOptimisticResponse("undoSubmit", variables, currentUser()),
  }
}

const useMutationJobSkip = (job) =>
  useMutation(JOB_SKIP_MUTATION, {
    context: {
      serializationKey: serializationKeys.job(job),
    },
  })

const useMutationJobUpdateTitle = (job) =>
  useMutation(JOB_UPDATE_TITLE_MUTATION, {
    context: {
      serializationKey: serializationKeys.job(job),
    },
  })

const useMutationJobSubmit = (job) =>
  useMutation(JOB_SUBMIT_MUTATION, {
    context: {
      serializationKey: serializationKeys.job(job),
    },
  })

const useMutationJobUndoSubmit = (job) =>
  useMutation(JOB_UNDO_SUBMIT_MUTATION, {
    context: {
      serializationKey: serializationKeys.job(job),
    },
  })

const useMutationJobDelete = (job) =>
  useMutation(JOB_DELETE_MUTATION, {
    context: {
      serializationKey: serializationKeys.job(job),
    },
  })

export {
  useMutationJobSkip,
  useMutationJobUpdateTitle,
  useMutationJobSubmit,
  useMutationJobUndoSubmit,
  useMakeJobOptimistic,
  useMutationJobDelete,
}
