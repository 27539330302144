import React, { useState } from "react"
import { Skeleton } from "@material-ui/lab"
import { Box, makeStyles } from "@material-ui/core"
import { useQueryReportLocationsTraining } from "../../data"
import { toId } from "../../utils"
import { ChartCard } from "../ChartCards"
import { MultiSeriesCircularProgress } from "../CircularProgress"
import { ColumnBox } from ".."
import { LocationUserTrainingStatusDialog } from "./LocationUserTrainingStatusDialog"
import { Icon } from "../Icon"
import { RowBox } from "../Boxes"

const useStyles = makeStyles(() => ({
  chartCard: {
    cursor: "pointer",
  },
}))

const TrainingLocationCompletionCard = ({ location }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { data, loading } = useQueryReportLocationsTraining({
    variables: { locations: [toId(location)] },
    pollInterval: 1000 * 120,
  })

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const { complete, inProgress, overdue, none, percentComplete, percentInProgress, percentOverdue, userCount } = data
    ?.reportLocationsTraining[0] || {
    complete: 0,
    inProgress: 0,
    overdue: 0,
    none: 0,
    percentComplete: 0,
    percentInProgress: 0,
    percentOverdue: 0,
    userCount: 0,
  }

  const mappedData = [
    {
      value: percentOverdue,
      cumulative: percentComplete + percentInProgress,
      count: overdue,
      name: "are overdue",
      color: "secondary",
    },
    { value: percentInProgress, cumulative: percentComplete, count: inProgress, name: "in progress", color: "primary" },
    {
      value: userCount ? percentComplete : 0,
      cumulative: 0,
      count: complete,
      name: "up to date",
      color: "complete",
    },
  ]

  const hasUsers = Boolean(userCount)

  return (
    <>
      {open && <LocationUserTrainingStatusDialog open={open} location={location} onClose={handleClose} />}

      <ChartCard
        title={<strong>Staff Training</strong>}
        detail={
          <>
            {loading && (
              <ColumnBox>
                <Skeleton variant="text" width={110} />
                <Skeleton variant="text" width={110} />
                <Skeleton variant="text" width={110} />
                <Skeleton variant="text" width={110} />
              </ColumnBox>
            )}
            {!loading && hasUsers && (
              <>
                {[...mappedData]
                  .reverse()
                  .filter((set) => set.count)
                  .map((set, index) => (
                    <div key={index}>
                      {set.count || "None"} {set.name.toLowerCase()}
                    </div>
                  ))}
                {Boolean(none) && <div>{none} unassigned</div>}
              </>
            )}
            {!loading && !hasUsers && <>No staff at this location have been assigned training</>}
          </>
        }
        chart={
          <>
            {loading && <Skeleton variant="circle" width={120} height={120} />}
            {!loading && (
              <MultiSeriesCircularProgress
                series={mappedData}
                complete={userCount ? Math.round(percentComplete) : 0}
                size={120}
              />
            )}
          </>
        }
        adminOnly
        footerPrompt={
          <RowBox>
            <Icon name="list-alt" />
            <Box ml={0.6}>View</Box>
          </RowBox>
        }
        onClick={handleClick}
        className={classes.chartCard}
      />
    </>
  )
}

export { TrainingLocationCompletionCard }
