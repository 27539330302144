import React, { useState, useEffect } from "react"
import { List, useTheme } from "@material-ui/core"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import { SubNavigationListItem, NoItemsMessage, ActionList } from "../../components"
import {
  ACTION_STATUS,
  queryAction,
  useLazyQueryActions,
  useMutationDeleteAction,
  useQueryActionCounts,
} from "../../data"
import { mapToIds, toId, useMountAwareReactiveVar } from "../../utils"
import { useAuth } from "../../services"
import { useLazyQueryAction } from "../../data/actions/useQueryAction"
import { hubFiltersVar } from "../../data/hubFiltersVar"

const Actions = ({ onMenuClick }) => {
  const history = useHistory()
  const client = useApolloClient()
  const [deleteAction] = useMutationDeleteAction()
  const { groups, locations, tags } = useMountAwareReactiveVar(hubFiltersVar)

  const [editAction, setEditAction] = useState(null)
  const [loadActions, actionsResult] = useLazyQueryActions()
  const [loadAction, actionResult] = useLazyQueryAction()
  const [unsubscribes, setUnsubscribes] = useState(null)
  const { set, id, action } = useParams()

  const isSingle = set === "action"
  const isSingleDeleted = isSingle && id === "null"
  const baseUri = `/hub/actions/${set}`

  const result = isSingle ? actionResult : actionsResult

  useEffect(() => {
    if (isSingleDeleted) return
    if (isSingle) {
      loadAction({ variables: { id } })
    } else {
      loadActions({
        variables: {
          filter: {
            set,
            groups: groups.includes("all") ? null : mapToIds(groups),
            locations: locations.includes("all") ? null : mapToIds(locations),
            tags: tags.includes("all") ? null : mapToIds(tags),
          },
        },
      })
    }
  }, [isSingle, id, loadActions, loadAction, isSingleDeleted, set, groups, locations, tags])

  useEffect(() => {
    if (isSingle && !editAction && id && action === "edit" && actionResult.data)
      setEditAction(actionResult.data.action.items[0])
  }, [action, editAction, id, isSingle, actionResult.data])

  useEffect(() => {
    if (!isSingle) {
      if (!editAction && id && action === "edit" && actionsResult.data) {
        const item = actionsResult.data.actions.list.find((a) => toId(a) === id)
        if (!item) {
          const updateLoadAction = async () => {
            const loadItem = await queryAction(id, client)
            if (loadItem && loadItem.data) {
              setEditAction(loadItem.data.actions.list[0])
            }
          }
          updateLoadAction()
        } else setEditAction(item)
      } else if (!id && editAction) {
        setEditAction(null)
      }
    }
  }, [action, client, editAction, id, isSingle, actionsResult.data])

  useEffect(() => {
    if (isSingle && actionResult.error) history.push("/hub/all")
  })

  useEffect(() => {
    if (editAction && action !== "edit") setEditAction(null)
  }, [action, editAction])

  useEffect(() => {
    const { called, subscribe } = actionsResult
    if (called && !unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [actionsResult, unsubscribes])

  useEffect(() => {
    const { called, subscribe } = actionResult
    if (called && !unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [actionResult, unsubscribes])
  const handleEditAction = (post) => {
    history.push(`${baseUri}/${toId(post)}/edit`)
  }
  const handleDeleteAction = async ({ id: deleteId }) => {
    await deleteAction({
      variables: {
        id: deleteId,
      },
    })
    loadActions()
  }

  const handleStatusChange = () => {
    if (!isSingle && set !== "all") {
      actionsResult.refetch()
    }
  }

  const handleToggleSubNav = (event) => {
    onMenuClick && onMenuClick(event)
  }

  const getTitle = () => {
    switch (set) {
      case "all":
        return "All actions"
      case ACTION_STATUS.UNRESOLVED:
        return "Unresolved actions"
      case ACTION_STATUS.RESOLVED:
        return "Resolved actions"
      case ACTION_STATUS.IN_PROGRESS:
        return "In progress actions"
      case ACTION_STATUS.BLOCKED:
        return "Blocked actions"
      case ACTION_STATUS.CANCELLED:
        return "Cancelled actions"
      default:
        return "Unrecognised set"
    }
  }

  return (
    <>
      {!isSingleDeleted && (
        <ActionList
          title={getTitle()}
          actions={actionsResult.data?.actions.list}
          {...result}
          onEdit={handleEditAction}
          onDelete={handleDeleteAction}
          onOpenSubNav={handleToggleSubNav}
          onStatusChange={handleStatusChange}
        />
      )}
      {isSingleDeleted && <NoItemsMessage>This action has been removed</NoItemsMessage>}
    </>
  )
}

const ActionsSubNav = ({ onClick }) => {
  const theme = useTheme()
  const { hasFeature } = useAuth()
  const location = useLocation()
  const actionCountsResult = useQueryActionCounts()
  const [unsubscribes, setUnsubscribes] = useState(null)

  useEffect(() => {
    const { called, subscribe } = actionCountsResult
    if (called && !unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [actionCountsResult, unsubscribes])

  const handleClick = () => {
    onClick && onClick()
  }

  if (!hasFeature("actions")) return null

  return (
    <List>
      <SubNavigationListItem
        to="/hub/actions/all"
        text="All actions"
        active={location.pathname.startsWith("/hub/actions/all")}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/hub/actions/unresolved"
        text="Unresolved"
        badgeCount={actionCountsResult.data?.actionCounts.unresolved || 0}
        badgeStyleProps={{ color: "white", backgroundColor: theme.palette.error.main }}
        active={location.pathname.startsWith("/hub/actions/unresolved")}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/hub/actions/in_progress"
        text="In progress"
        active={location.pathname.startsWith("/hub/actions/in_progress")}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/hub/actions/blocked"
        text="Blocked"
        active={location.pathname.startsWith("/hub/actions/blocked")}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/hub/actions/resolved"
        text="Resolved"
        active={location.pathname.startsWith("/hub/actions/resolved")}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/hub/actions/cancelled"
        text="Cancelled"
        active={location.pathname.startsWith("/hub/actions/cancelled")}
        onClick={handleClick}
      />
    </List>
  )
}

export { Actions, ActionsSubNav }
