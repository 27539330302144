import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { REPORT_JOB_COMPLETIONS_FIELDS } from "./fragments/reportJobCompletionsFieldsFragment"

const REPORT_GROUPS_JOBS_COMPLETIONS_QUERY = gql`
  query ReportJobsGroupsCompletions($input: ReportJobsGroupsInput!) {
    report: reportV2 {
      jobs {
        groups {
          completions(input: $input) {
            id
            name
            completions {
              ...ReportJobCompletionsFields
            }
          }
        }
      }
    }
  }
  ${REPORT_JOB_COMPLETIONS_FIELDS}
`

const useQueryReportJobsGroupsCompletions = (options) =>
  useQuery(REPORT_GROUPS_JOBS_COMPLETIONS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportJobsGroupsCompletions = (options) =>
  useLazyQuery(REPORT_GROUPS_JOBS_COMPLETIONS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJobsGroupsCompletions, useLazyQueryReportJobsGroupsCompletions }
