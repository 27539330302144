import React, { useState, Suspense } from "react"

import { makeStyles } from "@material-ui/styles"
import { Box, Tabs, Tab, Divider } from "@material-ui/core"

import { ColumnBox, LoadingSpinner } from "../../../components"

const Views = React.lazy(() => import("../Views"))
const Tags = React.lazy(() => import("../Tags"))

const tabs = ["Views", "Tags"]

const useTabStyles = makeStyles({
  root: {
    justifyContent: "start",
  },
  scroller: {
    flexGrow: "0",
  },
})

const Tagging = () => {
  const tabClasses = useTabStyles()
  const [currentTab, setCurrentTab] = useState(0)

  const loadingSpinnerOverlay = () => {
    return (
      <ColumnBox justifyContent="center" alignItems="center">
        <Box>
          <LoadingSpinner size={70} />
        </Box>
      </ColumnBox>
    )
  }

  return (
    <>
      <Tabs
        width="100%"
        classes={tabClasses}
        variant="scrollable"
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        aria-label="Select tabs"
      >
        {tabs.map((tab, index) => (
          <Tab key={`${tab}-${index}`} label={tab} value={index} onClick={() => setCurrentTab(index)} />
        ))}
      </Tabs>
      <Divider />
      <Suspense fallback={loadingSpinnerOverlay()}>
        {currentTab === 0 && (
          <Box sx={{ py: 3, pt: 5 }}>
            <Views />
          </Box>
        )}
        {currentTab === 1 && (
          <Box sx={{ py: 3, pt: 5 }}>
            <Tags />
          </Box>
        )}
      </Suspense>
    </>
  )
}

export default Tagging
