import React from "react"

import { TextField, Typography, Box, Hidden, makeStyles } from "@material-ui/core"

import { KnowledgeArticleContentVideo } from "../Article/ArticleContentVideo"

const useStyles = makeStyles((theme) => ({
  contentField: {
    backgroundColor: "#ffffff",
  },
  hint: {
    color: theme.palette.text.secondary,
  },
}))

const CreatorContentVideo = ({ content, onUpdateContent }) => {
  const classes = useStyles()

  return (
    <>
      <TextField
        variant="outlined"
        value={content.url}
        fullWidth
        label="Media URL"
        onChange={(e) => onUpdateContent("url", e.target.value)}
        className={classes.contentField}
      />
      <Typography variant="caption" className={classes.hint}>
        <Box mr={1}>Supported: YouTube, Vimeo, SoundCloud, Mixcloud, Loom</Box>
      </Typography>
      {content.url && (
        <Hidden smDown>
          <Box mt={1}>
            <KnowledgeArticleContentVideo value={content.url} />
          </Box>
        </Hidden>
      )}
    </>
  )
}

export { CreatorContentVideo }
