import React from "react"
import { ActionCard } from "."
import { AuthorTools } from "../Author"
import { ColumnBox, FlexBox } from "../Boxes"
import { Icon } from "../Icon"

const RegionActionCard = ({ region, onEdit, onDelete, ...rest }) => {
  const { id, name } = region

  const handleEdit = () => {
    onEdit && onEdit(region)
  }

  const handleDelete = async () => {
    onDelete && onDelete(region)
  }

  return (
    <ActionCard
      key={id}
      title={name}
      stub={
        <ColumnBox mr={1} mt={0.25} alignSelf="flex-start">
          <Icon name="region" />
        </ColumnBox>
      }
      cursor={null}
      rightChildren={
        <FlexBox alignSelf="flex-start">
          <AuthorTools
            item={region}
            admins={{
              edit: ["location_create"],
              delete: ["location_update"],
            }}
            onEdit={handleEdit}
            subject="region"
            onDelete={handleDelete}
          />
        </FlexBox>
      }
      {...rest}
    />
  )
}

export default RegionActionCard
