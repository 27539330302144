import mixpanel from "mixpanel-browser"
import { useEffect, useMemo } from "react"
import Config from "react-global-configuration"
import { useAuth, WebVersion as versionString } from "../services"
import { toId } from "./data"
import { useDeviceUtils } from "./useDeviceUtils"

const useAnalyticsInit = () => {
  const {
    analytics: {
      mixpanel: { enabled },
    },
  } = Config.get()
  const auth = useAuth()
  const { deviceClientInfo } = useDeviceUtils()
  const { authed, principal, settings } = auth

  const config = useMemo(
    () =>
      authed
        ? {
            userId: principal.userID,
            name: principal.firstName,
            organisation: toId(settings.organisation, true),
            signed_up_at: principal.createdAt,
          }
        : {},
    [authed, principal.createdAt, principal.firstName, principal.userID, settings.organisation]
  )

  useEffect(() => {
    if (authed && enabled) {
      const { userId, name, organisation, signed_up_at } = config
      mixpanel.identify(userId)
      mixpanel.people.set({
        $name: name,
        Organisation: organisation,
        "Signed Up": signed_up_at,
        "Web Client": versionString,
        "Device Client": deviceClientInfo?.app?.version || undefined,
      })
      console.log("[MP]", "Id")
    }
  }, [authed, config, deviceClientInfo?.app?.version, enabled])

  return {}
}

const disabled = () => console.log("[ANALYTICS]", "Disabled")

const useAnalytics = () => {
  const {
    analytics: {
      mixpanel: { enabled },
    },
  } = Config.get()

  const track = (...args) => {
    if (enabled) {
      return mixpanel.track(...args)
    }
    disabled()
  }

  // const start = (...args) => {
  //   if (enabled) {
  //     return mixpanel.start(...args)
  //   }
  //   disabled()
  // }

  return {
    track,
    // start,
  }
}

export { useAnalyticsInit, useAnalytics }
