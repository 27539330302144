import { useQuery, gql } from "@apollo/client"
import { REPORT_JOB_COMPLETIONS_FIELDS } from "./fragments/reportJobCompletionsFieldsFragment"

const REPORT_JOBS_OVERALL_COMPLETIONS_QUERY = gql`
  query ReportJobsOverallCompletions($input: ReportJobsOverallInput!) {
    report: reportV2 {
      jobs {
        overall {
          completions(input: $input) {
            ...ReportJobCompletionsFields
          }
        }
      }
    }
  }
  ${REPORT_JOB_COMPLETIONS_FIELDS}
`

const useQueryReportJobsOverallCompletions = (options) =>
  useQuery(REPORT_JOBS_OVERALL_COMPLETIONS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJobsOverallCompletions }
