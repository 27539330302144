import React from "react"
import { makeStyles } from "@material-ui/styles"
import { List, Hidden, Drawer, Box } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { DrawerHeading, SubNavigation, SubNavigationListItem } from "../../components"

const useStyles = makeStyles((theme) => ({
  subNav: {
    paddingTop: 0,
    borderRadius: "0 0 8px 8px",
    "&&:first-of-type": {
      marginBottom: 0,
      borderRadius: "8px 8px 0 0",
      paddingBottom: 0,
    },
  },
  subNavList: {
    maxWidth: 240 - theme.spacing(1),
  },
}))

const AreaTagLeftSidebar = ({ tags, open, onClose }) => {
  const classes = useStyles()
  const { slug, tag: tagSlug } = useParams()

  const handleClick = () => {
    onClose && onClose()
  }

  const renderTagsNav = () => (
    <List className={classes.subNavList}>
      {tags.map((tag) => (
        <SubNavigationListItem
          key={tag.id}
          to={`/area/${slug}/${tag?.url}`}
          text={tag?.name ?? ""}
          badgeVariant="text"
          active={tagSlug === tag?.url}
          onClick={handleClick}
        />
      ))}
    </List>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer open={open} onClose={onClose}>
          <Box mt={2}>
            <DrawerHeading>Tags</DrawerHeading>
            {renderTagsNav()}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <SubNavigation title="Tags" loading={false} className={classes.subNav}>
          {renderTagsNav()}
        </SubNavigation>
      </Hidden>
    </>
  )
}

export default AreaTagLeftSidebar
