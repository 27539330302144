import { Box, Divider, makeStyles } from "@material-ui/core"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Avatar, Caption, ColumnBox, FormatDateTimeCompact, NoItemsMessage, ReportSectionActionCard, RowBox } from ".."
import { useLazyQueryReportJobStepsNotes } from "../../data/report/useQueryReportJobStepsNotes"
import { useAuth } from "../../services"
import { mapToIds, toId } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"

const useStyles = makeStyles(() => ({
  container: {
    height: ({ containerHeight, fullscreen }) => (fullscreen ? "calc(100vh - 150px)" : containerHeight),
    overflowY: "auto",
  },
  title: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 100,
  },
  text: {
    overflowWrap: "anywhere",
    fontSize: 12,
  },
  date: {
    whiteSpace: "nowrap",
  },
}))

const JobStepsNotesReport = ({ gt, lt, locations, containerHeight = 332 }) => {
  const [fullscreen, setFullscreen] = useState(false)
  const classes = useStyles({ containerHeight, fullscreen })
  const [load, { data, loading }] = useLazyQueryReportJobStepsNotes()

  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    if (!gt) {
      return
    }

    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations

    load({
      variables: { locations: filterLocations, gt, lt },
    })
  }, [gt, load, locations, lt, userLocations])

  const handleFullscreen = (newFullscreen) => {
    setFullscreen(newFullscreen)
  }

  const chartData = data?.reportJobStepsNotes || []

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = ["Job", "Step", "Author", "Note", "Date"]

    const rows = []
    chartData.forEach(({ job, name, notes }) => {
      rows.push(
        ...notes.map(({ author: { fullName }, text, createdAt }) =>
          [
            stringFormatter(job),
            stringFormatter(name),
            stringFormatter(fullName),
            stringFormatter(text),
            createdAt,
          ].join(",")
        )
      )
    })

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "job-step-notes.csv", "text/csv")
  }

  return (
    <ReportSectionActionCard
      title="Notes"
      detail="By job step"
      loading={loading}
      onFullscreen={handleFullscreen}
      tools={<ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />}
    >
      <Box className={classes.container}>
        {chartData.map(({ id, job, name, locationName, notes }) => (
          <Box key={toId(id)} mb={3}>
            <Box className={classes.title}>
              <Box mb={1}>
                <Box size="small" style={{ fontSize: 14, fontWeight: 500 }} mb={0}>
                  {job}
                </Box>
                <Caption mb={0}>
                  {locationName} - {name}
                </Caption>
              </Box>
              <Divider />
            </Box>
            {notes.map((item) => (
              <RowBox key={toId(item)} p={1} alignItems="center">
                <Box mr={1}>
                  <Avatar size="small" {...item.author} />
                </Box>
                <RowBox flexGrow={1} className={classes.text}>
                  {item.text}
                </RowBox>
                <ColumnBox ml={1} alignItems="flex-end" mr={1}>
                  <Caption mb={0} className={classes.date}>
                    <FormatDateTimeCompact value={item.createdAt} title={moment(item.createdAt).format()} />
                    {/* <FormatDateTimeCompact value={item.createdAt} compactTodayTimeZone={location?.timeZone || null} /> */}
                  </Caption>
                </ColumnBox>
              </RowBox>
            ))}
          </Box>
        ))}
        {chartData.length === 0 && <NoItemsMessage>No notes</NoItemsMessage>}
      </Box>
    </ReportSectionActionCard>
  )
}

export default JobStepsNotesReport
