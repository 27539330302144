import React, { useEffect, useState } from "react"
import { IconButton, makeStyles, Chip, Box } from "@material-ui/core"
import { Icon, LoadingSpinner, TooltipIcon } from ".."
import { preventDefault } from "../../utils"

const useStyles = makeStyles(() => ({
  delete: {
    cursor: "pointer",
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
  iconRef: {
    display: "flex",
    flexDirection: "row",
  },
}))

const DeleteConfirmIconButton = ({
  deleting: deletingInput = false,
  confirmLabel = "",
  confirmTooltip = "Confirm delete",
  onDeleting,
  onCancelDeleting,
  onDelete,
  loading,
  size = "small",
  disabled,
  cy = "DeleteConfirmIconButton",
}) => {
  const classes = useStyles()
  const [deleting, setDeleting] = useState(deletingInput)

  useEffect(() => {
    setDeleting(deletingInput)
  }, [deletingInput])

  const handleDeleting = () => {
    setDeleting(true)
    onDeleting && onDeleting()
  }

  const handleCancelDeleting = () => {
    setDeleting(false)
    onCancelDeleting && onCancelDeleting()
  }

  const handleDelete = () => {
    onDelete && onDelete()
    setDeleting(false)
  }

  if (loading) {
    return <LoadingSpinner size={20} />
  }

  return (
    <Box onClick={preventDefault}>
      {deleting && (
        <Chip
          icon={
            <TooltipIcon
              name="delete"
              tooltip={confirmLabel ? "" : confirmTooltip}
              className={classes.delete}
              onClick={handleDelete}
              refClassName={classes.iconRef}
              data-cy={`${cy}-confirm`}
            />
          }
          deleteIcon={
            <TooltipIcon name="cancel" tooltip="Cancel" refClassName={classes.iconRef} data-cy={`${cy}-cancel`} />
          }
          label={confirmLabel}
          color="secondary"
          onDelete={handleCancelDeleting}
        />
      )}
      {!deleting && (
        <IconButton
          edge="end"
          aria-label="delete"
          size={size}
          onClick={handleDeleting}
          data-cy={`${cy}-delete`}
          disabled={disabled}
        >
          <Icon name="delete" />
        </IconButton>
      )}
    </Box>
  )
}

export { DeleteConfirmIconButton }
