import React, { useState } from "react"
import { Button, Menu, makeStyles, MenuItem, ListItemIcon, Box } from "@material-ui/core"
import { SpeedDialAction } from "@material-ui/lab"
import { Add } from "@material-ui/icons"
import { RequirePermissions, FloatingSpeedDial, Icon } from ".."
import { useAuth } from "../../services"
import FloatingActionButton from "../FloatingActionButton"

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: 50,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
}))

const useSpeedDialActionStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
  },
}))

const DesktopCreateCommsButton = ({ onCreateClick }) => {
  const classes = useStyles()
  const { hasFeature } = useAuth()
  const [showCreateOptions, setShowCreateOptions] = useState(false)
  const [showCreateOptionsEl, setShowCreateOptionsEl] = useState(null)

  const handleCreateClick = (type) => {
    setShowCreateOptions(false)
    setShowCreateOptionsEl(null)
    onCreateClick && onCreateClick(type)
  }

  const handleToggleCreateMenu = ({ currentTarget }) => {
    setShowCreateOptionsEl(!showCreateOptions ? currentTarget : null)
    setShowCreateOptions(!showCreateOptions)
  }

  const hasActions = hasFeature("actions")

  return (
    <RequirePermissions requires={["post_create", "action_create"]}>
      {!hasActions && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCreateClick("post")}
          data-cy="Button-create-post"
        >
          Create post
        </Button>
      )}
      {hasActions && (
        <Box display="flex" flexDirection="row">
          <Button onClick={handleToggleCreateMenu} variant="contained" color="primary" data-cy="Button-create-post">
            Create
          </Button>
          <Menu
            anchorEl={showCreateOptionsEl}
            keepMounted
            open={showCreateOptions}
            onClose={handleToggleCreateMenu}
            className={classes.menu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => handleCreateClick("post")} data-cy="MenuItem-create-post">
              <ListItemIcon className={classes.menuListItemIcon}>
                <Icon name="post" />
              </ListItemIcon>
              <>Create a new post</>
            </MenuItem>
            <MenuItem onClick={() => handleCreateClick("action")} data-cy="MenuItem-create-action">
              <ListItemIcon className={classes.menuListItemIcon}>
                <Icon name="action" />
              </ListItemIcon>
              <>Create a new action</>
            </MenuItem>
          </Menu>
        </Box>
      )}
    </RequirePermissions>
  )
}

const MobileCreateCommsButton = ({ creatorOpen, onCreateClick }) => {
  const classes = useSpeedDialActionStyles()
  const { hasFeature } = useAuth()
  const [openSpeedDial, setOpenSpeedDial] = useState(false)

  const handleCreateClick = (type) => {
    handleToggleSpeedDial()
    onCreateClick && onCreateClick(type)
  }

  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }

  const hasActions = hasFeature("actions")

  return (
    <RequirePermissions requires={["post_create", "action_create"]}>
      {!hasActions && !creatorOpen && (
        <FloatingActionButton color="primary" aria-label="Create post" onClick={() => handleCreateClick("post")}>
          <Add />
        </FloatingActionButton>
      )}
      {hasActions && !creatorOpen && (
        <FloatingSpeedDial onClose={handleToggleSpeedDial} onOpen={handleToggleSpeedDial} open={openSpeedDial}>
          <SpeedDialAction
            tooltipTitle="Create a new post"
            icon={<Icon name="post" />}
            tooltipOpen
            onClick={() => handleCreateClick("post")}
            classes={classes}
          />
          <SpeedDialAction
            tooltipTitle="Create a new action"
            icon={<Icon name="action" />}
            tooltipOpen
            onClick={() => handleCreateClick("action")}
            classes={classes}
          />
        </FloatingSpeedDial>
      )}
    </RequirePermissions>
  )
}

export { DesktopCreateCommsButton, MobileCreateCommsButton }
