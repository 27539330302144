import React from "react"
import { Box, Grid, FormControl, InputLabel, Select, MenuItem, useMediaQuery, useTheme } from "@material-ui/core"
import SearchInput from "../SearchInput"
import { jobSort } from "../JobList"

const JobsSearch = ({ onTextChange, onSortChange, textValue, sortValue, textRight }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const handleFilterTextChange = (filterText) => {
    if (onTextChange) onTextChange(filterText)
  }

  const handleSortChange = ({ target: { value } }) => {
    if (onSortChange) onSortChange(value)
  }

  return (
    <Box mb={2}>
      <Grid container direction="row">
        <Grid item sm={9} xs={12}>
          <Box display="flex" alignItems="center" flexDirection="row" mb={xs ? 0 : 2}>
            <Box flexGrow={1}>
              <SearchInput
                placeholder="Search jobs for this day"
                initialValue={textValue}
                debounce={300}
                onChange={handleFilterTextChange}
                boxProps={{ pl: 0, pr: xs || textRight ? 0 : 2, mb: 0 }}
              />
            </Box>
            {textRight && <Box>{textRight}</Box>}
          </Box>
        </Grid>
        {!xs && (
          <Grid item sm={3} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Sort by</InputLabel>
              <Select value={sortValue} onChange={handleSortChange} label="Sort by">
                {jobSort.options.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export { JobsSearch }
