import { Box } from "@material-ui/core"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import React, { useEffect, useMemo, useState } from "react"
import { JobStepCompletionChart, ReportSectionActionCard, RowBox } from ".."
import { useLazyQueryReportJobsOverview } from "../../data"
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.rank - b.rank)

const JobsOverviewReport = ({ gt, lt, locations, groups, containerHeight = 300, onJobClick }) => {
  const [sort, setSort] = useState("alpha")
  const [loadReportJobsOverview, { data, loading }] = useLazyQueryReportJobsOverview()
  const [fullscreen, setFullscreen] = useState(false)

  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    if (!gt) {
      return
    }

    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterGroups = groups.includes("all") ? null : groups

    loadReportJobsOverview({
      variables: { locations: filterLocations, groups: filterGroups, gt, lt },
    })
  }, [groups, gt, loadReportJobsOverview, locations, lt, userLocations])

  const handleFullscreen = (newFullscreen) => {
    setFullscreen(newFullscreen)
  }

  const handleJobClick = ({ process }) => {
    onJobClick && onJobClick(process)
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const chartData = useMemo(
    () =>
      data
        ? data.reportJobsOverview
            .map(({ process, name, steps: { completed, overdue: late, uncompleted, skipped } }) => ({
              argument: process,
              name,
              process,
              completed,
              late,
              uncompleted,
              skipped,
              rank: (completed + late + skipped) / (completed + late + uncompleted + skipped),
            }))
            .reverse()
            .sort((a, b) => sortData(sort, a, b))
        : [],
    [data, sort]
  )

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = ["Name", "On-time", "Late", "Skipped", "Uncompleted"]
    const rows = [...chartData]
      .reverse()
      .sort((a, b) => sortData(sort, a, b))
      .map(({ name, completed, late, skipped, uncompleted }) =>
        [stringFormatter(name), completed, late, skipped, uncompleted].join(",")
      )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "overview-jobs-step-completion.csv", "text/csv")
  }

  return (
    <ReportSectionActionCard
      title="Jobs"
      detail="Step completion %"
      loading={loading}
      onFullscreen={handleFullscreen}
      tools={
        <RowBox>
          <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
          <Box ml={1}>
            <ReportToolsIconButton
              title={sort === "alpha" ? "Sort by completions %" : "Sort alphabetically"}
              icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
              onClick={handleSort}
              disabled={loading}
            />
          </Box>
        </RowBox>
      }
    >
      <JobStepCompletionChart
        data={chartData}
        barWidth={0.75}
        argumentLabels
        rotated
        height={chartData.length * 32}
        containerHeight={fullscreen ? null : containerHeight}
        onClick={handleJobClick}
      />
    </ReportSectionActionCard>
  )
}

export default JobsOverviewReport
