import { TagChipMenuItem, TagValueText } from "../ChipMenu/TagChipMenu"
import { FiltersChipMenuItem } from "./FiltersChipMenuItem"

const TagsFiltersChipMenuItem = ({ menuItemRef, value, onChange, onExpanded, ...props }) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue)
  }

  return (
    <FiltersChipMenuItem
      menuItemRef={menuItemRef}
      name="Tags"
      description={<TagValueText value={value} />}
      onExpanded={onExpanded}
    >
      <TagChipMenuItem value={value} onChange={handleChange} {...props} />
    </FiltersChipMenuItem>
  )
}

export { TagsFiltersChipMenuItem }
