import { Box, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { useEffect, useMemo, useState } from "react"
import moment from "moment"
import { ErrorBoundary } from "../../ErrorBoundary"
import { mapToIds, toId } from "../../../utils"
import { Icon } from "../../Icon"
import { useQueryAllJobs, useQueryAllJobsCount } from "../../../data/jobs/useQueryAllJobs"
import { useAuth } from "../../../services"
import { ProcessDetailText } from "../../ActionCards"
import { ColumnBox, RowBox } from "../../Boxes"
import { LoadingSpinner } from "../../LoadingSpinner"
import { CompletedByAvatar } from "../../Avatar"
import { JobViewer } from "../../JobViewer"
import { DownloadExportJobButton } from "../../Buttons"
import { CompletionCellContent } from "./CompletionCellContent"
import { SeparatorBarTableRow } from "../../Table/SeparatorBarTableRow"
import { FormatDateFormal } from "../../Format"
import { NoItemsMessage } from "../../Lists"
import { TablePagination } from "../../Table/TablePagination"
import { TablePageIndicator } from "../../Table/TablePageIndicator"
import { TooltipIconButton } from "../../Buttons/TooltipIconButton"

const useStyles = makeStyles((theme) => ({
  noBottomBorder: {
    borderBottom: "none",
  },
  actionsAndNotes: {
    color: theme.palette.primary.main,
  },
  actionIcon: {
    color: theme.palette.raised.main,
    marginRight: theme.spacing(0.5),
  },
  noteIcon: {
    color: theme.palette.raised.secondary,
    marginRight: theme.spacing(0.5),
  },
}))

const JobsTableReport = ({
  title,
  gt,
  lt,
  processes,
  locations,
  groups,
  tags,
  defaultLimit = 50,
  showLocation = true,
}) => {
  const classes = useStyles()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const [{ offset, limit }, setOffsetLimit] = useState({ offset: 0, limit: defaultLimit })
  const baseQueryVariables = {
    filter: {
      locations: !locations || locations.includes("all") ? mapToIds(userLocations) : locations,
      dateRange: {
        start: gt,
        end: lt,
      },
      processes: !processes || processes.includes("all") ? undefined : mapToIds(processes),
      tags: !tags || tags.includes("all") ? undefined : mapToIds(tags),
    },
  }
  const { data: listData, loading: listLoading } = useQueryAllJobs({
    variables: {
      ...baseQueryVariables,
      offset,
      limit,
    },
  })
  const { data: countData, loading: countLoading } = useQueryAllJobsCount({
    variables: baseQueryVariables,
  })
  const [viewJob, setViewJob] = useState(null)

  useEffect(() => {
    setOffsetLimit({ offset: 0, limit: defaultLimit })
  }, [gt, lt, locations, groups, processes, defaultLimit])

  const handlePageChange = (_, page) => {
    setOffsetLimit({
      offset: page * limit,
      limit,
    })
  }

  const handleViewJobClose = () => {
    setViewJob(null)
  }

  const handleViewJobDelete = () => {
    setViewJob(null)
  }

  const count = countData?.jobs?.countAll || 0
  const items = useMemo(() => {
    if (!listData) {
      return []
    }

    const groupedByDate = listData.jobs.listAll.reduce(
      (acc, item) => {
        const itemDate = moment(item.availableFrom).format("YYYY-MM-DD")

        if (acc.date !== itemDate) {
          acc.items.push({
            type: "separator",
            value: itemDate,
          })
          acc.date = itemDate
        }

        acc.items.push({
          type: "job",
          value: item,
        })

        return acc
      },
      {
        date: null,
        items: [],
      }
    )

    return groupedByDate.items
  }, [listData])

  return (
    <>
      <JobViewer
        key={toId(viewJob)}
        open={!!viewJob}
        id={toId(viewJob)}
        onClose={handleViewJobClose}
        onDelete={handleViewJobDelete}
        noInline
      />

      <Box>{title}</Box>

      <Box mb={2}>
        <Typography variant="body2" color="textSecondary" component="div">
          <TablePageIndicator subject="jobs" loading={countLoading} total={count} limit={limit} offset={offset} />
        </Typography>
      </Box>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Job Name</TableCell>
            <TableCell>Actions &amp; Notes</TableCell>
            {showLocation && <TableCell>Location</TableCell>}
            <TableCell>Complete</TableCell>
            <TableCell>Completion</TableCell>
            <TableCell>Completion By</TableCell>
            <TableCell align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(({ type, value }, index) => {
            if (type === "separator") {
              const valueDate = moment(value)
              const isToday = valueDate.isSame(moment(), "day")
              return (
                <SeparatorBarTableRow key={value} colSpan={7}>
                  <FormatDateFormal value={valueDate} formats="alternate" />
                  {isToday && <strong> (Today)</strong>}
                </SeparatorBarTableRow>
              )
            }

            const nextItem = items[index + 1]
            const cellClass = nextItem?.type === "separator" ? classes.noBottomBorder : undefined

            return (
              <ErrorBoundary key={toId(value)}>
                <TableRow>
                  <TableCell className={cellClass}>
                    <Box>
                      <Box>{value.processName}</Box>
                      <Typography variant="body2" color="textSecondary" component="div">
                        <ProcessDetailText job={value} />
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={cellClass}>
                    <RowBox alignItems="center" className={classes.actionsAndNotes}>
                      <RowBox mr={1.5}>
                        {value.numberOfActions > 0 && (
                          <>
                            <Icon name="action-raised" className={classes.actionIcon} />
                            {value.numberOfActions}
                          </>
                        )}
                      </RowBox>
                      <RowBox>
                        {value.numberOfNotes > 0 && (
                          <>
                            <Icon name="note-added" className={classes.noteIcon} />
                            {value.numberOfNotes}
                          </>
                        )}
                      </RowBox>
                    </RowBox>
                  </TableCell>
                  {showLocation && <TableCell className={cellClass}>{value.location?.name}</TableCell>}
                  <TableCell className={cellClass}>
                    {value.percentComplete > 0 && <>{value.percentComplete}%</>}
                  </TableCell>
                  <TableCell className={cellClass}>
                    <CompletionCellContent job={value} />
                  </TableCell>
                  <TableCell className={cellClass}>
                    {value.status.submittedBy && (
                      <RowBox>
                        <CompletedByAvatar by={value.status.submittedBy} />
                        <Box ml={1}>{value.status.submittedBy?.fullName}</Box>
                      </RowBox>
                    )}
                  </TableCell>
                  <TableCell className={cellClass}>
                    <RowBox justifyContent="flex-end">
                      <Box mr={1}>
                        <TooltipIconButton tooltip="View job" size="small" onClick={() => setViewJob(toId(value))}>
                          <Icon name="view" />
                        </TooltipIconButton>
                      </Box>
                      <DownloadExportJobButton variant="icon" iconSize="small" iconName="download" job={value} />
                    </RowBox>
                  </TableCell>
                </TableRow>
              </ErrorBoundary>
            )
          })}
        </TableBody>
      </Table>
      {listLoading && items.length === 0 && (
        <ColumnBox alignItems="center" justifyContent="center" py={2}>
          <LoadingSpinner size={60} />
        </ColumnBox>
      )}
      {!listLoading && items.length === 0 && <NoItemsMessage>No matching jobs</NoItemsMessage>}
      <TablePagination
        count={count}
        rowsPerPage={limit}
        page={offset / limit}
        boxProps={{
          mt: 2,
        }}
        onPageChange={handlePageChange}
        disabled={listLoading}
      >
        <TablePageIndicator
          subject="jobs"
          loading={countLoading}
          total={count}
          limit={limit}
          offset={offset}
          hideWhenNone
        />
      </TablePagination>
    </>
  )
}

export { JobsTableReport }
