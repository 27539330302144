import React, { useMemo } from "react"
import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItemAvatar,
  ListItemText,
  Divider,
  Box,
  DialogActions,
  ListItemSecondaryAction,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Avatar } from ".."
import { LinkButton } from "../LinkButton"
import { PickerListItem } from "../PickerListItems"
import { Caption, FieldSectionHeading } from "../Headings"
import { DialogTitleCloser } from "../Creators"
import TimeAgo from "../TimeAgo"
import { useQueryReportLocationTraining, USER_TRAINING_STATUS } from "../../data"
import { toId } from "../../utils"
import { LoadingSpinner } from "../LoadingSpinner"
import { LinearProgress } from "../LinearProgress"
import { ColumnBox } from "../Boxes"
import { Icon } from "../Icon"

const useDialogStyles = makeStyles((theme) => ({
  dialog: {
    width: theme.dimensions.dialogs.training.width,
    marginLeft: ({ xs }) => (xs ? 0 : null),
    marginRight: ({ xs }) => (xs ? 0 : null),
    height: 600,
  },
  inProgress: {
    color: theme.palette.primary.main,
  },
  overdue: {
    color: theme.palette.error.main,
  },
  complete: {
    color: theme.palette.success.main,
  },
  none: {
    color: theme.palette.text.secondary,
  },
  name: {
    maxWidth: 150,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}))

const LocationUserTrainingStatusDialog = ({ open, location, onClose }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useDialogStyles({ xs })
  const { data } = useQueryReportLocationTraining({ variables: { location: toId(location) } })

  const overdueItems = useMemo(
    () =>
      data
        ? [...data.reportLocationTraining.users]
            .filter((user) => user.training.status === USER_TRAINING_STATUS.OVERDUE)
            .sort(({ fullName: a }, { fullName: b }) => a.localeCompare(b))
        : [],
    [data]
  )

  const inProgressItems = useMemo(
    () =>
      data
        ? [...data.reportLocationTraining.users]
            .filter((user) => user.training.status === USER_TRAINING_STATUS.IN_PROGRESS)
            .sort(({ fullName: a }, { fullName: b }) => a.localeCompare(b))
        : [],
    [data]
  )

  const completeItems = useMemo(
    () =>
      data
        ? [...data.reportLocationTraining.users]
            .filter((user) => user.training.status === USER_TRAINING_STATUS.COMPLETE)
            .sort(({ fullName: a }, { fullName: b }) => a.localeCompare(b))
        : [],
    [data]
  )

  const noneItems = useMemo(
    () =>
      data
        ? [...data.reportLocationTraining.users]
            .filter((user) => user.training.status === USER_TRAINING_STATUS.NONE)
            .sort(({ fullName: a }, { fullName: b }) => a.localeCompare(b))
        : [],
    [data]
  )

  const hasOverdue = Boolean(overdueItems.length)
  const hasInProgress = Boolean(inProgressItems.length)
  const hasComplete = Boolean(completeItems.length)
  const hasNone = Boolean(noneItems.length)

  const handleClose = () => onClose()

  const sections = []

  if (hasOverdue) {
    sections.push(
      <Box mb={2}>
        <FieldSectionHeading className={classes.overdue}>Overdue ({overdueItems.length})</FieldSectionHeading>
        <List>
          {overdueItems.map((user) => (
            <OverdueUserListItem key={toId(user)} user={user} />
          ))}
        </List>
      </Box>
    )
  }
  if (hasInProgress) {
    sections.push(
      <Box mt={hasOverdue ? 2 : 0} mb={2}>
        <FieldSectionHeading className={classes.inProgress}>In progress ({inProgressItems.length})</FieldSectionHeading>
        <List>
          {inProgressItems.map((user) => (
            <InProgressUserListItem key={toId(user)} user={user} />
          ))}
        </List>
      </Box>
    )
  }
  if (hasComplete) {
    sections.push(
      <Box mt={hasOverdue || hasInProgress ? 2 : 0}>
        <FieldSectionHeading className={classes.complete}>Up to date ({completeItems.length})</FieldSectionHeading>
        <List>
          {completeItems.map((user) => (
            <CompleteUserListItem key={toId(user)} user={user} />
          ))}
        </List>
      </Box>
    )
  }
  if (hasNone) {
    sections.push(
      <Box mt={hasOverdue || hasInProgress || hasComplete ? 2 : 0}>
        <FieldSectionHeading className={classes.none}>No training assigned ({noneItems.length})</FieldSectionHeading>
        <List>
          {noneItems.map((user) => (
            <NoneUserListItem key={toId(user)} user={user} />
          ))}
        </List>
      </Box>
    )
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle>
        <ColumnBox>
          <Box>Staff Training</Box>
          <Caption mb={0}>{location.name}</Caption>
        </ColumnBox>
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        {!data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {sections.map((section, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Divider />}
            {section}
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Box py={1} px={3}>
          <LinkButton onClick={handleClose}>Close</LinkButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const useOverdueUserStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.error.main,
  },
  secondary: {
    color: theme.palette.error.main,
    right: 0,
  },
}))

const OverdueUserListItem = ({ user }) => {
  const classes = useOverdueUserStyles()
  const dueAt = user.training.dueAt

  return (
    <PickerListItem>
      <ListItemAvatar>
        <Avatar {...user} />
      </ListItemAvatar>
      <ListItemText
        classes={{ primary: classes.name }}
        primary={
          <>
            {user.fullName}
            <Box className={classes.subtitle}>
              <TimeAgo date={dueAt} />
            </Box>
          </>
        }
      />
      <ListItemSecondaryAction className={classes.secondary}>
        <Icon name="warning" />
      </ListItemSecondaryAction>
    </PickerListItem>
  )
}

const useInProgressUserStyles = makeStyles((theme) => ({
  progress: {
    width: 205,
  },
  dueAt: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
}))

const InProgressUserListItem = ({ user }) => {
  const classes = useInProgressUserStyles()
  const dueAt = user.training.dueAt

  return (
    <PickerListItem>
      <ListItemAvatar>
        <Avatar {...user} />
      </ListItemAvatar>
      <ListItemText
        classes={{ primary: classes.name }}
        primary={
          <>
            {user.fullName}
            <Box className={classes.progress} mt={0.4}>
              <span title={`${user.training.percentComplete}%`}>
                <LinearProgress value={user.training.percentComplete} />
              </span>
              {Boolean(dueAt) && (
                <Box mt={0.4} className={classes.dueAt}>
                  Due <TimeAgo date={dueAt} />
                </Box>
              )}
            </Box>
          </>
        }
      />
    </PickerListItem>
  )
}

const useCompleteUserStyles = makeStyles((theme) => ({
  secondary: {
    color: theme.palette.success.main,
    right: 0,
  },
}))

const CompleteUserListItem = ({ user }) => {
  const classes = useCompleteUserStyles()

  return (
    <PickerListItem>
      <ListItemAvatar>
        <Avatar {...user} />
      </ListItemAvatar>
      <ListItemText
        classes={{ primary: classes.name }}
        primary={user.fullName}
        secondary={<TimeAgo date={user.training.lastActiveAt} />}
      />
      <ListItemSecondaryAction className={classes.secondary}>
        <Icon name="training-module-complete" />
      </ListItemSecondaryAction>
    </PickerListItem>
  )
}

const useNoneUserStyles = makeStyles((theme) => ({
  secondary: {
    color: theme.palette.text.faint,
    right: 0,
  },
}))

const NoneUserListItem = ({ user }) => {
  const classes = useNoneUserStyles()

  return (
    <PickerListItem>
      <ListItemAvatar>
        <Avatar {...user} />
      </ListItemAvatar>
      <ListItemText classes={{ primary: classes.name }} primary={user.fullName} secondary={<>No training assigned</>} />
      <ListItemSecondaryAction className={classes.secondary}>
        <Icon name="none" />
      </ListItemSecondaryAction>
    </PickerListItem>
  )
}

export { LocationUserTrainingStatusDialog }
