import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { useMemo } from "react"

const REPORTLOCATIONSTRAINING_FIELDS = `
  id
  name
  timeZone
  complete
  inProgress
  overdue
  none
  percentComplete
  percentInProgress
  percentOverdue
  userCount
`

const REPORTLOCATIONSTRAINING_QUERY = gql`
  query ReportLocationsTraining($locations: [ID!]!, $groups: [ID!]) {
    reportLocationsTraining(locations: $locations, groups: $groups)
    {
      ${REPORTLOCATIONSTRAINING_FIELDS}
    } 
  }
`

const useQueryReportLocationsTraining = (options) =>
  useQuery(REPORTLOCATIONSTRAINING_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportLocationsTraining = (options) =>
  useLazyQuery(REPORTLOCATIONSTRAINING_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useTrainingReportData = (result) => {
  const reportData = useMemo(() => {
    if (!result?.data) {
      return null
    }

    const reduced = result.data.reportLocationsTraining.reduce(
      (acc, { percentComplete, percentInProgress, percentOverdue, complete, inProgress, overdue, userCount }) => {
        acc.percentComplete += percentComplete
        acc.percentInProgress += percentInProgress
        acc.percentOverdue += percentOverdue
        acc.complete += complete
        acc.inProgress += inProgress
        acc.overdue += overdue
        acc.userCount += userCount

        return acc
      },
      {
        percentComplete: 0,
        percentInProgress: 0,
        percentOverdue: 0,
        complete: 0,
        inProgress: 0,
        overdue: 0,
        userCount: 0,
      }
    )

    const total = reduced.percentComplete + reduced.percentInProgress + reduced.percentOverdue

    return {
      percentComplete: (reduced.percentComplete / total) * 100,
      percentInProgress: (reduced.percentInProgress / total) * 100,
      percentOverdue: (reduced.percentOverdue / total) * 100,
      complete: reduced.complete,
      inProgress: reduced.inProgress,
      overdue: reduced.overdue,
      userCount: reduced.userCount,
    }
  }, [result.data])

  return { reportData }
}

const useQueryReportLocationsTrainingChartData = (options) => {
  const result = useQueryReportLocationsTraining(options)
  const { reportData } = useTrainingReportData(result)

  return { ...result, reportData }
}

const useLazyQueryReportLocationsTrainingReportData = (options) => {
  const [execute, result] = useLazyQueryReportLocationsTraining(options)
  const { reportData } = useTrainingReportData(result)

  return [execute, { ...result, reportData }]
}

export {
  useQueryReportLocationsTraining,
  useLazyQueryReportLocationsTraining,
  useQueryReportLocationsTrainingChartData,
  useLazyQueryReportLocationsTrainingReportData,
}
