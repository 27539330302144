import { ACTION_PRIORITY } from ".."

const useQuerySettings = () => ({
  // language
  lang: {
    area: {
      dashboard: "Home",
      jobs: "Active Jobs",
      knowledge: "Knowledge Base",
      hub: "Comms Hub",
      labels: "Labels",
      manager: "Managers Hub",
      more: "More",
      people: "People",
      training: "Training",
      audits: "Audits",
      assets: "Assets",
      suppliers: "Suppliers",
      sensors: "Sensors",
      reports: "Reports",
      templates: "Job Manager",
    },
    areaShort: {
      dashboard: "Home",
      jobs: "Jobs",
      knowledge: "Knowledge",
      hub: "Comms",
      labels: "Labels",
      manager: "Manager",
      more: "More",
      people: "People",
      training: "Training",
      audits: "Audits",
      assets: "Assets",
      suppliers: "Suppliers",
      sensors: "Sensors",
      reports: "Reports",
      templates: "Job Manager",
    },
    action: {
      login: "Sign In",
      logout: "Sign Out",
    },
    input: {
      email_address: "Email Address",
      password: "Password",
    },
    notification: {
      message0: (notification) => notification.message,
      message1: () => "has assigned you a new process",
      message2: () => "has assigned you a new task",
      message4: () => "requires your confirmation on an updated knowledge base article",
      message8: (notification, includeTitle = true) => {
        if (notification.hasConfirm) {
          if (includeTitle) {
            return `requires your confirmation on ${
              notification.post ? notification.post.title || "a post" : "<post removed>"
            }`
          }
          return "requires your confirmation"
        }
        return `shared ${notification.post ? notification.post.title : "<post removed>"}`
      },
      message16: (notification) => notification.message,
      message32: (notification, includeTitle = true) =>
        `raised ${
          notification.action?.priority === ACTION_PRIORITY.HIGH
            ? "a high priority"
            : notification.action?.priority === ACTION_PRIORITY.CRITICAL
            ? "a critical"
            : "an"
        } action for ${includeTitle ? notification.action?.title || "<action removed>" : "your attention"}`,
      message64: (notification, includeTitle = true) =>
        `assigned you training course ${
          includeTitle ? notification.userTraining?.name || "<training removed>" : "for completion"
        }`,
      message128: ({ userAccreditations }) =>
        `requested ${
          userAccreditations.length > 1
            ? `${userAccreditations.length} accreditations from you`
            : `your ${userAccreditations[0]?.accreditation?.name || "<accreditation removed>"} accreditation`
        }`,
    },
  },
  // org
  org: {
    jobs: {
      startHour: 7,
    },
  },
})

export { useQuerySettings }
