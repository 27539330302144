import React, { useEffect, useMemo, useState } from "react"
import { Box } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import useAsyncEffect from "use-async-effect"
import {
  NoItemsMessage,
  IntegrationActionCard,
  IntegrationCreator,
  IntegrationProviderActionCard,
  FieldSectionHeading,
  Bracketise,
  FlexBox,
  LoadingSpinner,
} from "../../components"
import {
  useLazyQueryIntegrationProvider,
  useMutationDisableIntegration,
  useMutationPauseIntegration,
  useMutationResumeIntegration,
  useQueryIntegrationProviders,
  useQueryIntegrations,
} from "../../data"
import { toId } from "../../utils"
import { IntegrationProviderConnector } from "../../components/Integrations"
import { useIntegrationProviderConnector } from "../../utils/useIntegrationProviderConnector"

const Integrations = () => {
  const { data, refetch } = useQueryIntegrations({
    pollInterval: 20000,
  })
  const [pause, { loading: pauseLoading }] = useMutationPauseIntegration()
  const [resume, { loading: resumeLoading }] = useMutationResumeIntegration()
  const [disable, { loading: disableLoading }] = useMutationDisableIntegration()
  const { refetch: refetchProviders } = useQueryIntegrationProviders({
    pollInterval: 20000,
  })
  const { id } = useParams()
  const history = useHistory()
  const [edit, setEdit] = useState(null)

  useEffect(() => {
    if (!data) {
      return
    }
    if (id) {
      setEdit(data.integrations.find((item) => toId(item) === id))
      return
    }
    setEdit(null)
  }, [data, id])

  const handleClose = async () => {
    history.push("/account/integrations")
  }
  const handleEdit = (item) => {
    history.push(`/account/integrations/${toId(item)}`)
  }

  const handlePause = async (item) => {
    await pause({ variables: { id: toId(item) } })
    await refetch()
    await refetchProviders()
    history.push("/account/integrations")
  }

  const handleResume = async (item) => {
    await resume({ variables: { id: toId(item) } })
    await refetch()
    await refetchProviders()
    history.push("/account/integrations")
  }

  const handleDisable = async (item) => {
    await disable({ variables: { id: toId(item) } })
    await refetch()
    await refetchProviders()
    history.push("/account/integrations")
  }

  const enabled = useMemo(
    () =>
      data
        ? [...data.integrations]
            .sort((a, b) => a.integrationProvider.name.localeCompare(b.integrationProvider.name))
            .filter((integration) => integration.enabled)
        : [],
    [data]
  )

  const paused = useMemo(
    () =>
      data
        ? [...data.integrations]
            .sort((a, b) => a.integrationProvider.name.localeCompare(b.integrationProvider.name))
            .filter((integration) => integration.paused)
        : [],
    [data]
  )

  const enabledCount = enabled.length

  const pausedCount = paused.length

  return (
    <>
      {Boolean(edit) && (
        <IntegrationCreator
          open
          onPause={handlePause}
          onResume={handleResume}
          onDisable={handleDisable}
          onClose={handleClose}
          integration={edit}
        />
      )}

      {Boolean(enabledCount) && (
        <Box mb={5}>
          <FieldSectionHeading size="large">
            Integrations you've enabled <Bracketise value={enabledCount} />
          </FieldSectionHeading>
          {enabled.map((integration) => (
            <IntegrationActionCard
              key={toId(integration)}
              integration={integration}
              onEdit={handleEdit}
              loading={disableLoading || pauseLoading || resumeLoading}
            />
          ))}
        </Box>
      )}

      {Boolean(pausedCount) && (
        <Box mb={5}>
          <FieldSectionHeading size="large">
            Integrations you've paused <Bracketise value={pausedCount} />
          </FieldSectionHeading>
          {paused.map((integration) => (
            <IntegrationActionCard
              key={toId(integration)}
              integration={integration}
              onEdit={handleEdit}
              loading={disableLoading || pauseLoading || resumeLoading}
            />
          ))}
        </Box>
      )}

      <IntegrationProviderList />
    </>
  )
}

const IntegrationProviderList = () => {
  const { data, refetch, loading } = useQueryIntegrationProviders()
  const [loadIntegrationProvider] = useLazyQueryIntegrationProvider()
  const { checkConnectionResult } = useIntegrationProviderConnector()
  const { refetch: refetchIntegrations } = useQueryIntegrations()
  const { integrationProviderId: id } = useParams()
  const history = useHistory()
  const [view, setView] = useState(null)

  useAsyncEffect(async () => {
    if (!data) {
      return
    }
    if (id) {
      const integrationProvider = data.integrationProviders.find((item) => toId(item) === id)
      if (integrationProvider) {
        setView(integrationProvider)
        return
      }
      const result = checkConnectionResult()
      if (result.reconnect) {
        // handle reconnect load & view
        const integrationProviderResult = await loadIntegrationProvider({ variables: { id } })
        if (integrationProviderResult?.data?.integrationProvider) {
          setView(integrationProviderResult.data.integrationProvider)
          return
        }
      }
    }
    setView(null)
  }, [data, id])

  const handleEnable = (item) => {
    history.push(`/account/integrations/provider/${toId(item)}`)
  }

  const handleClose = async (changed) => {
    if (changed) {
      await refetch()
      await refetchIntegrations()
    }
    history.push("/account/integrations")
  }

  const handleConnected = async (integration) => {
    if (!integration) {
      return
    }
    await refetch()
    await refetchIntegrations()
    history.push(`/account/integrations/${toId(integration)}`)
  }

  const count = data?.integrationProviders.length || 0

  const items = useMemo(
    () => (data ? [...data.integrationProviders].sort((a, b) => a.name.localeCompare(b.name)) : []),
    [data]
  )

  return (
    <>
      {Boolean(view) && (
        <IntegrationProviderConnector
          open
          onClose={handleClose}
          onConnected={handleConnected}
          integrationProvider={view}
        />
      )}

      <Box mb={5}>
        <FieldSectionHeading size="large">
          Other integrations available <Bracketise value={count} />
        </FieldSectionHeading>
        {items.map((integrationProvider) => (
          <IntegrationProviderActionCard
            key={toId(integrationProvider)}
            integrationProvider={integrationProvider}
            onEnable={handleEnable}
          />
        ))}
        {!loading && !count && <NoItemsMessage>No further integrations available</NoItemsMessage>}
        {loading && !count && (
          <FlexBox justifyContent="center">
            <LoadingSpinner size={60} />
          </FlexBox>
        )}
      </Box>
    </>
  )
}

export default Integrations
