import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/styles"
import { Grid, List, Collapse, Box } from "@material-ui/core"
import Highlighter from "react-highlight-words"
import { NavLink } from "react-router-dom"
import { SEARCH_RESULT } from "../../data"
import { LoadingSpinner } from ".."
import { LinkButton } from "../LinkButton"
import { SearchListItem } from "./SearchListItem"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  area: {
    fontSize: 16,
    lineHeight: "16px",
    fontWeight: "600",
  },
  more: {
    marginLeft: "auto",
  },
  linkButton: {
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: "600",
  },
  busy: {
    margin: "0 50%",
  },
  empty: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  highlight: {
    fontWeight: "600",
    marginLeft: 0,
  },
}))

const limitResults = 3

const SearchAreaResultList = ({
  area,
  searchText,
  onClick,
  isBusy,
  results,
  linkBase,
  onMoreResultsClick,
  selected,
}) => {
  const classes = useStyles()
  const { items: resultsItems, total } = results
  const [text, setText] = useState("")
  const [items, setItems] = useState(null)

  useEffect(() => {
    if (text !== setText && !isBusy && resultsItems) {
      setText(searchText)
      setItems(resultsItems)
    }
  }, [text, isBusy, resultsItems, searchText])

  const handleMoreResultsClick = () => {
    if (onMoreResultsClick) {
      onMoreResultsClick(area)
    }
  }

  const showResults = !isBusy && items && items.length > 0

  return (
    <Box className={classes.wrapper}>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
        <Grid item className={classes.area}>
          {area}
        </Grid>
        <Grid item className={classes.more}>
          {!selected && !isBusy && total > limitResults && (
            <LinkButton onClick={handleMoreResultsClick} className={classes.linkButton}>
              More results
            </LinkButton>
          )}
          {selected && (
            <LinkButton onClick={handleMoreResultsClick} className={classes.linkButton}>
              Back to other results
            </LinkButton>
          )}
          {!isBusy && items && items.length === 0 && <div className={classes.empty}>No matches found</div>}
        </Grid>
      </Grid>
      <Collapse in={isBusy}>
        {isBusy && (
          <div className={classes.busy}>
            <LoadingSpinner size={30} />
          </div>
        )}
      </Collapse>
      <Collapse in={showResults}>
        <List disablePadding>
          {items &&
            items.slice(0, selected ? 10 : 3).map((result, index) => (
              <SearchListItem
                key={index}
                date={result.date}
                component={NavLink}
                to={{ pathname: `${typeof linkBase === "function" ? linkBase(result) : linkBase}${result.id}` }}
                onClick={onClick}
              >
                <Box display="flex" flexDirection="column">
                  {result.type === SEARCH_RESULT.PROCESS && (
                    <Highlighter
                      highlightClassName={classes.highlight}
                      searchWords={searchText.split(" ")}
                      autoEscape
                      textToHighlight={result.displayName}
                    />
                  )}

                  {result.title && result.type !== SEARCH_RESULT.PROCESS && (
                    <Highlighter
                      highlightClassName={classes.highlight}
                      searchWords={searchText.split(" ")}
                      autoEscape
                      textToHighlight={result.title}
                    />
                  )}
                </Box>

                {!result.title && result.type !== SEARCH_RESULT.PROCESS && <>&lt;No title&gt;</>}
              </SearchListItem>
            ))}
        </List>
      </Collapse>
    </Box>
  )
}

export default SearchAreaResultList
