import React, { useEffect, useState } from "react"
import { PeopleOutline } from "@material-ui/icons"
import { FormControl, InputLabel, Select, MenuItem, ListSubheader, Box, Divider } from "@material-ui/core"
import { Assigner } from "."
import { useQueryUsers, useQueryGroups } from "../../data"
import { UserPickerListItem, GroupPickerListItem } from ".."
import { useAuth } from "../../services"
import { LinkButton } from "../LinkButton"
import { toId, arraysMatch, mapToIds } from "../../utils"
import { TruncateNames } from "../DataDisplay/TruncateNames"

const labelPropName = (d) => (d.__typename === "User" ? d.fullName : d.name)

const getEveryoneItem = (name = "All staff") => ({
  id: "everyone",
  name,
  __typename: "Everyone",
  keywords: "all staff everyone everybody",
})

const dividerItem = {
  __typename: "Divider",
  keywords: "",
}

const UsersGroupsAssigner = ({
  variant,
  title,
  allStaffLabel,
  onPickedChanged,
  openPickerAfterInit,
  selectAllowAdvanced,
  allowAll = true,
  allowUsers = true,
  allowGroups = true,
  allowSelf,
  onInitComplete,
  onVariantChange,
  className,
  assigneeFlow,
  initialPicked,
  disableInitialPicked,
  initialSelectValue,
  placeholder,
  topBorder,
  locations,
  selectProps,
  icon,
  cy,
  ...rest
}) => {
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useQueryUsers({
    variables: {
      filter: {
        locations: locations?.length > 0 ? mapToIds(locations) : undefined,
      },
    },
  })
  const { data: groupsData, loading: groupsLoading, error: groupsError } = useQueryGroups()
  const { isCurrentUser } = useAuth()
  const [data, setData] = useState(null)
  const [dataLocations, setDataLocations] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeVariant, setActiveVariant] = useState(variant === "select" ? "select" : "advanced")
  const [selectValue, setSelectValue] = useState("")
  const [selectItem, setSelectItem] = useState(null)
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search")

  const everyoneItem = getEveryoneItem(allStaffLabel)

  useEffect(() => {
    if (
      usersData &&
      groupsData &&
      (!data ||
        !arraysMatch(
          locations.map((item) => toId(item)),
          dataLocations.map((item) => toId(item))
        ))
    ) {
      setDataLocations(locations)
      let concatData = []
      if (allowAll) {
        concatData.push(everyoneItem)
      }
      if (allowGroups && groupsData)
        concatData = concatData.concat(
          groupsData.groups.map((group) => ({ ...group, keywords: `${group.name} ${group.description}`.toLowerCase() }))
        )
      if (allowGroups && groupsData && allowUsers && usersData) {
        concatData.push(dividerItem)
      }
      if (allowUsers && usersData)
        concatData = concatData.concat(
          usersData.users
            .filter(
              (user) =>
                (allowSelf || !isCurrentUser(user.id)) && user.locations.some((item) => locations.includes(toId(item)))
            )
            .map(({ locations: userLocations, ...user }) => ({
              locations: userLocations.filter((item) => locations.includes(toId(item))),
              keywords: `${user.fullName} ${user.firstName}${isCurrentUser(user.id) && " me"}`.toLowerCase(),
              ...user,
            }))
        )
      setData(concatData)
    }
  }, [
    usersData,
    groupsData,
    data,
    setData,
    allowSelf,
    isCurrentUser,
    locations,
    dataLocations,
    everyoneItem,
    allowAll,
    allowGroups,
    allowUsers,
  ])

  useEffect(() => {
    if (!selectValue && data && variant === "select") {
      if (initialSelectValue) {
        const initialSelectItem = data.find((item) => item.id === initialSelectValue)
        setSelectValue(initialSelectValue || everyoneItem)
        setSelectItem(initialSelectItem)
        onPickedChanged([initialSelectItem])
      } else {
        setSelectValue(everyoneItem.id)
        setSelectItem(everyoneItem)
      }
    }
  }, [data, variant, initialSelectValue, setSelectItem, selectValue, onPickedChanged, everyoneItem])

  useEffect(() => {
    if (!error && (usersError || groupsError)) {
      setError(true)
    }
  }, [usersError, groupsError, error, setError])

  useEffect(() => {
    if (!loading && (usersLoading || groupsLoading)) setLoading(true)
    else if (loading && !usersLoading && !groupsLoading) setLoading(false)
  }, [usersLoading, groupsLoading, loading, setLoading])

  useEffect(() => {
    if (allowUsers && allowGroups) {
      setSearchPlaceholder("Search staff or area")
    } else if (allowUsers) {
      setSearchPlaceholder("Search staff")
    } else if (allowGroups) {
      setSearchPlaceholder("Search area")
    }
  }, [allowGroups, allowUsers])

  const getListItem = (item, index, checked, onChange, { picked }) => {
    const props = {
      item,
      checked,
      onChange,
      disabled:
        (item.__typename !== "Everyone" &&
          picked &&
          picked.length &&
          picked.find((p) => p.__typename === "Everyone")) ||
        (disableInitialPicked && mapToIds(disableInitialPicked).includes(toId(item))),
    }
    switch (item.__typename) {
      case "User":
        return <UserPickerListItem key={index} {...props} showLocations={locations.length > 1} />
      case "Group":
        return <GroupPickerListItem key={index} {...props} />
      case "Everyone":
        return <GroupPickerListItem key={index} iconName="everyone" {...props} />
      case "Divider":
        return (
          <Box key={index} mb={1}>
            <Divider />
          </Box>
        )
      default:
        throw new Error("No matching typename for data")
    }
  }

  const handlePickedChanged = ({ picked }) => {
    onPickedChanged(picked)
  }
  const handleSelectChanged = ({ target: { value } }) => {
    if (value && data) {
      setSelectValue(value)
      const item = data?.find((d) => d.id === value)
      setSelectItem(item)
      handlePickedChanged({ picked: [item] })
    }
  }

  const handleSetActiveVariant = (newVariant) => {
    setActiveVariant(newVariant)
    if (onVariantChange) onVariantChange(newVariant)
  }

  const getHasEveryone = (picked) => picked.length && picked.find(isEveryone)
  const isEveryone = (item) => item.__typename === "Everyone"

  if (activeVariant === "select") {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel>{title}</InputLabel>
        <Select value={selectValue} onChange={handleSelectChanged} label={title} {...selectProps}>
          <MenuItem value="everyone">All staff</MenuItem>
          <ListSubheader disableSticky>Groups</ListSubheader>
          {data &&
            data
              .filter((item) => item.__typename === "Group")
              .map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          <ListSubheader disableSticky>Staff</ListSubheader>
          {data &&
            data
              .filter((item) => item.__typename === "User")
              .map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {isCurrentUser(item.id) ? "Me" : item.fullName}
                </MenuItem>
              ))}
          {selectAllowAdvanced && (
            <Box p={2}>
              <LinkButton onClick={() => handleSetActiveVariant("advanced")}>Advanced...</LinkButton>
            </Box>
          )}
        </Select>
      </FormControl>
    )
  }

  return (
    <Assigner
      {...{
        title,
        icon: icon || <PeopleOutline />,
        openPickerAfterInit,
        onInitComplete,
        className,
        placeholder: placeholder || "Nobody selected",
        topBorder,
        pickerConfig: {
          wide: locations.length > 1,
          closeText: "Close",
          loading,
          initialPicked: selectItem ? [selectItem] : initialPicked && initialPicked.length ? [...initialPicked] : [],
          disableInitialPicked,
          data,
          error,
          labelPropName,
          search: {
            placeholder: searchPlaceholder,
          },
          onSearch: (searchTextLower, searchData, { picked }) => {
            const hasEveryone = getHasEveryone(picked)
            return searchData.filter(
              (item) => item.keywords.indexOf(searchTextLower) > -1 || (hasEveryone && isEveryone(item))
            )
          },
          getListItem,
          onPickedChanged: handlePickedChanged,
          mapPickedToText: ({ picked }) =>
            !picked.length ? (
              ""
            ) : picked.find((p) => p.__typename === "Everyone") ? (
              "All staff"
            ) : (
              <TruncateNames
                names={picked.map((p) => (p.__typename === "Group" ? p.name : p.fullName))}
                max={20}
                separator={assigneeFlow === "none" ? "\n" : ", "}
              />
            ),
          hasClear: true,
        },
        cy,
        ...rest,
      }}
    />
  )
}

const usersGroupsEveryoneItem = getEveryoneItem()

export { UsersGroupsAssigner, usersGroupsEveryoneItem }
