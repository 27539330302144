import React from "react"
import { IconButton, Badge, Tooltip } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import { useQueryNotifications } from "../../data"
import { NotificationsPopover, Icon } from ".."
import { useMountAwareReactiveVar } from "../../utils"
import { newDeviceClientAvailableVar } from "../../data/newDeviceClientAvailableVar"

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 2,
    top: 4,
    fontWeight: "600",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge)

const NotificationIndicator = ({ buttonClassName, iconClassName }) => {
  const result = useQueryNotifications()
  const hasNewDeviceClientVersion = useMountAwareReactiveVar(newDeviceClientAvailableVar)

  return (
    <NotificationIndicatorRender
      {...result}
      buttonClassName={buttonClassName}
      iconClassName={iconClassName}
      hasNewVersion={hasNewDeviceClientVersion}
    />
  )
}

class NotificationIndicatorRender extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationsEl: null,
    }
  }

  componentDidMount() {
    const { subscribe } = this.props
    this.unsubscribe = subscribe()
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      try {
        this.unsubscribe()
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }

  handleNotificationsClick = (event) => {
    this.setState({ notificationsEl: event.currentTarget })
  }

  handleNotificationsClose = () => {
    this.setState({ notificationsEl: null })
  }

  render() {
    const { loading, error, data, buttonClassName, iconClassName, hasNewVersion } = this.props
    const { notificationsEl } = this.state

    let notificationCount = loading || error ? 0 : data && data.notifications ? data.notifications.unread : 0
    notificationCount += !loading && hasNewVersion ? 1 : 0
    const notificationsOpen = Boolean(notificationsEl)

    const hasAny = !loading && data && data.notifications.items.length > 0

    return (
      <>
        <Tooltip title={!hasAny ? "" : "Notifications"}>
          <IconButton
            aria-label="Notifications"
            onClick={this.handleNotificationsClick}
            className={buttonClassName}
            disabled={!hasAny}
          >
            <StyledBadge badgeContent={notificationCount} color="secondary" overlap="circular">
              <Icon name="notifications" className={iconClassName} />
            </StyledBadge>
          </IconButton>
        </Tooltip>

        <NotificationsPopover
          open={notificationsOpen}
          anchorEl={notificationsEl}
          onClose={this.handleNotificationsClose}
          {...this.props}
        />
      </>
    )
  }
}

export default NotificationIndicator
