import React, { useState, useEffect } from "react"
import { Box, Divider, Grid, TextField, makeStyles, Button } from "@material-ui/core"
import { Business, ImageOutlined } from "@material-ui/icons"
import { useApolloClient } from "@apollo/client"
import {
  FieldSectionHeading,
  OutlinedSelect,
  UploaderProfileImage,
  LinkButton,
  useSnackbar,
  LoadingSpinner,
} from "../../components"
import { useMutationUpdateOrganisation, useQueryOrganisation, ORGANISATION_INDUSTRIES } from "../../data"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    width: 30,
    height: 30,
    opacity: 0,
  },
}))

const Organisation = () => {
  const classes = useStyles()
  const client = useApolloClient()
  const {
    settings: {
      organisation: { id: organisationID },
    },
    refresh,
  } = useAuth(client)
  const { data } = useQueryOrganisation({ variables: { id: organisationID } })
  const [updateOrganisation] = useMutationUpdateOrganisation()
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [touched, setTouched] = useState(false)
  const [name, setName] = useState("")
  const [industry, setIndustry] = useState("")
  const [logo, setLogo] = useState(null)

  useEffect(() => {
    if (data) {
      const { organisation } = data
      setName(organisation.name)
      setIndustry(organisation.industry || "")
      setLogo(organisation.logo)
    }
  }, [data])

  const handleSave = async () => {
    try {
      setLoading(true)
      const variables = { id: organisationID, name, industry, logo: logo && logo.id }
      await updateOrganisation({ variables })
      await refresh(client)
      setLoading(false)
      setTouched(false)
      snackbar.showMessage({ message: "Organisation updated", icon: <Business /> })
    } catch (error) {
      snackbar.showMessage({ message: error.message })
    }
  }

  const handleCancel = () => {
    const { organisation } = data
    setName(organisation.name)
    setIndustry(organisation.industry)
    setLogo(organisation.logo)
    setTouched(false)
  }

  const handleChange = (set, oldValue, newValue) => {
    if (newValue !== oldValue) {
      if (!touched) setTouched(true)
      set(newValue)
    }
  }

  const handleLogoChange = (file) => {
    if (file) {
      setLogo(file.upload)
    } else {
      setLogo(null)
    }
    setTouched(true)
  }

  return (
    <>
      <Box mb={5}>
        {!data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {data && (
          <>
            <FieldSectionHeading size="large">Company details</FieldSectionHeading>
            <Box mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(event) => handleChange(setName, name, event.target.value)}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OutlinedSelect
                    label="Industry"
                    value={industry}
                    onChange={(event) => handleChange(setIndustry, industry, event.target.value)}
                  >
                    <option value="" aria-label="None" />
                    {ORGANISATION_INDUSTRIES.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </OutlinedSelect>
                </Grid>
              </Grid>
            </Box>

            <FieldSectionHeading size="large">Company logo</FieldSectionHeading>
            <UploaderProfileImage
              avatarProps={{ avatar: logo, fullName: name, icon: <Business className={classes.icon} /> }}
              subject="logo"
              badgeIcon={<ImageOutlined style={{ fontSize: 16 }} />}
              onChange={handleLogoChange}
            />
          </>
        )}
      </Box>

      <Divider />

      <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mt={2}>
        <Box mr={3}>
          <LinkButton
            onClick={handleCancel}
            className={`${classes.button} ${classes.buttonText}`}
            disabled={!touched || loading}
          >
            Cancel
          </LinkButton>
        </Box>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={!touched || loading}>
          <Box px={2}>Save</Box>
        </Button>
      </Box>
    </>
  )
}

export default Organisation
