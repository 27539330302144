import React, { useEffect, useMemo, useState } from "react"
import { RadioGroup, FormControlLabel, Radio, Box, makeStyles } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { ColumnBox, FlexBox } from "../../Boxes"
import { ActionCard } from "../../ActionCards"
import { Avatar } from "../../Avatar"
import { Icon } from "../../Icon"
import { toId, useMountEffect, useTraining } from "../../../utils"
import { useMutationUserTrainingModuleKnowledgeAnswer } from "../../../data"
import { useAuth } from "../../../services"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    width: "100%",
  },
  title: {
    fontWeight: 600,
  },
  avatar: {
    backgroundColor: theme.palette.quiz.main,
  },
  prompt: {
    fontSize: 14,
  },
  answerLabel: {
    color: theme.palette.text.primary,
  },
  alert: {
    marginTop: theme.spacing(1),
  },
}))

const KnowledgeArticleContentMultichoice = ({ knowledge, content, onCompleted }) => {
  const classes = useStyles()
  const { hasFeature } = useAuth()
  const linear = !hasFeature("training_nonlinear")

  const [selectedAnswerId, setSelectedAnswerId] = useState(null)
  const [sortedAnswers, setSortedAnswers] = useState(null)
  const { getActiveTrainingForKnowledge, getTrainingForKnowledge } = useTraining()
  const training = linear ? getActiveTrainingForKnowledge(knowledge) : getTrainingForKnowledge(knowledge)
  const hasUserTraining = Boolean(training && (training.knowledgeActive || training.knowledgeCompleted))
  const [saveAnswer, { loading }] = useMutationUserTrainingModuleKnowledgeAnswer(training?.userTraining || null)

  useMountEffect(() => {
    setSortedAnswers([...content.answers].sort(() => Math.random() - 0.5))
  })

  useEffect(() => {
    if (training) {
      const userAnswer = training.moduleKnowledge.answers.find((answer) => {
        return answer.knowledgeContent === toId(content)
      })
      if (userAnswer) {
        setSelectedAnswerId(userAnswer.answer)
      }
    }
  }, [content, training])

  const handleChange = async (event) => {
    const answerId = event.target.value
    setSelectedAnswerId(answerId)
    onCompleted && onCompleted(content)
    if (!hasUserTraining) {
      return
    }
    saveAnswer({
      variables: {
        userTraining: toId(training.userTraining),
        module: toId(training.module),
        moduleKnowledge: toId(training.moduleKnowledge),
        question: toId(content),
        answer: answerId,
      },
    })
  }

  const correctAnswer = content.answers.find((answer) => {
    return answer.isCorrect === true
  })

  const correctAnswerSelected = correctAnswer?.id === selectedAnswerId

  const renderedAnswers = useMemo(
    () =>
      sortedAnswers?.map((answer) => (
        <FormControlLabel
          control={<Radio color="primary" disabled={loading} />}
          className={classes.answerLabel}
          key={answer.id}
          label={answer.text}
          value={answer.id}
        />
      )) || [],
    [classes.answerLabel, loading, sortedAnswers]
  )

  return (
    <Box className={classes.root}>
      <ActionCard
        titleProps={{ className: classes.title }}
        cardProps={{ className: classes.card }}
        title={content.question}
        rightChildren={
          <FlexBox>
            <Avatar className={classes.avatar} icon={<Icon name="question" />} />
          </FlexBox>
        }
        cursor="default"
        footer={
          <>
            {correctAnswer && (
              <ColumnBox py={1} flexGrow={1}>
                <Box className={classes.prompt}>Please select from one of the following answers:</Box>
                <Box mt={1}>
                  <RadioGroup aria-label="quiz" value={selectedAnswerId} onChange={handleChange}>
                    {renderedAnswers}
                  </RadioGroup>
                </Box>

                {!hasUserTraining && (
                  <>
                    {selectedAnswerId !== null && correctAnswerSelected === true && (
                      <Alert severity="success" icon={false} className={classes.alert}>
                        That's correct!
                      </Alert>
                    )}
                    {selectedAnswerId !== null && correctAnswerSelected === false && (
                      <Alert severity="error" icon={false} className={classes.alert}>
                        That's not quite right, have another try.
                      </Alert>
                    )}
                  </>
                )}
              </ColumnBox>
            )}
            {!correctAnswer && (
              <Alert severity="error">
                Unable to display answers for this question. No correct answer entered, edit this article and provide a
                correct answer.
              </Alert>
            )}
          </>
        }
      />
    </Box>
  )
}

export { KnowledgeArticleContentMultichoice }
