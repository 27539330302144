import { useMutation, gql } from "@apollo/client"
import { toId } from "../../utils"

const DELETE_REGION_MUTATION = gql`
  mutation DeleteRegion($id: ID!) {
    region(id: $id) {
      delete
    }
  }
`
const useMutationDeleteRegion = (options) =>
  useMutation(DELETE_REGION_MUTATION, {
    ...options,
    update(cache, { errors }, { variables: { id } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(id), __typename: "Region" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

export { useMutationDeleteRegion }
