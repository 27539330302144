import { Box } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ActionCard, TimeAgo, accreditationAwaitingOverdue } from ".."
import { useQueryUserAccreditations, USER_ACCREDITATIONS_STATUS } from "../../data"
import { useAuth } from "../../services"
import { ColumnBox, RowBox } from "../Boxes"
import { Icon } from "../Icon"

const attentionStatuses = [
  USER_ACCREDITATIONS_STATUS.AWAITING,
  USER_ACCREDITATIONS_STATUS.WARNING,
  USER_ACCREDITATIONS_STATUS.EXPIRED,
]

const AccreditationDashboardModule = ({ classes, cy = "AccreditationDashboardModule", onLoaded }) => {
  const {
    principal: { userID: user },
  } = useAuth()
  const { data } = useQueryUserAccreditations({ variables: { user } })

  const attentionItems = useMemo(
    () =>
      data?.userAccreditations
        .filter((item) => attentionStatuses.includes(item.status))
        .sort((a, b) => (moment(a.statusAt).isAfter(moment(b.statusAt)) ? 1 : -1)) || [],
    [data]
  )

  const overdue = useMemo(
    () =>
      attentionItems.some(
        ({ status, statusAt }) =>
          status === USER_ACCREDITATIONS_STATUS.EXPIRED ||
          (status === USER_ACCREDITATIONS_STATUS.AWAITING && accreditationAwaitingOverdue(statusAt))
      ),
    [attentionItems]
  )

  useEffect(() => {
    if (attentionItems) {
      onLoaded && onLoaded(Boolean(attentionItems.length))
    }
  }, [attentionItems, onLoaded])

  if (!attentionItems.length) {
    return null
  }

  return (
    <ActionCard
      cy={cy}
      variant="navigate"
      overdue={overdue}
      cardProps={{
        component: NavLink,
        to: { pathname: "/account/accreditation" },
      }}
      title={
        <RowBox className={classes.title} alignItems="flex-start">
          <Icon name="accreditation" />
          <ColumnBox ml={1}>
            <Box mb={0.5}>
              Attention required for {attentionItems.length} accreditation{attentionItems.length > 1 ? "s" : ""}
            </Box>
            {attentionItems.map(({ id, accreditation: { name }, status, statusAt, expiresAt }, index) => (
              <Box key={id} className={classes.caption} mt={index ? 0.5 : 0}>
                {status === USER_ACCREDITATIONS_STATUS.WARNING && (
                  <>
                    Your {name} is{" "}
                    <span className={classes.captionWarning}>
                      expiring soon <TimeAgo date={expiresAt} />
                    </span>
                  </>
                )}
                {status === USER_ACCREDITATIONS_STATUS.EXPIRED && (
                  <>
                    Your {name}{" "}
                    <span className={classes.captionExpired}>
                      expired <TimeAgo date={expiresAt} />
                    </span>
                  </>
                )}
                {status === USER_ACCREDITATIONS_STATUS.AWAITING && (
                  <>
                    Upload your {name}
                    {accreditationAwaitingOverdue(statusAt) && (
                      <span className={classes.captionExpired}>
                        {" "}
                        (requested <TimeAgo date={statusAt} compact />)
                      </span>
                    )}
                  </>
                )}
              </Box>
            ))}
          </ColumnBox>
        </RowBox>
      }
    />
  )
}

export { AccreditationDashboardModule }
