import React from "react"
import { useQueryTags } from "../../data/tags/useQueryTags"
import { TruncateNames } from "../DataDisplay/TruncateNames"

const TagNames = ({ ids, max = 3, ...rest }) => {
  const { data } = useQueryTags({
    skip: !ids?.length,
  })

  if (!data) {
    return null
  }

  return (
    <TruncateNames
      names={data.tags.filter(({ id }) => ids.includes(id)).map(({ name }) => name)}
      max={max || 10000}
      {...rest}
    />
  )
}

export { TagNames }
