import React, { useEffect, useRef, useState } from "react"
import pluralize from "pluralize"
import { LocationOutlinedSelect } from ".."
import { ChipMenu } from "./ChipMenu"
import { ChipMenuItem } from "./ChipMenuItem"
import { ChipMenuActions } from "./ChipMenuActions"
import { multipleSelectChange } from "../../utils"
import { useAuth } from "../../services"

const LocationValueText = ({ value }) => {
  const { getUserLocation } = useAuth()

  if (!value) {
    return "Locations"
  }

  if (value === "all" || (Array.isArray(value) && value.includes("all"))) {
    return "All locations"
  }

  const count = Array.isArray(value) ? value.length : 0

  if (count === 0) {
    return "No locations"
  }

  if (count === 1) {
    const location = getUserLocation(value[0])
    return location ? `Location: ${location.name}` : "Unknown location"
  }

  return pluralize("location", count, true)
}

const LocationChipMenuItem = ({ value, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange && onChange(multipleSelectChange(value, event.target.value))
  }

  return (
    <ChipMenuItem>
      <LocationOutlinedSelect value={value} onChange={handleChange} {...props} hasAll multiple />
    </ChipMenuItem>
  )
}

const LocationChipMenu = ({ value, onChange, onRegionChange, ...props }) => {
  const [nextValue, setNextValue] = useState(value)
  const menuRef = useRef()

  useEffect(() => {
    setNextValue(value)
  }, [value])

  const handleChange = (newValue) => {
    setNextValue(newValue)
  }

  const handleRegionChange = (regionLocations) => {
    setNextValue(regionLocations)
  }

  const handleApply = () => {
    menuRef.current?.close()
    onChange && onChange(nextValue)
  }

  const handleClear = () => {
    menuRef.current?.close()
    onChange && onChange(props?.multiple ? ["all"] : null)
  }

  return (
    <ChipMenu menuRef={menuRef} text={<LocationValueText value={value} />}>
      <LocationChipMenuItem value={nextValue} onChange={handleChange} onRegionChange={handleRegionChange} {...props} />
      <ChipMenuActions onApply={handleApply} onClear={handleClear} />
    </ChipMenu>
  )
}

export { LocationChipMenu, LocationChipMenuItem, LocationValueText }
