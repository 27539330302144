import React, { useEffect, useRef, useState } from "react"
import pluralize from "pluralize"
import { ChipMenu } from "./ChipMenu"
import { ChipMenuItem } from "./ChipMenuItem"
import { ChipMenuActions } from "./ChipMenuActions"
import { multipleSelectChange } from "../../utils"
import { JobStatusOutlinedSelect } from "../OutlinedSelect/JobStatusOutlinedSelect"

const JobStatusValueText = ({ value }) => {
  if (!value) {
    return "Status"
  }

  if (value === "all" || (Array.isArray(value) && value.includes("all"))) {
    return "All statuses"
  }

  const count = Array.isArray(value) ? value.length : 1

  return `${pluralize("status", count, true)}`
}

const JobStatusChipMenuItem = ({ value, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange && onChange(multipleSelectChange(value, event.target.value))
  }

  return (
    <ChipMenuItem>
      <JobStatusOutlinedSelect value={value} onChange={handleChange} {...props} hasAll multiple />
    </ChipMenuItem>
  )
}

const JobStatusChipMenu = ({ value, onChange, ...props }) => {
  const [nextValue, setNextValue] = useState(value)
  const menuRef = useRef()

  useEffect(() => {
    setNextValue(value)
  }, [value])

  const handleChange = (newValue) => {
    setNextValue(newValue)
  }

  const handleApply = () => {
    menuRef.current?.close()
    onChange && onChange(nextValue)
  }

  return (
    <ChipMenu menuRef={menuRef} text={<JobStatusValueText value={value} />}>
      <JobStatusChipMenuItem value={nextValue} onChange={handleChange} {...props} />
      <ChipMenuActions onApply={handleApply} />
    </ChipMenu>
  )
}

export { JobStatusChipMenu, JobStatusChipMenuItem, JobStatusValueText }
