import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: theme.palette.grey[400],
  },
}))

const InactiveChip = ({ className }) => {
  const classes = useStyles()

  return <Chip label="Inactive" classes={classes} className={className} size="small" />
}

export { InactiveChip }
