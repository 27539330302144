import React from "react"
import { NavLink } from "react-router-dom"
import { ListItem, ListItemAvatar, ListItemText, ListItemIcon, Badge } from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { makeStyles, withStyles } from "@material-ui/styles"
import MuiAvatar from "@material-ui/core/Avatar"
import Config from "react-global-configuration"
import { ErrorBoundary, Icon } from ".."
import TimeAgo from "../TimeAgo"
import { useDeviceUtils } from "../../utils"

const StyledBadge = withStyles(() => ({
  badge: {
    right: 5,
    top: 5,
  },
}))(Badge)

const useStylesUpdateVersion = makeStyles((theme) => ({
  normal: {
    backgroundColor: "#efefef",
    borderRadius: "4px",
    color: theme.palette.text.primary,
    padding: "3px !important",
  },
  avatar: {
    backgroundColor: "#fff",
  },
}))

const NotificationUpdateVersion = ({ classes }) => {
  const classesUpdateVersion = useStylesUpdateVersion()
  const { isOS } = useDeviceUtils()

  const messageiOS = "Click here to update your app through the App Store."
  const messageAndroid = "Click here to update your app through the Play Store."

  const message = (() => {
    if (isOS("ios")) return messageiOS
    if (isOS("android")) return messageAndroid
    return "Click here to update your app."
  })()

  const navigateToLink = (e) => {
    e.preventDefault()
    let link = ""
    if (isOS("ios")) link = Config.get("linkDownloadApp.ios")
    if (isOS("android")) link = Config.get("linkDownloadApp.android")
    if (link) window.open(link, "_blank")
  }

  return (
    <ErrorBoundary>
      <ListItem
        className={classes.item}
        alignItems="center"
        component={NavLink}
        to={{ pathname: "/" }}
        onClick={navigateToLink}
      >
        <ListItemAvatar className={classes.itemAvatar}>
          <StyledBadge color="secondary" variant="dot" overlap="circular" content=" ">
            <MuiAvatar className={classesUpdateVersion.avatar}>
              <Icon name="operandio" size={33} link={false} />
            </MuiAvatar>
          </StyledBadge>
        </ListItemAvatar>
        <ListItemIcon className={classes.itemIcon}>
          <Icon name="notifications" className={classesUpdateVersion.normal} />
        </ListItemIcon>
        <ListItemText
          className={classes.text}
          primary={
            <div className={classes.itemMessage}>
              <strong>New app version available</strong> {message}
            </div>
          }
          secondary={<TimeAgo date={Date.now()} className={classes.age} />}
        />

        <ListItemIcon className={classes.itemLinkIcon}>
          <ChevronRightIcon />
        </ListItemIcon>
      </ListItem>
    </ErrorBoundary>
  )
}

export { NotificationUpdateVersion }
