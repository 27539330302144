import React, { useState, useEffect } from "react"
import { Grid, Box } from "@material-ui/core"
import { LoadingSpinner, IconMessage, NoItemsMessage } from "../../components"
import { useQueryLabels } from "../../data"
import SearchInput from "../../components/SearchInput"
import { useAuth } from "../../services"
import { toId } from "../../utils"
import { LabelsItemList } from "../../components/Labels/LabelsItemList"

const Items = ({ onEditItem, onDeleteItem }) => {
  const { location } = useAuth()
  const [searchText, setSearchText] = useState("")

  const { data, loading, subscribe } = useQueryLabels({ variables: { filter: { location: toId(location) } } })

  const [unsubscribes, setUnsubscribes] = useState(null)

  useEffect(() => {
    if (!unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [subscribe, unsubscribes, setUnsubscribes])

  const filteredLabels =
    data?.labels.filter((item) => !searchText || item.title.toLowerCase().includes(searchText)) || []

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  return (
    <>
      <Grid container direction="row">
        <Grid item sm={12} xs={12}>
          <Box display="flex" alignItems="center" flexDirection="row">
            <Box flexGrow={1}>
              <SearchInput
                placeholder="Search all labels"
                debounce={300}
                onChange={handleFilter}
                boxProps={{ pl: 0, pr: 0, mb: 1 }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {!data?.labels.length && !loading && (
        <IconMessage name="empty-labels" text={"You don't have any labels available to print at this location."} />
      )}

      <Grid container direction="column">
        {loading && !data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        <Grid item>
          <LabelsItemList items={filteredLabels} onEditItem={onEditItem} onDeleteItem={onDeleteItem} categoryChip />
        </Grid>
        {!loading && data?.labels.length !== 0 && filteredLabels.length === 0 && (
          <Grid item sm={12} xs={12}>
            <Box>
              <NoItemsMessage>No matching labels at this location</NoItemsMessage>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Items
