import React, { useState } from "react"
import { Box, Grid, Button, Typography, makeStyles, Hidden, useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import { useParams } from "react-router-dom"
import { useMakeJobOptimistic, useMutationJobSkip, useMutationJobSubmit } from "../../data/jobs/useMutationJob"
import { toId, useJobUtils } from "../../utils"
import { PROCESS_TYPE } from "../../data"
import { Alert } from "../Alerts"
import { CopyLinkButton, DownloadExportJobButton } from "../Buttons"
import { SubmittedAuditStatus } from "./SubmittedAuditStatus"
import { SubmitIncompleteAuditConfirmation } from "./SubmitIncompleteAuditConfirmation"

const useStyles = makeStyles((theme) => ({
  submitSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    width: "100%",
  },
  submitHint: {
    fontSize: 12,
    color: theme.palette.text.primary,
    textAlign: "center",
  },
}))

const SubmitJobButton = ({ job, onResetMessage, disabled }) => {
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { day } = useParams()
  const { getFullNavigateToLink } = useJobUtils()
  const { makeJobSubmitOptimisticResponse, makeJobSkipOptimisticResponse } = useMakeJobOptimistic()
  const [skipJob, { loading: skipLoading }] = useMutationJobSkip(job)
  const [submitJob, { loading: submitLoading }] = useMutationJobSubmit(job)
  const [confirm, setConfirm] = useState(false)

  const {
    status: { completed, submitted },
  } = job

  const handleSubmit = async (result) => {
    if (confirm && !result) {
      setConfirm(false)
      return
    }

    if (!confirm) {
      setConfirm(true)
      return
    }

    const optimisticResponse = makeJobSubmitOptimisticResponse({ job })

    await submitJob({ variables: { job: toId(job) }, optimisticResponse })
  }

  if (!job?.hasSubmit) {
    return null
  }

  const subject = job.type === PROCESS_TYPE.AUDIT ? "audit" : "job"

  const loading = submitLoading || skipLoading

  const hasUncompletedRequiredSteps = job.status.steps.some((step) => step.responseMandatory && !step.completedAt)

  const handleClose = () => {
    if (confirm) {
      setConfirm(false)
    }
  }

  const handleSubmitIncomplete = async () => {
    if (confirm) {
      setConfirm(false)
    }

    const optimisticResponse = makeJobSubmitOptimisticResponse({ job })

    const variables = { job: toId(job) }
    await submitJob({ variables, optimisticResponse })
  }

  const handleSubmitSkip = async () => {
    if (confirm) {
      setConfirm(false)
    }

    const skipOptimisticResponse = makeJobSkipOptimisticResponse({ job })
    await skipJob({ variables: { job: toId(job) }, optimisticResponse: skipOptimisticResponse })

    const submitOptimisticResponse = makeJobSubmitOptimisticResponse({ job })
    await submitJob({ variables: { job: toId(job) }, optimisticResponse: submitOptimisticResponse })
  }

  return (
    <>
      {!completed && (
        <SubmitIncompleteAuditConfirmation
          open={confirm}
          onClose={handleClose}
          onSubmitIncomplete={handleSubmitIncomplete}
          onSubmitSkip={handleSubmitSkip}
        />
      )}

      {completed && (
        <Alert
          open={confirm}
          title="Submit audit?"
          description={<>Once submitted, the audit will be locked and you'll be unable to edit it.</>}
          okLabel="Submit audit"
          onClose={handleSubmit}
        />
      )}

      <Box className={classes.submitSection} alignContent="flex-end" justifyContent="space-between">
        {!submitted && (
          <>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              disabled={hasUncompletedRequiredSteps || loading || disabled}
            >
              Submit {subject}
            </Button>
            <Hidden lgUp>
              <Typography className={classes.submitHint}>
                {hasUncompletedRequiredSteps
                  ? "Please complete all required steps in this audit."
                  : "When finished, press submit to finalise this audit."}
              </Typography>
            </Hidden>
          </>
        )}

        {submitted && (
          <>
            <Grid justifyContent="center" container spacing={xs ? 3 : 1}>
              <Grid item xs={6} sm="auto">
                <CopyLinkButton link={getFullNavigateToLink(job, day)} title="Copy link to clipboard">
                  <Button variant="contained" fullWidth={xs} color="default">
                    Copy link
                  </Button>
                </CopyLinkButton>
              </Grid>
              <Grid item xs={6} sm="auto">
                <DownloadExportJobButton fullWidth={xs} job={job} variant="button" />
              </Grid>
            </Grid>
            <Hidden lgUp>
              <div className={classes.submitHint}>
                <SubmittedAuditStatus job={job} onResetMessage={onResetMessage} />
              </div>
            </Hidden>
          </>
        )}
      </Box>
    </>
  )
}

export { SubmitJobButton }
