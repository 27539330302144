import React from "react"
import { Chart, ArgumentAxis, ValueAxis, Legend, Tooltip, SplineSeries } from "@devexpress/dx-react-chart-material-ui"
import { EventTracker, HoverState, ScatterSeries, ValueScale } from "@devexpress/dx-react-chart"
import moment from "moment"
import { Box, Typography, useTheme } from "@material-ui/core"
import { symbol, symbolCircle } from "d3-shape"
import { RowBox } from "../Boxes"

const Root = (props) => (
  <Legend.Root {...props} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", flexGrow: 1 }} />
)

const Item = (props) => <Legend.Item style={{ fontSize: 5, width: "auto" }} {...props} />

const ValueLabel = (props) => {
  const { text } = props
  return <ValueAxis.Label {...props} text={`${text}%`} />
}

const TooltipOverlay = ({ children, ...props }) => (
  <Tooltip.Overlay {...props} style={{ zIndex: 999999 }}>
    {children}
  </Tooltip.Overlay>
)

function Point(type, styles) {
  return function renderPoint(props) {
    const { value, arg, val, color } = props

    const size = 800

    return (
      <>
        <path
          fill={color}
          transform={`translate(${arg} ${val})`}
          d={symbol().size([size]).type(type)()}
          style={styles}
        />
        <text
          transform={`translate(${arg} ${val})`}
          width={30}
          height={40}
          fill="white"
          fontSize={10}
          textAnchor="middle"
          x={0}
          y={3}
          fontWeight="bold"
        >
          {value}%
        </text>
      </>
    )
  }
}

const CirclePoint = Point(symbolCircle, {
  stroke: "white",
  strokeWidth: "2px",
})

const SplineSeriesWithScore = (props) => {
  return (
    <>
      <SplineSeries.Path {...props} />
      <ScatterSeries.Path {...props} pointComponent={CirclePoint} />
    </>
  )
}

const format = () => (tick) => moment.unix(tick).format("D MMM")

const SplineDaysChart = ({
  data = [],
  series = [],
  argumentField,
  labelResolver,
  getPropertyKeyFromChartKey,
  height,
}) => {
  const TooltipContent = ({ targetItem: { point, series: seriesKey } }) => {
    const key = getPropertyKeyFromChartKey(seriesKey)
    const item = data[point]
    const value = item[key]
    const year = moment.unix(item[argumentField]).format("D MMM")
    const label = labelResolver(key)

    return (
      <Box>
        <Box>
          <strong>{label}</strong>
        </Box>
        <RowBox justifyContent="center">
          {year} - {value}%
        </RowBox>
      </Box>
    )
  }

  const Label = ({ text: key, ...rest }) => {
    const theme = useTheme()

    const label = labelResolver(getPropertyKeyFromChartKey(key))

    return (
      <Typography
        variant="body2"
        style={{ whiteSpace: "nowrap", paddingLeft: 4, fontSize: 12, color: theme.palette.text.secondary }}
      >
        {label}
      </Typography>
    )
  }

  return (
    <Chart data={data} height={height}>
      <ArgumentAxis tickFormat={format} indentFromAxis={15} />
      <ValueAxis indentFromAxis={20} labelComponent={ValueLabel} />

      {series.map((s) => (
        <SplineSeries
          key={s}
          name={s}
          valueField={s}
          argumentField={argumentField}
          seriesComponent={SplineSeriesWithScore}
        />
      ))}

      <Legend position="bottom" flexDirection="row" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
      <EventTracker />
      <Tooltip contentComponent={TooltipContent} overlayComponent={TooltipOverlay} />
      <ValueScale name="Total" />
      <HoverState />
    </Chart>
  )
}

export { SplineDaysChart }
