import React, { useState, useEffect } from "react"
import { List, useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import { PostList, SubNavigationListItem } from "../../components"
import PostCreator from "../../components/PostCreator"
import { queryPost, useLazyQueryPosts, useMutationDeletePost, useQueryPostCounts } from "../../data"
import { mapToIds, toId, useMountAwareReactiveVar } from "../../utils"
import { hubFiltersVar } from "../../data/hubFiltersVar"

const Posts = ({ onMenuClick }) => {
  const history = useHistory()
  const client = useApolloClient()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const [deletePost] = useMutationDeletePost()
  const { groups, locations } = useMountAwareReactiveVar(hubFiltersVar)

  const [editPost, setEditPost] = useState(null)
  const [loadPosts, postsResult] = useLazyQueryPosts()
  const [unsubscribes, setUnsubscribes] = useState(null)
  const { set, id, action } = useParams()

  const baseUri = `/hub/${set}`

  useEffect(() => {
    loadPosts({
      variables: {
        filter: {
          set,
          groups: groups.includes("all") ? null : mapToIds(groups),
          locations: locations.includes("all") ? null : mapToIds(locations),
        },
        offset: 0,
      },
    })
  }, [groups, loadPosts, locations, set])

  useEffect(() => {
    if (!editPost && id && action === "edit" && postsResult.data) {
      const item = postsResult.data.posts.list.find((post) => toId(post) === id)
      if (!item) {
        const updateLoadPost = async () => {
          const loadItem = await queryPost(id, client)
          if (loadItem && loadItem.data) {
            setEditPost(loadItem.data.posts.list[0])
          }
        }
        updateLoadPost()
      } else setEditPost(item)
    } else if (!id && editPost) {
      setEditPost(null)
    }
  }, [action, client, editPost, id, postsResult.data])

  useEffect(() => {
    if (editPost && action !== "edit") setEditPost(null)
  }, [action, editPost])

  useEffect(() => {
    const { called, subscribe } = postsResult
    if (called && !unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [postsResult, unsubscribes])

  const handleEditPost = (post) => {
    history.push(`${baseUri}/${toId(post)}/edit`)
  }
  const handleDeletePost = async ({ id: deleteId }) => {
    await deletePost({
      variables: {
        id: deleteId,
      },
    })
    // postsResult.refresh()
  }

  const handleCreatePostClose = () => {
    history.push(baseUri)
  }

  const handleToggleSubNav = (event) => {
    onMenuClick && onMenuClick(event)
  }

  const getTitle = () => {
    switch (set) {
      case "important":
        return "Important posts"
      case "scheduled":
        return "My scheduled posts"
      case "unconfirmed":
        return "Unconfirmed"
      case "jobs":
        return "Jobs"
      case "knowledge":
        return "Knowledge Base"
      default:
        return "All posts"
    }
  }

  return (
    <>
      {editPost && <PostCreator open onClose={handleCreatePostClose} isInline={xs} edit={editPost} />}

      {(!xs || (xs && !editPost)) && (
        <PostList
          title={getTitle()}
          posts={postsResult.data?.posts.list || []}
          {...postsResult}
          onEdit={handleEditPost}
          onDelete={handleDeletePost}
          onOpenSubNav={handleToggleSubNav}
        />
      )}
    </>
  )
}

const PostsSubNav = ({ onClick }) => {
  const history = useHistory()
  const location = useLocation()
  const { data, subscribe, called } = useQueryPostCounts()
  const theme = useTheme()
  const [unconfirmed, setUnconfirmed] = useState(0)
  const [unsubscribes, setUnsubscribes] = useState(null)

  useEffect(() => {
    const newUnconfirmed = data?.postCounts.unconfirmed.requested
    if (!newUnconfirmed && location.pathname.startsWith("/hub/unconfirmed")) {
      history.push("/hub/all")
      return
    }
    setUnconfirmed(newUnconfirmed)
  }, [data, history, location.pathname, unconfirmed])

  useEffect(() => {
    if (called && !unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [called, subscribe, unsubscribes, setUnsubscribes])

  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <List>
      <SubNavigationListItem
        to="/hub/all"
        text="All posts"
        active={location.pathname.startsWith("/hub/all")}
        onClick={handleClick}
      />
      {Boolean(data?.postCounts.scheduled) && (
        <SubNavigationListItem
          to="/hub/scheduled"
          text="My scheduled posts"
          active={location.pathname.startsWith("/hub/scheduled")}
          onClick={handleClick}
        />
      )}
      <SubNavigationListItem
        to="/hub/important"
        text="Important posts"
        active={location.pathname.startsWith("/hub/important")}
        onClick={handleClick}
      />
      {Boolean(unconfirmed) && (
        <SubNavigationListItem
          to="/hub/unconfirmed"
          text="Unconfirmed"
          active={location.pathname.startsWith("/hub/unconfirmed")}
          badgeCount={unconfirmed}
          badgeStyleProps={{ color: "white", backgroundColor: theme.palette.error.main }}
          onClick={handleClick}
        />
      )}
      <SubNavigationListItem
        to="/hub/jobs"
        text="Jobs"
        active={location.pathname.startsWith("/hub/jobs")}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/hub/knowledge"
        text="Knowledge Base"
        active={location.pathname.startsWith("/hub/knowledge")}
        onClick={handleClick}
      />
    </List>
  )
}

export { Posts, PostsSubNav }
