import { useMemo } from "react"
import { Box, Grid, makeStyles } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useQueryReportJobsLocationsCompletions } from "../../data/report/useQueryReportJobsLocationsOverall"
import { useAuth } from "../../services"
import { mapToIds, toId } from "../../utils"
import { ColumnBox, PaperBox, RowBox } from "../Boxes"
import { LinearProgress } from "../LinearProgress/LinearProgress"
import { RankingNumber } from "./RankingNumber"
import { NoItemsMessage } from "../Lists"
import { useQueryReportLocationsTraining } from "../../data"

const useLocationRankingCardStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    width: "100%",
  },
  progressLabel: {
    fontSize: 14,
    fontWeight: 400,
  },
  progress: {
    width: 150,
  },
}))

const LocationRankingCard = ({ variant, location, onClick }) => {
  const classes = useLocationRankingCardStyles()
  const { hasFeature } = useAuth()

  const handleClick = () => {
    onClick && onClick(toId(location))
  }

  const loading = !location

  const hasTraining = hasFeature("training")

  const { rank, completions } = location || {}

  const jobCompletionPercentage =
    !loading && completions.total > 0
      ? Math.floor(((completions.completedOnTime + completions.completedLate) / completions.total) * 100)
      : 0

  const trainingCompletionPercentage = location?.training ? Math.floor(location.training.percentComplete) : 0

  return (
    <PaperBox p={2} className={classes.root} onClick={handleClick}>
      <RowBox flexGrow={1} alignItems="flex-start">
        <Box mr={2}>
          <RankingNumber
            value={loading ? <Skeleton variant="text" width={30} height={30} /> : rank}
            color={variant === "highest" ? "success" : "secondary"}
          />
        </Box>
        <ColumnBox flexGrow={1}>
          <ColumnBox className={classes.title} width="100%">
            {loading ? <Skeleton variant="text" width="80%" height={20} /> : location.name}
          </ColumnBox>
          <RowBox width="100%" justifyContent="space-between" mt={0.5}>
            {loading ? (
              <Skeleton variant="text" width="100%" height={20} />
            ) : (
              <>
                <ColumnBox className={classes.progressLabel}>Job Completion:</ColumnBox>
                <ColumnBox justifySelf="flex-end">
                  {loading ? (
                    <Skeleton variant="text" width={150} height={20} />
                  ) : (
                    <LinearProgress value={jobCompletionPercentage} showValue className={classes.progress} />
                  )}
                </ColumnBox>
              </>
            )}
          </RowBox>
          {hasTraining && (
            <RowBox width="100%" justifyContent="space-between" mt={0.5}>
              {loading ? (
                <Skeleton variant="text" width="100%" height={20} />
              ) : (
                <>
                  <ColumnBox className={classes.progressLabel}>Training:</ColumnBox>
                  <ColumnBox justifySelf="flex-end">
                    {loading ? (
                      <Skeleton variant="text" width={150} height={20} />
                    ) : (
                      <LinearProgress value={trainingCompletionPercentage} showValue className={classes.progress} />
                    )}
                  </ColumnBox>
                </>
              )}
            </RowBox>
          )}
        </ColumnBox>
      </RowBox>
    </PaperBox>
  )
}

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.rating > b.rating ? -1 : 1)

const LocationsRankingsReport = ({ variant, count = 3, gt, lt, locations, groups, tags, onLocationClick }) => {
  const {
    settings: { locations: userLocations },
    hasFeature,
  } = useAuth()
  const { data: jobsData, loading: jobsLoading } = useQueryReportJobsLocationsCompletions({
    variables: {
      input: {
        locations: locations.includes("all") ? mapToIds(userLocations) : locations,
        groups: groups.includes("all") ? null : groups,
        gt,
        lt,
        tags: tags.includes("all") ? null : tags,
      },
    },
    skip: !gt || !locations || !groups,
  })
  const { data: trainingData, loading: trainingLoading } = useQueryReportLocationsTraining({
    variables: {
      gt,
      lt,
      locations: locations.includes("all") ? mapToIds(userLocations) : locations,
      groups,
      tags,
    },
    skip: !gt || !lt || !locations || !groups || !hasFeature("training"),
  })

  const loading = jobsLoading || trainingLoading

  const handleLocationClick = ({ id }) => {
    onLocationClick && onLocationClick(id)
  }

  const chartData = useMemo(() => {
    const hasTraining = hasFeature("training")

    if (!jobsData || (hasTraining && !trainingData)) {
      return []
    }

    const result = jobsData?.report.jobs.locations.completions
      .map(({ id, name, region, completions }) => {
        const training = trainingData?.reportLocationsTraining.find((location) => toId(location) === id)

        return {
          id,
          name,
          region,
          completions,
          training,
          rating:
            completions.total > 0 ? (completions.completedOnTime + completions.completedLate) / completions.total : -1,
        }
      })
      .sort((a, b) => sortData("rating", a, b))
      .map((item, index) => ({ ...item, rank: index + 1 }))

    if (variant === "lowest") {
      return result.slice(result.length - count)
    }

    return result.slice(0, count)
  }, [count, hasFeature, jobsData, trainingData, variant])

  if (!loading && chartData?.length === 0) {
    return (
      <PaperBox p={2}>
        <NoItemsMessage>No data found</NoItemsMessage>
      </PaperBox>
    )
  }

  return (
    <Grid container spacing={2}>
      {!loading &&
        chartData?.length > 0 &&
        chartData.map((location) => (
          <Grid item xs={12 / count} key={toId(location)}>
            <LocationRankingCard variant={variant} location={location} onClick={handleLocationClick} />
          </Grid>
        ))}
      {loading &&
        Array(count)
          .fill()
          .map((_, index) => (
            <Grid key={index} item xs={12 / count}>
              <LocationRankingCard variant={variant} />
            </Grid>
          ))}
    </Grid>
  )
}

export { LocationsRankingsReport }
