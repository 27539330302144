import React, { useState, useEffect, useRef, Suspense } from "react"
import moment from "moment-timezone"
import {
  Button,
  OutlinedInput,
  InputLabel,
  FormControl,
  TextField,
  Box,
  Collapse,
  MenuItem,
  Icon,
  Divider,
  Grid,
  ListSubheader,
  InputAdornment,
  List,
  ListItem,
  Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useParams } from "react-router-dom"
import { Add, ZoomOutMapOutlined, PanToolOutlined, MouseOutlined } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { RowBox } from "../Boxes"
import { LabelsPopper } from "./LabelsPopper"
import { useFormUtils, deepRemoveTypenames, toId, mapToIds, multipleSelectChange, useDraft } from "../../utils"
import { LocationOutlinedSelect, OutlinedSelect, DraftBlockquote, DisplayLimiter, LoadingSpinner } from ".."
import { CreatorActions, CreatorMaster } from "../Creators"
import { useAuth } from "../../services"
import { labelSizes } from "../../data/labels/labelSizes"

import {
  useMutationCreateLabelTemplate,
  useMutationUpdateLabelTemplate,
  useQueryLabelTemplates,
  LABEL_TEMPLATE_FIELD_TYPE,
  DEMO_PROFILE,
} from "../../data"
import { useDemonstration } from "../../utils/useDemonstration"

const LabelsBuilder = React.lazy(() => import("./LabelsBuilder"))

// TODO Declare these in a global config somewhere where we can see the largest labels that the printer can handle. This might need to come from the RN side once we have the printer connected.
const minimumWidth = 20
const maximumWidth = 60
const minimumHeight = 15
const maximumHeight = 80
const maximumPadding = 5
const minimumPadding = 0

const initialState = {
  title: "",
  size: labelSizes[1].id,
  width: labelSizes[1].width,
  height: labelSizes[1].height,
  padding: labelSizes[1].padding,
  fields: [],
  locations: ["all"],
}

const useStyles = makeStyles((theme) => ({
  listIcon: {
    marginRight: theme.spacing(1),
  },
}))

const LabelsTemplateCreator = ({ open, onCreated, onClose, edit, isInline }) => {
  const classes = useStyles()
  const { id } = useParams()
  const { isValid } = useFormUtils()
  const { hasFeature } = useAuth()
  const { isDemo } = useDemonstration()

  const [createLabelTemplate] = useMutationCreateLabelTemplate()
  const [updateLabelTemplate] = useMutationUpdateLabelTemplate()
  const { data, loading: templatesLoading } = useQueryLabelTemplates()
  const [loading, setLoading] = useState(false)

  // Refs for uncontrolled inputs. Used for the label size input fields to allow for onBlur events
  const widthRef = useRef()
  const heightRef = useRef()
  const paddingRef = useRef()

  // Popper control
  const [popperOpen, setPopperOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState("")
  const [activeEl, setActiveEl] = useState(null)

  // Draft state
  const [draft, setDraft, removeDraft, draftUntouched] = useDraft("labels_template_creator", null)

  // General form state
  const [title, setTitle] = useState(draft?.title ? draft.title : initialState.title)
  const [fields, setFields] = useState(draft?.fields ? draft.fields : initialState.fields)
  const [width, setWidth] = useState(draft?.width ? draft.width : initialState.width)
  const [height, setHeight] = useState(draft?.height ? draft.height : initialState.height)
  const [padding, setPadding] = useState(draft?.padding ? draft.padding : initialState.padding)
  const [size, setSize] = useState(draft?.size ? draft.size : initialState.size)
  const [locations, setLocations] = useState(draft?.locations ? draft.locations : initialState.locations)
  const [templateSizeValues, setTemplateSizeValues] = useState(null)
  const [count, setCount] = useState(0)

  const isEdit = edit
  const showDraft = !isEdit && !!draft && draftUntouched
  const validValue = /^[0-9]+(\.[0-9]+)?$/

  // Get user details, location and current time for the template
  const { principal, location } = useAuth()
  const { firstName, lastName } = principal
  const [currentTime] = useState(moment())
  const [prepTime] = useState(moment())
  const [readyTime] = useState(moment())
  const [discardTime] = useState(moment())

  const currentLocation = location.name
  const dynamicData = {
    firstName,
    lastName,
    currentLocation,
    currentTime,
    prepTime,
    readyTime,
    discardTime,
  }

  const isLabelsDemo = isDemo(DEMO_PROFILE.LABELS)

  // When editing a category, filter the data to get the category to edit
  useEffect(() => {
    if (data && id && edit && !templatesLoading) {
      const result = data.labelTemplates.find((c) => c.id === id)
      setCount(result?.numberOfLabels ?? 0)
      if (result) {
        setTitle(result.title)
        setFields(result.fields)
        setWidth(result.width)
        setHeight(result.height)
        setPadding(result.padding)
        setSize(result.size)
        setLocations(result.locations.length ? mapToIds(result.locations) : ["all"])
        setFields(result.fields)

        // Deep clone required to make "content" object editable
        const copy = JSON.parse(JSON.stringify(result.fields))
        setFields(copy)
      }
    }
  }, [data, id, edit, templatesLoading])

  // Update the "templateSizeValues" state, which is used by the LabelsViewer to calculate the size to display on screen
  useEffect(() => {
    const update = { width, height, padding }

    // Roughly update the uncontrolled inputs when editing. Not ideal but seems to work
    if (edit) {
      if (widthRef.current) {
        widthRef.current.value = width
      }
      if (heightRef.current) {
        heightRef.current.value = height
      }
      if (paddingRef.current) {
        paddingRef.current.value = padding
      }
    }

    setTemplateSizeValues(update)
  }, [width, height, padding, edit])

  // Handles a change in template size from the dropdown list
  const handleSelectedSizeChange = (set, name, newValue) => {
    set(newValue)

    // If the size is not custom, i.e. a default size
    if (newValue !== "custom") {
      const filter = labelSizes.filter((item) => toId(item.id) === newValue)
      setWidth(filter[0].width)
      setHeight(filter[0].height)
      setPadding(filter[0].padding)

      setDraft((prev) => ({
        ...prev,
        [name]: newValue,
        width: filter[0].width,
        height: filter[0].height,
        padding: filter[0].padding,
      }))
    } else {
      setWidth(width)
      setHeight(height)
      setPadding(padding)

      setDraft((prev) => ({
        ...prev,
        [name]: newValue,
        width,
        height,
        padding,
      }))
    }
  }

  // When the user changes the width, height or padding of the label need to check that these are within the allowed ranges
  const handleDimensionChange = (set, name, newValue) => {
    let checkedValue = newValue

    if (name === "width") {
      if (newValue < minimumWidth) {
        setWidth(minimumWidth)
        checkedValue = minimumWidth
        widthRef.current ? (widthRef.current.value = minimumWidth) : null
      } else if (newValue > maximumWidth) {
        setWidth(maximumWidth)
        checkedValue = maximumWidth
        widthRef.current ? (widthRef.current.value = maximumWidth) : null
      } else {
        widthRef.current ? (widthRef.current.value = newValue) : null
      }
    } else if (name === "height") {
      if (newValue < minimumHeight) {
        setHeight(minimumHeight)
        checkedValue = minimumHeight
        heightRef.current ? (heightRef.current.value = minimumHeight) : null
      } else if (newValue > maximumHeight) {
        setHeight(maximumHeight)
        checkedValue = maximumHeight
        heightRef.current ? (heightRef.current.value = maximumHeight) : null
      } else {
        heightRef.current ? (heightRef.current.value = newValue) : null
      }
    } else if (name === "padding") {
      if (newValue < minimumPadding) {
        setPadding(minimumPadding)
        checkedValue = minimumPadding
        paddingRef.current ? (paddingRef.current.value = minimumPadding) : null
      } else if (newValue > maximumPadding) {
        setPadding(maximumPadding)
        checkedValue = maximumPadding
        paddingRef.current ? (paddingRef.current.value = maximumPadding) : null
      } else {
        paddingRef.current ? (paddingRef.current.value = newValue) : null
      }
    }

    set(checkedValue)
    setDraft((prev) => ({
      ...prev,
      [name]: checkedValue,
    }))
  }

  const validateNumbers = (value, dimension) => {
    if (validValue.test(value)) {
      return value
    }
    if (dimension === "width") {
      return minimumWidth
    }
    if (dimension === "height") {
      return minimumHeight
    }
    if (dimension === "padding") {
      return minimumPadding
    }
  }

  const handleOnClose = (event, isCancel = true) => {
    if (isEdit) {
      removeDraft()
    }
    handleResetState()
    onClose && onClose(isCancel)
  }

  const handleCreated = (item) => {
    removeDraft()
    onCreated && onCreated(item)
  }

  // Used for "clicking off" the popper when it's open
  const handleClickAway = () => {
    setPopperOpen(false)
    setActiveEl(null)
  }

  // Get the data of the field in the label and open the popper for it
  const handleSetPopper = (field) => {
    const el = document.getElementById(field.key)
    setAnchorEl(el)
    if (popperOpen) {
      setActiveEl(null)
      setPopperOpen(false)
    } else {
      setActiveEl(field)
      setPopperOpen(true)
    }
  }

  // By default, new fields are added in the centre of the label
  const handleAddField = () => {
    const key = new Date().getTime().toString()
    setFields((state) => {
      const result = [
        ...state,
        {
          key,
          x: 0,
          y: (height - padding * 2) / 2 - 2,
          width: width - padding * 2,
          height: 4,
          content: {
            fontHeight: 4,
            align: "Center",
            upperCase: false,
            textWrap: false,
            type: LABEL_TEMPLATE_FIELD_TYPE.STATIC_TEXT,
            staticText: "Text",
          },
        },
      ]
      handleUpdateDraftContent(result)
      return result
    })
  }

  const handleUpdateField = (field) => {
    const newField = [...fields]
    newField[field.key] = field
    setFields(newField)
    handleUpdateDraftContent(newField)
  }

  const handleRemoveField = (field) => {
    setPopperOpen(false)
    setFields((state) => {
      const result = state.filter((item) => item.key !== field.key)
      handleUpdateDraftContent(result)
      return result
    })
    setActiveEl(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (formValid()) {
      setLoading(true)

      // To work with the oneOf schema, need to convert the field content to the correct type first. This means adding an additional object into each. This is removed when the template is saved
      const cleanContent = fields.map((item) => {
        const { content, ...rest } = item

        switch (item.content.type) {
          case LABEL_TEMPLATE_FIELD_TYPE.NAME:
            return {
              ...rest,
              content: {
                name: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                },
              },
            }
          case LABEL_TEMPLATE_FIELD_TYPE.USER:
            return {
              ...rest,
              content: {
                user: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                  userFormat: item.content.userFormat,
                },
              },
            }
          case LABEL_TEMPLATE_FIELD_TYPE.LOCATION:
            return {
              ...rest,
              content: {
                location: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                },
              },
            }
          case LABEL_TEMPLATE_FIELD_TYPE.CURRENT_DATETIME:
            return {
              ...rest,
              content: {
                current_datetime: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                  dateFormat: item.content.dateFormat,
                },
              },
            }
          case LABEL_TEMPLATE_FIELD_TYPE.PREP_DATETIME:
            return {
              ...rest,
              content: {
                prep_datetime: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                  dateFormat: item.content.dateFormat,
                },
              },
            }
          case LABEL_TEMPLATE_FIELD_TYPE.READY_DATETIME:
            return {
              ...rest,
              content: {
                ready_datetime: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                  dateFormat: item.content.dateFormat,
                },
              },
            }
          case LABEL_TEMPLATE_FIELD_TYPE.DISCARD_DATETIME:
            return {
              ...rest,
              content: {
                discard_datetime: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                  dateFormat: item.content.dateFormat,
                },
              },
            }
          case LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONCREATE:
            return {
              ...rest,
              content: {
                dynamic_text_oncreate: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                  dynamicName: item.content.dynamicName,
                  dynamicText: item.content.dynamicText,
                },
              },
            }
          case LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONPRINT:
            return {
              ...rest,
              content: {
                dynamic_text_onprint: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                  dynamicName: item.content.dynamicName,
                  dynamicText: item.content.dynamicText,
                },
              },
            }
          case LABEL_TEMPLATE_FIELD_TYPE.STATIC_TEXT:
            return {
              ...rest,
              content: {
                static_text: {
                  type: item.content.type,
                  fontHeight: item.content.fontHeight,
                  align: item.content.align,
                  upperCase: item.content.upperCase,
                  textWrap: item.content.textWrap,
                  staticText: item.content.staticText,
                },
              },
            }

          default:
            throw new Error("Unknown type was passed to clean function")
        }
      })

      const clean = deepRemoveTypenames(cleanContent)

      const variables = {
        input: {
          title,
          width,
          height,
          padding,
          locations: locations.includes("all") ? [] : mapToIds(locations),
          fields: clean,
        },
      }

      if (isEdit) {
        await updateLabelTemplate({ variables: { id, ...variables } })
        setLoading(false)
        removeDraft()
        handleOnClose(e, false)
      } else {
        const result = await createLabelTemplate({ variables })
        setLoading(false)
        handleCreated(result.data.createLabelTemplate)
      }
    }
  }

  const handleChange = (set, name, newValue) => {
    set(newValue)
    setDraft((prev) => ({
      ...prev,
      [name]: newValue,
    }))
  }

  const handleUpdateDraftContent = (newValue) => {
    if (edit) return
    setDraft((prev) => ({
      ...prev,
      fields: newValue,
    }))
  }

  // When discarding, make sure to close any open popper and clear the anchor element
  const handleDiscardDraft = () => {
    removeDraft()
    handleResetState()
    setPopperOpen(false)
    setAnchorEl("")
  }

  // Clear selections but reset the label template to the defaults
  const handleResetState = () => {
    setTitle(initialState.title)
    setFields(initialState.fields)
    setLocations(initialState.locations)
    setSize(initialState.size)
    setWidth(initialState.width)
    setHeight(initialState.height)
    setPadding(initialState.padding)
  }

  const handleLocationsChanged = (event) => {
    handleChange(setLocations, "locations", [...multipleSelectChange(locations, event)])
  }

  const handleRegionChange = (regionLocations) => {
    handleChange(setLocations, "locations", [...mapToIds(regionLocations)])
  }

  const formValid = () => isValid(title, width, height, padding)
  const isFormValid = formValid()

  const hasLocationsAndGroups = hasFeature("labels")

  const form = (
    <>
      <DraftBlockquote show={showDraft} subject="Template" onDiscard={handleDiscardDraft} />

      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="title"
              label="Template name"
              name="title"
              value={title}
              onChange={(event) => handleChange(setTitle, "title", event.target.value)}
              required
              data-cy="TextField-title"
            />
          </Grid>
          {!edit && (
            <Grid item xs={12} sm={12}>
              <OutlinedSelect
                label="Label size"
                value={size}
                native={false}
                onChange={(event) => handleSelectedSizeChange(setSize, "size", event.target.value)}
                required
                data-cy="OutlinedSelect-size"
              >
                <MenuItem value="custom" data-cy="MenuItem-category-custom">
                  <RowBox>
                    <Icon>add</Icon>
                    <Box ml={1}>Custom size</Box>
                  </RowBox>
                </MenuItem>
                <Divider />

                {/* List a set of default metric size labels */}
                <ListSubheader onClickCapture={(e) => e.stopPropagation()}>Metric</ListSubheader>
                {labelSizes &&
                  labelSizes.map(({ id: labelId, title: name, format }) =>
                    format === "metric" ? (
                      <MenuItem key={labelId} value={labelId}>
                        <RowBox>
                          <Box>{name}</Box>
                        </RowBox>
                      </MenuItem>
                    ) : null
                  )}

                {/* List a set of default imperial size labels */}
                <ListSubheader onClickCapture={(e) => e.stopPropagation()}>Imperial</ListSubheader>
                {labelSizes &&
                  labelSizes.map(({ id: labelId, title: name, format }) =>
                    format === "imperial" ? (
                      <MenuItem key={labelId} value={labelId}>
                        <RowBox>
                          <Box>{name}</Box>
                        </RowBox>
                      </MenuItem>
                    ) : null
                  )}
              </OutlinedSelect>
            </Grid>
          )}
        </Grid>
      </Box>
      {(size === "custom" || isEdit) && (
        <Box mt={2}>
          <Divider />

          <Box display="flex" flexDirection="row" mt={2} mb={2} alignItems="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="width">Width</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="width"
                    label="Width"
                    name="width"
                    defaultValue={width}
                    inputRef={widthRef}
                    endAdornment={<InputAdornment position="end">mm</InputAdornment>}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 5,
                      min: minimumHeight,
                      max: maximumHeight,
                    }}
                    onBlur={(e) =>
                      handleDimensionChange(setWidth, "width", validateNumbers(parseFloat(e.target.value), "width"))
                    }
                    required
                    data-cy="TextField-size-width"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="width">Height</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="height"
                    label="Height"
                    name="height"
                    defaultValue={height}
                    inputRef={heightRef}
                    endAdornment={<InputAdornment position="end">mm</InputAdornment>}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 5,
                      min: minimumHeight,
                      max: maximumHeight,
                    }}
                    onBlur={(e) =>
                      handleDimensionChange(setHeight, "height", validateNumbers(parseFloat(e.target.value), "height"))
                    }
                    required
                    data-cy="TextField-size-height"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="width">Padding</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="padding"
                    label="Padding"
                    name="padding"
                    defaultValue={padding}
                    inputRef={paddingRef}
                    endAdornment={<InputAdornment position="end">mm</InputAdornment>}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 5,
                      max: maximumPadding,
                      min: minimumPadding,
                    }}
                    onBlur={(e) =>
                      handleDimensionChange(
                        setPadding,
                        "padding",
                        validateNumbers(parseFloat(e.target.value), "padding")
                      )
                    }
                    required
                    data-cy="TextField-size-padding"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Box>
      )}

      {hasLocationsAndGroups && (
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocationOutlinedSelect
                value={locations}
                onChange={handleLocationsChanged}
                onRegionChange={handleRegionChange}
                multiple
                data-cy="LabelTemplateCreator-locations"
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box mt={2} mb={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Suspense fallback={<LoadingSpinner />}>
              <LabelsBuilder
                loading={templatesLoading}
                onSetPopper={handleSetPopper}
                templateSize={templateSizeValues}
                fields={fields}
                activeEl={activeEl}
                onUpdateField={handleUpdateField}
                dynamicData={dynamicData}
              />
            </Suspense>

            {activeEl !== null && data && (
              <LabelsPopper
                count={count}
                open={popperOpen}
                activeEl={activeEl}
                anchorEl={anchorEl}
                onClickAway={handleClickAway}
                onUpdateField={handleUpdateField}
                onRemoveField={handleRemoveField}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleAddField}
          disabled={popperOpen}
          data-cy="Button-add-field"
        >
          <Add /> Add a field
        </Button>
      </Box>
      <Box mb={1} mt={2}>
        <Collapse in={fields.length > 0}>
          <Alert icon={false} severity="info">
            <AlertTitle>Editing template fields</AlertTitle>
            <>
              <List disablePadding>
                <ListItem disableGutters>
                  <PanToolOutlined className={classes.listIcon} />
                  <Typography variant="body2">
                    <strong>Drag</strong> a field to move it to its correct place on the label.
                  </Typography>
                </ListItem>
                <ListItem disableGutters>
                  <ZoomOutMapOutlined className={classes.listIcon} />
                  <Typography variant="body2">
                    <strong>Resize</strong> a field by clicking and dragging any of its edges.
                  </Typography>
                </ListItem>
                <ListItem disableGutters>
                  <MouseOutlined className={classes.listIcon} />
                  <Typography variant="body2">
                    <strong>Click</strong> a field to bring up the field type and formatting options.
                  </Typography>
                </ListItem>
              </List>
            </>
          </Alert>
        </Collapse>
      </Box>
      <CreatorActions
        id="LabelsTemplateCreator-CreatorActions"
        subject="Template"
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid || loading || isLabelsDemo}
        submitLoading={loading}
      />
    </>
  )

  return (
    <DisplayLimiter>
      <CreatorMaster
        id="LabelsTemplateCreator"
        open={open}
        subject="Template"
        form={form}
        isEdit={isEdit}
        isInline={isInline}
        onClose={handleOnClose}
        disableEnforceFocus
      />
    </DisplayLimiter>
  )
}

export { LabelsTemplateCreator }
