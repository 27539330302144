import React from "react"
import { ThemeProvider, createTheme } from "@material-ui/core/styles"
import { CssBaseline } from "@material-ui/core"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/styles"
import { ACTION_PRIORITY, TIMESHEET_STATUS } from "./data"

// Tokens should follow the MUI theme defaults where possible: https://v4.mui.com/customization/default-theme/#default-theme

// The current secondary colour is a temporary error color for certain components (Chips and Buttons) that don't support an "error" prop in V4. It's a workaround until V5 update at which point any components using it for error states should switch to the correct "error" colors below

const palette = {
  // Background
  background: {
    default: "white",
    shaded: "#fafcff",
    dark: "#1a1b43",
  },
  // Deprecated: These shades to be eventually replaced with MUI grey
  shaded: {
    main: "#fafafa",
    nav: "#f5f5f5",
    border: "#cccccc",
    filter: "#fafcff",
  },
  // Blue
  primary: {
    main: "#2092ff",
    mainRgb: "32,146,255",
    light: "#28A8FF",
    dark: "#0A72EB",
    background: "#e8f4fd",
  },
  // Red (temporary secondary color)
  secondary: {
    main: "#F63B3B",
    mainRgb: "246,59,59",
    light: "#FE6B6B",
    dark: "#E41E1E",
    background: "#feebeb",
  },
  // Purple (this is the secondary colour in MUI V5)
  tertiary: {
    main: "#9c27b0",
    mainRgb: "156,39,176",
    light: "#ba68c8",
    lighter: "#D1C4E9", // deprecated, to eventually replace
    dark: "#7b1fa2",
    background: "#fbf7fc",
  },
  // Green
  success: {
    main: "#18ce99",
    mainRgb: "24,206,153",
    light: "#72E3BB",
    dark: "#09926D",
    background: "#f5fffc",
  },
  // Red
  error: {
    main: "#F63B3B",
    mainRgb: "246,59,59",
    light: "#FE6B6B",
    dark: "#E41E1E",
    background: "#feebeb",
  },
  // Grey tones from default theme
  grey: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
    A100: "#d5d5d5",
    A200: "#aaaaaa",
    A400: "#303030",
    A700: "#616161",
  },
  // Text
  text: {
    primary: "#1a1b43",
    secondary: "#757575",
    faint: "#e0e0e0",
    white: "#ffffff",
    readonly: "rgba(26, 27, 67, 0.75)",
    disabled: "rgba(26, 27, 67, 0.38)",
  },
  // Status colours for raised actions
  status: {
    unresolved: "#f44336",
    resolved: "#18ce99",
    cancelled: "#757575",
    blocked: "#fe9316",
    in_progress: "#2092ff",
  },
  // Priority colours for raised actions
  priority: {
    [ACTION_PRIORITY.CRITICAL]: "#f44236",
    [ACTION_PRIORITY.HIGH]: "#f57c01",
    [ACTION_PRIORITY.NORMAL]: "#ffb74d",
    [ACTION_PRIORITY.LOW]: "#ffdfb2",
  },
  // Raised actions (main) and notes (secondary)
  raised: {
    main: "#f63b3b",
    secondary: "#fd9317",
  },
  invited: {
    main: "#fd9317",
  },
  scheduled: {
    main: "#fd9317",
  },
  pending: {
    main: "#fbc02d",
  },
  quiz: {
    main: "#ffcc00",
  },
  featured: {
    main: "#ffcc00",
    dark: "#edbe02",
  },
  sensor: {
    humidity: "#fd9317",
    temperature: "#057DEF",
  },
  inlineEditable: {
    hover: {
      background: "rgba(150,150,150,0.1)",
    },
  },
  punchClock: {
    [TIMESHEET_STATUS.SCHEDULED]: {
      backgroundColor: "#f5fffc",
    },
    [TIMESHEET_STATUS.NOT_SCHEDULED]: {
      backgroundColor: "#f5fffc",
    },
    [TIMESHEET_STATUS.ACTIVE]: {
      backgroundColor: "#ffefdc",
    },
    [TIMESHEET_STATUS.PAUSED]: {
      backgroundColor: "#fafcff",
    },
    [TIMESHEET_STATUS.ENDED]: {
      backgroundColor: "#fafcff",
    },
  },
  calendar: {
    complete: {
      borderColor: "#18ce99",
      backgroundColor: "#f5fffc",
    },
    future: {
      borderColor: "#cccccc",
      backgroundColor: "#fff",
      opacity: 0.5,
    },
    overdue: {
      borderColor: "#f63b3b",
      backgroundColor: "#f7dddd",
    },
    uncomplete: {
      borderColor: "#cccccc",
      backgroundColor: "#fff",
    },
  },
  avatar: {
    number: {
      background: "#f3f3f3",
      color: "#1a1b43",
    },
    plus: {
      background: "#1a1b43",
    },
  },
  hoverCard: {
    boxShadow:
      "0px 10px 10px -2px rgba(32, 146, 255, 0.15), 0px 3px 4px 0px rgba(0, 0, 0, 0.05), 0px 1px 8px 0px rgba(32, 146, 255,0.1)",
    transition: "all 0.2s",
  },
  images: {
    tile: {
      background: "#fafcff",
    },
    backing: {
      background: "#f4f6f9",
    },
  },
  lists: {
    itemHover: "#fafcff",
  },
  buttons: {
    light: {
      background: "#f3f3f3",
      hover: "#EEEEEE",
    },
  },
  progress: {
    bar: {
      started: "#2092ff",
      overdue: "#f63b3b",
    },
  },
  chart: {
    background: "#def1fa",
  },
  cards: {
    primary: {
      divider: "#dbecff",
      backgroundColor: "#fafcff",
    },
    completed: {
      divider: "#d8f9f0",
      borderColor: "#18ce99",
      backgroundColor: "#f5fffc",
    },
    divide: {
      normal: "#EEEEEE",
      darker: "#e0e0e0",
    },
    icons: {
      incomplete: "#707574",
      complete: "#18ce99",
    },
  },
}

const muiTheme = createTheme({
  palette: { ...palette },
  dimensions: {
    header: {
      height: 60,
    },
    punchClock: {
      height: 68,
    },
    offlineBanner: {
      height: 40,
    },
    dialogs: {
      margin: 32,
      alerts: {
        width: 327,
      },
      pickers: {
        width: 327,
        wideWidth: null,
      },
      training: {
        width: 327,
      },
    },
    footer: {
      mobileBottomNav: {
        height: 65,
      },
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "Roboto",
      "'\"Helvetica Neue\"'",
      "Arial",
      "sans-serif",
      "'\"Apple Color Emoji\"'",
      "'\"Segoe UI Emoji\"'",
      "'\"Segoe UI Symbol\"'",
      "'\"Noto Emoji\"'",
      "'\"Noto Color Emoji\"'",
    ].join(","),
    color: palette.text.primary,
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: palette.text.primary,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#d2d2d2",
      },
    },
    // MuiIconButton: {

    // },
    MuiButton: {
      root: {
        textTransform: "none",
      },
      text: {
        textTransform: "none",
        fontWeight: "600",
        fontSize: 14,
        color: palette.primary.main,
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: "rgba(32, 146, 255, 0.04)",
        },
        "&:focus": {
          backgroundColor: "rgba(32, 146, 255, 0.04)",
        },
      },
      contained: {
        textTransform: "none",
        fontWeight: "600",
        paddingLeft: "20px",
        paddingRight: "20px",
        boxShadow: "none",
        color: palette.text.primary,
      },
      containedPrimary: {
        color: "white",
        textTransform: "none",
        transition: "all 0.2s",
        "&:hover": {
          boxShadow: "0px 0px 10px 3px rgba(32, 146, 255, 0.4)",
          "background-color": "#2e99ff",
          transition: "all 0.3s ease",
        },
        "&:focus": {
          boxShadow: "0px 0px 10px 3px rgba(32, 146, 255, 0.4)",
          "background-color": "#2e99ff",
          transition: "all 0.3s ease",
        },
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiIconButton: {
      root: {
        color: palette.text.primary,
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        "&:focus-visible": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiChip: {
      root: {
        fontWeight: 500,
        color: palette.text.primary,
      },
    },
    MuiLink: {
      button: {
        fontSize: 14,
        fontWeight: "600",
      },
    },
    MuiBreadcrumbs: {
      li: {
        fontSize: 14,
        lineHeight: "16px",
        padding: "4px",
        color: palette.text.primary,
        "&& a": {
          color: palette.primary.main,
          fontWeight: "600",
        },
        "&:hover": {
          background: "#ecf4ff",
          borderRadius: "4px",
        },
        "&:focus": {
          background: "#ecf4ff",
          borderRadius: "4px",
        },
        "&:last-child": {
          background: "none",
        },
      },
    },
    MuiDialog: {
      paperFullScreen: {
        marginTop: 60,
        paddingBottom: 240,
      },
      paper: {
        position: "fixed",
        top: 0,
      },
      paperWidthMd: {
        maxWidth: "768px",
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: 20,
        paddingRight: 56,
        "&& h2": {
          fontSize: 20,
          fontWeight: "600 !important",
          lineHeight: "28px",
        },
      },
    },
    MuiDialogContentText: {
      root: {
        color: palette.text.primary,
      },
    },
    MuiDialogActions: {
      root: {
        fontSize: 14,
        fontWeight: "600",
      },
    },
    MuiBottomNavigationAction: {
      label: {
        paddingTop: 5,
        fontSize: 11,
        color: palette.text.primary,
        "&$selected": {
          color: palette.text.primary,
          fontSize: 11,
          background: "none !important",
        },
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: "none",
        color: palette.text.primary,
        fontSize: 14,
        fontWeight: "600",
        lineHeight: "16px",
      },
      root: {
        minWidth: "130px",
        "@media (min-width: 600px)": {
          minWidth: "130px",
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: 3,
        borderRadius: "3px 3px 0px 0px",
      },
    },
    MuiTableCell: {
      root: {
        color: palette.text.primary,
      },
      head: {
        color: palette.text.primary,
      },
    },
    MuiAccordion: {
      root: {
        marginBottom: 0,
        "&:before": {
          display: "none",
        },
        "&$expanded": {
          height: "auto",
          minHeight: "auto",
          marginTop: 0,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        border: "none",
        paddingLeft: 0,
        paddingRight: 0,
        margin: 0,
        padding: 0,
      },
    },
    MuiAccordionDetails: {
      root: {
        border: "none",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },

    MuiPaper: {
      root: {
        color: palette.text.primary,
      },
      rounded: {
        borderRadius: "8px",
      },
      elevation2: {
        boxShadow:
          "0px 3px 1px -2px rgba(32, 146, 255, 0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(32, 146, 255, 0.12)",
      },
      elevation3: {
        boxShadow:
          "0px 3px 3px -2px rgba(32, 146, 255, 0.15), 0px 3px 4px 0px rgba(0, 0, 0, 0.05), 0px 1px 8px 0px rgba(32, 146, 255,0.1)",
      },
      elevation8: {
        boxShadow:
          "0px 5px 5px -3px rgba(32, 146, 255, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.05), 0px 3px 14px 2px rgba(32, 146, 255,0.1)",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(8, 8, 33, 0.6)",
      },
    },
    MuiPickersToolbarText: {
      // toolbarBtnSelected: {
      //   color: "rgba(255, 255, 255, 1)"
      // },
      // toolbarTxt: {
      //   color: "rgba(255, 255, 255, 1)"
      // }
    },
    MuiListItem: {
      gutters: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    MuiAlert: {
      root: {
        width: "100%",
      },
      message: {
        width: "100%",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "32px",
      },
    },
    MuiAvatar: {
      root: {
        fontSize: "0.9rem",
        fontWeight: "600",
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButtonGroup: {
      disableRipple: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
  },
})

// cssbaseline styles override
const styles = (theme) => ({
  "@global": {
    html: {},
    body: {
      height: "100%",
      color: palette.text.primary,
    },
    "#root": {
      height: "100%",
      minHeight: "100vh",
      display: "flex",
      flexFlow: "column",
      flexGrow: 1,
    },
    a: {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "@keyframes successGlow": {
      "0%": {
        boxShadow: `0 0 5px rgba(${theme.palette.success.mainRgb},.2), inset 0 0 5px rgba(${theme.palette.success.mainRgb},.1), 0 2px 0 rgba(${theme.palette.success.mainRgb},.12)`,
      },
      "70%": {
        boxShadow: `0 0 20px rgba(${theme.palette.success.mainRgb},.9), inset 0 0 10px rgba(${theme.palette.success.mainRgb},.6), 0 2px 0 rgba(${theme.palette.success.mainRgb},.12)`,
      },
      "100%": {
        boxShadow: `0 0 5px rgba(${theme.palette.success.mainRgb},.2), inset 0 0 5px rgba(${theme.palette.success.mainRgb},.1), 0 2px 0 rgba(${theme.palette.success.mainRgb},.12)`,
      },
    },
    "@keyframes slide": {
      "0%": {
        transform: "translateX(-100%)",
      },
      "100%": {
        transform: "translateX(100%)",
      },
    },
    "@keyframes fadeInOpacity": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
    "@keyframes fadeInOpacityLight": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 0.3,
      },
    },
    "@keyframes beacon": {
      "0%": {
        opacity: 0,
      },
      "50%": {
        opacity: 0.3,
      },
      "100%": {
        opacity: 0,
      },
    },
    "@media (max-width: 1024px)": {
      "#hubspot-messages-iframe-container": {
        display: "none",
        visibility: "hidden",
      },
    },
    ".kiosk #hubspot-messages-iframe-container": {
      display: "none",
      visibility: "hidden",
    },
    ".device #hubspot-messages-iframe-container": {
      display: "none",
      visibility: "hidden",
    },
    ".BeaconContainer": {
      zIndex: "999999 !important",
    },
    ".announcekit-frame-wrapper": {
      width: "90vw !important",
      maxWidth: "750px !important",
    },
  },
})

// eslint-disable-next-line no-unused-vars
const OverrideCssBaseline = ({ classes }) => null
OverrideCssBaseline.propTypes = {
  classes: PropTypes.object.isRequired,
}

const WithStylesOverrideCssBaseline = withStyles(styles)(OverrideCssBaseline)

const Theme = ({ children }) => (
  <ThemeProvider theme={muiTheme}>
    <CssBaseline />
    <WithStylesOverrideCssBaseline />
    {children}
  </ThemeProvider>
)

export default Theme
