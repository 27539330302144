import React, { useEffect, useState } from "react"
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@impelsys/material-ui-pickers"
import { AccessTime as AccessTimeIcon } from "@material-ui/icons"
import MomentUtils from "@date-io/moment"
import moment from "moment"

const TimePicker = ({ label, value, onChange, emptyLabel, ...rest }) => {
  const [finalValue, setFinalValue] = useState(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const momentValue = moment(value)
    setFinalValue(emptyLabel && momentValue.hours() + momentValue.minutes() === 0 ? null : value)
  }, [value, emptyLabel])

  const handleChange = (newValue) => {
    if (newValue) onChange(newValue.startOf("minute"))
    else if (value) onChange(value.startOf("day"))
    else onChange(null)
  }

  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        label={label}
        value={finalValue}
        open={open}
        inputVariant="outlined"
        minutesStep={5}
        onChange={handleChange}
        onClick={handleClick}
        onClose={handleClose}
        clearable
        clearLabel="Available all day"
        InputAdornmentProps={{ position: "end", style: { marginRight: -16 } }}
        keyboardIcon={<AccessTimeIcon />}
        fullWidth
        emptyLabel={value && emptyLabel}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

export { TimePicker }
