import React, { useRef } from "react"
import { IconButton, Box, TextField, Tooltip } from "@material-ui/core"
import { Link as LinkIcon } from "@material-ui/icons"
import Config from "react-global-configuration"
import { useSnackbar } from "../SnackbarProvider"
import { Icon } from "../Icon"

const CopyLinkButton = ({ children, link, iconSize = "medium" }) => {
  const inputRef = useRef(null)
  const { showMessage } = useSnackbar()
  const config = Config.get()
  const url = link || `${config.clientLinkBaseUri || config.clientBaseUri}${window.location.pathname}`

  const handleClick = () => {
    inputRef.current.select()
    document.execCommand("copy")
    showMessage({ message: "Link copied to your clipboard", icon: <Icon name="clipboard" /> })
    inputRef.current.blur()
  }

  return (
    <>
      {children && <Box onClick={handleClick}>{children}</Box>}

      {!children && (
        <Tooltip title="Copy link">
          <IconButton size={iconSize} onClick={handleClick}>
            <LinkIcon />
          </IconButton>
        </Tooltip>
      )}

      <TextField
        inputRef={inputRef}
        value={url}
        style={{
          left: 0,
          bottom: 0,
          width: 1,
          height: 1,
          opacity: 0,
          position: "fixed",
        }}
      />
    </>
  )
}

export { CopyLinkButton }
