import React from "react"
import { Box, Button } from "@material-ui/core"
import { RowBox } from "../Boxes"
import { ChipMenuItem } from "./ChipMenuItem"

const ChipMenuActions = ({ applyLabel = "View results", clearLabel = "Clear", boxProps = {}, onApply, onClear }) => {
  const mergedBoxProps = { pt: 0.5, ...boxProps }

  const handleApply = () => {
    onApply && onApply()
  }

  const handleClear = () => {
    onClear && onClear()
  }

  return (
    <ChipMenuItem>
      <RowBox justifyContent="space-between" flexGrow={1} alignContent="center" {...mergedBoxProps}>
        <Box mr={1}>
          <Button onClick={handleClear}>{clearLabel}</Button>
        </Box>
        <Button color="primary" variant="contained" onClick={handleApply} fullWidth>
          {applyLabel}
        </Button>
      </RowBox>
    </ChipMenuItem>
  )
}

export { ChipMenuActions }
