import React, { useEffect, useMemo, useState } from "react"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import { Box } from "@material-ui/core"
import { RowBox, ReportSectionActionCard, NoItemsMessage } from ".."
import { mapToIds } from "../../utils"
import { useAuth } from "../../services"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"
import { useLazyQueryReportActionsLocationsOverview } from "../../data/report/useQueryReportActionsLocationsOverview"
import { CircularStatusChart } from "../Charts/CircularStatusChart"
import ActionsTableReport from "./ActionsTableReport"

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.rank > b.rank ? -1 : 1)

const LocationsActionsReport = ({
  gt,
  lt,
  process,
  locations,
  groups,
  tags,
  statuses,
  priorities,
  onLocationClick,
}) => {
  const [viewActions, setViewActions] = useState(null)
  const [sort, setSort] = useState("alpha")
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const [load, { data, loading }] = useLazyQueryReportActionsLocationsOverview()

  useEffect(() => {
    const filterProcess = process === "all" ? null : process
    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterGroups = groups.includes("all") ? null : groups
    const filterTags = tags.includes("all") ? null : tags
    const filterStatuses = statuses.includes("all") ? null : statuses
    const filterPriorities = priorities.includes("all") ? null : priorities

    load({
      variables: {
        input: {
          process: filterProcess,
          locations: filterLocations,
          groups: filterGroups,
          tags: filterTags,
          statuses: filterStatuses,
          priorities: filterPriorities,
          gt,
          lt,
        },
      },
    })
  }, [groups, gt, load, locations, lt, priorities, process, statuses, tags, userLocations])

  const handleLocationClick = ({ id }) => {
    setViewActions(id)
    onLocationClick && onLocationClick(id)
  }

  const handleViewStepsClose = () => {
    setViewActions(null)
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const chartData = useMemo(
    () =>
      data
        ? data?.report.actions.locations.overview
            .map(({ id, locationName: name, total, ...rest }) => ({
              id,
              name,
              rank: total,
              total,
              ...rest,
            }))
            .sort((a, b) => sortData(sort, a, b))
        : [],
    [data, sort]
  )

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = ["Name", "Unresolved", "InProgress", "Blocked", "Resolved", "Cancelled"]
    const rows = [...data.report.actions.locations.overview].map(
      ({ locationName: name, unresolved, inProgress, blocked, resolved, cancelled }) =>
        [stringFormatter(name), unresolved, inProgress, blocked, resolved, cancelled].join(",")
    )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "locations-actions-status.csv", "text/csv")
  }

  return (
    <>
      {viewActions && (
        <ActionsTableReport
          gt={gt}
          lt={lt}
          process={viewActions.process}
          step={viewActions.processStep}
          locations={[viewActions]}
          groups={groups}
          tags={tags}
          statuses={statuses}
          priorities={priorities}
          defaultFullscreen
          onFullscreen={handleViewStepsClose}
        />
      )}

      <ReportSectionActionCard
        title="Actions raised by location"
        detail="Where are actions being raised"
        loading={loading}
        tools={
          <RowBox>
            <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
            {chartData.length > 1 && (
              <Box ml={1}>
                <ReportToolsIconButton
                  title={sort === "alpha" ? "Sort by #" : "Sort alphabetically"}
                  icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
                  onClick={handleSort}
                  disabled={loading}
                />
              </Box>
            )}
          </RowBox>
        }
      >
        <RowBox flexWrap="wrap" alignItems="flex-start" justifyContent="center">
          {chartData.map(({ id, name, ...value }) => (
            <CircularStatusChart key={id} text={name} value={value} onClick={() => handleLocationClick({ id })} />
          ))}
          {chartData.length === 0 && <NoItemsMessage>No data</NoItemsMessage>}
        </RowBox>
      </ReportSectionActionCard>
    </>
  )
}

export default LocationsActionsReport
