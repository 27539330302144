import { gql } from "@apollo/client"

const REPORT_JOB_COMPLETIONS_FIELDS = gql`
  fragment ReportJobCompletionsFields on ReportJobCompletions {
    completedOnTime
    completedLate
    inProgress
    uncompleted
    uncompletedOverdue
    total
  }
`

export { REPORT_JOB_COMPLETIONS_FIELDS }
