import React from "react"
import { Box, Typography, Breadcrumbs } from "@material-ui/core"
import { NavLink, useParams } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "30px",
    marginBottom: ({ mb }) => theme.spacing(mb),
  },
}))

const AreaTagBreadcrumb = ({ area, ml = 1, mb = 1 }) => {
  const classes = useStyles({ ml, mb })
  const { tag: tagSlug } = useParams()

  const getTagName = () => area?.tags?.find((tag) => tag.url === tagSlug)?.name ?? ""

  return (
    <Box>
      <Box mb={1}>
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Box>
            <Typography variant="h3" className={classes.title}>
              {getTagName()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mb={{ xs: 2, sm: 3 }}>
        <Breadcrumbs>
          <NavLink to={`/area/${area?.url}`}>{area?.name ?? ""}</NavLink>
          <Box>{getTagName()}</Box>
        </Breadcrumbs>
      </Box>
    </Box>
  )
}

export default AreaTagBreadcrumb
