import { useLazyQuery, useQuery, gql } from "@apollo/client"
import * as Sentry from "@sentry/browser"
import { offsetLimitPagination } from "@apollo/client/utilities"
import { TAG_FIELDS } from "../tags/useQueryTags"
import { ASSET_FIELDS } from "./fragments/assetFieldsFragment"

const ASSETS_LIMIT = 20

const ASSETS_QUERY = gql`
  query Assets($filter: AssetFilterInput, $offset: Int, $limit: Int) {
    assets {
      list(filter: $filter, limit: $limit, offset: $offset) {
        ...AssetFields
      }
      count(filter: $filter)
    }
  }
  ${ASSET_FIELDS}
`

const ASSETS_COUNT_QUERY = gql`
  query AssetsCount($filter: AssetFilterInput) {
    assets {
      count(filter: $filter)
    }
  }
`
const ASSET_TAGS_QUERY = gql`
  query AssetTags {
    assets {
      tags {
        ...TagFields
      }
    }
  }
  ${TAG_FIELDS}
`

const useQueryAssets = (options) => useQuery(ASSETS_QUERY, options)
const useQueryAssetsCount = (options) => useQuery(ASSETS_COUNT_QUERY, options)
const useQueryAssetTags = (options) => useQuery(ASSET_TAGS_QUERY, options)

const loadMore = (result) => async () => {
  try {
    await result.fetchMore({
      variables: {
        ...result.variables,
        offset: result.data.assets.list.length,
        limit: ASSETS_LIMIT,
      },
    })
  } catch (error) {
    console.log(error)
    Sentry.captureException(error)
  }
}

const useLazyQueryAssets = (options) => {
  const { variables, ...otherOptions } = options || {}
  const [loadItems, result] = useLazyQuery(ASSETS_QUERY, {
    variables: {
      offset: 0,
      limit: ASSETS_LIMIT,
      ...variables,
    },
    fetchPolicy: "cache-and-network",
    ...otherOptions,
  })

  return [
    loadItems,
    {
      ...result,
      loadMore: loadMore(result),
    },
  ]
}

const assetsTypePolicies = {
  scoped: {
    AssetsQuery: {
      merge(_, next) {
        return { ...next }
      },
      fields: {
        list: offsetLimitPagination(["filter"]),
        count: {
          keyArgs: ["filter"],
        },
      },
    },
  },
}

export {
  useQueryAssets,
  useQueryAssetsCount,
  useLazyQueryAssets,
  useQueryAssetTags,
  assetsTypePolicies,
  ASSETS_COUNT_QUERY,
}
