import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { REPORT_JOB_COMPLETIONS_FIELDS } from "./fragments/reportJobCompletionsFieldsFragment"

const REPORT_LOCATIONS_JOBS_COMPLETIONS_QUERY = gql`
  query ReportJobsLocationsCompletions($input: ReportJobsLocationsInput!) {
    report: reportV2 {
      jobs {
        locations {
          completions(input: $input) {
            id
            name
            region
            completions {
              ...ReportJobCompletionsFields
            }
          }
        }
      }
    }
  }
  ${REPORT_JOB_COMPLETIONS_FIELDS}
`

const useQueryReportJobsLocationsCompletions = (options) =>
  useQuery(REPORT_LOCATIONS_JOBS_COMPLETIONS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportJobsLocationsCompletions = (options) =>
  useLazyQuery(REPORT_LOCATIONS_JOBS_COMPLETIONS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJobsLocationsCompletions, useLazyQueryReportJobsLocationsCompletions }
