import React, { useState } from "react"
import { Switch, useHistory, useLocation } from "react-router-dom"
import { Box, Button, Divider, Tab, Tabs } from "@material-ui/core"
import { AddLocationOutlined } from "@material-ui/icons"
import { useApolloClient } from "@apollo/client"
import {
  LocationActionCard,
  RequirePermissions,
  NoItemsMessage,
  SearchInput,
  LoadingSpinner,
  Icon,
  PrivateRoute,
} from "../../components"
import { useAuth } from "../../services"
import { LocationCreator } from "../../components/LocationCreator/LocationCreator"
import SettingsFooter from "../../components/Settings/SettingsFooter"
import { joinStringsWithNaturalLanguage, toId } from "../../utils"
import { useQueryRegions } from "../../data/locations/useQueryRegions"
import RegionActionCard from "../../components/ActionCards/RegionActionCard"
import RegionCreator from "../../components/LocationCreator/RegionCreator"
import { ListWindowVirtualizer } from "../../components/Lists/ListWindowVirtualizer"
import { useMutationDeleteRegion } from "../../data/locations/useMutationDeleteRegion"

const Locations = () => {
  const {
    settings: { organisation, locations },
    hasPermission,
  } = useAuth()
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(null)
  const [searchText, setSearchText] = useState("")

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleCreateLocation = () => {
    setOpen(true)
  }
  const handleCreateLocationClose = () => {
    setOpen(false)
    setEdit(null)
  }
  const handleEdit = (editLocation) => {
    setEdit(editLocation)
    setOpen(true)
  }
  const handleDelete = () => {}

  if (!locations.length) {
    return <NoItemsMessage>No locations are configured for your user</NoItemsMessage>
  }

  const showLocations = locations.filter((item) => !searchText || item.name.toLowerCase().includes(searchText))

  const hasFilter = locations.length > 5

  const canCreate = hasPermission("location_create")

  return (
    <>
      {canCreate && <LocationCreator open={open} onClose={handleCreateLocationClose} edit={edit} />}

      {hasFilter && <SearchInput placeholder="Filter" boxProps={{ pl: 0, pr: 0 }} onChange={handleFilter} />}

      <Box mb={5} data-cy="Locations-list">
        <ListWindowVirtualizer
          items={showLocations}
          itemContent={(_, location) => (
            <LocationActionCard
              key={toId(location)}
              location={location}
              organisation={organisation}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          )}
          components={{
            Footer: () => <Box my={10}>&nbsp;</Box>,
          }}
        />

        {showLocations.length === 0 && <NoItemsMessage>No locations</NoItemsMessage>}
      </Box>

      <RequirePermissions requires="location_create">
        <SettingsFooter>
          <Button variant="contained" color="primary" onClick={handleCreateLocation} data-cy="Button-create-location">
            <AddLocationOutlined /> <Box ml={1}>Add location</Box>
          </Button>
        </SettingsFooter>
      </RequirePermissions>
    </>
  )
}

const Regions = () => {
  const { data, loading, refetch } = useQueryRegions()
  const apollo = useApolloClient()
  const [deleteRegion] = useMutationDeleteRegion()

  const {
    settings: { locations },
    refresh: refreshAuth,
  } = useAuth()
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(null)

  const handleCreate = () => {
    setOpen(true)
  }

  const handleEdit = (region) => {
    setEdit(region)
    setOpen(true)
  }

  const handleClose = async () => {
    setOpen(false)
    setEdit(null)
    await refetch()
  }

  const handleDelete = async ({ id: deleteId }) => {
    const result = await deleteRegion({
      variables: {
        id: deleteId,
      },
    })
    if (result?.data?.region) {
      await refetch()
      await refreshAuth(apollo, true)
    }
  }

  const handleFilter = () => {}

  const hasFilter = false

  const regions = data?.regions || []

  return (
    <>
      <RegionCreator open={open} onClose={handleClose} edit={edit} />

      {hasFilter && <SearchInput placeholder="Filter" boxProps={{ pl: 0, pr: 0 }} onChange={handleFilter} />}

      <Box mb={5} data-cy="Regions-list">
        {regions.map((region) => {
          const regionLocations = locations.filter(
            (location) => location.region && toId(location.region) === toId(region)
          )

          return (
            <RegionActionCard
              key={toId(region)}
              region={region}
              detail={
                <Box mr={1}>
                  {regionLocations.length
                    ? joinStringsWithNaturalLanguage(regionLocations.map((location) => location.name))
                    : "No locations"}
                </Box>
              }
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          )
        })}

        {!data && loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}

        {regions.length === 0 && <NoItemsMessage>No regions created</NoItemsMessage>}
      </Box>

      <RequirePermissions requires="location_create">
        <SettingsFooter>
          <Button variant="contained" color="primary" onClick={handleCreate} data-cy="Button-create-region">
            <Icon name="region" /> <Box ml={1}>Create region</Box>
          </Button>
        </SettingsFooter>
      </RequirePermissions>
    </>
  )
}

const Index = () => {
  const location = useLocation()
  const history = useHistory()
  const { hasFeature } = useAuth()

  const handleTabChange = (event, value) => {
    history.push(`/account/${value}`)
  }

  const path = location.pathname.split("/")
  const page = path.length > 2 ? path[2] : "locations"

  const hasRegions = hasFeature("regions")

  return (
    <>
      {hasRegions && (
        <Tabs
          variant="standard"
          indicatorColor="primary"
          textColor="primary"
          value={page}
          onChange={handleTabChange}
          aria-label="Location type tabs"
          data-cy="Tabs"
        >
          <Tab label="Locations" value="locations" data-cy="Tab-locations" />
          <Tab label="Regions" value="regions" data-cy="Tab-regions" />
        </Tabs>
      )}

      {hasRegions && <Divider />}

      <Box pt={hasRegions ? 5 : 0}>
        <Switch>
          <PrivateRoute path="/account/locations" component={Locations} />
          <PrivateRoute path="/account/regions" component={Regions} feature="regions" />
        </Switch>
      </Box>
    </>
  )
}

export default Index
