import { Box, Button, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { RowBox } from "../Boxes"
import { DatePicker } from "."
import { Icon } from "../Icon"
import TimeAgo from "../TimeAgo"

const useStyles = makeStyles(() => ({
  button: {
    whiteSpace: "nowrap",
  },
  buttonIcon: {
    fontSize: 20,
  },
}))

const ExpiryDatePicker = ({ value, onChange, buttonTextClassName = null, cy = "ExpiryDatePicker" }) => {
  const classes = useStyles()
  const [openExpiry, setOpenExpiry] = useState(false)

  const handleChange = (event) => {
    onChange && onChange(event)
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpenExpiry(true)}
        className={classes.button}
        data-cy={`${cy}-button`}
      >
        <RowBox className={buttonTextClassName}>
          <Icon name="date" className={classes.buttonIcon} />
          <Box ml={0.5}>
            {value ? (
              <>
                <TimeAgo date={value} xsCompact={false} />
              </>
            ) : (
              <>Set an expiry date</>
            )}
          </Box>
        </RowBox>
      </Button>
      <DatePicker
        open={openExpiry}
        label="Set expiry date"
        value={value}
        variant="dialog"
        size="small"
        onClose={() => setOpenExpiry(false)}
        onChange={handleChange}
        TextFieldComponent={() => null}
        clearLabel="Clear"
        clearable
      />
    </>
  )
}

export { ExpiryDatePicker }
