import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { SENSOR_FIELDS } from "./useQuerySensors"

const SENSOR_QUERY = gql`
  query Sensor($id: ID!, $gt: Date!, $lt: Date) {
    sensor(id: $id, gt: $gt, lt: $lt) {
      ${SENSOR_FIELDS}
    }
  }
`

const sensorTypePolicies = {
  Sensor: {
    fields: {
      samples: {
        merge(prev, next) {
          return next
        },
      },
    },
  },
}

const useQuerySensor = (options) => useQuery(SENSOR_QUERY, options)

const useLazyQuerySensor = (options) => useLazyQuery(SENSOR_QUERY, options)

export { useQuerySensor, useLazyQuerySensor, sensorTypePolicies }
