import React, { useMemo } from "react"
import { Box, Divider, Tooltip } from "@material-ui/core"
import moment from "moment"
import { PaperBox, RowBox } from "../Boxes"
import { InfoSection } from "../Viewers"
import { useLazyQueryIntegrations, USER_STATUS } from "../../data"
import TimeAgo from "../TimeAgo"
import { LinkButton } from "../LinkButton"
import { FormatDateTimeCompact } from "../Format"
import { Caption, FieldSectionHeading } from "../Headings"
import { toId, useMountEffect } from "../../utils"
import { PersonNotes } from "./PersonNotes"
import { useAuth } from "../../services"
import { TruncateNames } from "../DataDisplay/TruncateNames"
import { LoadingSpinner } from "../LoadingSpinner"
import { InfoCollapse } from "../Viewers/InfoCollapse"
import { snakeToTitle } from "../../utils/string"
import { Icon } from "../Icon"

const PersonAttributeList = ({ attributes, integrations }) => {
  const getAttribute = (userAttribute) => {
    const integration = integrations?.find((_integration) => toId(_integration) === toId(userAttribute.integration))
    return integration?.integrationProvider.attributes?.user.find((attribute) => attribute.key === userAttribute.key)
  }

  if (!attributes?.length > 0) {
    return null
  }

  return (
    <>
      {attributes.map((attribute) => {
        const integrationAttribute = getAttribute(attribute)

        const tooltip = [attribute.key]
        if (integrationAttribute) {
          tooltip.push(toId(attribute.integration))
        }

        return (
          <React.Fragment key={toId(attribute)}>
            <InfoSection
              title={
                <Tooltip title={tooltip.join(" - ")}>
                  <RowBox>
                    {Boolean(integrationAttribute) && (
                      <Box mr={1}>
                        <Icon name="integration" fontSize="small" />
                      </Box>
                    )}
                    <Box>{integrationAttribute?.name || snakeToTitle(attribute.key)}</Box>
                  </RowBox>
                </Tooltip>
              }
              value={attribute?.value || "(not set)"}
            />
            <Divider />
          </React.Fragment>
        )
      })}
    </>
  )
}

const PersonDetails = ({ user, onResendInvite, sendInviteLoading }) => {
  const { hasPermission, canCreateRoles, isCurrentUser } = useAuth()
  const [loadIntegrations, { data: integrationsData, loading: integrationsLoading }] = useLazyQueryIntegrations()
  const isInitial = USER_STATUS.INITIAL === user.status
  const isInvite = [USER_STATUS.INVITED, USER_STATUS.INITIAL].includes(user.status)
  const isInviteExpired = isInvite && moment().isAfter(user.invite.expiresAt)

  useMountEffect(() => {
    if (hasPermission("integration_read")) {
      loadIntegrations()
    }
  })

  const handleResendInvite = () => {
    onResendInvite && onResendInvite()
  }

  const hasAttributes = useMemo(
    () =>
      user.attributes?.length > 0 ||
      integrationsData?.integrations.some(({ integrationProvider: { attributes } }) => attributes?.user.length),
    [integrationsData?.integrations, user.attributes?.length]
  )

  const hasEditDetails = canCreateRoles(user.roles)
  const hasNotes = hasPermission("user_note_read") && !isCurrentUser(user)

  const attributesWithValue = user.attributes?.filter((attribute) => attribute.value)
  const attributesWithoutValue = user.attributes?.filter((attribute) => !attribute.value)

  return (
    <>
      <FieldSectionHeading>Account &amp; login</FieldSectionHeading>
      <PaperBox mb={3}>
        <InfoSection title="Email address" value={user.email} />
        {isInvite && (
          <>
            <Divider />
            <InfoSection
              title="Invitation"
              value={
                <RowBox>
                  <LinkButton onClick={handleResendInvite} disabled={sendInviteLoading}>
                    {isInitial && <>Send invite</>}
                    {!isInitial && <>Resend invite</>}
                  </LinkButton>
                  {isInviteExpired && (
                    <Box ml={1}>
                      Expired <FormatDateTimeCompact value={user.invite.expiresAt} />
                    </Box>
                  )}
                  {!isInviteExpired && Boolean(user.invite?.sentAt) && (
                    <Box ml={1}>
                      Sent <TimeAgo date={user.invite.sentAt} lowercase />, expires{" "}
                      <FormatDateTimeCompact value={user.invite.expiresAt} />
                    </Box>
                  )}
                </RowBox>
              }
            />
          </>
        )}
        {user.external && (
          <>
            <Divider />
            <InfoSection title="Identity provider" value={user.identityProvider.name} />
          </>
        )}
      </PaperBox>
      <FieldSectionHeading>Work, locations &amp; permissions</FieldSectionHeading>
      <PaperBox mb={3}>
        <InfoSection title="Areas of Work" value={user.groups.map((group) => group.name).join(", ")} />
        <Divider />
        <InfoSection
          title="Locations"
          value={<TruncateNames names={user.locations.map((location) => location.name)} max={3} />}
        />

        <Divider />
        <InfoSection
          title="Access level"
          value={user.roles.map((role) => role.friendlyName).join(", ") || "Employee"}
        />
      </PaperBox>
      {hasAttributes && (
        <>
          <FieldSectionHeading>Attributes</FieldSectionHeading>
          <PaperBox mb={3}>
            {!integrationsLoading && integrationsData?.integrations && (
              <>
                <PersonAttributeList attributes={attributesWithValue} integrations={integrationsData.integrations} />

                {attributesWithoutValue.length > 0 && (
                  <InfoCollapse subject="attributes">
                    <PersonAttributeList
                      attributes={attributesWithoutValue}
                      integrations={integrationsData.integrations}
                    />
                  </InfoCollapse>
                )}
              </>
            )}
            {integrationsLoading && (
              <Box display="flex" justifyContent="center" p={1}>
                <LoadingSpinner delay={false} />
              </Box>
            )}
          </PaperBox>
        </>
      )}
      {hasEditDetails && hasNotes && (
        <>
          <FieldSectionHeading mb={0.5}>Internal notes</FieldSectionHeading>
          <Caption mb={1.5}>
            Only users with Regional Manager permission level and above can view and leave notes.
          </Caption>
          <PersonNotes user={user} />
        </>
      )}
    </>
  )
}

export { PersonDetails }
