import React from "react"
import { Box } from "@material-ui/core"
import { Schedule as ScheduleIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { useJobUtils } from "../../utils"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  scheduledText: {
    color: theme.palette.scheduled.main,
  },
}))

const JobScheduledMessage = ({ type, availableFrom }) => {
  const { getScheduleFormattedDate } = useJobUtils()
  const classes = useStyles()

  return (
    <RowBox mr="auto" className={classes.scheduledText}>
      <ScheduleIcon />
      <Box ml={1}>
        {type || "Job"} scheduled for <strong>{getScheduleFormattedDate(availableFrom)}</strong>
      </Box>
    </RowBox>
  )
}

export { JobScheduledMessage }
