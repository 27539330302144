import React, { useState } from "react"
import { Box, Button, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { ActionCard } from "../ActionCards"
import { AuthorTools } from "../Author"
import { Avatar } from "../Avatar"
import { ColumnBox, FlexBox, RowBox } from "../Boxes"
import { Icon } from "../Icon"
import {
  TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE_LABELS,
  TRIGGER_TARGET_TYPE,
  TRIGGER_TARGET_TYPE_LABELS,
  TRIGGER_TYPE,
  TRIGGER_NOTIFY_METHOD_LABELS,
  TRIGGER_NOTIFY_METHOD,
  EVENT_NAME,
} from "../../data"
import { FormatDateTimeCompact } from "../Format"
import HumanReadableCron from "./HumanReadableCron"
import { formatEventLabel } from "../../utils/events"
import { Assignees } from "../Assignees"
import { joinStringsWithNaturalLanguage, toId } from "../../utils"
import {
  useMutationDeleteTrigger,
  useMutationPauseTrigger,
  useMutationResumeTrigger,
  useMutationTestTrigger,
} from "../../data/triggers/useMutationTrigger"
import { LoadingSpinner } from "../LoadingSpinner"
import { useSnackbar } from "../SnackbarProvider"
import { ActionCardFooterInfo } from "../ActionCards/ActionCardFooterInfo"

const { NOTIFY_USERS, NOTIFY_EMAILS, NOTIFY_AUTHOR, INTEGRATION } = TRIGGER_TARGET_TYPE
const { EMAIL, PUSH } = TRIGGER_NOTIFY_METHOD

const useFooterStyles = makeStyles(() => ({
  pausedAvatar: {
    width: 24,
    height: 24,
  },
}))

const Footer = ({ trigger }) => {
  const classes = useFooterStyles()
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("sm"))
  const snackbar = useSnackbar()
  const [testTrigger, { loading: testLoading }] = useMutationTestTrigger()
  const [testing, setTesting] = useState(false)
  const { targetType, notifyUsersTarget, pausedAt, pausedBy, createdAt, updatedAt } = trigger

  const handleTest = async () => {
    if (!testing) {
      setTesting(true)
      return
    }
    await testTrigger({ variables: { id: toId(trigger) } })
    snackbar.showMessage({ message: "Test notification sent", icon: <Icon name="mail" /> })
    setTesting(false)
  }

  const hasTest =
    targetType === NOTIFY_EMAILS ||
    (targetType === NOTIFY_USERS && notifyUsersTarget?.methods.some((method) => [EMAIL, PUSH].includes(method)))

  return (
    <>
      {hasTest && !pausedAt && (
        <Button variant="outlined" size="small" onClick={handleTest} disabled={testLoading}>
          {!testing && !testLoading && <>Test</>}
          {testing && !testLoading && <>Confirm test?</>}
          {testLoading && <LoadingSpinner size={24} delay={false} />}
        </Button>
      )}

      {Boolean(pausedAt) && (
        <RowBox>
          <ActionCardFooterInfo
            title="Currently paused"
            value={
              <>
                Paused <FormatDateTimeCompact value={pausedAt} title="Paused" />
              </>
            }
            icon="pause"
            mr={1}
          />
          {pausedBy && <Avatar {...pausedBy} className={classes.pausedAvatar} />}
        </RowBox>
      )}

      <RowBox ml="auto">
        <ActionCardFooterInfo
          title="Created"
          value={<FormatDateTimeCompact value={createdAt} title="Created" />}
          icon="date"
          mr={small ? 1 : 3}
        />
        <ActionCardFooterInfo
          title="Last activity"
          value={<FormatDateTimeCompact value={updatedAt} title="Last activity" />}
          icon="last-activity"
          mr={small ? -1 : 0}
        />
      </RowBox>
    </>
  )
}

const ScheduleTriggerTargetSummary = ({ trigger }) => {
  const classes = useScheduleStyles()

  const { name, scheduleSource, targetType, notifyUsersTarget, notifyEmailsTarget, integrationTarget } = trigger

  switch (targetType) {
    case NOTIFY_USERS:
      return (
        <>
          {!!name && (
            <Box mb={0.5}>
              <HumanReadableCron
                expression={scheduleSource.schedules[0]}
                timeZone={scheduleSource.timeZone}
                className={classes.title}
              />
            </Box>
          )}
          <Box mb={0.5}>
            {TRIGGER_TARGET_TYPE_LABELS[targetType]}
            {": "}
            <Assignees users={notifyUsersTarget.users} groups={notifyUsersTarget.groups} />
          </Box>
          <Box mb={0.5}>
            {TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE_LABELS[notifyUsersTarget.output]} for{" "}
            {scheduleSource.locations?.map((item) => item.name).join(", ") || "all locations"}
          </Box>
          <Box>{notifyUsersTarget.methods.map((method) => TRIGGER_NOTIFY_METHOD_LABELS[method]).join(", ")}</Box>
        </>
      )
    case NOTIFY_EMAILS:
      return (
        <>
          {!!name && (
            <Box mb={0.5}>
              <HumanReadableCron
                expression={scheduleSource.schedules[0]}
                timeZone={scheduleSource.timeZone}
                className={classes.title}
              />
            </Box>
          )}

          <Box mb={0.5}>
            {TRIGGER_TARGET_TYPE_LABELS[targetType]}
            {": "}
            {notifyEmailsTarget.emails.join(", ")}
          </Box>
          <Box>
            {TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE_LABELS[notifyEmailsTarget.output]} for{" "}
            {scheduleSource.locations?.map((item) => item.name).join(", ") || "all locations"}
          </Box>
        </>
      )
    case INTEGRATION:
      return <Box>{toId(integrationTarget)}</Box>
    default:
      return null
  }
}

const useScheduleStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(0.5),
  },
}))

const ScheduleTriggerActionCard = ({ trigger, onEdit, onDelete, detail, ...rest }) => {
  const classes = useScheduleStyles()
  const [pauseTrigger, { loading: pauseLoading }] = useMutationPauseTrigger()
  const [resumeTrigger, { loading: resumeLoading }] = useMutationResumeTrigger()
  const [deleteTrigger, { loading: deleteLoading }] = useMutationDeleteTrigger(trigger.__typename)
  const {
    id,
    name,
    scheduleSource: { schedules, timeZone },
    pausedAt,
  } = trigger

  const handleEdit = () => {
    if (onEdit) onEdit(trigger)
  }
  const handleTogglePause = async () => {
    if (trigger.pausedAt) {
      await resumeTrigger({ variables: { id: toId(trigger) } })
    } else {
      await pauseTrigger({ variables: { id: toId(trigger) } })
    }
  }
  const handleDelete = async () => {
    await deleteTrigger({ variables: { id: toId(trigger) } })
  }

  const loading = pauseLoading || resumeLoading || deleteLoading

  return (
    <ActionCard
      key={id}
      title={
        name || (
          <Typography variant="h2" className={classes.title}>
            <HumanReadableCron expression={schedules[0]} timeZone={timeZone} className={classes.title} />
          </Typography>
        )
      }
      stub={
        <ColumnBox mr={1} alignSelf="flex-start">
          <Icon name="trigger-scheduled" />
        </ColumnBox>
      }
      detail={<ScheduleTriggerTargetSummary trigger={trigger} />}
      cursor={null}
      rightChildren={
        <FlexBox alignSelf="flex-start">
          <AuthorTools
            item={trigger}
            author={toId(trigger.author, true)}
            admins={{
              edit: ["trigger_update_all", "trigger_update_locations"],
              delete: ["trigger_update_all", "trigger_update_locations"],
            }}
            disableLabel={pausedAt ? "Unpause trigger" : "Pause trigger"}
            onEdit={handleEdit}
            subject="trigger"
            onDisable={handleTogglePause}
            onDelete={handleDelete}
            loading={loading}
          />
        </FlexBox>
      }
      footer={<Footer trigger={trigger} />}
      {...rest}
    />
  )
}

const EventTriggerTargetSummary = ({ trigger }) => {
  const {
    name,
    eventSource,
    targetType,
    notifyUsersTarget,
    notifyEmailsTarget,
    notifyAuthorTarget,
    integrationTarget,
  } = trigger

  const hasUserTrainingCourseCompleted = eventSource?.events?.includes(EVENT_NAME.USER_TRAINING_COURSE_COMPLETED)
  const hasUserTrainingModuleCompleted = eventSource?.events?.includes(EVENT_NAME.USER_TRAINING_MODULE_COMPLETED)

  switch (targetType) {
    case NOTIFY_USERS:
      return (
        <>
          {!!name && Boolean(eventSource.events?.length) && (
            <Box mb={0.5}>{eventSource.events.map((event) => formatEventLabel(event)).join(", ")}</Box>
          )}
          {Boolean(eventSource.process) && (
            <Box mb={0.5}>
              {eventSource.process?.name}, for{" "}
              {eventSource.locations?.map((item) => item.name).join(", ") || "all locations"}
            </Box>
          )}
          {!eventSource.process && (
            <Box mb={0.5}>For {eventSource.locations?.map((item) => item.name).join(", ") || "all locations"}</Box>
          )}
          {hasUserTrainingCourseCompleted && Boolean(eventSource.trainingCourses) && (
            <Box mb={0.5}>
              For{" "}
              {eventSource.trainingCourses.length > 0
                ? joinStringsWithNaturalLanguage(eventSource.trainingCourses.map((item) => item.name))
                : "all training courses"}
            </Box>
          )}
          {hasUserTrainingModuleCompleted && Boolean(eventSource.trainingModules) && (
            <Box mb={0.5}>
              For{" "}
              {eventSource.trainingModules.length > 0
                ? joinStringsWithNaturalLanguage(eventSource.trainingModules.map((item) => item.name))
                : "all training modules"}
            </Box>
          )}
          <Box mb={0.5}>
            {TRIGGER_TARGET_TYPE_LABELS[targetType]}
            {": "}
            <Assignees users={notifyUsersTarget.users} groups={notifyUsersTarget.groups} />
          </Box>
          <Box>{notifyUsersTarget.methods.map((method) => TRIGGER_NOTIFY_METHOD_LABELS[method]).join(", ")}</Box>
        </>
      )
    case NOTIFY_EMAILS:
      return (
        <>
          {Boolean(eventSource.events?.length) && (
            <Box mb={0.5}>{eventSource.events.map((event) => formatEventLabel(event)).join(", ")}</Box>
          )}
          {Boolean(eventSource.process) && (
            <Box>
              {eventSource.process?.name}, for{" "}
              {eventSource.locations?.map((item) => item.name).join(", ") || "all locations"}
            </Box>
          )}
          {hasUserTrainingCourseCompleted && Boolean(eventSource.trainingCourses) && (
            <Box mb={0.5}>
              For{" "}
              {eventSource.trainingCourses.length > 0
                ? joinStringsWithNaturalLanguage(eventSource.trainingCourses.map((item) => item.name))
                : "all training courses"}
            </Box>
          )}
          {hasUserTrainingModuleCompleted && Boolean(eventSource.trainingModules) && (
            <Box mb={0.5}>
              For{" "}
              {eventSource.trainingModules.length > 0
                ? joinStringsWithNaturalLanguage(eventSource.trainingModules.map((item) => item.name))
                : "all training modules"}
            </Box>
          )}
          {!eventSource.process && (
            <Box mb={0.5}>For {eventSource.locations?.map((item) => item.name).join(", ") || "all locations"}</Box>
          )}
          <Box mb={0.5}>
            {TRIGGER_TARGET_TYPE_LABELS[targetType]}
            {": "}
            {notifyEmailsTarget.emails.join(", ")}
          </Box>
        </>
      )
    case NOTIFY_AUTHOR:
      return (
        <>
          {!!name && Boolean(eventSource.events?.length) && (
            <Box mb={0.5}>{eventSource.events.map((event) => formatEventLabel(event)).join(", ")}</Box>
          )}
          {Boolean(eventSource.process) && (
            <Box mb={0.5}>
              {eventSource.process?.name}, for{" "}
              {eventSource.locations?.map((item) => item.name).join(", ") || "all locations"}
            </Box>
          )}
          {hasUserTrainingCourseCompleted && Boolean(eventSource.trainingCourses) && (
            <Box mb={0.5}>
              For{" "}
              {eventSource.trainingCourses.length > 0
                ? joinStringsWithNaturalLanguage(eventSource.trainingCourses.map((item) => item.name))
                : "all training courses"}
            </Box>
          )}
          {hasUserTrainingModuleCompleted && Boolean(eventSource.trainingModules) && (
            <Box mb={0.5}>
              For{" "}
              {eventSource.trainingModules.length > 0
                ? joinStringsWithNaturalLanguage(eventSource.trainingModules.map((item) => item.name))
                : "all training modules"}
            </Box>
          )}
          {!eventSource.process && (
            <Box mb={0.5}>For {eventSource.locations?.map((item) => item.name).join(", ") || "all locations"}</Box>
          )}
          <Box mb={0.5}>{TRIGGER_TARGET_TYPE_LABELS[targetType]}</Box>
          <Box>{notifyAuthorTarget.methods.map((method) => TRIGGER_NOTIFY_METHOD_LABELS[method]).join(", ")}</Box>
        </>
      )
    case INTEGRATION:
      return <Box>{toId(integrationTarget)}</Box>
    default:
      return null
  }
}

const EventTriggerActionCard = ({ trigger, onEdit, onDelete, detail, ...rest }) => {
  const [pauseTrigger, { loading: pauseLoading }] = useMutationPauseTrigger()
  const [resumeTrigger, { loading: resumeLoading }] = useMutationResumeTrigger()
  const [deleteTrigger, { loading: deleteLoading }] = useMutationDeleteTrigger(trigger.__typename)
  const {
    id,
    name,
    eventSource: { events },
    pausedAt,
  } = trigger

  const handleEdit = () => {
    onEdit && onEdit(trigger)
  }

  const handleTogglePause = async () => {
    if (trigger.pausedAt) {
      await resumeTrigger({ variables: { id: toId(trigger) } })
    } else {
      await pauseTrigger({ variables: { id: toId(trigger) } })
    }
  }
  const handleDelete = async () => {
    await deleteTrigger({ variables: { id: toId(trigger) } })
  }

  const loading = pauseLoading || resumeLoading || deleteLoading

  return (
    <ActionCard
      key={id}
      title={name || events.map((event) => formatEventLabel(event)).join(", ")}
      stub={
        <ColumnBox mr={1} alignSelf="flex-start">
          <Icon name="trigger-event" />
        </ColumnBox>
      }
      detail={<EventTriggerTargetSummary trigger={trigger} />}
      cursor={null}
      rightChildren={
        <FlexBox alignSelf="flex-start">
          <AuthorTools
            item={trigger}
            admins={{
              edit: ["trigger_update_all", "trigger_update_locations", "trigger_update_self"],
              disable: ["trigger_update_all", "trigger_update_locations", "trigger_update_self"],
              delete: ["trigger_update_all", "trigger_update_locations", "trigger_update_self"],
            }}
            disableLabel={pausedAt ? "Unpause trigger" : "Pause trigger"}
            onEdit={handleEdit}
            subject="trigger"
            onDisable={handleTogglePause}
            onDelete={handleDelete}
            loading={loading}
          />
        </FlexBox>
      }
      footer={<Footer trigger={trigger} />}
      {...rest}
    />
  )
}

const TriggerActionCard = ({ trigger, onEdit, onDelete, ...props }) => {
  const type = trigger.__typename === "EventTrigger" ? TRIGGER_TYPE.EVENT : TRIGGER_TYPE.SCHEDULE

  const handleEdit = () => {
    onEdit && onEdit(trigger)
  }

  switch (type) {
    case TRIGGER_TYPE.EVENT:
      return <EventTriggerActionCard trigger={trigger} onEdit={handleEdit} {...props} />
    case TRIGGER_TYPE.SCHEDULE:
      return <ScheduleTriggerActionCard trigger={trigger} onEdit={handleEdit} {...props} />
    default:
      throw new Error("Unknown trigger type")
  }
}

export default TriggerActionCard
