import React, { useEffect, useMemo, useState } from "react"
import { Box, makeStyles, Grid } from "@material-ui/core"
import { useHistory, useParams } from "react-router"
import { RowBox, ReportSectionActionCard, CircularTrainingCompletionChart } from "../../../components"
import { useQueryGroups, useLazyQueryReportLocationsTrainingReportData } from "../../../data"
import { mapToIds, useDateUtils, useReportUtils } from "../../../utils"
import { useAuth } from "../../../services"
import { TrainingUserTableReport } from "../../../components/Reports/TrainingUserTableReport"
import ReportToolsIconButton from "../../../components/Buttons/ReportToolsIconButton"
import { ReportsFilters } from "../../../components/Reports/ReportsFilters"
import { LocationChipMenu } from "../../../components/ChipMenu/LocationChipMenu"
import { GroupChipMenu } from "../../../components/ChipMenu/GroupChipMenu"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
    width: 300,
    flexShrink: 0,
  },
  filterSelect: {
    backgroundColor: "white",
  },
  reports: {
    marginTop: theme.spacing(3),
  },
}))

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.percentComplete > b.percentComplete ? -1 : 1)

const Training = () => {
  const classes = useStyles()
  const { days, locations, groups } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const { getNavigateToLink, filterChange } = useReportUtils()
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data: groupsData } = useQueryGroups()
  const [
    loadReportLocationsTraining,
    { data: locationsTrainingReportData, loading: locationsTrainingLoading, reportData: overallTrainingReportData },
  ] = useLazyQueryReportLocationsTrainingReportData()
  const [sort, setSort] = useState("alpha")

  useEffect(() => {
    if (!groupsData) return
    const filterLocations = locations === "all" ? mapToIds(userLocations) : locations.split("-")
    const filterGroups = groups === "all" ? null : groups.split("-")

    const options = {
      variables: { locations: filterLocations, groups: filterGroups },
    }

    loadReportLocationsTraining({ ...options })
  }, [days, groups, groupsData, loadReportLocationsTraining, locations, momentToScheduleDate, userLocations])

  const groupIds = useMemo(() => groups?.split("-") || ["all"], [groups])
  const locationIds = useMemo(() => locations?.split("-") || ["all"], [locations])

  const handleFiltersMenuApply = (values) => {
    const { days: newDays, locations: newLocations, groups: newGroups } = values
    history.push(getNavigateToLink("training", newDays, newLocations, newGroups))
  }

  const handleLocationsChange = (event) => {
    history.push(getNavigateToLink("training", days, filterChange(locationIds, event), groupIds))
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToLink("training", days, mapToIds(regionLocations), groupIds))
  }

  const handleGroupsChange = (event) => {
    history.push(getNavigateToLink("training", days, locationIds, filterChange(groupIds, event)))
  }

  // const handleLocationClick = ({ id }) => {
  //   history.push(getNavigateToLink("training", days, [id], groupIds))
  // }

  // const handleGroupClick = ({ id }) => {
  //   history.push(getNavigateToLink("training", days, locationIds, [id]))
  // }

  const handleClear = () => {
    history.push(getNavigateToLink("training", 7, ["all"], ["all"], null))
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const hasGroups = !!groupsData?.groups.length

  return (
    <>
      <ReportsFilters
        values={{
          locations: locationIds,
          groups: groupIds,
        }}
        menu={{
          locations: true,
          groups: hasGroups,
        }}
        onApply={handleFiltersMenuApply}
        onClear={handleClear}
      >
        <Box ml={0.5}>
          <LocationChipMenu
            value={locationIds}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        {hasGroups && (
          <Box ml={0.5}>
            <GroupChipMenu value={groupIds} onChange={handleGroupsChange} multiple />
          </Box>
        )}
      </ReportsFilters>

      <Box flexGrow={1}>
        <Grid container spacing={1} className={classes.reports}>
          <Grid item xs={3}>
            <ReportSectionActionCard title="Overall" detail="% people per status" loading={locationsTrainingLoading}>
              <RowBox flexWrap="wrap" alignItems="flex-start" justifyContent="center">
                {!locationsTrainingLoading && overallTrainingReportData && (
                  <CircularTrainingCompletionChart
                    text={
                      locations === "all"
                        ? "All locations"
                        : `${locationIds.length} selected location${locationIds.length > 1 ? "s" : ""}`
                    }
                    data={overallTrainingReportData}
                  />
                )}
              </RowBox>
            </ReportSectionActionCard>
          </Grid>
          {/* <Grid item xs={9}>
            <ReportSectionActionCard title="Time Zones" detail="% people per status" loading={locationsTrainingLoading}>
              <RowBox flexWrap="wrap" alignItems="flex-start" justifyContent="center">
                {!locationsTrainingLoading &&
                  timeZoneTrainingReportData?.map(({ timeZone, ...timeZoneTrainingData }) => (
                    <CircularTrainingCompletionChart
                      key={timeZone}
                      text={timeZone.replace("/", " / ").replace("_", " ")}
                      data={timeZoneTrainingData}
                    />
                  ))}
              </RowBox>
            </ReportSectionActionCard>
          </Grid> */}
          <Grid item xs={9}>
            <ReportSectionActionCard
              title="Locations"
              detail="% people per status"
              loading={locationsTrainingLoading}
              tools={
                <RowBox>
                  <ReportToolsIconButton
                    title={sort === "alpha" ? "Sort by completions %" : "Sort alphabetically"}
                    icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
                    onClick={handleSort}
                    disabled={locationsTrainingLoading}
                  />
                </RowBox>
              }
            >
              <RowBox flexWrap="wrap" alignItems="flex-start" justifyContent="center">
                {!locationsTrainingLoading &&
                  [...(locationsTrainingReportData?.reportLocationsTraining || [])]
                    .sort((a, b) => sortData(sort, a, b))
                    .map(({ id, name, ...locationTrainingData }) => (
                      <CircularTrainingCompletionChart key={id} text={name} data={locationTrainingData} showCount />
                    ))}
              </RowBox>
            </ReportSectionActionCard>
          </Grid>
          <Grid item xs={12}>
            <TrainingUserTableReport locations={locationIds} groups={groupIds.filter((groupId) => groupId !== "all")} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Training
