import React from "react"
import { Box, Collapse } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useAuth } from "../../services"
import { BILLING_TYPE, useQueryOrganisation } from "../../data"

const CreateLocationBillingNoticeBlockquote = ({ mb = 3 }) => {
  const {
    settings: {
      organisation: { id: organisationID },
    },
  } = useAuth()
  const { data: organisationData } = useQueryOrganisation({ variables: { id: organisationID } })

  return (
    <Collapse in={organisationData?.organisation.billing?.type === BILLING_TYPE.PER_LOCATION}>
      <Box mb={mb}>
        <Alert severity="info">
          <AlertTitle>Location based billing</AlertTitle>
          Your account is billed per location. Please note additional charges apply for adding more locations.
        </Alert>
      </Box>
    </Collapse>
  )
}

export { CreateLocationBillingNoticeBlockquote }
