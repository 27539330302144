import { Box, makeStyles, TableCell, TableRow, Button } from "@material-ui/core"
import React, { useMemo, useState } from "react"
import useAsyncEffect from "use-async-effect"
import {
  ErrorBoundary,
  LoadingSpinner,
  NoItemsMessage,
  TrainerChip,
  UserTrainingAssessmentCreator,
  UserTrainingAssessmentStatusChipButton,
} from ".."
import { UserAssessmentTooltip } from "./UserAssessmentTooltip"
import {
  useLazyQueryAdminableUser,
  useLazyQueryAdminableUsersAndCount,
  useLazyQueryTrainingModules,
  USER_STATUS,
} from "../../data"
import { mapToIds, toId, useMountEffect, useWindowSize } from "../../utils"
import { compileAdminableUsersFilters as makeUserFilters } from "../../areas/people"
import { TrainingModuleViewer } from "./TrainingModuleViewer"
import { UserTableRowCell } from "../UserPanel/UserTableRowCell"
import { TableVirtualizer } from "../Lists/TableVirtualizer"

const useStyles = makeStyles((theme) => ({
  tableHeaderCell: {
    whiteSpace: "nowrap",
    width: 150,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  tableCell: {
    width: 150,
    textAlign: "center",
  },
}))

const statusFilter = [USER_STATUS.INVITED, USER_STATUS.ACTIVE]

const isTrainer = (module, user) => {
  const userIds = mapToIds(module.users)
  if (userIds.includes(toId(user))) {
    return true
  }

  return Boolean(module.groups.length) && module.groups.some((group) => mapToIds(user.groups).includes(toId(group)))
}

const AssessmentUserRow = ({ user, modules, classes, onEdit }) => {
  const moduleColumns = useMemo(() => {
    const handleEdit = (edit) => {
      onEdit && onEdit(edit)
    }

    return (
      modules?.map((module) => {
        const trainer = isTrainer(module, user)
        const assessment = !trainer && user.training.assessments.find((item) => toId(module) === toId(item.module))

        return (
          <TableCell key={toId(module)} className={classes.tableCell} style={{ cursor: "grab" }}>
            {trainer && (
              <UserAssessmentTooltip user={user} module={module} userTrainingAssessment={assessment}>
                <TrainerChip />
              </UserAssessmentTooltip>
            )}

            {!trainer && (
              <UserAssessmentTooltip user={user} module={module} userTrainingAssessment={assessment}>
                <UserTrainingAssessmentStatusChipButton
                  assessment={assessment}
                  onClick={() => handleEdit({ user, module, assessment })}
                />
              </UserAssessmentTooltip>
            )}
          </TableCell>
        )
      }) || []
    )
  }, [classes.tableCell, modules, onEdit, user])

  return (
    <ErrorBoundary key={toId(user)}>
      <TableCell style={{ width: 150, background: "white", position: "sticky", left: 0, zIndex: 1 }}>
        <UserTableRowCell user={user} />
      </TableCell>
      {moduleColumns}
    </ErrorBoundary>
  )
}

const TrainingAssessmentTable = ({ fullscreen, filters, modules, searchText, onUsersLoadComplete }) => {
  const classes = useStyles()
  const { height: windowHeight } = useWindowSize()
  const [loadAdminableUsers, { data: usersData, loading: adminableUsersLoading, loadMore }] =
    useLazyQueryAdminableUsersAndCount({
      variables: {
        status: statusFilter,
        limit: 20,
      },
    })
  const [loadAdminableUser] = useLazyQueryAdminableUser()
  const [loadTrainingModules, { data: modulesData, loading: modulesLoading }] = useLazyQueryTrainingModules()
  const [edit, setEdit] = useState(null)
  const [viewModule, setViewModule] = useState(null)

  const tableHeight = useMemo(() => (windowHeight || 1280) - (fullscreen ? 129 : 358), [fullscreen, windowHeight])

  useMountEffect(() => {
    loadAdminableUsers()
    loadTrainingModules()
  })

  useAsyncEffect(async () => {
    const result = await loadAdminableUsers({
      variables: {
        offset: 0,
        ...makeUserFilters(searchText, filters, statusFilter),
      },
    })
    onUsersLoadComplete && onUsersLoadComplete(result?.data?.adminableUsers?.count || 0)
  }, [filters, loadAdminableUsers, searchText])

  const handleEdit = (value) => {
    setEdit(value)
  }

  const handleCloseEdit = async (updated) => {
    if (updated) {
      await loadAdminableUser({ variables: { id: toId(edit.user) } })
    }
    setEdit(null)
  }

  const handleModuleClick = (module) => {
    setViewModule(toId(module))
  }

  const loading = adminableUsersLoading || modulesLoading

  const items = useMemo(() => usersData?.adminableUsers.list || [], [usersData])

  const editUser =
    edit && usersData ? usersData.adminableUsers.list.find((user) => toId(user) === toId(edit.user)) : null

  const moduleHeaderCells = useMemo(() => {
    return modules.map((module) => (
      <TableCell key={toId(module)} align="center" className={classes.tableHeaderCell}>
        {module.knowledges?.length > 0 && (
          <Button variant="outlined" onClick={() => handleModuleClick(module)}>
            {module.name}
          </Button>
        )}
        {module.knowledges?.length === 0 && module.name}
      </TableCell>
    ))
  }, [classes.tableHeaderCell, modules])

  const viewingModule = viewModule ? modules.find((module) => toId(module) === viewModule) : null

  return (
    <>
      {edit && <UserTrainingAssessmentCreator {...edit} user={editUser} onClose={handleCloseEdit} />}

      {viewingModule && <TrainingModuleViewer module={viewingModule} onClose={() => setViewModule(null)} />}

      <div id="Assessment-scroller" data-cy="Assessment-scroller">
        {loading && !usersData && !modulesData && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {usersData && modulesData && (
          <>
            <TableVirtualizer
              items={items}
              endReached={loadMore}
              style={{ height: tableHeight }}
              fixedHeaderContent={() => (
                <TableRow className={classes.tableHeaderRow}>
                  <TableCell style={{ width: 150, background: "white", position: "sticky", left: 0, zIndex: 2 }}>
                    Name &amp; Role
                  </TableCell>
                  {moduleHeaderCells}
                </TableRow>
              )}
              itemContent={(_, item) => (
                <AssessmentUserRow
                  key={toId(item)}
                  user={item}
                  modules={modules}
                  classes={classes}
                  onEdit={handleEdit}
                />
              )}
            />

            {!loading && items && !items.length && <NoItemsMessage>No matching items</NoItemsMessage>}
          </>
        )}
      </div>
    </>
  )
}

export { TrainingAssessmentTable }
