import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_AUDITS_SCORES_FIELDS = gql`
  fragment ReportAuditScoresFields on ReportAuditScores {
    list {
      process
      processName
      processSchedule
      location
      locationName
      data {
        score
        possibleScore
        scorePercent
        reportAtDay {
          year
          month
          day
        }
      }
    }
    counts {
      jobsTotal
      jobsReturned
    }
  }
`

const REPORT_AUDIT_SCORES_QUERY = gql`
  query ReportAuditScores($locations: [ID!]!, $process: ID, $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportAuditScores(locations: $locations, process: $process, gt: $gt, lt: $lt) {
      ...ReportAuditScoresFields
    }
  }
  ${REPORT_AUDITS_SCORES_FIELDS}
`

const useQueryReportAuditScores = (options) =>
  useQuery(REPORT_AUDIT_SCORES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportAuditScores = (options) =>
  useLazyQuery(REPORT_AUDIT_SCORES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportAuditScores, useLazyQueryReportAuditScores }
