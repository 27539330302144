import React, { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  makeStyles,
  DialogActions,
  Divider,
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import useMeasure from "react-use-measure"
import { ProcessViewer } from "./ProcessViewer"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { InlineTitleCloser } from "../Creators"
import { Icon, InlineTitle, FieldSectionHeading, LoadingSpinner, NoItemsMessage } from ".."
import { useMutationJobDelete } from "../../data/jobs/useMutationJob"
import { RowBox } from "../Boxes"
import { DownloadExportJobButton } from "../Buttons"
import { getNavigateToLinkForLocationDay, hasExport as hasJobExport, toId, useMountEffect } from "../../utils"
import { SubmitJobButton } from "./SubmitJobButton"
import { PROCESS_TYPE, useQueryJob, useQueryJobPreview } from "../../data"
import { AuditFooterActions } from "./AuditFooterActions"

const inlinePaddingHeight = 5

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: "center",
  },
  mobileActions: {
    bottom: 0,
    position: "fixed",
    backgroundColor: "#ffffff",
    width: "100%",
    paddingBottom: 0,
    zIndex: 100,
    [theme.breakpoints.only("xs")]: {
      paddingBottom: theme.spacing(9),
    },
  },
  dialogPaper: {
    minHeight: ({ showingMessage }) =>
      !showingMessage ? `calc(100vh - ${theme.dimensions.dialogs.margin * 2}px)` : "auto",
  },
  dialogContent: {
    padding: ({ showingMessage }) => (!showingMessage ? theme.spacing(0, 0) : theme.spacing(1, 3)),
  },
  back: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
  },
  wrapper: {
    height: "50vh",
  },
  wrappperDelay: {
    animationName: "fadeInOpacityLight",
    animationIterationCount: 1,
    animationTimingFunction: "ease-in",
    animationDuration: "1s",
  },
}))

const renderDelayMs = 300

const JobViewer = ({ open, id, scheduleDate, preview, onClose, onDelete, isParentSubmitted, noInline }) => {
  const {
    called,
    data,
    subscribe,
    loading: jobLoading,
  } = useQueryJob({ variables: { id }, skip: !id || id === "new" || preview })
  const { data: previewData, loading: previewLoading } = useQueryJobPreview({
    variables: { process: toId(preview?.process, true), date: scheduleDate, location: toId(preview?.location, true) },
    skip: !preview,
  })
  const loading = jobLoading || previewLoading
  const job = preview ? previewData?.jobs.preview : data?.jobs.one
  const theme = useTheme()
  const [unsubscribeView, setUnsubscribeView] = useState(null)
  const shouldInline = useMediaQuery(theme.breakpoints.down("md"))
  const isInline = !noInline && shouldInline
  const [allowMessage, setAllowMessage] = useState(true)
  const classes = useStyles({ showingMessage: allowMessage && job?.status?.submitted })
  const params = useParams()
  const history = useHistory()
  const [render, setRender] = useState(false)
  const [inlineTitleRef, { height: inlineTitleHeight }] = useMeasure()
  const [inlineActionsRef, { height: inlineActionsHeight }] = useMeasure()
  const [dialogTitleRef, { height: dialogTitleHeight }] = useMeasure()
  const [dialogActionsRef, { height: dialogActionsHeight }] = useMeasure()
  const [stepsHeight, setStepsHeight] = useState("100vh")
  const [deleteJob, { loading: deleteLoading }] = useMutationJobDelete(job)
  const hasExport = hasJobExport(job)
  const nested = !!job?.parent?.job

  useMountEffect(() => {
    const renderTimeout = null
    if (!render) {
      setTimeout(() => setRender(true), renderDelayMs)
    }
    return () => clearTimeout(renderTimeout)
  })

  useEffect(() => {
    if (called && !unsubscribeView) {
      setUnsubscribeView(subscribe())
      return () => {
        if (unsubscribeView) {
          unsubscribeView()
        }
      }
    }
  }, [called, subscribe, unsubscribeView, setUnsubscribeView])

  useEffect(() => {
    if (!open || !render) {
      return
    }

    const offset = isInline
      ? inlineTitleHeight + inlineActionsHeight + theme.dimensions.footer.mobileBottomNav.height - inlinePaddingHeight
      : dialogTitleHeight + dialogActionsHeight + theme.dimensions.dialogs.margin * 2

    setStepsHeight(`calc(100vh - ${offset}px)`)
  }, [
    dialogActionsHeight,
    dialogTitleHeight,
    inlineTitleHeight,
    inlineActionsHeight,
    isInline,
    open,
    render,
    theme.dimensions.footer.mobileBottomNav.height,
    theme.dimensions.dialogs.margin,
  ])

  const handleClose = (_, isCancel) => {
    if (job?.parent?.job) {
      history.push(getNavigateToLinkForLocationDay(job.parent.job, params.day, job.location))
      return
    }

    onClose(isCancel)
    setAllowMessage(true)
  }

  const handleCloseMessage = () => {
    setAllowMessage(false)
  }

  const handleResetMessage = () => {
    // We'll call this when unsubmit an audit
    setAllowMessage(true)
  }

  const handleDelete = async () => {
    await deleteJob({ variables: { job: toId(job) } })
    onDelete && onDelete()
  }

  const CenteredWrapper = ({ children, delay = false }) => (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.wrapper}>
      <Box
        display="flex"
        justifySelf="flex-start"
        alignSelf="center"
        className={delay ? classes.wrappperDelay : undefined}
      >
        {children}
      </Box>
    </Box>
  )

  const RenderDelayLoading = () => (
    <CenteredWrapper>
      <LoadingSpinner size={85} delay={false} />
    </CenteredWrapper>
  )

  if (open && isInline && !job && loading) {
    return <RenderDelayLoading />
  }

  if (open && isInline && !job) {
    return (
      <CenteredWrapper delay>
        <NoItemsMessage>Job not found</NoItemsMessage>
      </CenteredWrapper>
    )
  }

  const audit = job ? job.type === PROCESS_TYPE.AUDIT : false
  const subject = audit ? "Audit" : "Job"

  const hasSubmit = job ? job.hasSubmit && (!job.status.submitted || !allowMessage) && !nested : false
  const hasAuditFooterActions = audit && hasSubmit
  const hasProcessFooterActions = !audit && hasExport

  const jobViewerContentId = `JobViewer-content${nested ? "-nested" : ""}`

  const display = (
    <>
      <Box data-cy="JobViewer">
        {!!job && (
          <ProcessViewer
            job={job}
            allowMessage={allowMessage}
            onClose={handleClose}
            onDelete={handleDelete}
            onCloseMessage={handleCloseMessage}
            deleteLoading={deleteLoading}
            isParentSubmitted={isParentSubmitted}
            isInline={isInline}
            scrollableTarget={jobViewerContentId}
            stepsHeight={stepsHeight}
          />
        )}
        {!job &&
          (loading ? (
            <RenderDelayLoading />
          ) : (
            <CenteredWrapper delay>
              <NoItemsMessage>Job not found</NoItemsMessage>
            </CenteredWrapper>
          ))}
      </Box>
    </>
  )

  if (isInline && open) {
    return (
      <>
        <InlineTitle boxProps={{ pb: 1 }} ref={inlineTitleRef}>
          {nested ? (
            <IconButton aria-label="back" size="small" onClick={handleClose} className={classes.back}>
              <Icon onClose={handleClose} name="back" />
            </IconButton>
          ) : null}
          {subject} details
          <InlineTitleCloser onClose={handleClose} />
        </InlineTitle>

        {render && display}

        {!render && <RenderDelayLoading />}

        <div ref={inlineActionsRef} className={classes.mobileActions}>
          {nested && (
            <>
              <Divider />
              <RowBox py={1} px={2} justifyContent="center">
                <Button variant="contained" color="inherit" fullWidth onClick={handleClose}>
                  Done
                </Button>
              </RowBox>
            </>
          )}

          {hasAuditFooterActions && (
            <>
              <Divider />
              <RowBox py={1} px={2} justifyContent="center">
                {hasSubmit && <SubmitJobButton job={job} onResetMessage={handleResetMessage} />}
              </RowBox>
            </>
          )}

          {hasProcessFooterActions && (
            <>
              <Divider />
              <RowBox py={1} px={2} justifyContent="center">
                {hasExport && <DownloadExportJobButton job={job} />}
              </RowBox>
            </>
          )}
        </div>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="form-dialog-title" ref={dialogTitleRef}>
        {nested ? (
          <IconButton aria-label="back" size="small" onClick={handleClose} className={classes.back}>
            <Icon onClose={handleClose} name="back" />
          </IconButton>
        ) : null}
        {subject} details
        <DialogTitleCloser key={toId(job)} onClose={handleClose} />
      </DialogTitle>
      <DialogContent id={jobViewerContentId} classes={{ root: classes.dialogContent }}>
        {display}
      </DialogContent>

      <div ref={dialogActionsRef}>
        {nested && (
          <>
            <Divider />
            <DialogActions className={classes.actions}>
              <RowBox flexGrow={1} px={2} py={1} pb={1}>
                <Button variant="contained" color="inherit" fullWidth onClick={handleClose}>
                  Done
                </Button>
              </RowBox>
            </DialogActions>
          </>
        )}

        {hasAuditFooterActions && (
          <>
            <Divider />
            <DialogActions className={classes.actions}>
              <AuditFooterActions job={job} onResetMessage={handleResetMessage} disabled={!!preview} />
            </DialogActions>
          </>
        )}
        {hasProcessFooterActions && (
          <>
            <Divider />
            <DialogActions className={classes.actions}>
              <RowBox flexGrow={1} px={2} py={1} pb={1}>
                <Box>
                  <FieldSectionHeading mb={0.75}>Process completed</FieldSectionHeading>
                </Box>
                <Box ml="auto">{hasExport && <DownloadExportJobButton job={job} />}</Box>
              </RowBox>
            </DialogActions>
          </>
        )}
      </div>
    </Dialog>
  )
}

export { JobViewer }
