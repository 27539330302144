import React from "react"
import { Dialog, DialogTitle, DialogContent, Box } from "@material-ui/core"
import { InlineTitle } from "./InlineTitle"
import { InlineTitleCloser } from "./InlineTitleCloser"
import { DialogTitleCloser } from "./DialogTitleCloser"
import { ErrorBoundary } from "../ErrorBoundary"
import { disableBackdropClick } from "../../utils"
import { FlexBox } from "../Boxes"
import { LoadingSpinner } from "../LoadingSpinner"

const CreatorMaster = ({
  id = "CreatorMaster",
  open,
  subject,
  title: titleInput,
  form,
  isEdit,
  isCopy,
  isInline,
  loading,
  onClose,
  contentRef,
  disableEnforceFocus,
  maxWidth = "md",
}) => {
  const subjectLower = subject && subject.toLowerCase()
  const title =
    titleInput || (isEdit ? <>Edit {subjectLower}</> : isCopy ? <>Copy {subjectLower}</> : <>Create {subjectLower}</>)

  const spinner = (
    <FlexBox justifyContent="center" mb={2}>
      <LoadingSpinner size={60} />
    </FlexBox>
  )

  if (isInline) {
    return (
      <>
        {open && (
          <>
            <InlineTitle>
              {title}
              <InlineTitleCloser onClose={onClose} />
            </InlineTitle>
            <Box py={2} px={2}>
              <ErrorBoundary>{loading ? spinner : form}</ErrorBoundary>
            </Box>
          </>
        )}
      </>
    )
  }

  return (
    <Dialog
      id={id}
      open={Boolean(open)}
      onClose={disableBackdropClick(onClose)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={maxWidth}
      disableEnforceFocus={disableEnforceFocus}
    >
      <DialogTitle id="form-dialog-title">
        <ErrorBoundary>
          {title}
          <DialogTitleCloser onClose={onClose} />
        </ErrorBoundary>
      </DialogTitle>
      <DialogContent id={`${id}-content`} ref={contentRef}>
        <ErrorBoundary>{loading ? spinner : form}</ErrorBoundary>
      </DialogContent>
    </Dialog>
  )
}

export { CreatorMaster }
