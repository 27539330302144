import { Box } from "@material-ui/core"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import React, { useEffect, useMemo, useState } from "react"
import { JobStepCompletionChart, ReportSectionActionCard, RowBox } from ".."
import { useLazyQueryReportJobStepsOverview } from "../../data/report/useQueryReportJobStepsOverview"
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.rank - b.rank)

const JobsStepsOverviewReport = ({ gt, lt, locations, containerHeight = 300, onJobClick }) => {
  const [sort, setSort] = useState("alpha")
  const [load, { data, loading }] = useLazyQueryReportJobStepsOverview()
  const [fullscreen, setFullscreen] = useState(false)

  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    if (!gt) {
      return
    }

    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations

    load({
      variables: { locations: filterLocations, gt, lt },
    })
  }, [gt, load, locations, lt, userLocations])

  const handleFullscreen = (newFullscreen) => {
    setFullscreen(newFullscreen)
  }

  const handleJobClick = ({ process }) => {
    onJobClick && onJobClick(process)
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const chartData = useMemo(
    () =>
      data
        ? data.reportJobStepsOverview
            .map(({ id, job, process, name, completed, overdue: late, uncompleted, skipped }) => ({
              argument: id,
              name: job === name ? name : `${job} - ${name}`,
              job,
              process,
              completed,
              late,
              uncompleted,
              skipped,
              rank: completed + late + skipped,
            }))
            .reverse()
            .sort((a, b) => sortData(sort, a, b))
        : [],
    [data, sort]
  )

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = ["Name", "On-time", "Late", "Skipped", "Uncompleted"]
    const rows = [...chartData]
      .reverse()
      .sort((a, b) => sortData(sort, a, b))
      .map(({ name, completed, late, skipped, uncompleted }) =>
        [stringFormatter(name), completed, late, skipped, uncompleted].join(",")
      )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "job-steps-completion.csv", "text/csv")
  }

  const rotated = true // chartData?.length > 6

  return (
    <ReportSectionActionCard
      title="Steps"
      detail="Completion by step"
      loading={loading}
      onFullscreen={handleFullscreen}
      tools={
        <RowBox>
          <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
          <Box ml={1}>
            <ReportToolsIconButton
              title={sort === "alpha" ? "Sort by completions %" : "Sort alphabetically"}
              icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
              onClick={handleSort}
              disabled={loading}
            />
          </Box>
        </RowBox>
      }
    >
      <JobStepCompletionChart
        data={chartData}
        barWidth={rotated ? 0.75 : 0.5}
        argumentLabels
        rotated={rotated}
        truncateLabelAt={fullscreen ? 60 : rotated ? 30 : 10}
        height={rotated ? chartData.length * 32 : containerHeight}
        containerHeight={fullscreen ? null : containerHeight}
        variant="stacked-bar"
        onClick={handleJobClick}
      />
    </ReportSectionActionCard>
  )
}

export default JobsStepsOverviewReport
