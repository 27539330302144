import React, { useState } from "react"
import { Box, Divider, Hidden, makeStyles } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import { LinearProgress } from "../LinearProgress"
import { ActionCard } from "../ActionCards"
import { RowBox } from "../Boxes"
import { queryJob } from "../../data"
import { getNavigateToLinkForLocationDay, toId } from "../../utils"
import { TemplateViewer } from "./TemplateViewer"
import { useJobState } from "../../data/jobs/jobStateVar"
import { ProcessCollapse } from "./ProcessCollapse"

const useStyles = makeStyles(() => ({
  rightChildren: {
    height: 40,
    overflow: "visible",
  },
  progress: {
    width: 140,
  },
}))

const NestedProcessStep = ({
  job: parentJob,
  step,
  index,
  isPreview,
  classes: externalClasses,
  complete,
  expand,
  collapseTimeout = "auto",
  skipped,
}) => {
  const { job } = step
  const history = useHistory()
  const apollo = useApolloClient()
  const params = useParams()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [navigating, setNavigating] = useState(false)
  const [, { merge }] = useJobState(parentJob)

  const handleClick = async () => {
    if (!isPreview) {
      setNavigating(true)
      merge({ index })
      const result = await queryJob(toId(job), apollo)
      if (result?.data?.jobs?.one) {
        history.push(getNavigateToLinkForLocationDay(result.data.jobs.one, params.day))
        return
      }
      setNavigating(false)
    }
  }

  const handlePreviewClick = async () => {
    if (!open && isPreview) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (!job) {
    return null
  }

  const { title, percentComplete, totalSteps, completedSteps } = job

  const remainingSteps = totalSteps - completedSteps

  return (
    <>
      {open && job?.process && isPreview && (
        <TemplateViewer process={job.process} parentJob={parentJob} onClose={handleClose} />
      )}

      <ProcessCollapse in={expand} timeout={collapseTimeout}>
        <Divider className={externalClasses.divider} />
        <Box px={1} pt={1}>
          <ActionCard
            completed={complete}
            skipped={skipped}
            loading={navigating}
            variant="navigate"
            elevation={0}
            title={title}
            cardProps={{
              onClick: isPreview ? handlePreviewClick : handleClick,
            }}
            detail={
              <>
                {skipped ? (
                  <>Skipped</>
                ) : (
                  <>
                    {remainingSteps === totalSteps && <>{remainingSteps} steps to complete</>}
                    {remainingSteps < totalSteps && remainingSteps > 0 && (
                      <>
                        {remainingSteps} of {totalSteps} steps to complete
                      </>
                    )}
                    {remainingSteps === 0 && <>All steps completed</>}
                  </>
                )}
              </>
            }
            rightChildren={
              <>
                {!skipped && (
                  <RowBox mr="auto" alignSelf="center" alignItems="center" className={classes.rightChildren}>
                    <Hidden xsDown>
                      <Box className={classes.progress} mr={1}>
                        <LinearProgress value={percentComplete} showValue />
                      </Box>
                    </Hidden>
                  </RowBox>
                )}
              </>
            }
          >
            <Hidden smUp>
              {!skipped && (
                <Box mt={1}>
                  <LinearProgress value={percentComplete} showValue />
                </Box>
              )}
            </Hidden>
          </ActionCard>
        </Box>
      </ProcessCollapse>
    </>
  )
}

export { NestedProcessStep }
