import React from "react"
import { Box, ListItemIcon, makeStyles } from "@material-ui/core"
import { Icon, RowBox, TooltipIcon } from ".."

const useFieldStyles = makeStyles((theme) => ({
  listItemIcon: {
    position: "absolute",
  },
  adornments: {
    display: "flex",
    gap: theme.spacing(1),
  },
  adornmentIcon: {
    color: theme.palette.text.secondary,
    display: "flex",
    fontSize: 20,
  },
  heading: {
    fontSize: 14,
  },
}))

const AdornmentIcon = ({ name, tooltip, classes }) => (
  <TooltipIcon tooltip={tooltip} name={name} className={classes.adornmentIcon} />
)

const IconMenuItemContent = ({ label, icon, iconProps = {}, adornments }) => {
  const classes = useFieldStyles()

  return (
    <RowBox flexGrow={1}>
      {icon && (
        <ListItemIcon className={classes.listItemIcon}>
          <Icon name={icon} {...iconProps} />
        </ListItemIcon>
      )}

      <RowBox flexGrow={1} ml={icon ? 5 : 0}>
        <Box mr="auto">{label}</Box>
        {adornments ? (
          <Box className={classes.adornments}>
            {adornments.map((adornment, index) => (
              <AdornmentIcon key={index} name={adornment.name} tooltip={adornment.title} classes={classes} />
            ))}
          </Box>
        ) : null}
      </RowBox>
    </RowBox>
  )
}

export { IconMenuItemContent }
