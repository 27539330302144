import React from "react"
import { useApolloClient } from "@apollo/client"
import { Box, Container, makeStyles, Typography } from "@material-ui/core"
import { useAuth } from "../../services/useAuth"
import { useMountEffect } from "../../utils"
import { PublicContainer } from "../Master"
import { LogoFull, PaperBox } from "../../components"

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 300,
    padding: 0,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}))

const LogoutExternal = () => {
  const classes = useStyles()
  const client = useApolloClient()
  const auth = useAuth(client)

  useMountEffect(() => {
    auth.logout()
  })

  console.log("[Logout]", "Signing out")

  return (
    <PublicContainer>
      <PaperBox p={5}>
        <Container component="main" className={classes.root}>
          <div className={classes.wrapper}>
            <Box mb={2}>
              <LogoFull width="197" />
            </Box>
            <Typography paragraph align="center">
              <strong>Logged out</strong>
            </Typography>
            <>You may now close this window.</>
          </div>
        </Container>
      </PaperBox>
    </PublicContainer>
  )
}

export { LogoutExternal }
