import React, { useEffect, useState } from "react"
import { Box, makeStyles, Grid, Button } from "@material-ui/core"
import moment from "moment"
import { useHistory, useParams } from "react-router"
import { RowBox, GroupOutlinedSelect, DateRangeOutlinedSelect, LocationOutlinedSelect } from "../../components"
import { useQueryGroups } from "../../data"
import { mapToIds, useDateUtils, useReportUtils } from "../../utils"
import { useAuth } from "../../services"
import GroupsStepCompletionReport from "../../components/Reports/GroupsStepCompletionReport"
import LocationsStepCompletionReport from "../../components/Reports/LocationsStepCompletionReport"
import JobsOverviewReport from "../../components/Reports/JobsOverviewReport"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
    width: 300,
    flexShrink: 0,
  },
  filterSelect: {
    backgroundColor: "white",
  },
  reports: {
    width: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
}))

const Overview = () => {
  const classes = useStyles()
  const { days, locations, groups } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const { getNavigateToLink, getNavigateToJobsLink, filterChange } = useReportUtils()
  const [gt, setGt] = useState(null)
  const [lt, setLt] = useState(null)
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data: groupsData } = useQueryGroups()

  const groupIds = groups?.split("-") || ["all"]
  const locationIds = locations?.split("-") || ["all"]

  useEffect(() => {
    let newGt = null
    let newLt = null
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      newGt = momentToScheduleDate(moment(daysParts[0]))
      newLt = momentToScheduleDate(moment(daysParts[1]))
    } else {
      newGt = momentToScheduleDate(moment().subtract(days, "days"))

      // If not "Today"
      if (days !== "1") {
        // Set end of yesterday (23h59)
        newLt = momentToScheduleDate(moment().subtract(1, "days").endOf("day"))
      }
    }
    setGt(newGt)
    setLt(newLt)
  }, [days, momentToScheduleDate])

  const handleDaysChange = (event) => {
    history.push(getNavigateToLink("overview", event.target.value, locationIds, groupIds))
  }

  const handleLocationsChange = (event) => {
    history.push(getNavigateToLink("overview", days, filterChange(locationIds, event), groupIds))
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToLink("overview", days, mapToIds(regionLocations), groupIds))
  }

  const handleGroupsChange = (event) => {
    history.push(getNavigateToLink("overview", days, locationIds, filterChange(groupIds, event)))
  }

  const handleLocationClick = (id) => {
    history.push(getNavigateToLink("overview", days, [id], groupIds))
  }

  const handleGroupClick = (id) => {
    history.push(getNavigateToLink("overview", days, locationIds, [id]))
  }

  const handleJobClick = (process) => {
    history.push(getNavigateToJobsLink(days, locationIds, groupIds, process))
  }

  const handleReset = () => {
    history.push(getNavigateToLink("overview", 7, ["all"], ["all"], null))
  }

  const groupsLength = groupsData?.groups.length || 0
  const hasGroups = !!groupsData?.groups.length

  const multiLocation = locationIds.length > 1 || (locationIds.includes("all") && userLocations?.length > 1)

  const { locationsColumns, groupsColumns } = (() => {
    if (!multiLocation && hasGroups) return { locationsColumns: 4, groupsColumns: 8 }
    if (!hasGroups || multiLocation.length > 2) return { locationsColumns: 12, groupsColumns: 12 }
    if (
      (locationIds.includes("all") && userLocations.length > groupsLength) ||
      locationIds.length > groupsData?.groups.length
    )
      return { locationsColumns: 7, groupsColumns: 5 }
    return { locationsColumns: 5, groupsColumns: 7 }
  })()

  return (
    <RowBox alignItems="flex-start">
      <Box p={3} className={classes.filter} mt={3} mb={3}>
        <Box mb={2}>
          <DateRangeOutlinedSelect
            label="Date range"
            value={days}
            onChange={handleDaysChange}
            className={classes.filterSelect}
          />
        </Box>
        <Box>
          <LocationOutlinedSelect
            value={locationIds}
            className={classes.filterSelect}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        {hasGroups && (
          <Box mt={2}>
            <GroupOutlinedSelect
              value={groupIds}
              className={classes.filterSelect}
              onChange={handleGroupsChange}
              multiple
            />
          </Box>
        )}
        <RowBox mt={2} justifyContent="flex-end">
          <Button variant="contained" onClick={handleReset}>
            Reset
          </Button>
        </RowBox>
      </Box>
      <Box flexGrow={1}>
        <Grid container spacing={1} className={classes.reports}>
          <Grid item xs={locationsColumns}>
            <LocationsStepCompletionReport
              gt={gt}
              lt={lt}
              locations={locationIds}
              groups={groupIds}
              onLocationClick={handleLocationClick}
            />
          </Grid>
          {hasGroups && (
            <Grid item xs={groupsColumns}>
              <GroupsStepCompletionReport
                gt={gt}
                lt={lt}
                locations={locationIds}
                groups={groupIds}
                onGroupClick={handleGroupClick}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <JobsOverviewReport gt={gt} lt={lt} locations={locationIds} groups={groupIds} onJobClick={handleJobClick} />
          </Grid>
        </Grid>
      </Box>
    </RowBox>
  )
}

export default Overview
