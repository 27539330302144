import React, { useRef, useState } from "react"
import { IconButton, makeStyles, Menu, Tooltip, useTheme } from "@material-ui/core"
import { HelpOutline, LiveHelpOutlined, MailOutline } from "@material-ui/icons"
import Config from "react-global-configuration"
import { useCookies } from "react-cookie"
import { Icon } from "../Icon"
import { HeaderMenuItem } from "./HeaderMenuItem"
import { useAuth } from "../../services"
import { triggerUserHelpBeacon } from "../../utils/helpscout"

const useStyles = makeStyles((theme) => ({
  menu: {
    minWidth: 307,
  },
  menuItem: {
    "&:hover": {
      background: theme.palette.background.shaded,
    },
  },
  menuItemShaded: {
    backgroundColor: theme.palette.shaded.main,
    "&:hover": {
      background: theme.palette.shaded.nav,
    },
  },
  menuUser: {
    fontSize: 16,
    lineHeight: "16px",
    fontWeight: "600",
    color: theme.palette.text.primary,
  },
  menuLight: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
}))

const HelpMenu = ({ className, size = 22 }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { principal, location, settings } = useAuth()
  const [{ hide_banners: hideBanners }, , removeCookie] = useCookies()
  const [helpEl, setHelpEl] = useState(null)
  const helpButtonEl = useRef(null)
  const {
    links: { help: helpLink },
  } = Config.get()

  const handleClick = (event) => {
    setHelpEl(event.currentTarget)
  }
  const handleHelpCenter = () => {
    window.open(helpLink, "_blank")
    handleClose()
  }
  const handleContactSupport = () => {
    triggerUserHelpBeacon(principal, location, settings)
    handleClose()
  }
  const handleBanners = () => {
    removeCookie("hide_banners")
    handleClose()
  }
  const handleClose = () => {
    setHelpEl(null)
  }

  return (
    <>
      <Tooltip title="Help">
        <IconButton ref={helpButtonEl} aria-label="Help" onClick={handleClick} className={className}>
          <Icon name="help" style={{ width: size, height: size }} />
        </IconButton>
      </Tooltip>
      <Menu
        open={Boolean(helpEl)}
        anchorEl={helpEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: theme.spacing(1), horizontal: "right" }}
        classes={{ paper: classes.menu }}
      >
        <HeaderMenuItem
          autoFocus
          divider
          onClick={handleContactSupport}
          text="Contact support"
          icon={<MailOutline />}
        />
        <HeaderMenuItem divider onClick={handleHelpCenter} text="Help Center" icon={<HelpOutline />} />
        <HeaderMenuItem
          onClick={handleBanners}
          text="Show help banners"
          icon={<LiveHelpOutlined />}
          disabled={!hideBanners}
        />
      </Menu>
    </>
  )
}

export { HelpMenu }
