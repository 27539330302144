import React, { useEffect, useRef, useState } from "react"
import { Box } from "@material-ui/core"
import { DateRangeOutlinedSelect, FormatDateCompact, RowBox } from ".."
import { ChipMenu } from "./ChipMenu"
import { ChipMenuItem } from "./ChipMenuItem"
import { ChipMenuActions } from "./ChipMenuActions"

const DateRangeValueText = ({ value }) => {
  if (!value) {
    return "Date range"
  }

  if (value === "yesterday") {
    return "Yesterday"
  }

  if (Number.isNaN(Number(value))) {
    const daysParts = value.split("-to-")
    return (
      <RowBox>
        <FormatDateCompact value={daysParts[0]} />
        <Box mx={0.5}>-</Box>
        <FormatDateCompact value={daysParts[1]} />
      </RowBox>
    )
  }

  if (value.toString() === "7") {
    return `Last ${value} days`
  }

  return `${value} days`
}

const DateRangeChipMenuItem = ({ value, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange && onChange(event.target.value)
  }

  return (
    <ChipMenuItem>
      <DateRangeOutlinedSelect value={value} onChange={handleChange} {...props} />
    </ChipMenuItem>
  )
}

const DateRangeChipMenu = ({ value, onChange, ...props }) => {
  const [nextValue, setNextValue] = useState(value)
  const menuRef = useRef()

  useEffect(() => {
    setNextValue(value)
  }, [value])

  const handleChange = (newValue) => {
    setNextValue(newValue)
  }

  const handleApply = () => {
    menuRef.current?.close()
    onChange && onChange(nextValue)
  }

  const handleClear = () => {
    menuRef.current?.close()
    onChange && onChange(7)
  }

  return (
    <ChipMenu menuRef={menuRef} text={<DateRangeValueText value={value} />}>
      <DateRangeChipMenuItem value={nextValue} onChange={handleChange} {...props} />
      <ChipMenuActions onApply={handleApply} onClear={handleClear} />
    </ChipMenu>
  )
}

export { DateRangeChipMenu, DateRangeChipMenuItem, DateRangeValueText }
