import React from "react"
import { Grid, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { TimeAgo, Avatar, Assignees, AuthorTools } from ".."
import { useActionUtils } from "../../utils"
import { CopyLinkButton } from "../Buttons"
import { Icon } from "../Icon"
import { useAuth } from "../../services"
import { ACTION_STATUS } from "../../data"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75),
  },
  icon: {
    color: theme.palette.raised.main,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  avatarName: {
    fontSize: 16,
    fontWeight: "600",
    lineHeight: "20px",
  },
  scheduleAndRecipients: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.scheduled.main,
  },
  ageAndRecipients: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
}))

const ActionHeader = ({ action, onEdit, onDelete, showIcon = true, showToolbar = true }) => {
  const classes = useStyles()
  const { getFullNavigateToLink } = useActionUtils()
  const {
    principal: { userID },
  } = useAuth()
  const { users, groups, author, createdAt } = action

  const handleEdit = (event) => {
    onEdit && onEdit(event)
  }
  const handleDelete = (event) => {
    onDelete && onDelete(event)
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      className={classes.root}
    >
      {showIcon && (
        <Box mr={2} display={{ xs: "none", sm: "flex" }}>
          {action.status === ACTION_STATUS.RESOLVED ? (
            <Icon name="action-resolved" className={classes.icon} />
          ) : (
            <Icon name="action-raised" className={classes.icon} />
          )}
        </Box>
      )}
      <Box mr={1} display="flex">
        <Avatar className={classes.avatar} {...author} />
      </Box>
      <Grid item xs>
        <div className={classes.avatarName}>
          {author.firstName} {author.lastName}
        </div>
        <div className={classes.ageAndRecipients}>
          <TimeAgo date={createdAt} />
          <>
            <span> assigned to </span>
            <Assignees {...{ users, groups, limit: 4 }} />
          </>
        </div>
      </Grid>
      {showToolbar && (
        <Box ml="auto" display="flex" alignItems="center" flexDirection="row">
          <CopyLinkButton link={getFullNavigateToLink(action)} title="Copy link to clipboard" />
          <AuthorTools
            item={action}
            author={userID}
            admins={{ delete: "action_delete_all" }}
            subject="action"
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </Box>
      )}
    </Grid>
  )
}

export { ActionHeader }
