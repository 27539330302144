import React, { useState } from "react"
import { Box, Divider, MenuItem, makeStyles } from "@material-ui/core"
import moment from "moment-timezone"
import { DialogDateTimePicker, Icon, OutlinedSelect, RowBox } from ".."
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  menuDate: {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
  },
}))

const defaultOptions = [
  { value: "none", label: "At no set time" },
  { divider: true },
  { value: "tomorrow", label: "Tomorrow", calculate: (timeZone) => moment.tz(timeZone).add(1, "day") },
  {
    value: "next_week",
    label: "End of this week",
    calculate: (timeZone) => moment.tz(timeZone).endOf("isoweek"),
  },
  { value: "one_week", label: "One week", calculate: (timeZone) => moment.tz(timeZone).add(1, "week") },
  { divider: true },
  { value: "custom", label: "Custom..." },
]

const formatDate = (date, timeZone) => {
  if (!date) {
    return null
  }

  if (moment.tz(date, timeZone).isSame(moment.tz(date, timeZone).startOf("day"), "minute")) {
    return moment.tz(date, timeZone).format("ddd, D MMM z")
  }

  return moment.tz(date, timeZone).format("ddd, D MMM h:mma z")
}

const PresetOptionsDateOutlinedSelect = ({
  value,
  defaultValue = "none",
  options = [...defaultOptions],
  timeZone: timeZoneInput,
  onChange,
}) => {
  const classes = useStyles()
  const { location: userLocation } = useAuth()
  const [internalValue, setInternalValue] = useState(defaultValue)

  const timeZone = timeZoneInput || userLocation?.timeZone

  const [openPicker, setOpenPicker] = useState(false)

  const handleSelectChange = (event) => {
    const { value: selectedValue } = event.target

    if (selectedValue === "custom") {
      setOpenPicker(true)
    } else {
      const option = options.find((item) => item.value === selectedValue)
      if (option) {
        onChange && onChange(option.calculate ? option.calculate(timeZone) : null)
        setInternalValue(selectedValue)
      }
    }
  }

  const handleDateChange = (newDate) => {
    setOpenPicker(false)
    onChange && onChange(newDate)
  }

  const handleClosePicker = () => {
    setOpenPicker(false)
  }

  return (
    <>
      <OutlinedSelect
        label="Due"
        value={internalValue}
        native={false}
        onChange={handleSelectChange}
        selectProps={{
          IconComponent: () => <Icon name="date" className="MuiSelect-icon MuiSelect-iconOutlined" />,
          renderValue: (selected) => {
            if (value) {
              return formatDate(value, timeZone)
            }

            const option = options.find((item) => item.value === selected)
            if (!option) {
              return ""
            }

            return option.calculate ? formatDate(option.calculate(timeZone), timeZone) : option.label
          },
        }}
      >
        {options.map((option) => {
          if (option.divider) {
            return <Divider key={Math.random()} />
          }

          return (
            <MenuItem key={option.value} value={option.value}>
              <RowBox flexGrow={1}>
                <Box mr="auto">{option.label}</Box>

                {option.calculate ? (
                  <Box className={classes.menuDate} ml={1}>
                    {formatDate(option.calculate(timeZone), timeZone)}
                  </Box>
                ) : null}
              </RowBox>
            </MenuItem>
          )
        })}
      </OutlinedSelect>
      <DialogDateTimePicker
        open={openPicker}
        variant="dialog"
        value={value}
        onChange={handleDateChange}
        onClose={handleClosePicker}
        showTodayButton
      />
    </>
  )
}

export { PresetOptionsDateOutlinedSelect }
