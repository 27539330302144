import Axios from "axios"
import Config from "react-global-configuration"
import FileDownload from "js-file-download"
import { authStateVar } from "../authentication"

const downloadUserTrainingAssessmentExport = async (filters) => {
  const { httpBaseUri } = Config.get()
  const {
    principal: { token },
  } = authStateVar()

  const response = await Axios({
    url: `${httpBaseUri}/user-training-assessment-export`,
    method: "POST",
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: filters,
  })

  if (response.status !== 200) {
    throw new Error(response.data?.message || "Failed to download user training assessment export")
  }

  FileDownload(response.data, "training-assessment.csv")
}

export default downloadUserTrainingAssessmentExport
