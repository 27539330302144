import { ActionPriorityChipMenuItem, ActionPriorityValueText } from "../ChipMenu/ActionPriorityChipMenu"
import { FiltersChipMenuItem } from "./FiltersChipMenuItem"

const ActionPriorityFiltersChipMenuItem = ({ menuItemRef, value, onChange, onExpanded, ...props }) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue)
  }

  return (
    <FiltersChipMenuItem
      menuItemRef={menuItemRef}
      name="Priority"
      description={<ActionPriorityValueText value={value} />}
      onExpanded={onExpanded}
    >
      <ActionPriorityChipMenuItem value={value} onChange={handleChange} {...props} />
    </FiltersChipMenuItem>
  )
}

export { ActionPriorityFiltersChipMenuItem }
