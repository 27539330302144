import React from "react"
import { PaperBox } from "../Boxes"
import { StatisticDisplay } from "../Charts/StatisticDisplay"
import { useQueryReportJobsOverallCompletions } from "../../data/report/useQueryReportJobsOverall"
import { mapToIds } from "../../utils"
import { useAuth } from "../../services"

const JobsStatistic = ({ gt, lt, locations, groups, tags, link }) => {
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data, loading } = useQueryReportJobsOverallCompletions({
    variables: {
      input: {
        gt,
        lt,
        locations: locations.includes("all") ? mapToIds(userLocations) : locations,
        groups,
        tags,
      },
    },
  })

  const { completedOnTime, completedLate, inProgress, uncompleted, uncompletedOverdue } =
    data?.report?.jobs?.overall.completions || {}

  const completed = completedOnTime + completedLate
  const total = completedOnTime + completedLate + inProgress + uncompleted + uncompletedOverdue

  return (
    <PaperBox>
      <StatisticDisplay
        icon="jobs-dark"
        label="Jobs completed"
        value={completed}
        total={total}
        link={link}
        loading={loading}
      />
    </PaperBox>
  )
}

export { JobsStatistic }
