import React, { useState } from "react"
import { Button, Menu, makeStyles, MenuItem, ListItemIcon, Box } from "@material-ui/core"
import { SpeedDialAction } from "@material-ui/lab"
import { useHistory } from "react-router-dom"
import { RequirePermissions, FloatingSpeedDial, Icon } from ".."
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: 50,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
}))

const useSpeedDialActionStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
  },
}))

const DesktopRunJobButton = ({ onCreateClick, isTemplates }) => {
  const classes = useStyles()
  const { permissionGroups } = useAuth()
  const [showCreateOptions, setShowCreateOptions] = useState(false)
  const [showCreateOptionsEl, setShowCreateOptionsEl] = useState(null)

  const handleCreateClick = (type) => {
    setShowCreateOptions(false)
    setShowCreateOptionsEl(null)
    onCreateClick(type)
  }

  const handleToggleCreateMenu = ({ currentTarget }) => {
    setShowCreateOptionsEl(!showCreateOptions ? currentTarget : null)
    setShowCreateOptions(!showCreateOptions)
  }

  return (
    <RequirePermissions requires={permissionGroups.createJobs}>
      <Box display="flex" flexDirection="row">
        {!isTemplates && (
          <Box>
            <Button
              id="runadhoc"
              onClick={() => handleCreateClick("adhoc-job")}
              variant="contained"
              color="primary"
              data-cy="Button-create-job"
            >
              Run ad hoc job
            </Button>
          </Box>
        )}
        {isTemplates && (
          <RequirePermissions requires={["process_create"]}>
            <Button onClick={handleToggleCreateMenu} variant="contained" color="primary" data-cy="Button-create-job">
              Create
            </Button>
            <Menu
              anchorEl={showCreateOptionsEl}
              keepMounted
              open={showCreateOptions}
              onClose={handleToggleCreateMenu}
              className={classes.menu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={() => handleCreateClick("process")} data-cy="MenuItem-create-process">
                <ListItemIcon className={classes.menuListItemIcon}>
                  <Icon name="process" />
                </ListItemIcon>
                <>Create a process</>
              </MenuItem>
              <MenuItem onClick={() => handleCreateClick("audit")} data-cy="MenuItem-create-audit">
                <ListItemIcon className={classes.menuListItemIcon}>
                  <Icon name="audit" />
                </ListItemIcon>
                <>Create an audit</>
              </MenuItem>
            </Menu>
          </RequirePermissions>
        )}
      </Box>
    </RequirePermissions>
  )
}

const MobileRunJobButton = ({ onCreateClick, isTemplates }) => {
  const classes = useSpeedDialActionStyles()
  const history = useHistory()
  const { permissionGroups, hasPermission } = useAuth()
  const [openSpeedDial, setOpenSpeedDial] = useState(false)

  const handleCreateClick = (type) => {
    handleToggleSpeedDial()
    onCreateClick(type)
  }

  const handleRaiseAction = () => {
    history.push("/hub/actions/unresolved/new")
  }

  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }

  return (
    <RequirePermissions requires={permissionGroups.createJobs}>
      <FloatingSpeedDial onClose={handleToggleSpeedDial} onOpen={handleToggleSpeedDial} open={openSpeedDial}>
        {!isTemplates && hasPermission("action_create") && (
          <SpeedDialAction
            tooltipTitle="Raise action"
            icon={<Icon name="action" />}
            tooltipOpen
            onClick={handleRaiseAction}
            classes={classes}
          />
        )}
        {!isTemplates && (
          <SpeedDialAction
            tooltipTitle="Run ad hoc job"
            icon={<Icon name="adhoc" />}
            tooltipOpen
            onClick={() => handleCreateClick("adhoc-job")}
            classes={classes}
          />
        )}
      </FloatingSpeedDial>
    </RequirePermissions>
  )
}

export { DesktopRunJobButton, MobileRunJobButton }
