import React, { useEffect, useMemo, useRef, useState } from "react"
import { Divider, Grid, MenuItem } from "@material-ui/core"
import moment from "moment-timezone"
import { OutlinedSelect } from "./OutlinedSelect"
import { DatePicker } from ".."

const formatDateRange = (start, end) => `${moment(start).format("YYYY-MM-DD")}-to-${moment(end).format("YYYY-MM-DD")}`

const DateRangeOutlinedSelect = ({ value, onChange, todayLabel = "Today", ...rest }) => {
  const initRef = useRef(false)
  const [custom, setCustom] = useState(null)
  const isDate = moment(value, "YYYY-MM-DD", true).isValid()

  useEffect(() => {
    setCustom(!isDate && value?.toString().includes("-to-"))
    initRef.current = true
  }, [isDate, value])

  const startDate = useMemo(
    () => (!isDate && custom && value && `${value}`.includes("-to-") ? moment(value.split("-to-")[0]) : null),
    [custom, isDate, value]
  )
  const endDate = useMemo(
    () => (!isDate && custom && value && `${value}`.includes("-to-") ? moment(value.split("-to-")[1]) : null),
    [custom, isDate, value]
  )

  const handleChange = (event) => {
    const {
      target: { value: eventValue },
    } = event
    if (eventValue === "custom") {
      setCustom(true)
      return
    }
    if (eventValue === "yesterday") {
      const yesterday = moment().add(-1, "day").startOf("day")
      onChange && onChange({ target: { value: formatDateRange(yesterday, yesterday) } })
      return
    }

    onChange && onChange(event)
  }

  const handleChangeStart = (date) => {
    const newStartDate = moment(date)
    let newEndDate = moment(endDate || moment())
    if (newStartDate.isSameOrAfter(newEndDate)) newEndDate = moment(newStartDate)
    const newValue = formatDateRange(newStartDate, newEndDate)
    onChange && onChange({ target: { value: newValue } })
  }

  const handleChangeEnd = (date) => {
    let newStartDate = moment(startDate || moment())
    const newEndDate = moment(date)
    if (newStartDate.isSameOrAfter(newEndDate)) newStartDate = moment(newEndDate)
    const newValue = formatDateRange(newStartDate, newEndDate)
    onChange && onChange({ target: { value: newValue } })
  }

  if (!initRef.current) return null

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OutlinedSelect
          label="Date range"
          value={custom ? "custom" : value}
          onChange={handleChange}
          native={false}
          {...rest}
        >
          <MenuItem value={1}>{todayLabel}</MenuItem>
          <MenuItem value="yesterday">Yesterday</MenuItem>
          <Divider />
          <MenuItem value={7}>Last 7 days</MenuItem>
          <MenuItem value={14}>14 days</MenuItem>
          <MenuItem value={28}>28 days</MenuItem>
          <MenuItem value={60}>60 days</MenuItem>
          <MenuItem value={90}>90 days</MenuItem>
          <MenuItem value={120}>120 days</MenuItem>
          <MenuItem value={180}>180 days</MenuItem>
          <MenuItem value={365}>365 days</MenuItem>
          <Divider />
          {isDate && <MenuItem value={value}>{value}</MenuItem>}
          <MenuItem value="custom">Custom...</MenuItem>
        </OutlinedSelect>
      </Grid>
      {custom && (
        <>
          <Grid item xs={6}>
            <DatePicker
              {...rest}
              label="From"
              value={startDate}
              onChange={handleChangeStart}
              mr={1}
              format="D MMM yyyy"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker {...rest} label="To" value={endDate} onChange={handleChangeEnd} format="D MMM yyyy" />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export { DateRangeOutlinedSelect }
