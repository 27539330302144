import React, { useState } from "react"
import { Dialog, DialogTitle, DialogContent, Box, Divider, DialogActions, Tabs, Tab, Button } from "@material-ui/core"
import { NavLink, useParams } from "react-router-dom"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { InlineTitleCloser } from "../Creators"
import { ErrorBoundary, InlineTitle, RowBox } from ".."
import { UserPanel } from "../UserPanel"
import { useLazyQueryAdminableUser, useMutationResendInvite } from "../../data"
import { toId } from "../../utils"
import { PersonTraining } from "./PersonTraining"
import { useAuth } from "../../services"
import { PersonAccreditation } from "./PersonAccreditation"
import { PersonTrainingAssessment } from "./PersonTrainingAssessment"
import { PersonDetails } from "./PersonDetails"

const PersonViewer = ({ user, onClose, isInline }) => {
  const { action } = useParams()
  const [resendInvite, { loading: sendInviteLoading }] = useMutationResendInvite()
  const [loadAdminableUser] = useLazyQueryAdminableUser({ variables: { id: toId(user) } })
  const [tab, setTab] = useState(action || "details")
  const { hasFeature, canCreateRoles } = useAuth()

  const handleResendInvite = async () => {
    await resendInvite({ variables: { id: user.id } })
    await loadAdminableUser()
  }

  const handleOnClose = (event, isCancel) => {
    onClose(isCancel)
  }

  if (!user) {
    return null
  }

  const hasCreateRoles = canCreateRoles(user.roles)

  const hasTraining = hasFeature("training") && hasCreateRoles
  const hasAccreditation = hasFeature("accreditation") && hasCreateRoles
  const hasTrainingAssessment = hasTraining && hasFeature("training_assessment") && hasCreateRoles
  const hasTabs = hasTraining || hasTrainingAssessment || hasAccreditation

  const hasEditDetails = tab === "details" && hasCreateRoles

  const display = (
    <>
      <Box px={isInline ? 2 : 0} py={isInline ? 2 : 0} data-cy="PersonViewer-display">
        <UserPanel user={user} layout="landscape" mb={2} />
        {hasTabs && (
          <Tabs
            variant="standard"
            indicatorColor="primary"
            textColor="primary"
            value={tab}
            onChange={(event, newTab) => setTab(newTab)}
            aria-label="Person details tabs"
            data-cy="PersonViewer-tabs"
          >
            <Tab label="Details" value="details" data-cy="Tab-details" />
            {hasTraining && <Tab label="Training courses" value="training" data-cy="Tab-training" />}
            {hasTrainingAssessment && (
              <Tab label="Training assessment" value="training-assessment" data-cy="Tab-training-assessment" />
            )}
            {hasAccreditation && <Tab label="Accreditations" value="accreditation" data-cy="Tab-accreditation" />}
          </Tabs>
        )}
        {hasTabs && <Divider />}

        <ErrorBoundary>
          <Box pt={2} data-cy="PersonViewer-page">
            {tab === "details" && (
              <PersonDetails user={user} onResendInvite={handleResendInvite} sendInviteLoading={sendInviteLoading} />
            )}
            {tab === "training" && <PersonTraining user={user} />}
            {tab === "training-assessment" && <PersonTrainingAssessment user={user} />}
            {tab === "accreditation" && <PersonAccreditation user={user} />}
          </Box>
        </ErrorBoundary>
      </Box>
    </>
  )

  if (isInline) {
    return (
      <>
        <InlineTitle>
          Staff details
          <InlineTitleCloser onClose={handleOnClose} />
        </InlineTitle>
        {display}
        {hasEditDetails && (
          <RowBox pr={2} pb={2} justifyContent="flex-end">
            <Button component={NavLink} variant="contained" color="primary" to={`/people/${user.id}/edit`}>
              Edit details
            </Button>
          </RowBox>
        )}
      </>
    )
  }
  return (
    <Dialog open={user !== null} onClose={handleOnClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        Staff details
        <DialogTitleCloser onClose={handleOnClose} />
      </DialogTitle>
      <DialogContent>{display}</DialogContent>
      {hasEditDetails && (
        <DialogActions>
          <Box pt={1} pr={2} pb={2}>
            <Button component={NavLink} variant="contained" color="primary" to={`/people/${user.id}/edit`}>
              Edit details
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}

export { PersonViewer }
