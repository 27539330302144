import * as Sentry from "@sentry/browser"
import { makeVar } from "@apollo/client"
import { v4 as uuid } from "uuid"
import { useState } from "react"
import moment from "moment"
import { DEVICE_POSTMESSAGE_TYPE, PROBE_TYPE, PROCESS_STEP_FORMAT_UNIT } from "../data"
import { useDeviceUtils } from "./useDeviceUtils"
import { useMountEffect } from "./useMountEffect"
import { useMountAwareReactiveVar } from "./useMountAwareReactiveVar"
import { getLocalStorageItem, useLocalStorage } from "./useLocalStorage"

const { ETI_BLUETOOTH_LE } = PROBE_TYPE

const probesVar = makeVar([])
const findingProbesVar = makeVar(false)

// init probe from local storage
const probeLocalStorageKey = "probe"
const storedProbeJson = getLocalStorageItem(probeLocalStorageKey)
const probeVar = makeVar(storedProbeJson ? JSON.parse(storedProbeJson) : null)

const dummyLoadingMs = 1000

const makeDummyProbe = () => {
  const random = Math.random()
  const displayRandom = Math.round(random * 100)

  const name = "BlueTherm One"

  return {
    id: uuid(),
    name: `${name} ${displayRandom}`,
    realName: `${name}${displayRandom}`,
    address: `1A:2B:3C:4D:5E:6F:${displayRandom}`,
    type: ETI_BLUETOOTH_LE,
  }
}

let dummyValueInterval = null

const useProbes = () => {
  const { postFindProbesMessage, postStartReadingProbeValueMessage, postStopReadingProbeValueMessage, canPostMessage } =
    useDeviceUtils()
  const [, setLocalStorage] = useLocalStorage(probeLocalStorageKey, null)
  const probes = useMountAwareReactiveVar(probesVar)
  const probe = useMountAwareReactiveVar(probeVar)
  const finding = useMountAwareReactiveVar(findingProbesVar)
  const [value, setValue] = useState(null)

  useMountEffect(() => () => clearInterval(dummyValueInterval))

  useMountEffect(() => {
    function handleProbesList(event) {
      const message = event.detail
      try {
        if (!message?.type || !message?.payload) {
          return
        }

        if (message.type === DEVICE_POSTMESSAGE_TYPE.PROBE_LIST) {
          probesVar([
            ...message.payload.reduce((acc, item) => {
              // ensure unique on address
              if (!acc.find((accItem) => accItem.address === item.address)) {
                acc.push(item)
              }
              return acc
            }, []),
          ])
          findingProbesVar(false)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // register listener
    window.addEventListener("deviceMessage", handleProbesList)

    // remove listener
    return () => {
      window.removeEventListener("deviceMessage", handleProbesList)
    }
  })

  useMountEffect(() => {
    function handleProbeValue(event) {
      const message = event.detail
      try {
        if (!message?.type || !message?.payload) {
          return
        }

        if (message.type === DEVICE_POSTMESSAGE_TYPE.PROBE_VALUE) {
          setValue(message.payload)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // register listener
    window.addEventListener("deviceMessage", handleProbeValue)

    // remove listener
    return () => {
      window.removeEventListener("deviceMessage", handleProbeValue)
    }
  })

  const findProbes = async (payload) => {
    findingProbesVar(true)
    if (!canPostMessage()) {
      // simulate find probes for testing
      setTimeout(() => {
        // random result
        const random = Math.random()
        if (random <= 0.7) {
          probesVar([
            ...Array(Math.round(Math.random() * 4))
              .fill()
              .map(makeDummyProbe),
          ])
        } else {
          probesVar([])
        }
        findingProbesVar(false)
      }, dummyLoadingMs)
      return
    }
    postFindProbesMessage(payload)
  }

  const startReadingValue = () => {
    if (!canPostMessage()) {
      // simulate reading value for testing
      dummyValueInterval = setInterval(() => {
        const fullValue = Number(Math.random() * 100)
        setValue({
          value: fullValue.toFixed(1),
          fullValue,
          unit: PROCESS_STEP_FORMAT_UNIT.TEMPERATURE_CELSIUS,
          at: moment().format(),
          device: { ...probe },
        })
      }, dummyLoadingMs)
      return
    }

    postStartReadingProbeValueMessage({
      probe,
    })
  }

  const stopReadingValue = () => {
    if (!canPostMessage()) {
      clearInterval(dummyValueInterval)
      setValue(null)
      return
    }

    postStopReadingProbeValueMessage()
  }

  const selectProbe = (item) => {
    if (!item) {
      return
    }

    probeVar({ ...item })
    setLocalStorage({ ...item })
  }

  return {
    probes,
    probe,
    finding,
    value,

    startReadingValue,
    stopReadingValue,
    findProbes,
    selectProbe,
  }
}

export { useProbes }
