import React, { useState, useEffect, useMemo } from "react"
import { Grid, Box, useMediaQuery, useTheme, Collapse, Hidden, makeStyles } from "@material-ui/core"
import moment from "moment"
import { useHistory, useParams } from "react-router-dom"
import Config from "react-global-configuration"
import {
  Content,
  AreaHeader,
  FormatDateFormal,
  UnconfirmedActionCard,
  JobsCompletionChartCard,
  Activity,
  DashboardModule,
  RequirePermissions,
  InsufficientPermissionsMessage,
  MoreJobsCount,
  DesktopRunJobButton,
  MobileRunJobButton,
  JobCreator,
  IconMessage,
  DeviceGroupsDisplay,
  TrainingLocationCompletionCard,
  JobList,
  TrainingAccreditationDashboardModule,
  RowBox,
  ErrorBoundary,
} from "../../components"
import {
  useQueryNotifications,
  useLazyQueryJobs,
  makeDashboardJobListFilters,
  useQueryTrainingCoursesCount,
  NOTIFICATION_TYPE,
  JOBS_SET,
  ACTION_STATUS,
} from "../../data"
import { useAuth } from "../../services"
import { ScrollToTop } from "../../components/Scrolling/ScrollToTop"
import { ShowMoreResults } from "../../components/ShowMoreResults/ShowMoreResults"
import { toId, useDateUtils } from "../../utils"
import { UnresolvedActionActionCard } from "../../components/ActionCards/UnresolvedActionActionCard"
import { DesktopRaiseActionButton } from "../../components/Buttons/RaiseActionButton"

const useStyles = makeStyles(() => ({
  gridItem: {
    width: "100%",
  },
}))

const Dashboard = () => {
  const { jobsMs } = Config.get("polling")
  const { type } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const { momentToScheduleDate } = useDateUtils()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const {
    location,
    principal: { userID, groups: usersGroups },
    settings: { deviceGroups },
    hasPermission,
    hasFeature,
    permissionGroups,
  } = useAuth()
  const date = moment()
    .tz(location?.timeZone || moment.tz.guess())
    .startOf("day")

  const [unsubscribes, setUnsubscribes] = useState(null)
  const [loadJobs, jobsResult] = useLazyQueryJobs()
  const { data: trainingCoursesCountData } = useQueryTrainingCoursesCount()
  const { called, data: dataJobs, refetch, subscribe, startPolling, stopPolling } = jobsResult
  const [locationId, setLocationId] = useState(null)

  const totalJobs = dataJobs?.jobs.count || 0

  const [notificationUnsubscribes, setNotificationUnsubscribes] = useState(null)

  const {
    called: calledNotifications,
    data: dataNotifications,
    subscribe: subscribeNotifications,
  } = useQueryNotifications()

  useEffect(() => {
    if (calledNotifications && !notificationUnsubscribes) {
      setNotificationUnsubscribes(subscribeNotifications())
      return () => {
        if (notificationUnsubscribes) {
          notificationUnsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [calledNotifications, notificationUnsubscribes, subscribeNotifications])

  const attentionNotifications = dataNotifications?.notifications.items.filter(({ hasConfirm, confirmed, action }) => {
    // If we have a location and it's not equal to where we are currently at, remove this notification from being seen. This allows non location based notifications (such as posts requiring confirmation) to still be passed through.

    if (action) {
      if (action.location !== (location && location.id)) {
        return false
      }
    }

    // Otherwise display notifications that require confirmation and haven't
    // been confirmed, or those that require action and haven't been resolved
    // yet
    return (
      (hasConfirm && !confirmed) ||
      (action && ![ACTION_STATUS.RESOLVED, ACTION_STATUS.CANCELLED].includes(action.status))
    )
  })

  const hasUnconfirmed = Boolean(attentionNotifications?.length)

  const title = <FormatDateFormal value={date} />

  const jobListFilters = useMemo(
    () => makeDashboardJobListFilters({ userID, hideCompleted: hasFeature("job_hide_completed") }),
    [hasFeature, userID]
  )

  useEffect(() => {
    if (location && location.id && locationId !== location.id && hasPermission(permissionGroups.readJobs)) {
      if (stopPolling) stopPolling()
      const newLocationId = location.id
      setLocationId(newLocationId)
      const filter = {
        location: newLocationId,
        date: momentToScheduleDate(date),
        set: JOBS_SET.SELF,
        toDeviceGroups: deviceGroups,
      }
      if (called && refetch) refetch({ filter })
      else
        loadJobs({
          variables: {
            filter,
          },
        })
    }
  }, [
    called,
    date,
    deviceGroups,
    hasPermission,
    loadJobs,
    location,
    locationId,
    momentToScheduleDate,
    permissionGroups.readJobs,
    refetch,
    stopPolling,
    usersGroups,
  ])

  useEffect(() => {
    if (startPolling && jobsMs) startPolling(jobsMs)
  }, [jobsMs, startPolling, locationId])

  useEffect(() => {
    if (called && !unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [called, subscribe, unsubscribes, setUnsubscribes])

  const subTitle = hasUnconfirmed ? (
    "There are items requiring your attention"
  ) : deviceGroups ? (
    <DeviceGroupsDisplay />
  ) : location ? (
    location.name
  ) : (
    "My dashboard"
  )

  const baseUri = "/dashboard"

  const handleCreate = (createType) => {
    history.push(`${baseUri}/${createType}/new`)
  }
  const handleCreateClose = () => {
    history.push(baseUri)
  }

  const hasTraining = hasFeature("training")
  const hasTrainingCourses = Boolean(trainingCoursesCountData?.trainingCoursesCount)
  const hasJobsItems = Boolean(dataJobs?.jobs.list.length)
  const hasLocationOverview = hasPermission(["activity_read_daily_all", "training_read_locations"])

  const modifiedAt1 = new Date()
  modifiedAt1.setMinutes(modifiedAt1.getMinutes() - 4)

  const modifiedAt2 = new Date()
  modifiedAt2.setMinutes(modifiedAt2.getMinutes() - 65)

  const isOpen = Boolean(type)

  // Render out all of the notifications before we return. These items will be
  // given to a show / hide component which is used to truncate the total
  // number of results if it exceeds a given max count of items shown.
  const renderedAttentionNotifications = attentionNotifications
    ? attentionNotifications.map((notification) => (
        <Box key={toId(notification)}>
          {notification.type !== NOTIFICATION_TYPE.ACTION && <UnconfirmedActionCard notification={notification} />}
          {notification.type === NOTIFICATION_TYPE.ACTION && <UnresolvedActionActionCard notification={notification} />}
        </Box>
      ))
    : []

  return (
    <>
      <ScrollToTop />
      <JobCreator open={Boolean(type) && type} onClose={handleCreateClose} />

      {(!xs || (xs && !isOpen)) && (
        <>
          <AreaHeader
            title={title}
            subTitle={subTitle}
            desktopButtons={
              <RowBox>
                <RequirePermissions requires="action_create">
                  <DesktopRaiseActionButton boxProps={{ mr: 1 }} />
                </RequirePermissions>
                <DesktopRunJobButton onCreateClick={handleCreate} />
              </RowBox>
            }
            mobileButtons={<MobileRunJobButton onCreateClick={handleCreate} />}
            hasUnconfirmed={hasUnconfirmed}
          />

          <Content full split={!xs} mb={0}>
            <Grid container direction="row" spacing={xs ? 0 : 6}>
              <Grid item xs={12} sm={6}>
                <Grid>
                  <Hidden smUp>
                    <TrainingAccreditationDashboardModule />
                  </Hidden>

                  <Collapse in={hasUnconfirmed}>
                    {hasUnconfirmed && (
                      <DashboardModule title={{ text: "Attention items", unconfirmed: true }}>
                        <ShowMoreResults items={renderedAttentionNotifications} subject="notification" />
                      </DashboardModule>
                    )}
                  </Collapse>

                  <DashboardModule
                    title={{
                      text: `Today's Jobs${totalJobs ? ` (${totalJobs})` : ""}`,
                      unconfirmed: false,
                    }}
                    cy="DashboardModule-jobs"
                  >
                    <RequirePermissions requires={permissionGroups.readJobs} lacks={<InsufficientPermissionsMessage />}>
                      <JobList
                        {...jobsResult}
                        filters={jobListFilters}
                        date={date}
                        compact
                        empty={
                          <IconMessage
                            name="empty-assignedjobs"
                            text="You don't have any jobs assigned to you right now."
                          />
                        }
                        showEndMessage={false}
                        showShowMore={xs}
                        pageSize={12}
                      />
                      <MoreJobsCount jobs={jobsResult.data && jobsResult.data.jobs} boxProps={{ pt: 4 }} />
                    </RequirePermissions>
                  </DashboardModule>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid>
                  <Hidden xsDown>
                    <TrainingAccreditationDashboardModule />
                  </Hidden>

                  {hasLocationOverview && (hasJobsItems || (hasTraining && hasTrainingCourses)) && (
                    <DashboardModule title={{ text: "Location Overview", unconfirmed: false }}>
                      <Grid container spacing={2} layout="column">
                        {hasJobsItems && (
                          <RequirePermissions requires={["activity_read_daily_all"]}>
                            <Grid item lg={hasTraining && hasTrainingCourses ? 6 : 12} className={classes.gridItem}>
                              <JobsCompletionChartCard
                                jobs={dataJobs?.jobs.list || []}
                                title={<strong>Today's Jobs</strong>}
                                detail={({ overall }) => (
                                  <>
                                    Staff have completed{" "}
                                    <strong>
                                      {overall.completed}/{overall.total}
                                    </strong>{" "}
                                    jobs
                                  </>
                                )}
                                adminOnly
                              />
                            </Grid>
                          </RequirePermissions>
                        )}

                        {hasTrainingCourses && (
                          <RequirePermissions requires={["training_read_locations"]} features={["training"]}>
                            <Grid item lg={hasJobsItems ? 6 : 12} className={classes.gridItem}>
                              <TrainingLocationCompletionCard location={location} />
                            </Grid>
                          </RequirePermissions>
                        )}
                      </Grid>
                    </DashboardModule>
                  )}

                  <Box mb={5}>
                    <ErrorBoundary>
                      <Activity />
                    </ErrorBoundary>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Content>
        </>
      )}
    </>
  )
}

export default Dashboard
