import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_ACTIONS_LOCATIONS_OVERVIEW = gql`
  fragment ReportActionsLocationsOverviewFields on ReportActionLocationOverview {
    id
    locationName
    total
    unresolved
    inProgress
    blocked
    resolved
    cancelled
  }
`

const REPORT_ACTIONS_LOCATIONS_OVERVIEW_QUERY = gql`
  query ReportActionsLocationsOverview($input: ReportActionsLocationsOverviewQueryInput!) {
    report {
      actions {
        locations {
          overview(input: $input) {
            ...ReportActionsLocationsOverviewFields
          }
        }
      }
    }
  }
  ${REPORT_ACTIONS_LOCATIONS_OVERVIEW}
`

const useQueryReportActionsLocationsOverview = (options) =>
  useQuery(REPORT_ACTIONS_LOCATIONS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportActionsLocationsOverview = (options) =>
  useLazyQuery(REPORT_ACTIONS_LOCATIONS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportActionsLocationsOverview, useLazyQueryReportActionsLocationsOverview }
