import { Box, Button, Divider } from "@material-ui/core"
import { RowBox } from "../Boxes"
import { ReportFiltersChipMenu } from "../FiltersChipMenu/ReportFiltersChipMenu"

const ReportsFilters = ({ children, values, menu, onApply, onClear }) => {
  return (
    <Box mb={2}>
      <RowBox mt={3} mb={2}>
        <RowBox flexGrow={1}>
          <ReportFiltersChipMenu values={values} menu={menu} onApply={onApply} onClear={onClear} />
          {children}
        </RowBox>
        <Button color="primary" size="small" onClick={onClear}>
          Clear all
        </Button>
      </RowBox>
      <Divider />
    </Box>
  )
}

export { ReportsFilters }
