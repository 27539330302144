import React from "react"
import { Grid, useTheme, useMediaQuery, makeStyles } from "@material-ui/core"
import { LabelsItem } from ".."
import { toId } from "../../utils"
import { ListWindowVirtualizer } from "../Lists/ListWindowVirtualizer"
import { useDemonstration } from "../../utils/useDemonstration"
import { DEMO_PROFILE } from "../../data"

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1.5),
  },
}))

const LabelsItemList = ({ items, onEditItem, onDeleteItem, categoryChip }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { isDemo } = useDemonstration()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const sm = useMediaQuery(theme.breakpoints.only("sm"))

  const getItemKey = (itemRow) => itemRow.map((item) => toId(item)).join("_")

  const itemsPerRow = sm ? 3 : xs ? 2 : 4

  const isLabelsDemo = isDemo(DEMO_PROFILE.LABELS)

  // chunk into rows of X items
  const itemRows =
    items.reduce((acc, curr, i) => {
      if (i % itemsPerRow === 0) {
        acc.push([])
      }
      acc[acc.length - 1].push(curr)
      return acc
    }, []) || []

  return (
    <ListWindowVirtualizer
      items={itemRows}
      itemContent={(_, itemRow) => (
        <Grid key={getItemKey(itemRow)} container direction="row" spacing={xs ? 2 : 3} className={classes.item}>
          {itemRow.map((item) => (
            <LabelsItem
              key={item.id}
              category={categoryChip ? item.category : null}
              item={item}
              onEdit={onEditItem}
              onDelete={isLabelsDemo ? null : onDeleteItem}
            />
          ))}
        </Grid>
      )}
    />
  )
}

export { LabelsItemList }
