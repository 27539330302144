import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_JOB_EXPORT_ATTACHMENTS_FIELDS = gql`
  fragment ReportJobExportAttachmentsFields on ReportJobExportAttachments {
    message
    fileName
    exportUrl
  }
`

const REPORT_JOB_EXPORT_ATTACHMENTS_QUERY = gql`
  ${REPORT_JOB_EXPORT_ATTACHMENTS_FIELDS}
  query ReportJobExportAttachments($process: ID!, $locations: [ID!]!, $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportJobExportAttachments(process: $process, locations: $locations, gt: $gt, lt: $lt) {
      ...ReportJobExportAttachmentsFields
    }
  }
`

const useQueryReportJobExportAttachments = (options) =>
  useQuery(REPORT_JOB_EXPORT_ATTACHMENTS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportJobExportAttachments = (options) =>
  useLazyQuery(REPORT_JOB_EXPORT_ATTACHMENTS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJobExportAttachments, useLazyQueryReportJobExportAttachments }
