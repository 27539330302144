import React, { useState } from "react"
import { makeStyles, Link } from "@material-ui/core"
import { Caption } from ".."
import { toId } from "../../utils"
import { useAuth } from "../../services"
import { useMakeJobOptimistic, useMutationJobUndoSubmit } from "../../data/jobs/useMutationJob"
import { Alert } from "../Alerts"

const useStyles = makeStyles((theme) => ({
  caption: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "inline-block",
  },
}))

const SubmittedAuditStatus = ({ job, onResetMessage }) => {
  const classes = useStyles()
  const { hasPermission } = useAuth()
  const { makeJobUndoSubmitOptimisticResponse } = useMakeJobOptimistic()
  const [undoSubmitJob, { loading: undoSubmitLoading }] = useMutationJobUndoSubmit(job)
  const [confirm, setConfirm] = useState(false)

  const handleUndoSubmit = async (result) => {
    if (confirm && !result) {
      // Cancelled
      setConfirm(false)
      return
    }

    if (!confirm) {
      setConfirm(true)
      return
    }

    onResetMessage && onResetMessage()

    const optimisticResponse = makeJobUndoSubmitOptimisticResponse({ job })
    await undoSubmitJob({ variables: { job: toId(job) }, optimisticResponse })
  }

  return (
    <>
      <Alert
        open={confirm}
        title="Unsubmit audit?"
        description={<>Once unsubmitted, the audit will be unlocked and you'll be able to edit it.</>}
        okLabel="Unsubmit audit"
        onClose={handleUndoSubmit}
      />

      <Caption mb={0} className={classes.caption}>
        This audit is locked.&nbsp;
        {hasPermission("job_update_all") ? (
          <>
            To make changes,{" "}
            <Link disabled={!undoSubmitLoading} className={classes.link} onClick={handleUndoSubmit}>
              unsubmit this audit.
            </Link>
          </>
        ) : (
          <>You can still raise actions or add notes.</>
        )}
      </Caption>
    </>
  )
}

export { SubmittedAuditStatus }
