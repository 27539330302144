import React, { useEffect, useState, useRef, useMemo } from "react"
import { Box, IconButton, Divider, CircularProgress } from "@material-ui/core"
import { Done as DoneIcon, Edit as EditIcon } from "@material-ui/icons"
import pluralize from "pluralize"
import * as Sentry from "@sentry/browser"
import { Icon, Uploader, UploadingImagesList, RowBox } from ".."
import { DEVICE_POSTMESSAGE_BEHAVIOUR, DEVICE_POSTMESSAGE_TYPE, PROCESS_STEP_RESPONSE_TYPE } from "../../data"
import { mapToIds, preventDefault, toId, useDeviceUtils, useMountEffect } from "../../utils"
import { useOffline } from "../Offline/useOffline"
import { ProcessCollapse } from "./ProcessCollapse"
import { useAuth } from "../../services"

const { PHOTOS } = PROCESS_STEP_RESPONSE_TYPE

const debounceRemoveMs = 1500
let debounceRemove

const AddPhotoStep = ({
  jobId,
  stepId,
  reference,
  expand,
  complete,
  responseUploads,
  classes,
  onChange,
  onRemove,
  onEdit,
  disabled,
  collapseTimeout = "auto",
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const { postPongMessage } = useDeviceUtils()
  const { hasFeature } = useAuth()
  const { offline } = useOffline()
  const doneRef = useRef(null)
  const [uploads, setUploads] = useState([])
  const [uploading, setUploading] = useState([])

  useEffect(() => {
    setUploads(responseUploads ? [...responseUploads] : [])
  }, [responseUploads])

  useMountEffect(() => {
    function handleDevicePing(event) {
      const message = event.detail
      try {
        if (!message?.type) {
          return
        }

        // return response to device
        if (message.type === DEVICE_POSTMESSAGE_TYPE.PING) {
          postPongMessage()
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // register listener
    window.addEventListener("deviceMessage", handleDevicePing)

    // remove listener
    return () => window.removeEventListener("deviceMessage", handleDevicePing)
  })

  const handleChange = (event) => {
    clearTimeout(debounceRemove)
    onChange && onChange(event, uploads)
  }

  const handleEdit = (event) => {
    onEdit && onEdit(event)
  }

  const handleBeforeUpload = (items) => {
    setIsUploading(true)
    setUploading(() => items)
  }
  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }
  const handleItemUploaded = (item) => {
    setUploads((state) => [...state, item])
    setUploading([...uploading.filter((upload) => toId(upload) !== toId(item))])
  }
  const handleRemoveUpload = (id) => {
    clearTimeout(debounceRemove)
    const removing = uploads.find((upload) => toId(upload) === id)
    const newUploads = uploads.filter((upload) => toId(upload) !== id)
    setUploads([...newUploads])
    setUploading([...uploading.filter((upload) => toId(upload) !== id)])
    if (!removing.__upload && onRemove) {
      debounceRemove = setTimeout(() => {
        onRemove(newUploads)
      }, debounceRemoveMs)
    }
  }
  const handleNotUploading = () => {
    setIsUploading(false)
    setTimeout(() => {
      doneRef.current.click()
    }, 100)
  }

  const handleDeviceUploaded = (items) => {
    setUploads((prev) => [...prev, ...items])
    setTimeout(() => {
      doneRef.current.click()
    }, 100)
  }

  const hasUploads = Boolean(uploads.length || uploading.length)
  const hasDeviceBehaviours = hasFeature("device_behaviours")

  const deviceOptions = useMemo(() => {
    if (!hasDeviceBehaviours || !jobId || !stepId) {
      return null
    }

    return {
      behaviours: [
        {
          type: DEVICE_POSTMESSAGE_BEHAVIOUR.COMPLETE_JOB_STEP,
          variables: {
            job: toId(jobId),
            step: toId(stepId),
            responseUploads: mapToIds(uploads),
          },
        },
      ],
    }
  }, [hasDeviceBehaviours, jobId, stepId, uploads])

  return (
    <ProcessCollapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />
      <Box py={1} px={2}>
        {hasUploads && (
          <Box mb={complete ? 0 : 1}>
            <UploadingImagesList
              uploaded={uploads}
              uploading={uploading}
              onRemoveUpload={complete ? null : handleRemoveUpload}
              collapseTimeout={collapseTimeout}
            />
          </Box>
        )}
        <ProcessCollapse in={!complete} timeout={collapseTimeout}>
          <Uploader
            variant="button"
            reference={reference}
            buttonText={
              <RowBox alignItems="center" justifyItems="center">
                <Icon name="photo" />
                <Box ml={1}>Add photo</Box>
              </RowBox>
            }
            buttonProps={{
              variant: "contained",
              fullWidth: true,
              className: classes.button,
            }}
            documents={false}
            cameraOnly
            onBeforeUpload={handleBeforeUpload}
            onProgress={handleUploadProgress}
            onItemUploaded={handleItemUploaded}
            onAfterUpload={handleNotUploading}
            onDeviceUploaded={handleDeviceUploaded}
            onError={handleNotUploading}
            disabled={disabled || offline}
            deviceOptions={deviceOptions}
          />
        </ProcessCollapse>
      </Box>
      <ProcessCollapse in={hasUploads} timeout={collapseTimeout}>
        <Divider className={classes.divider} />

        <RowBox py={1} px={2} className={classes.promptArea}>
          <Box>
            {isUploading && <strong>Uploading...</strong>}
            {!isUploading && !complete && <strong>Submit {pluralize("photo", uploads.length, true)}?</strong>}
            {!isUploading && complete && <strong>{pluralize("photo", uploads.length, true)} uploaded</strong>}
          </Box>
          <Box onClick={preventDefault} ml="auto">
            {isUploading && (
              <RowBox alignItems="center" mr={1}>
                <CircularProgress size={24} />
              </RowBox>
            )}
            {!isUploading && !complete && (
              <IconButton
                ref={doneRef}
                onClick={handleChange}
                className={classes.incompletedButton}
                data-cy={`ProcessStep-field-${PHOTOS}-complete`}
                disabled={disabled}
              >
                <DoneIcon />
              </IconButton>
            )}
            {!isUploading && complete && (
              <IconButton
                onClick={handleEdit}
                className={classes.completedButton}
                data-cy={`ProcessStep-field-${PHOTOS}-uncomplete`}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </RowBox>
      </ProcessCollapse>
    </ProcessCollapse>
  )
}

export { AddPhotoStep }
