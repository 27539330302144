import React from "react"
import { FiltersChipMenuItem } from "./FiltersChipMenuItem"
import { LocationChipMenuItem, LocationValueText } from "../ChipMenu/LocationChipMenu"

const LocationsFiltersChipMenuItem = ({ menuItemRef, value, onChange, onExpanded, ...props }) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue)
  }

  return (
    <FiltersChipMenuItem
      menuItemRef={menuItemRef}
      name="Locations"
      description={<LocationValueText value={value} />}
      onExpanded={onExpanded}
    >
      <LocationChipMenuItem value={value} onChange={handleChange} {...props} />
    </FiltersChipMenuItem>
  )
}

export { LocationsFiltersChipMenuItem }
