import { useMutation, gql } from "@apollo/client"

const RESEND_USERNAME_CHANGE_MUTATION = gql`
  mutation ResendUsernameChange {
    me {
      resendUsernameChange
    }
  }
`

const useMutationResendUsernameChange = () =>
  useMutation(RESEND_USERNAME_CHANGE_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationResendUsernameChange }
