import React, { useRef, useState } from "react"
import { Box } from "@material-ui/core"
import useAsyncEffect from "use-async-effect"
import { Caption, FieldSectionHeading } from "../Headings"
import { NoItemsMessage } from "../Lists"
import { useMutationIntegrationSetAttribute } from "../../data"
import { toId, useDebounce } from "../../utils"
import { IntegrationAttributeComponent } from "./IntegrationAttributeComponent"

const IntegrationAttributeItem = ({ index, integration, attribute, onChange }) => {
  const mounted = useRef(false)
  const [setAttribute] = useMutationIntegrationSetAttribute()
  const [value, setValue] = useState(integration.attributes.find((item) => item.key === attribute.key)?.value || "")
  const debouncedValue = useDebounce(value, 300)

  useAsyncEffect(async () => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    await setAttribute({
      variables: {
        id: toId(integration),
        key: attribute.key,
        value: debouncedValue.toString(),
      },
    })
    onChange && onChange()
  }, [debouncedValue])

  const handleChange = async (newValue) => {
    setValue(newValue)
  }

  return (
    <Box key={attribute.key} mt={index > 0 ? 2 : 0}>
      <FieldSectionHeading mb={0}>{attribute.label}</FieldSectionHeading>
      <IntegrationAttributeComponent attribute={attribute} value={value} onChange={handleChange} />
      <Caption mt={0.5} mb={0}>
        {attribute.description}
      </Caption>
    </Box>
  )
}

const IntegrationAttributesConfiguration = ({ integration }) => {
  const loading = false
  const {
    integrationProvider: {
      attributes: { integration: allAttributes },
    },
  } = integration

  return (
    <Box mt={3}>
      {allAttributes.map((attribute, index) => (
        <IntegrationAttributeItem
          key={attribute.key}
          index={index}
          integration={integration}
          attribute={attribute}
          loading={loading}
        />
      ))}
      {allAttributes.length === 0 && <NoItemsMessage>No general settings for this integration.</NoItemsMessage>}
    </Box>
  )
}

export { IntegrationAttributesConfiguration }
