import React from "react"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core"
import { ActionCard, FlexBox, AuthorTools, RowBox, Icon } from ".."
import { toId } from "../../utils"
import { ROLE_SCOPE } from "../../data"

const useStyles = makeStyles(() => ({
  card: {
    color: "inherit",
  },
}))

const RoleActionCard = ({ role, onEdit, onDelete, ...rest }) => {
  const classes = useStyles()

  const { friendlyName, description, scope } = role

  const isOrganisation = role.scope === ROLE_SCOPE.ORGANISATION

  const handleEdit = () => {
    onEdit && onEdit(role)
  }

  const handleDelete = () => {
    onDelete && onDelete(role)
  }

  return (
    <ActionCard
      title={friendlyName}
      detail={description}
      cardProps={{
        component: NavLink,
        to: `/account/roles/${scope}/${toId(role)}/view`,
        className: classes.card,
      }}
      stub={
        <RowBox mr={1}>
          {isOrganisation && <Icon name="role" size={24} />}
          {!isOrganisation && <Icon name="operandio" size={24} link={false} />}
        </RowBox>
      }
      onDelete={handleDelete}
      rightChildren={
        isOrganisation && (
          <FlexBox alignSelf="flex-start">
            <AuthorTools
              item={role}
              admins={{
                edit: "role_update",
                delete: "role_update",
              }}
              onEdit={handleEdit}
              subject="permission level"
              onDelete={handleDelete}
            />
          </FlexBox>
        )
      }
      className={classes.root}
      {...rest}
    />
  )
}

export { RoleActionCard }
