import React, { useState } from "react"
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@impelsys/material-ui-pickers"
import { useTheme } from "@material-ui/styles"
import { useMediaQuery } from "@material-ui/core"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import { useDateUtils } from "../../utils"

const DateTimePicker = ({
  label,
  placeholder = "Enter date and time",
  value,
  onChange,
  clearLabel = "Today",
  clearable = false,
  smartStripTime,
  showTodayButton = false,
  okLabel = "",
  autoOk = true,
  ...rest
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { hasTime } = useDateUtils()
  const [open, setOpen] = useState(false)

  const handleChange = (date) => {
    if (clearable && !date) {
      onChange(null)
      return
    }
    onChange(smartStripTime && !hasTime(value) && date ? date.startOf("day") : date || moment().startOf("day"))
  }
  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePicker
        label={label}
        placeholder={placeholder}
        value={value}
        open={open}
        inputVariant="outlined"
        minutesStep={5}
        InputAdornmentProps={{ position: "end", style: { marginRight: -16 } }}
        format={xs ? "DD/MMM/Y hh:mm A z" : "Do MMMM Y hh:mm A z"}
        onChange={handleChange}
        onClick={handleClick}
        onClose={handleClose}
        clearable={clearable}
        clearLabel={clearLabel}
        showTodayButton={showTodayButton}
        fullWidth
        okLabel={okLabel}
        autoOk={autoOk}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

export { DateTimePicker }
