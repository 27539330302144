import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_ACTIONS_LOCATIONS_DETAIL = gql`
  fragment ReportActionsLocationsFields on ReportAction {
    id
    title
    timeZone
    location
    locationName
    processName
    processStepName
    priority
    dueAt
    status
    statusAt
    statusBy {
      id
      fullName
    }
    note
    createdAt
    createdBy {
      id
      fullName
    }
  }
`

const REPORT_ACTIONS_LOCATIONS_DETAIL_QUERY = gql`
  query ReportActionsLocationsDetail($input: ReportActionsLocationsDetailQueryInput!) {
    report {
      actions {
        locations {
          detail(input: $input) {
            ...ReportActionsLocationsFields
          }
        }
      }
    }
  }
  ${REPORT_ACTIONS_LOCATIONS_DETAIL}
`

const useQueryReportActionsLocationsDetail = (options) =>
  useQuery(REPORT_ACTIONS_LOCATIONS_DETAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportActionsLocationsDetail = (options) =>
  useLazyQuery(REPORT_ACTIONS_LOCATIONS_DETAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportActionsLocationsDetail, useLazyQueryReportActionsLocationsDetail }
