import React from "react"
import { DateRangeChipMenuItem, DateRangeValueText } from "../ChipMenu/DateRangeChipMenu"
import { FiltersChipMenuItem } from "./FiltersChipMenuItem"

const DateRangeFiltersChipMenuItem = ({ menuItemRef, value, onChange, onExpanded, ...props }) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue)
  }

  return (
    <FiltersChipMenuItem
      menuItemRef={menuItemRef}
      name="Date range"
      description={<DateRangeValueText value={value} />}
      onExpanded={onExpanded}
    >
      <DateRangeChipMenuItem value={value} onChange={handleChange} {...props} />
    </FiltersChipMenuItem>
  )
}

export { DateRangeFiltersChipMenuItem }
