import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@material-ui/core"
import React from "react"
import { NavLink } from "react-router-dom"
import { toId } from "../../utils"
import { DialogTitleCloser } from "../Creators"
import KnowledgePeekMention from "../Mentions/KnowledgePeekMention"
import { Icon } from "../Icon"

const TrainingModuleViewer = ({ module, onClose }) => {
  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle>
        {module.name}
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        {module.knowledges.length === 0 && (
          <Box pb={2}>
            This module does not contain any linked Knowledge Base articles. Add some through the Modules area.
          </Box>
        )}
        {module.knowledges.length > 0 && (
          <>
            <Box pb={2}>This module contains the following Knowledge Base articles:</Box>
            {module.knowledges.map((knowledge, index) => (
              <Box key={toId(knowledge)}>
                {index > 0 && <Divider />}
                <Box py={2}>
                  <KnowledgePeekMention id={toId(knowledge)}>{knowledge.title}</KnowledgePeekMention>
                </Box>
              </Box>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button component={NavLink} to={`/training/modules/${toId(module)}`}>
          <Box mr={0.5}>Edit in Modules</Box>
          <Icon name="forward" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { TrainingModuleViewer }
