import React, { useState, useEffect } from "react"
import { useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import { useParams, useHistory } from "react-router-dom"
import { PostList, NoItemsMessage } from "../../components"
import PostCreator from "../../components/PostCreator"
import { useLazyQueryPost, useMutationDeletePost } from "../../data"
import { toId, useMountEffect } from "../../utils"

const SinglePost = ({ onMenuClick }) => {
  const history = useHistory()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const [deletePost] = useMutationDeletePost()

  const [editPost, setEditPost] = useState(null)
  const [loadPost, postResult] = useLazyQueryPost()
  const [unsubscribes, setUnsubscribes] = useState(null)
  const { id, action } = useParams()

  const isSingleDeleted = id === "null"
  const baseUri = "/hub/post"

  useMountEffect(() => {
    if (isSingleDeleted) return
    loadPost({ variables: { id } })
  })

  useEffect(() => {
    if (!editPost && id && action === "edit" && postResult.data) setEditPost(postResult.data.post)
  }, [action, editPost, id, postResult.data])

  useEffect(() => {
    if (postResult.error) history.push("/hub/all")
  }, [history, postResult.error])

  useEffect(() => {
    if (editPost && action !== "edit") setEditPost(null)
  }, [action, editPost])

  useEffect(() => {
    const { called, subscribe } = postResult
    if (called && !unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [postResult, unsubscribes])

  const handleEditPost = (post) => {
    history.push(`${baseUri}/${toId(post)}/edit`)
  }
  const handleDeletePost = async ({ id: deleteId }) => {
    await deletePost({
      variables: {
        id: deleteId,
      },
    })
  }

  const handleCreatePostClose = () => {
    history.push(`${baseUri}/${id}`)
  }

  const handleToggleSubNav = (event) => {
    onMenuClick && onMenuClick(event)
  }

  const getTitle = () => {
    const post = postResult.data?.post
    return post ? `Post from ${post.author.fullName}` : postResult.loading ? "Post from..." : "Post not found"
  }

  return (
    <>
      {editPost && <PostCreator open onClose={handleCreatePostClose} isInline={xs} edit={editPost} />}

      {(!xs || (xs && !editPost)) && !isSingleDeleted && (
        <PostList
          title={getTitle()}
          posts={postResult.data?.post ? [postResult.data?.post] : []}
          {...postResult}
          onEdit={handleEditPost}
          onDelete={handleDeletePost}
          onOpenSubNav={handleToggleSubNav}
        />
      )}

      {isSingleDeleted && <NoItemsMessage>This post has been removed</NoItemsMessage>}
    </>
  )
}

export { SinglePost }
