import React from "react"
import { NavLink } from "react-router-dom"
import { List, ListItem, ListItemAvatar, ListItemText, ListItemIcon } from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { ErrorBoundary, LoadingSpinner } from ".."
import NotificationFrom from "./NotificationFrom"
import NotificationMessage from "./NotificationMessage"
import NotificationIcon from "./NotificationIcon"
import TimeAgo from "../TimeAgo"
import { NOTIFICATION_TYPE, useMutationReadNotification } from "../../data"
import { useMountAwareReactiveVar, useNotificationUtils } from "../../utils"
import { newDeviceClientAvailableVar } from "../../data/newDeviceClientAvailableVar"
import { NotificationUpdateVersion } from "./NotificationUpdateVersion"

const useStyles = makeStyles((theme) => ({
  empty: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderBottom: "1px solid #e9e9e9",
    "&:last-child": {
      borderBottom: "none",
    },
    "&:hover": {
      background: theme.palette.shaded.nav,
      outline: "none",
    },
    "&:focus": {
      background: theme.palette.shaded.nav,
      outline: "none",
    },
  },
  itemAvatar: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  itemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  itemMessage: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
    width: 215,
  },
  itemLinkIcon: {
    minWidth: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  age: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: "normal",
  },
  avatar: {
    width: 40,
    height: 40,
  },
}))

const NotificationList = ({ loading, error, data, onNavigate, limit = 10, spinnerSize = 30 }) => {
  const classes = useStyles()
  const [readNotifications] = useMutationReadNotification()
  const { getNavigateToLink } = useNotificationUtils()
  const hasNewDeviceClientVersion = useMountAwareReactiveVar(newDeviceClientAvailableVar)

  if (loading)
    return (
      <div style={{ margin: "0 50%" }}>
        <LoadingSpinner size={spinnerSize} />
      </div>
    )
  if (!data || (data && !data.notifications)) return null

  if (data && data.notifications && data.notifications.items && !data.notifications.items.length)
    return <p className={classes.empty}>You don't currently have any notifications</p>

  const handleClick = (notification) => {
    if (!notification.read) {
      readNotifications({
        variables: { ids: [notification.id] },
      })
    }
    if (onNavigate) onNavigate()
  }

  return (
    <ErrorBoundary>
      <List disablePadding>
        {hasNewDeviceClientVersion && <NotificationUpdateVersion classes={classes} />}
        {(limit ? [...data.notifications.items].slice(0, limit) : [...data.notifications.items])
          .sort((a, b) => (moment(b.createdAt).isBefore(a.createdAt) ? -1 : 1))
          .map((notification) => {
            const hasLink = notification.type !== NOTIFICATION_TYPE.GENERAL

            return (
              <ErrorBoundary key={notification.id}>
                <ListItem
                  className={classes.item}
                  alignItems="center"
                  component={hasLink ? NavLink : "div"}
                  to={{ pathname: getNavigateToLink(notification) }}
                  onClick={() => handleClick(notification)}
                >
                  <ListItemAvatar className={classes.itemAvatar}>
                    <NotificationFrom notification={notification} className={classes.avatar} />
                  </ListItemAvatar>
                  <ListItemIcon className={classes.itemIcon}>
                    <NotificationIcon {...notification} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.text}
                    primary={<NotificationMessage notification={notification} className={classes.itemMessage} />}
                    secondary={<TimeAgo date={notification.createdAt} className={classes.age} />}
                  />
                  {hasLink && (
                    <ListItemIcon className={classes.itemLinkIcon}>
                      <ChevronRightIcon />
                    </ListItemIcon>
                  )}
                </ListItem>
              </ErrorBoundary>
            )
          })}
      </List>
    </ErrorBoundary>
  )
}

export default NotificationList
