import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button } from "@material-ui/core"
import moment from "moment-timezone"
import { withRouter } from "react-router-dom"
import { Icon } from ".."
import { jobFiltersStateVar } from "../../data"
import { useMountAwareReactiveVar } from "../../utils"

const useStyles = makeStyles((theme) => ({
  count: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: "600",
    lineHeight: "20px",
    opacity: 0.5,
  },
  link: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "600",
  },
}))

const MoreJobsCount = withRouter(({ history, jobs, boxProps = {} }) => {
  const classes = useStyles()
  const store = useMountAwareReactiveVar(jobFiltersStateVar)

  const handleViewAllJobs = () => {
    jobFiltersStateVar({
      ...store,
      hideFuture: false,
    })
    history.push("/jobs")
  }

  if (!jobs) return null

  const futureJobsCount = jobs.list.filter((job) => !moment().isSameOrAfter(job.availableFrom)).length

  if (!futureJobsCount) return null

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="column" className={classes.root} {...boxProps}>
        <Box display="flex" className={classes.count} mb={2} alignItems="center">
          <Icon name="jobs-dark" />
          <Box ml={1.5}>
            There's {futureJobsCount} more job{futureJobsCount > 1 && "s"} planned today
          </Box>
        </Box>

        <Button className={classes.link} color="primary" onClick={handleViewAllJobs}>
          View all jobs
        </Button>
      </Box>
    </>
  )
})

export { MoreJobsCount }
