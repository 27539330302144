import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, MenuItem, Popover, Divider } from "@material-ui/core"
import { PeopleOutline } from "@material-ui/icons"
import PopupState, { anchorRef, bindTrigger, bindMenu } from "material-ui-popup-state"
import pluralize from "pluralize"
import { GroupOutlinedSelect, LoadingSpinner, LocationOutlinedSelect, OutlinedSelect } from ".."
import { useQueryAccreditations, useQueryGroups, useQueryTrainingCourses } from "../../data"
import { useAuth } from "../../services"

const useBulkActionsPopoverStyles = makeStyles((theme) => ({
  root: {
    width: 264,
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const BulkActionsButton = ({ enabled, action, params, itemCount, onActionChange, onParamsChange, onApply }) => {
  const bulkActionsPopoverClasses = useBulkActionsPopoverStyles()
  const { hasFeature, hasPermission } = useAuth()
  const { data: groupsData } = useQueryGroups()
  const { data: trainingCoursesData } = useQueryTrainingCourses()
  const { data: accreditationsData } = useQueryAccreditations()
  const [bulkActionBusy, setBulkActionBusy] = useState(false)

  const handleActionChange = (event) => {
    onActionChange(event)
  }

  const handleParamsChange = (event) => {
    onParamsChange(event)
  }

  const handleApply = async (popupState) => {
    setBulkActionBusy(true)
    await onApply()
    setBulkActionBusy(false)
    popupState.close()
  }

  const hasBulkAction =
    action &&
    (["active", "inactive", "invites"].includes(action) ||
      (["Locations", "Groups", "TrainingCourses", "Accreditations"].some((item) => action.endsWith(item)) &&
        params.length > 0))

  const hasGroups = Boolean(groupsData?.groups.length)
  const hasTraining = hasFeature("training") && Boolean(trainingCoursesData?.trainingCourses.length)
  const hasAccreditation = hasFeature("accreditation") && Boolean(accreditationsData?.accreditations.length)
  const hasUserReadAdminLocationsPermission = hasPermission("user_read_admin_locations")

  return (
    <PopupState variant="popover" popupId="bulk-actions">
      {(popupState) => (
        <>
          <Button
            variant="contained"
            color="primary"
            disabled={!enabled}
            {...bindTrigger(popupState)}
            ref={anchorRef(popupState)}
          >
            <PeopleOutline />
            <Box ml={1}>Bulk actions</Box>
          </Button>
          <Popover
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={bulkActionsPopoverClasses}
          >
            <Box p={2}>
              <OutlinedSelect
                native={false}
                label="Select action"
                onChange={handleActionChange}
                value={action}
                disabled={bulkActionBusy}
              >
                <MenuItem value="inactive">Make inactive</MenuItem>
                <MenuItem value="active">Make active</MenuItem>
                <MenuItem value="invites">Resend invite</MenuItem>
                <Divider />
                <MenuItem value="addLocations">Add locations</MenuItem>
                <MenuItem value="removeLocations">Remove locations</MenuItem>
                {hasGroups && <Divider />}
                {hasGroups && <MenuItem value="addGroups">Add areas of work</MenuItem>}
                {hasGroups && <MenuItem value="removeGroups">Remove areas of work</MenuItem>}
                {hasTraining && <Divider />}
                {hasTraining && <MenuItem value="addTrainingCourses">Add training courses</MenuItem>}
                {hasAccreditation && <Divider />}
                {hasAccreditation && <MenuItem value="addAccreditations">Add accreditation types</MenuItem>}
                {hasAccreditation && <MenuItem value="removeAccreditations">Remove accreditation types</MenuItem>}
              </OutlinedSelect>
              {action.endsWith("Locations") && (
                <Box mt={2}>
                  <LocationOutlinedSelect
                    label="Locations"
                    mode={hasUserReadAdminLocationsPermission ? "user" : "admin"}
                    value={params}
                    onChange={handleParamsChange}
                    multiple
                    hasAll={false}
                    disabled={bulkActionBusy}
                  />
                </Box>
              )}
              {action.endsWith("Groups") && (
                <Box mt={2}>
                  <GroupOutlinedSelect
                    label="Areas of work"
                    value={params}
                    onChange={handleParamsChange}
                    multiple
                    hasAll={false}
                    disabled={bulkActionBusy}
                  />
                </Box>
              )}
              {action.endsWith("TrainingCourses") && (
                <Box mt={2}>
                  <OutlinedSelect
                    label="Training courses"
                    native={false}
                    value={params}
                    onChange={handleParamsChange}
                    multiple
                    disabled={bulkActionBusy}
                  >
                    {trainingCoursesData?.trainingCourses.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </OutlinedSelect>
                </Box>
              )}
              {action.endsWith("Accreditations") && (
                <Box mt={2}>
                  <OutlinedSelect
                    label="Accreditations"
                    native={false}
                    value={params}
                    onChange={handleParamsChange}
                    multiple
                    disabled={bulkActionBusy}
                  >
                    {accreditationsData?.accreditations.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </OutlinedSelect>
                </Box>
              )}
              {hasBulkAction && (
                <Box mt={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={bulkActionBusy}
                    onClick={() => handleApply(popupState)}
                  >
                    {!bulkActionBusy && (
                      <>
                        Apply ({itemCount} {pluralize("person", itemCount)})
                      </>
                    )}
                    {bulkActionBusy && <LoadingSpinner size="24px" delay={false} />}
                  </Button>
                </Box>
              )}
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  )
}

export { BulkActionsButton }
