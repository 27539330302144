import React, { useState } from "react"
import { Box, Chip, Tooltip, useTheme } from "@material-ui/core"
import fileDownload from "js-file-download"
import axios from "axios"
import { Icon, LoadingSpinner, RowBox, useSnackbar } from ".."
import { mapToIds } from "../../utils"
import { useAuth } from "../../services"
import { useLazyQueryReportJobExportAttachments } from "../../data/report/useQueryReportJobExportAttachments"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"

const ExportJobAttachmentsButton = ({ variant = "chip", gt, lt, process, locations, disabled, ...props }) => {
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const [downloading, setDownloading] = useState(false)
  const theme = useTheme()
  const [load, { loading }] = useLazyQueryReportJobExportAttachments()
  const snackbar = useSnackbar()

  const busyChipProps = { style: { backgroundColor: theme.palette.scheduled.main } }

  const handleClick = async () => {
    setDownloading(true)
    try {
      snackbar.showMessage({
        message: "Exporting photos, please wait",
        icon: <Icon name="zip" />,
        chipProps: busyChipProps,
        autoHideDuration: 30000,
      })

      const result = await load({
        variables: {
          process,
          locations: locations.includes("all") ? mapToIds(userLocations) : mapToIds(locations),
          gt,
          lt,
        },
      })

      if (!result?.data) {
        snackbar.showMessage({ message: "Unknown error exporting photos" })
        setDownloading(false)
        return
      }

      const data = result.data.reportJobExportAttachments

      if (!data.exportUrl) {
        snackbar.showMessage({ message: data.message || "Unknown error exporting photos" })
        setDownloading(false)
        return
      }

      const busySnackbarConfig = {
        message: "Downloading photos, please wait",
        icon: <Icon name="download" />,
        chipProps: busyChipProps,
        autoHideDuration: 60000 * 10,
      }

      snackbar.showMessage(busySnackbarConfig)

      const response = await axios({
        url: data.exportUrl,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          snackbar.showMessage({
            ...busySnackbarConfig,
            message: `Downloading photos (${percentCompleted}%)`,
          })
        },
      })

      fileDownload(response.data, data.fileName)

      snackbar.showMessage({ message: "Download ready", icon: <Icon name="download" />, autoHideDuration: 5000 })

      setDownloading(false)
    } catch (err) {
      setDownloading(false)
    }
  }

  if (variant === "icon") {
    return (
      <ReportToolsDownloadDataIconButton
        icon="download-photos"
        title="Download photos"
        onClick={handleClick}
        loading={downloading}
        disabled={disabled || downloading}
        {...props}
      />
    )
  }

  return (
    <Tooltip title="Download all photos">
      <Chip
        label={
          <RowBox>
            {!downloading && <Icon name="download-photos" fontSize="small" />}
            {downloading && <LoadingSpinner size="24px" delay={false} />}
            <Box ml={1}>
              {!downloading && <>Download</>}
              {downloading && loading && <>Exporting</>}
              {downloading && !loading && <>Downloading</>}
            </Box>
          </RowBox>
        }
        onClick={handleClick}
        disabled={disabled || downloading}
        {...props}
      />
    </Tooltip>
  )
}

export default ExportJobAttachmentsButton
