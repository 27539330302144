import { gql, useMutation } from "@apollo/client"
import { EVENT_TRIGGER_FIELDS } from "./useQueryTriggers"

const CREATE_EVENT_TRIGGER = gql`
  mutation CreateEventTrigger($input: EventTriggerInput!) {
    createEventTrigger(input: $input) {
      ...EventTriggerFields
    }
  }
  ${EVENT_TRIGGER_FIELDS}
`

const UPDATE_EVENT_TRIGGER = gql`
  mutation UpdateEventTrigger($id: ID!, $input: EventTriggerInput!) {
    eventTrigger(id: $id) {
      update(input: $input) {
        ...EventTriggerFields
      }
    }
  }
  ${EVENT_TRIGGER_FIELDS}
`

const useMutationCreateEventTrigger = (options) => useMutation(CREATE_EVENT_TRIGGER, options)

const useMutationUpdateEventTrigger = (options) => useMutation(UPDATE_EVENT_TRIGGER, options)

export { useMutationCreateEventTrigger, useMutationUpdateEventTrigger }
