import React from "react"
import { TextField, Link, makeStyles } from "@material-ui/core"
import Config from "react-global-configuration"

const useStyles = makeStyles(() => ({
  contentField: {
    backgroundColor: "#ffffff",
  },
}))

const CreatorContentText = ({ content, onUpdateContent }) => {
  const classes = useStyles()
  const configLinks = Config.get("links")

  const handleChange = (e) => {
    onUpdateContent("text", e.target.value)
  }

  return (
    <TextField
      variant="outlined"
      value={content.text}
      fullWidth
      size="small"
      label="Text"
      multiline
      minRows={10}
      onChange={(e) => handleChange(e)}
      className={classes.contentField}
      data-cy="CreatorContent-TextField-text"
      helperText={
        <>
          View the{" "}
          <Link
            href={`${configLinks.help}/article/33-styling-formatting-text-content-using-markdown`}
            target="_blank"
            rel="noopener"
            underline="none"
          >
            formatting guide
          </Link>
        </>
      }
    />
  )
}

export { CreatorContentText }
