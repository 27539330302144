import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/styles"
import { TextField, Box, Avatar, Icon, Grid } from "@material-ui/core"
import { useMutationCreateCategory, useMutationUpdateCategory } from "../../data"
import { mapToIds, multipleSelectChange, useFormUtils } from "../../utils"
import IconPicker from "../IconPicker/IconPicker"
import { CreatorActions, CreatorMaster } from "../Creators"
import { LocationOutlinedSelect, GroupOutlinedSelect } from "../OutlinedSelect"
import { useAuth } from "../../services"
import TagsField from "../TextField/TagsField"

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  actions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    fontSize: 14,
  },
  buttonText: {
    fontWeight: "600",
    fontSize: 14,
  },
}))

const initialState = {
  title: "",
  description: "",
  icon: "category",
  locations: ["all"],
  groups: ["all"],
  tags: [],
}

const KnowledgeCategoryCreator = ({ open, onClose, edit, isInline }) => {
  const classes = useStyles()
  const { isValid } = useFormUtils()
  const { hasFeature } = useAuth()
  const [createCategory, { loading: createCategoryLoading }] = useMutationCreateCategory()
  const [updateCategory, { loading: updateCategoryLoading }] = useMutationUpdateCategory()

  const [name, setName] = useState(initialState.name)
  const [description, setDescription] = useState(initialState.description)
  const [groups, setGroups] = useState(initialState.groups)
  const [locations, setLocations] = useState(initialState.locations)
  const [icon, setIcon] = useState(initialState.icon)
  const [tags, setTags] = useState(initialState.tags)

  const isEdit = edit != null

  useEffect(() => {
    if (edit) {
      setName(edit.name)
      setTags(
        edit?.tags.map((tag) => ({
          id: tag.id,
          name: tag.name,
        })) ?? []
      )
      setDescription(edit.description)
      setGroups(edit.groups.length ? mapToIds(edit.groups) : ["all"])
      setLocations(edit.locations.length ? mapToIds(edit.locations) : ["all"])
      setIcon(edit.icon)
    }
  }, [edit, setName, setDescription, setIcon, setTags])

  const handleOnClose = () => {
    handleResetState()
    if (onClose) onClose(true)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (formValid()) {
      const variables = {
        name,
        description,
        icon,
        tags: mapToIds(tags),
        locations: locations.includes("all") ? [] : mapToIds(locations),
        groups: groups.includes("all") ? [] : mapToIds(groups),
      }
      if (isEdit) {
        await updateCategory({ variables: { id: edit.id, ...variables } })
      } else {
        await createCategory({ variables })
      }
      handleOnClose(event)
    }
  }

  const handleLocationsChanged = (event) => {
    setLocations([...multipleSelectChange(locations, event)])
  }

  const handleRegionChange = (regionLocations) => {
    setLocations([...mapToIds(regionLocations)])
  }

  const handleGroupsChanged = (event) => {
    setGroups([...multipleSelectChange(groups, event)])
  }

  const handleTagChanged = (options) => {
    setTags(options)
  }

  const handleResetState = () => {
    setName(initialState.name)
    setDescription(initialState.description)
    setTags(initialState.tags)
  }

  const formValid = () => isValid(name) && isValid(description)

  const isFormValid = formValid()

  const hasLocationsAndGroups = hasFeature("knowledge_access")

  const hasAreas = hasFeature("areas")

  const form = (
    <>
      <Box display="flex" flexDirection="row" mb={2} alignItems="center">
        <Box flexGrow={1}>
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
            required
          />
        </Box>
        <Box>
          <IconPicker value={icon} onChange={setIcon} selectText={icon ? "Change icon" : "Select icon"}>
            <Avatar className={classes.avatar}>
              <Icon>{icon}</Icon>
            </Avatar>
          </IconPicker>
        </Box>
      </Box>
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          id="name"
          label="Description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          minRows={4}
          required
        />
      </Box>

      {hasLocationsAndGroups && (
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <LocationOutlinedSelect
                value={locations}
                onChange={handleLocationsChanged}
                onRegionChange={handleRegionChange}
                multiple
                data-cy="KnowledgeCategoryCreator-locations"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <GroupOutlinedSelect
                value={groups}
                onChange={handleGroupsChanged}
                multiple
                data-cy="KnowledgeCategoryCreator-groups"
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {hasAreas && (
        <Box my={2}>
          <TagsField value={tags ?? []} label="Tags" onChange={(_, options) => handleTagChanged(options)} />
        </Box>
      )}

      <CreatorActions
        subject="Category"
        submitLoading={createCategoryLoading || updateCategoryLoading}
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid}
      />
    </>
  )

  return (
    <CreatorMaster
      open={open}
      subject="Category"
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleOnClose}
    />
  )
}

export { KnowledgeCategoryCreator }
