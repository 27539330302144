import React, { useMemo } from "react"
import { TableCell, TableRow, makeStyles } from "@material-ui/core"
import moment from "moment"
import { toId, useWindowSize } from "../../utils"
import { FlexBox } from "../Boxes"
import { PersonTrainingCourseStatus } from "../Training/PersonTrainingCourseStatus"
import { NoItemsMessage } from "../Lists"
import { UserTableRowCell } from "../UserPanel/UserTableRowCell"
import { TableVirtualizer } from "../Lists/TableVirtualizer"
import { ErrorBoundary } from "../ErrorBoundary"

const useStyles = makeStyles(() => ({
  tableCellSticky: {
    position: "sticky",
    left: 0,
    backgroundColor: "white",
    zIndex: 999,
  },
  status: {
    maxWidth: 80,
  },
}))

const UserTrainingCoursesDataTable = ({ data, fullscreen, fetchMore, filter }) => {
  const classes = useStyles({ fullscreen })
  const { height: windowHeight } = useWindowSize()

  const { list: users, courses } = data || {}

  const renderTableCell = (item, user) => (
    <ErrorBoundary>
      <TableCell
        style={{ width: 150, background: "white", position: "sticky", left: 0, zIndex: 1 }}
        key={`${item.key}-name`}
      >
        <UserTableRowCell user={user} />
      </TableCell>
      {courses.map((course) => {
        const userCourse = [...user.training.courses]
          .sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1))
          .find((userTraining) => toId(userTraining.course) === toId(course))

        return (
          <TableCell align="center" key={`${item.key}-${toId(course)}`}>
            <FlexBox justifyContent="center" flexGrow={1}>
              <FlexBox className={classes.status} justifySelf="center">
                <PersonTrainingCourseStatus course={userCourse} />
              </FlexBox>
            </FlexBox>
          </TableCell>
        )
      })}
    </ErrorBoundary>
  )

  const tableHeight = useMemo(() => (windowHeight || 1280) - (fullscreen ? 150 : 489), [fullscreen, windowHeight])

  const handleLoadMore = async () => {
    await fetchMore({
      variables: {
        filter,
        offset: users.length,
        limit: 10,
      },
    })
  }

  if (!data) {
    return null
  }

  return (
    <>
      <TableVirtualizer
        items={users}
        endReached={handleLoadMore}
        style={{ height: tableHeight }}
        fixedHeaderContent={() => (
          <TableRow>
            <TableCell style={{ width: 150, background: "white", position: "sticky", left: 0, zIndex: 2 }}>
              Name
            </TableCell>
            {courses.map((course) => (
              <TableCell key={toId(course)} align="center" style={{ whiteSpace: "nowrap" }}>
                {course.name}
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(item, user) => {
          return <ErrorBoundary key={toId(user)}>{renderTableCell(item, user)}</ErrorBoundary>
        }}
      />
      {users && !users.length && <NoItemsMessage>No matching items</NoItemsMessage>}
    </>
  )
}

export { UserTrainingCoursesDataTable }
