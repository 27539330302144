import { useApolloClient } from "@apollo/client"
import { Box, Container, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { useParams } from "react-router"
import { LoadingSpinner, LogoFull, PaperBox } from "../../components"
import { useAuth } from "../../services"
import { useMountEffect } from "../../utils"
import { PublicContainer } from "../Master"

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 300,
    padding: 0,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}))

const LoginExternal = () => {
  const classes = useStyles()
  const { loginWithExternal } = useAuth(useApolloClient())
  const { id } = useParams()
  const [error, setError] = useState()

  useMountEffect(() => {
    const handleLoginWithExternal = async () => {
      try {
        await loginWithExternal(id)
      } catch (err) {
        setError(err)
      }
    }
    handleLoginWithExternal()
  })

  return (
    <PublicContainer>
      <PaperBox p={5}>
        <Container component="main" className={classes.root}>
          <div className={classes.wrapper}>
            <Box mb={2}>
              <LogoFull width="197" />
            </Box>
            <Typography paragraph align="center">
              <strong>Signing in</strong>
            </Typography>
            {!error && <LoadingSpinner size={60} />}
            {error && (
              <>
                An error occurred while trying to sign you in:
                <br />
                <em>{error.message}</em>
              </>
            )}
          </div>
        </Container>
      </PaperBox>
    </PublicContainer>
  )
}

export { LoginExternal }
