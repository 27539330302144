import { useQuery, gql } from "@apollo/client"

import { AREA_FIELDS } from "./fragments/areaFieldsFragment"
import { useAuth } from "../../services"

export const AREAS_QUERY = gql`
  query Areas($filter: AreaFilterInput) {
    areas {
      list(filter: $filter) {
        ...AreaFields
      }
    }
  }
  ${AREA_FIELDS}
`

const areasTypePolicies = {
  scoped: {
    AreasQuery: {
      merge(_, next) {
        return { ...next }
      },
    },
  },
}

const useQueryAreas = (options) => {
  const { hasPermission, hasFeature } = useAuth()

  const skip = !hasPermission("area_read") || !hasFeature("areas") || options?.skip

  return useQuery(AREAS_QUERY, {
    skip,
    ...(options || {}),
  })
}

export { useQueryAreas, AREA_FIELDS, areasTypePolicies }
