import React, { useState } from "react"
import { Box, Button } from "@material-ui/core"
import {
  NoItemsMessage,
  SearchInput,
  IdentityProviderCreator,
  IdentityProviderActionCard,
  Icon,
} from "../../components"
import { useQueryIdentityProviders } from "../../data"
import { toId } from "../../utils"
import SettingsFooter from "../../components/Settings/SettingsFooter"

const IdentityProviders = () => {
  const { data, refetch } = useQueryIdentityProviders()

  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(null)
  const [searchText, setSearchText] = useState("")

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleCreate = () => {
    setOpen(true)
  }
  const handleCreateClose = () => {
    setOpen(false)
    setEdit(null)
    refetch()
  }
  const handleEdit = (editDevice) => {
    setEdit(editDevice)
    setOpen(true)
  }

  const hasFilter = data?.identityProviders.length > 1

  return (
    <>
      {open && <IdentityProviderCreator open={open} onClose={handleCreateClose} edit={edit} />}

      {hasFilter && <SearchInput placeholder="Filter" boxProps={{ pl: 0, pr: 0 }} onChange={handleFilter} />}

      <Box mb={5}>
        {data &&
          data.identityProviders
            .filter((item) => !searchText || JSON.stringify(item).toLowerCase().includes(searchText))
            .map((identityProvider) => (
              <IdentityProviderActionCard
                key={toId(identityProvider)}
                identityProvider={identityProvider}
                onEdit={handleEdit}
              />
            ))}
        {data?.identityProviders.length === 0 && <NoItemsMessage>No identity providers registered</NoItemsMessage>}
      </Box>

      <SettingsFooter>
        <Button variant="contained" color="primary" onClick={handleCreate} data-cy="Button-create-identityprovider">
          <Icon name="identity-provider" /> <Box ml={1}>Add identity provider</Box>
        </Button>
      </SettingsFooter>
    </>
  )
}

export default IdentityProviders
