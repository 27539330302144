import React, { useRef } from "react"
import { Divider } from "@material-ui/core"
import { DateTimePicker } from "../DateTimePickers"
import { RowBox } from "../Boxes"
import { ProcessCollapse } from "./ProcessCollapse"

const DateTimeStep = ({ expand, value, onChange, isScheduled, classes, collapseTimeout = "auto" }) => {
  const inputRef = useRef()

  const handleChange = (date) => {
    onChange && onChange(date)
    setTimeout(() => {
      try {
        inputRef.current.blur()
      } catch (error) {
        // ignore
      }
    })
  }

  return (
    <ProcessCollapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />
      <RowBox p={2}>
        <DateTimePicker
          value={value || null}
          placeholder="Select date and time"
          onChange={handleChange}
          disabled={isScheduled}
          clearable
          clearLabel="Clear"
          inputRef={inputRef}
          showTodayButton
          todayLabel="Now"
          cancelLabel=""
          okLabel="OK"
          autoOk={false}
          minutesStep={1}
        />
      </RowBox>
    </ProcessCollapse>
  )
}

export { DateTimeStep }
