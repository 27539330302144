import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { EVENT_TRIGGER_FIELDS, SCHEDULE_TRIGGER_FIELDS } from "./useQueryTriggers"

const TRIGGER_QUERY = gql`
  query Trigger($id: ID!) {
    trigger(id: $id) {
      ... on ScheduleTrigger {
        ...ScheduleTriggerFields
      }
      ... on EventTrigger {
        ...EventTriggerFields
      }
    }
  }
  ${SCHEDULE_TRIGGER_FIELDS}
  ${EVENT_TRIGGER_FIELDS}
`

const useQueryTrigger = (options) => useQuery(TRIGGER_QUERY, options)

const useLazyQueryTrigger = (options) => useLazyQuery(TRIGGER_QUERY, options)

export { useQueryTrigger, useLazyQueryTrigger }
