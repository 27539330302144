import React, { useState } from "react"
import { Box, Button } from "@material-ui/core"
import { AddLocationOutlined } from "@material-ui/icons"
import { useParams, useHistory } from "react-router-dom"
import { RequirePermissions, NoItemsMessage, GroupCreator, LoadingSpinner, SearchInput } from "../../components"
import { useMutationDeleteGroup, useQueryAdminableGroups } from "../../data"
import { toId } from "../../utils"
import SettingsFooter from "../../components/Settings/SettingsFooter"
import { GroupActionCard } from "../../components/Groups/GroupActionCard"
import { ListWindowVirtualizer } from "../../components/Lists/ListWindowVirtualizer"

const baseUri = "/account/groups"

const Groups = () => {
  const { id } = useParams()
  const history = useHistory()
  const { data, refetch, loading } = useQueryAdminableGroups()
  const [deleteGroup] = useMutationDeleteGroup()
  const [searchText, setSearchText] = useState("")

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleCreate = () => {
    history.push(`${baseUri}/new`)
  }
  const handleClose = () => {
    refetch()
    history.push(baseUri)
  }

  const handleEdit = (editGroup) => {
    history.push(`${baseUri}/${toId(editGroup)}`)
  }

  const handleDelete = async (group) => {
    await deleteGroup({
      variables: {
        id: toId(group),
      },
    })
    refetch()
  }

  const hasFilter = data?.groups.length > 5

  const items = data?.groups.filter((item) => !searchText || item.name.toLowerCase().includes(searchText))

  const open = Boolean(id)

  return (
    <>
      <GroupCreator key={id} open={open} id={id} onClose={handleClose} />

      {hasFilter && <SearchInput placeholder="Filter" boxProps={{ pl: 0, pr: 0 }} onChange={handleFilter} />}

      <Box pb={5} data-cy="Groups-list">
        <ListWindowVirtualizer
          items={items}
          itemContent={(_, group) => (
            <GroupActionCard key={toId(group)} group={group} onEdit={handleEdit} onDelete={handleDelete} />
          )}
          components={{
            Footer: () => <Box my={10}>&nbsp;</Box>,
          }}
        />

        {!data && loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}

        {data?.groups.length === 0 && <NoItemsMessage>No areas of work found</NoItemsMessage>}
      </Box>

      <RequirePermissions requires="group_create">
        <SettingsFooter>
          <Button variant="contained" color="primary" onClick={handleCreate} data-cy="Button-create-group">
            <AddLocationOutlined /> <Box ml={1}>Add area of work</Box>
          </Button>
        </SettingsFooter>
      </RequirePermissions>
    </>
  )
}

export default Groups
