import React, { useEffect, useRef, useState } from "react"
import pluralize from "pluralize"
import { ActionPriorityOutlinedSelect } from "../OutlinedSelect/ActionPriorityOutlinedSelect"
import { ChipMenu } from "./ChipMenu"
import { ChipMenuItem } from "./ChipMenuItem"
import { ChipMenuActions } from "./ChipMenuActions"
import { multipleSelectChange } from "../../utils"

const ActionPriorityValueText = ({ value }) => {
  if (!value) {
    return "Priority"
  }

  if (value === "all" || (Array.isArray(value) && value.includes("all"))) {
    return "All priorities"
  }

  const count = Array.isArray(value) ? value.length : 1

  return `${pluralize("priority", count, true)}`
}

const ActionPriorityChipMenuItem = ({ value, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange && onChange(multipleSelectChange(value, event.target.value))
  }

  return (
    <ChipMenuItem>
      <ActionPriorityOutlinedSelect value={value} onChange={handleChange} {...props} hasAll multiple />
    </ChipMenuItem>
  )
}

const ActionPriorityChipMenu = ({ value, onChange, ...props }) => {
  const [nextValue, setNextValue] = useState(value)
  const menuRef = useRef()

  useEffect(() => {
    setNextValue(value)
  }, [value])

  const handleChange = (newValue) => {
    setNextValue(newValue)
  }

  const handleApply = () => {
    menuRef.current?.close()
    onChange && onChange(nextValue)
  }

  return (
    <ChipMenu menuRef={menuRef} text={<ActionPriorityValueText value={value} />}>
      <ActionPriorityChipMenuItem value={nextValue} onChange={handleChange} {...props} />
      <ChipMenuActions onApply={handleApply} />
    </ChipMenu>
  )
}

export { ActionPriorityChipMenu, ActionPriorityChipMenuItem, ActionPriorityValueText }
