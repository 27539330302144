import { useQuery, gql } from "@apollo/client"
import { REPORT_JOB_COMPLETIONS_FIELDS } from "./fragments/reportJobCompletionsFieldsFragment"

const REPORT_PROCESSES_JOBS_COMPLETIONS_LIST_QUERY = gql`
  query ReportProcessesJobsCompletionsList(
    $filter: ReportProcessesJobsCompletionsFilterInput!
    $offset: Int
    $limit: Int
  ) {
    report: reportV2 {
      processes {
        jobs {
          completions(filter: $filter) {
            list(offset: $offset, limit: $limit) {
              id
              name
              tags
              completions {
                ...ReportJobCompletionsFields
              }
            }
          }
        }
      }
    }
  }
  ${REPORT_JOB_COMPLETIONS_FIELDS}
`

const REPORT_PROCESSES_JOBS_COMPLETIONS_COUNT_QUERY = gql`
  query ReportProcessesJobsCompletionsCount($filter: ReportProcessesJobsCompletionsFilterInput!) {
    report: reportV2 {
      processes {
        jobs {
          completions(filter: $filter) {
            count
          }
        }
      }
    }
  }
`

const useQueryReportProcessesJobsCompletionsList = (options) =>
  useQuery(REPORT_PROCESSES_JOBS_COMPLETIONS_LIST_QUERY, options)

const useQueryReportProcessesJobsCompletionsCount = (options) =>
  useQuery(REPORT_PROCESSES_JOBS_COMPLETIONS_COUNT_QUERY, options)

export { useQueryReportProcessesJobsCompletionsList, useQueryReportProcessesJobsCompletionsCount }
