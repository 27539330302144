import React from "react"
import { DeleteOutline } from "@material-ui/icons"
import { makeStyles, TextField, Box, Grid } from "@material-ui/core"
import { ExpansionPanel, KnowledgeContentTypeIcon, RemoveIconButton } from "../.."
import { KNOWLEDGE_CONTENT_TYPENAMES, KNOWLEDGE_CONTENT_TYPENAME_LABELS } from "../../../data"
import { CreatorContentFile } from "./CreatorContentFile"
import { CreatorContentImage } from "./CreatorContentImage"
import { CreatorContentMultiChoice } from "./CreatorContentMultiChoice"
import { CreatorContentNote } from "./CreatorContentNote"
import { CreatorContentText } from "./CreatorContentText"
import { CreatorContentVideo } from "./CreatorContentVideo"

const useStyles = makeStyles((theme) => ({
  contentTypeIcon: {
    color: theme.palette.text.secondary,
  },
  content: {
    border: `1px solid ${theme.palette.shaded.border}`,
    borderRadius: 4,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  contentField: {
    backgroundColor: "#ffffff",
  },
  hint: {
    color: theme.palette.text.secondary,
  },
}))

const CreatorContent = ({ contentIndex: index, content, onToggleExpanded, onUpdateContent, onRemoveContent }) => {
  const classes = useStyles()

  // This should be removed once we're totally off the V1 knowledge base
  // articles. It only exists so we can continue to render the current items.
  if (content.__typename === "KnowledgeContent") {
    console.warn(
      `Detected deprecated KnowledgeContent type, was expecting one of ${JSON.stringify(
        KNOWLEDGE_CONTENT_TYPENAMES
      )} but got: ${JSON.stringify(content.__typename)}. Rendering will be skipped`
    )
  }

  return (
    <ExpansionPanel
      {...{
        icon: (
          <KnowledgeContentTypeIcon
            type={content.__typename}
            subType={content.type}
            fontSize="large"
            className={classes.contentTypeIcon}
          />
        ),
        title: content.heading,
        description: KNOWLEDGE_CONTENT_TYPENAME_LABELS[content.__typename],
        sortable: true,
        expanded: content.expanded,
        onChange: () => onToggleExpanded(index),
        className: classes.content,
        placeholders: { title: "Untitled" },
        TransitionProps: { unmountOnExit: true },
      }}
    >
      <Box flexDirection="column" flexGrow={1} pl={3} pr={3}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth
              label="Heading"
              value={content.heading}
              className={classes.contentField}
              onChange={(e) => onUpdateContent(index, "heading", e.target.value)}
              data-cy="CreatorContent-TextField-heading"
            />
          </Grid>
          <Grid item>
            {content.__typename === KNOWLEDGE_CONTENT_TYPENAMES.TEXT && (
              <CreatorContentText content={content} onUpdateContent={(...args) => onUpdateContent(index, ...args)} />
            )}
            {content.__typename === KNOWLEDGE_CONTENT_TYPENAMES.IMAGE && (
              <CreatorContentImage content={content} onUpdateContent={(...args) => onUpdateContent(index, ...args)} />
            )}
            {content.__typename === KNOWLEDGE_CONTENT_TYPENAMES.NOTE && (
              <CreatorContentNote content={content} onUpdateContent={(...args) => onUpdateContent(index, ...args)} />
            )}
            {content.__typename === KNOWLEDGE_CONTENT_TYPENAMES.FILES && (
              <CreatorContentFile content={content} onUpdateContent={(...args) => onUpdateContent(index, ...args)} />
            )}
            {content.__typename === KNOWLEDGE_CONTENT_TYPENAMES.VIDEO && (
              <CreatorContentVideo content={content} onUpdateContent={(...args) => onUpdateContent(index, ...args)} />
            )}
            {content.__typename === KNOWLEDGE_CONTENT_TYPENAMES.MULTICHOICE && (
              <CreatorContentMultiChoice
                content={content}
                onUpdateContent={(...args) => onUpdateContent(index, ...args)}
              />
            )}
          </Grid>
        </Grid>
        <Box mt={1} display="flex">
          <Box ml="auto">
            <RemoveIconButton onConfirmedClick={() => onRemoveContent(index)} subject="content">
              <DeleteOutline />
            </RemoveIconButton>
          </Box>
        </Box>
      </Box>
    </ExpansionPanel>
  )
}

export { CreatorContent }
