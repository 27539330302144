import { Chip, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useState } from "react"
import { useFormUtils } from "../../utils"

const TagsTextField = ({
  label = "Email Address(es)",
  placeholder = "Type address then press enter...",
  addHelperText = "Press enter / return to add the address",
  inputProps: inputPropsIn,
  onChange,
  ...props
}) => {
  const inputProps = { label, placeholder, ...inputPropsIn }
  const { validate } = useFormUtils()
  const [showHelperText, setShowHelperText] = useState(false)
  const [validEmail, setValidEmail] = useState(false)

  const handleChange = (event, option) => {
    onChange && onChange(event, option)
  }

  const helperText = validEmail ? addHelperText : "Please enter a valid email address"

  return (
    <Autocomplete
      options={[]}
      freeSolo
      multiple
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option}
            color={validate.requiredEmail(option) ? "default" : "secondary"}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} variant="outlined" {...inputProps} helperText={showHelperText ? helperText : ""} />
      )}
      onChange={handleChange}
      onInputChange={(_, newValue) => {
        setShowHelperText(newValue.length > 0)
        setValidEmail(validate.requiredEmail(newValue))
      }}
      {...props}
    />
  )
}

export default TagsTextField
