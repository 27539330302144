import React, { useState } from "react"
import { Box, MenuItem, Divider, Grid, TextField, IconButton, Button, makeStyles, Input, Icon } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { CodeOutlined, DeleteOutline, Add } from "@material-ui/icons"
import Validator from "@impelsys/validatorjs"
import { CreatorActions, CreatorMaster } from "../Creators"
import { useFormUtils } from "../../utils"
import { FieldSectionHeading } from "../Headings"
import { useQueryLocations, useMutationCreateUser, useQueryGroups, useMutationCreateExternalUser } from "../../data"
import { LocationOutlinedSelect, OutlinedSelect } from "../OutlinedSelect"
import Checkbox from "../Checkbox"
import { useSnackbar } from "../SnackbarProvider"
import { GroupCreator, RowBox, IdentityProviderOutlinedSelect } from ".."
import { PersonCreatorExternalSwitcher } from "."
import { useAuth } from "../../services"
import { RoleOutlinedSelect } from "../OutlinedSelect/RoleOutlinedSelect"

const useStyles = makeStyles((theme) => ({
  userRow: {
    marginBottom: theme.spacing(1),
  },
}))

const initialUser = { firstName: "", lastName: "", email: "", emailTouched: false, emailValid: false }

const MultiplePersonCreator = ({ onClose, isInline }) => {
  const classes = useStyles()
  const { isValid } = useFormUtils()
  const { hasFeature } = useAuth()
  const snackbar = useSnackbar()

  const [createUser, { loading: createUserLoading }] = useMutationCreateUser()
  const [createExternalUser, { loading: createExternalUserLoading }] = useMutationCreateExternalUser()
  const { data: groupsData, refetch: refetchGroups } = useQueryGroups()
  const { data: locationsData } = useQueryLocations()

  const loading = !locationsData
  const [external, setExternal] = useState(false)
  const [identityProvider, setIdentityProvider] = useState("")
  const [groups, setGroups] = useState([])
  const [locations, setLocations] = useState([])
  const [roles, setRoles] = useState([])
  const [users, setUsers] = useState([{ ...initialUser }, { ...initialUser }, { ...initialUser }])
  const [invite, setInvite] = useState(true)

  const [openCreateGroup, setOpenCreateGroup] = useState(false)

  const handleOnClose = (event, isCancel) => {
    onClose(isCancel)
  }

  const handleIdentityProviderChange = (event) => {
    setIdentityProvider(event.target.value)
  }

  const handleChange = (index, propName, newValue) => {
    setUsers((prev) => {
      prev[index][propName] = newValue
      return [...prev]
    })
  }

  const handleEmailChange = (index, newValue) => {
    setUsers((prev) => {
      prev[index].email = newValue
      prev[index].emailValid = new Validator({ value: newValue }, { value: "required|email" }).passes()
      return [...prev]
    })
  }

  const handleEmailTouched = (index) => {
    setUsers((prev) => {
      prev[index].emailTouched = true
      return [...prev]
    })
  }

  const handleChangeMultiple = (set, value) => {
    set(value.filter((item) => item !== "new"))
  }

  const handleRolesChange = (event) => {
    setRoles(event.target.value)
  }

  const handleAdd = () => {
    setUsers([...users, { ...initialUser }])
  }

  const handleDelete = (index) => {
    setUsers((prev) => {
      prev.splice(index, 1)
      return [...prev]
    })
  }

  const handleSubmit = async () => {
    if (formValid()) {
      const enteredUsers = users.filter((user) => user.firstName)
      const remainingUsers = [...enteredUsers]
      for (const user of enteredUsers) {
        let result
        if (external) {
          result = await createExternalUser({
            variables: {
              input: {
                identityProvider,
                unique: user.unique,
                firstName: user.firstName,
                lastName: user.lastName,
                groups,
                locations,
                roles,
              },
            },
          })
        } else {
          result = await createUser({
            variables: {
              input: {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                groups,
                locations,
                roles,
                invite,
              },
            },
          })
        }
        if (result.errors?.length) {
          snackbar.showMessage({ message: result.errors[0].message, icon: <CodeOutlined />, color: "secondary" })
          setUsers([...remainingUsers])
          return
        }
        remainingUsers.shift()
      }
      handleOnClose()
    }
  }

  const handleCreateGroupClose = async (updated) => {
    setOpenCreateGroup(false)
    if (updated) await refetchGroups()
  }

  const formLocalValid = () => {
    const localUserValid = (user) => isValid(user.firstName, user.lastName, user.email) && user.emailValid

    return (
      locations.length &&
      roles.length &&
      users.some(localUserValid) &&
      users.every((user) => localUserValid(user) || (!user.firstName && !user.lastName && !user.email))
    )
  }

  const formExternalValid = () => {
    const externalUserValid = (user) => isValid(user.firstName, user.lastName, user.unique)

    return (
      locations.length &&
      roles.length &&
      isValid(identityProvider) &&
      users.some(externalUserValid) &&
      users.every((user) => externalUserValid(user) || (!user.firstName && !user.lastName && !user.unique))
    )
  }

  const formValid = () => (external ? formExternalValid() : formLocalValid())

  const isFormValid = formValid()

  const hasFeatureIdentityProviders = hasFeature("identity_providers")

  const form = (
    <>
      <GroupCreator open={openCreateGroup} onClose={handleCreateGroupClose} />

      {hasFeatureIdentityProviders && (
        <PersonCreatorExternalSwitcher external={external} onChange={setExternal} variant="plural" />
      )}

      {!external && (
        <p>
          Choose a location for your new people and then add as many as you want by typing their names and email
          addresses. You can always edit someone's details later so don't worry if you can't remember everything.
        </p>
      )}

      {!external && (
        <Box mb={2}>
          <Alert severity="info">
            <AlertTitle>Got a lot of people to import?</AlertTitle>
            Contact us if you would like us to help you import your data file.
          </Alert>
        </Box>
      )}

      {external && (
        <Box mb={2}>
          <IdentityProviderOutlinedSelect
            value={identityProvider}
            onChange={handleIdentityProviderChange}
            disabled={loading}
            required
          />
        </Box>
      )}

      <FieldSectionHeading size="large">Work, locations &amp; access</FieldSectionHeading>

      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} id="areas-of-work-grid-item">
            <OutlinedSelect
              label="Areas of work"
              id="areas-of-work"
              native={false}
              value={groups}
              onChange={(event) => handleChangeMultiple(setGroups, event.target.value)}
              input={<Input />}
              multiple
              menuPortal={false}
              disabled={loading}
              data-cy="OutlinedSelect-groups"
            >
              <MenuItem value="new" onClick={() => setOpenCreateGroup(true)} data-cy="OutlinedSelect-groups-new">
                <RowBox>
                  <Icon>add</Icon>
                  <Box ml={1}>New area of work...</Box>
                </RowBox>
              </MenuItem>
              {groupsData && groupsData.groups.length > 0 && <Divider />}
              {groupsData &&
                groupsData.groups.map((item, index) => (
                  <MenuItem key={item.id} value={item.id} data-cy={`OutlinedSelect-group-${index}`}>
                    {item.name}
                  </MenuItem>
                ))}
            </OutlinedSelect>
          </Grid>
          <Grid item xs={12}>
            <LocationOutlinedSelect
              mode="admin"
              value={locations}
              onChange={(event) => handleChangeMultiple(setLocations, event.target.value)}
              onRegionChange={(newLocations) => handleChangeMultiple(setLocations, newLocations)}
              hasAll={false}
              multiple
              required
            />
          </Grid>
          <Grid item xs={12}>
            <RoleOutlinedSelect value={roles} onChange={handleRolesChange} disabled={loading} required />
          </Grid>
        </Grid>
      </Box>

      <Divider />

      {external && (
        <Box mt={2}>
          User ID is the unique identifier assigned by your identity provider. Often called User ID or Object ID (Azure
          AD). See your identity providers documentation for further guidance.
        </Box>
      )}

      <Box my={3}>
        {users.map((user, index) => (
          <Grid key={index} container spacing={2} className={classes.userRow}>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                fullWidth
                label="First name"
                value={user.firstName}
                onChange={(event) => handleChange(index, "firstName", event.target.value)}
                required
                disabled={loading}
                inputProps={{ "data-cy": `TextField-user-firstname-${index}` }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                fullWidth
                label="Last name"
                value={user.lastName}
                onChange={(event) => handleChange(index, "lastName", event.target.value)}
                required
                disabled={loading}
                inputProps={{ "data-cy": `TextField-user-lastname-${index}` }}
              />
            </Grid>
            {!external && (
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Email address"
                  value={user.email}
                  onBlur={() => handleEmailTouched(index)}
                  onChange={(event) => handleEmailChange(index, event.target.value)}
                  error={user.emailTouched && !user.emailValid}
                  required
                  disabled={loading}
                  inputProps={{ "data-cy": `TextField-user-email-${index}` }}
                />
              </Grid>
            )}
            {external && (
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="User ID"
                  value={user.unique}
                  onChange={(event) => handleChange(index, "unique", event.target.value)}
                  required
                  disabled={loading}
                  inputProps={{ "data-cy": `TextField-user-unique-${index}` }}
                />
              </Grid>
            )}
            <Grid item xs={1}>
              <IconButton
                onClick={() => handleDelete(index)}
                disabled={users.length === 1}
                data-cy={`Button-delete-${index}`}
              >
                <DeleteOutline />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Box>

      <Box mb={3}>
        <Button variant="contained" color="primary" fullWidth onClick={handleAdd} data-cy="Button-add">
          <Add />
          Add another person
        </Button>
      </Box>

      <Divider />

      {!external && (
        <>
          <Box my={2}>
            <Checkbox
              color="primary"
              type="label"
              label="Send invitation email"
              checked={invite}
              onChange={() => setInvite(!invite)}
            />
          </Box>
          <Divider />
        </>
      )}

      <CreatorActions
        id="MultiplePersonCreator-CreatorActions"
        submitLabel="Add people"
        submitLoading={createUserLoading || createExternalUserLoading}
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid || loading || createUserLoading || createExternalUserLoading}
      />
    </>
  )

  return <CreatorMaster open title="Add multiple people" form={form} isInline={isInline} onClose={handleOnClose} />
}

export { MultiplePersonCreator }
