import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_JOBS_DAYS_OVERVIEW_FIELDS = gql`
  fragment ReportDayStepsOverviewFields on ReportDayStepsOverview {
    day {
      year
      month
      day
    }
    stepsCompleted
    stepsOverdue
    stepsSkipped
    stepsUncompleted
  }
`

const REPORT_JOBS_DAYS_OVERVIEW_QUERY = gql`
  ${REPORT_JOBS_DAYS_OVERVIEW_FIELDS}
  query ReportJobsDaysOverview($locations: [ID!]!, $groups: [ID!], $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportJobsDaysOverview(locations: $locations, groups: $groups, gt: $gt, lt: $lt) {
      ...ReportDayStepsOverviewFields
    }
  }
`

const useQueryReportJobsDaysOverview = (options) =>
  useQuery(REPORT_JOBS_DAYS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportJobsDaysOverview = (options) =>
  useLazyQuery(REPORT_JOBS_DAYS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJobsDaysOverview, useLazyQueryReportJobsDaysOverview }
