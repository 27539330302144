import React, { useEffect, useState } from "react"

import { Grid, Hidden, Box } from "@material-ui/core"
import { useParams, useHistory } from "react-router-dom"

import { AreaHeader, Content, LoadingSpinner, BackToTop, RowBox, NoItemsMessage } from "../../components"
import { useQueryArea } from "../../data/areas/useQueryArea"
import { useAuth } from "../../services"
import AreaTagLeftSidebar from "./AreaTagLeftSidebar"

const Area = () => {
  const { slug } = useParams()
  const history = useHistory()
  const { loading: queryAreaLoading, data: areaData } = useQueryArea({ variables: { id: slug } })
  const [area, setArea] = useState()
  const { location: userLocation } = useAuth()
  const [openSubNav, setOpenSubNav] = useState(false)

  const handleToggleSubNav = () => {
    setOpenSubNav(!openSubNav)
  }

  useEffect(() => {
    if (areaData && Object.entries(areaData).length > 0) {
      const firstTag = areaData?.area?.tags?.[0]
      if (firstTag) {
        history.push(`/area/${slug}/${firstTag?.url}`)
      }
      setArea(areaData?.area)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaData])

  if (queryAreaLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <LoadingSpinner />
      </Box>
    )
  }

  const hasTags = area?.tags?.length > 0
  const singleTag = area?.tags?.length === 1

  return (
    <>
      <AreaHeader
        title={area?.name ?? ""}
        titleIcon={`${area?.icon ?? "templates"}-light`}
        subTitle={userLocation?.name ?? ""}
        beta
      />
      <Content full start>
        {!hasTags && <NoItemsMessage>Area doesn’t contain any tags</NoItemsMessage>}

        {hasTags && (
          <Grid container direction="row">
            {!singleTag && (
              <Hidden mdDown>
                <Grid item xs={12} lg={3}>
                  <AreaTagLeftSidebar tags={area?.tags ?? []} open={openSubNav} onClose={handleToggleSubNav} />
                  <BackToTop from={300} />
                </Grid>
              </Hidden>
            )}
            {!singleTag && (
              <Hidden lgUp>
                <AreaTagLeftSidebar tags={area?.tags ?? []} open={openSubNav} onClose={handleToggleSubNav} />
              </Hidden>
            )}
            <Grid item xs={12} lg={singleTag ? 12 : 9}>
              <RowBox flexGrow={1}>Content</RowBox>
            </Grid>
          </Grid>
        )}
      </Content>
    </>
  )
}

export default Area
