import React, { useMemo } from "react"
import { TextField } from "@material-ui/core"
import moment from "moment-timezone"
import { Autocomplete } from "@material-ui/lab"

const sortByZone = (a, b) => {
  const [ahh, amm] = a.display.split("UTC")[1].split(")")[0].split(":")
  const [bhh, bmm] = b.display.split("UTC")[1].split(")")[0].split(":")
  return +ahh * 60 + amm - (+bhh * 60 + bmm)
}

const timeZoneList = moment.tz
  .names()
  .map((tzName) => ({ key: tzName, display: `(UTC${moment.tz(tzName).format("Z")}) ${tzName}` }))
  .sort(sortByZone)

const TimeZoneTextField = ({
  label = "Time zone",
  placeholder = "Start typing time zone...",
  required = false,
  inputProps: inputPropsIn,
  value,
  onChange,
  ...props
}) => {
  const inputProps = { label, placeholder, required, ...inputPropsIn }

  const handleChange = (event, option) => {
    onChange && onChange(event, option)
  }

  const optionValue = useMemo(() => timeZoneList.find((item) => item.key === value) || null, [value])

  return (
    <Autocomplete
      freeSolo
      value={optionValue}
      getOptionLabel={(item) => item?.display || ""}
      getOptionSelected={(option) => option.key === optionValue.key}
      options={timeZoneList}
      onChange={handleChange}
      selectOnFocus
      disableClearable
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} placeholder={placeholder} {...inputProps} fullWidth />
      )}
      {...props}
    />
  )
}

export default TimeZoneTextField
