import { Box } from "@material-ui/core"
import fileDownload from "js-file-download"
import React, { useEffect, useMemo, useState } from "react"
import { DayStepCompletionChart, ReportSectionActionCard, RowBox } from ".."
import { useLazyQueryReportUsersDaysOverview } from "../../data"
import { useAuth } from "../../services"
import { mapToIds, useDateUtils } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"

const sortData = (sort, a, b) => (sort === "day" ? 0 : a.rank > b.rank ? 1 : -1)

const UsersDaysOverviewReport = ({ gt, lt, locations, groups, users, containerHeight = 300, onDayClick }) => {
  const [sort, setSort] = useState("day")
  const { scheduleDateToMoment } = useDateUtils()
  const [loadReportUsersDaysOverview, { data, loading }] = useLazyQueryReportUsersDaysOverview()
  const [fullscreen, setFullscreen] = useState(false)

  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterGroups = groups.includes("all") ? null : groups

    loadReportUsersDaysOverview({
      variables: { users: users ? mapToIds(users) : [], locations: filterLocations, groups: filterGroups, gt, lt },
    })
  }, [groups, gt, loadReportUsersDaysOverview, locations, lt, userLocations, users])

  const handleFullscreen = (newFullscreen) => {
    setFullscreen(newFullscreen)
  }

  const handleDayClick = ({ argument }) => {
    onDayClick && onDayClick(argument)
  }

  const handleSort = () => {
    setSort(sort === "day" ? "rank" : "day")
  }

  const chartData = useMemo(
    () =>
      data && data.reportUsersDaysOverview[0]
        ? data.reportUsersDaysOverview[0].days
            .map(
              ({
                day,
                stepsCompleted: completed,
                stepsOverdue: late,
                stepsSkipped: skipped,
                stepsUncompleted: uncompleted,
              }) => ({
                argument: scheduleDateToMoment(day).format("DD MMM YY"),
                completed,
                late,
                uncompleted,
                skipped,
                rank: completed + late + skipped,
              })
            )
            .filter(({ completed, late, skipped }) => completed || late || skipped)
            .reverse()
            .sort((a, b) => sortData(sort, a, b))
        : [],
    [data, scheduleDateToMoment, sort]
  )

  const handleDownloadData = () => {
    const header = ["Day", "On-time", "Late", "Skipped", "Uncompleted"]
    const rows = [...chartData]
      .reverse()
      .sort((a, b) => sortData(sort, a, b))
      .map(({ argument, completed, late, skipped, uncompleted }) =>
        [argument, completed, late, skipped, uncompleted].join(",")
      )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "step-completion-by-day.csv", "text/csv")
  }

  const rotateDaysOverview = chartData?.length > 6

  return (
    <ReportSectionActionCard
      title="Jobs"
      detail="Step completion # by day"
      loading={loading}
      onFullscreen={handleFullscreen}
      tools={
        <RowBox>
          <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
          <Box ml={1}>
            <ReportToolsIconButton
              title={sort === "day" ? "Sort by completions #" : "Sort by day"}
              icon={sort === "day" ? "rank" : "calendar"}
              onClick={handleSort}
              disabled={loading}
            />
          </Box>
        </RowBox>
      }
    >
      <DayStepCompletionChart
        data={chartData}
        rotated={rotateDaysOverview}
        barWidth={rotateDaysOverview ? 0.75 : 0.5}
        argumentLabels
        height={rotateDaysOverview ? chartData.length * 32 : containerHeight}
        containerHeight={fullscreen ? null : containerHeight}
        onClick={handleDayClick}
      />
    </ReportSectionActionCard>
  )
}

export default UsersDaysOverviewReport
