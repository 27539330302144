import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_AUDITS_OVERVIEW_FIELDS = gql`
  fragment ReportAuditsOverviewFields on ReportAuditsOverview {
    list {
      id
      name
      total
      submitted
      overdue
      inProgress
    }
    counts {
      jobsTotal
      jobsReturned
    }
  }
`

const REPORT_AUDITS_OVERVIEW_QUERY = gql`
  query ReportAuditsOverview($locations: [ID!]!, $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportAuditsOverview(locations: $locations, gt: $gt, lt: $lt) {
      ...ReportAuditsOverviewFields
    }
  }
  ${REPORT_AUDITS_OVERVIEW_FIELDS}
`

const useQueryReportAuditsOverview = (options) =>
  useQuery(REPORT_AUDITS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportAuditsOverview = (options) =>
  useLazyQuery(REPORT_AUDITS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportAuditsOverview, useLazyQueryReportAuditsOverview }
