import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_JOB_STEPS_NOTES_FIELDS = gql`
  fragment ReportJobStepNotesFields on ReportJobStepNotes {
    id
    process
    name
    job
    locationName
    notes {
      id
      text
      createdAt
      author {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
    }
  }
`

const REPORT_JOB_STEPS_NOTES_QUERY = gql`
  ${REPORT_JOB_STEPS_NOTES_FIELDS}
  query ReportJobStepsNotes($locations: [ID!]!, $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportJobStepsNotes(locations: $locations, gt: $gt, lt: $lt) {
      ...ReportJobStepNotesFields
    }
  }
`

const useQueryReportJobStepsNotes = (options) =>
  useQuery(REPORT_JOB_STEPS_NOTES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportJobStepsNotes = (options) =>
  useLazyQuery(REPORT_JOB_STEPS_NOTES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJobStepsNotes, useLazyQueryReportJobStepsNotes }
