import React, { useState } from "react"
import { Button, makeStyles } from "@material-ui/core"
import { SpeedDialAction } from "@material-ui/lab"
import { FloatingSpeedDial, Icon, RequirePermissions } from ".."

const useSpeedDialActionStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
  },
}))

const DesktopCreateSuppliersButton = ({ onCreate }) => {
  const handleCreate = () => {
    onCreate && onCreate()
  }

  return (
    <RequirePermissions requires="supplier_update" features="suppliers">
      <Button onClick={handleCreate} variant="contained" color="primary" data-cy="Button-create-supplier">
        Create
      </Button>
    </RequirePermissions>
  )
}

const MobileCreateSuppliersButton = ({ creatorOpen, onCreate }) => {
  const classes = useSpeedDialActionStyles()
  const [openSpeedDial, setOpenSpeedDial] = useState(false)

  const handleCreate = () => {
    onCreate && onCreate()
  }

  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }

  return (
    <RequirePermissions requires="supplier_update" features="suppliers">
      {!creatorOpen && (
        <FloatingSpeedDial onClose={handleToggleSpeedDial} onOpen={handleToggleSpeedDial} open={openSpeedDial}>
          <SpeedDialAction
            tooltipTitle="Create a new supplier"
            icon={<Icon name="suppliers-dark" />}
            tooltipOpen
            onClick={handleCreate}
            classes={classes}
          />
        </FloatingSpeedDial>
      )}
    </RequirePermissions>
  )
}

export { DesktopCreateSuppliersButton, MobileCreateSuppliersButton }
