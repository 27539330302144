import React from "react"

import { useParams, useHistory } from "react-router-dom"
import useAsyncEffect from "use-async-effect"
import { Box } from "@material-ui/core"

import { useMutationConfirmUsername } from "../../data/users/useMutationConfirmUsername"
import { LoadingSpinner } from "../../components"
import { userStateVar } from "../../data/users/userStateVar"
import { useMountAwareReactiveVar } from "../../utils"

const UsernameChangeConfirmation = () => {
  const { token } = useParams()
  const history = useHistory()
  const [confirmUsername, { loading }] = useMutationConfirmUsername()
  const store = useMountAwareReactiveVar(userStateVar)

  useAsyncEffect(
    async (isMounted) => {
      if (token) {
        const result = await confirmUsername({
          variables: {
            input: {
              token,
            },
          },
        })

        if (!isMounted()) return null

        if (result?.errors?.length) {
          userStateVar({
            ...store,
            usernameChange: {
              status: "error",
              message: result?.errors?.[0]?.message ?? "",
            },
          })
        } else {
          userStateVar({
            ...store,
            usernameChange: {
              status: "success",
              message: "Your email address has been changed. You can now use your new email address to sign in.",
            },
          })
        }
        history.replace("/account/profile")
      }
    },
    [token]
  )

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}
    </>
  )
}

export default UsernameChangeConfirmation
