import React from "react"
import { Divider } from "@material-ui/core"
import { ChipMenu } from "../ChipMenu/ChipMenu"
import { Icon } from "../Icon"
import { ChipMenuActions } from "../ChipMenu/ChipMenuActions"

const FiltersChipMenu = ({ text = "Filters", children, onApply, onClear, ...props }) => {
  const handleApply = () => {
    onApply && onApply()
  }

  const handleClear = () => {
    onClear && onClear()
  }

  return (
    <ChipMenu text={text} chipProps={{ icon: <Icon name="filters" />, color: "primary" }} {...props}>
      {children}
      {!!children && <Divider />}
      <ChipMenuActions clearLabel="Clear all" boxProps={{ pt: 1 }} onApply={handleApply} onClear={handleClear} />
    </ChipMenu>
  )
}

export { FiltersChipMenu }
