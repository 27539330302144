import React, { useEffect, useRef, useState } from "react"
import pluralize from "pluralize"
import { ChipMenu } from "./ChipMenu"
import { ChipMenuItem } from "./ChipMenuItem"
import { ChipMenuActions } from "./ChipMenuActions"
import { UserAutocomplete } from "../Autocompletes"
import { toId } from "../../utils"

const UserValueText = ({ value }) => {
  if (!value) {
    return "People"
  }

  if (value === "all" || (Array.isArray(value) && value.includes("all"))) {
    return "All people"
  }

  const count = Array.isArray(value) ? value.length : 1

  if (count === 0) {
    return "No people"
  }

  if (count === 1) {
    return "1 person"
  }

  return pluralize("person", count, true)
}

const UserChipMenuItem = ({ value, onChange, ...props }) => {
  const handleChange = (option) => {
    onChange && onChange(toId(option, true))
  }

  return (
    <ChipMenuItem>
      <UserAutocomplete value={value} onChange={handleChange} style={{ width: "100%" }} {...props} />
    </ChipMenuItem>
  )
}

const UserChipMenu = ({ value, onChange, onRegionChange, ...props }) => {
  const [nextValue, setNextValue] = useState(value)
  const menuRef = useRef()

  useEffect(() => {
    setNextValue(value)
  }, [value])

  const handleChange = (newValue) => {
    setNextValue(newValue)
  }

  const handleApply = () => {
    menuRef.current?.close()
    onChange && onChange(nextValue)
  }

  const handleClear = () => {
    menuRef.current?.close()
    onChange && onChange(null)
  }

  return (
    <ChipMenu menuRef={menuRef} text={<UserValueText value={value} />}>
      <UserChipMenuItem value={nextValue} onChange={handleChange} {...props} />
      <ChipMenuActions onApply={handleApply} onClear={handleClear} />
    </ChipMenu>
  )
}

export { UserChipMenu, UserChipMenuItem, UserValueText }
