import React, { useState, useEffect } from "react"
import { TextField, Box, Grid } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { useQueryLabelCategories, useMutationUpdateLabelCategory, DEMO_PROFILE } from "../../data"
import { useFormUtils, mapToIds, multipleSelectChange } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import { useDemonstration } from "../../utils/useDemonstration"
import { LocationOutlinedSelect, GroupOutlinedSelect } from "../OutlinedSelect"

const initialState = {
  name: "",
  locations: ["all"],
  groups: ["all"],
}

const LabelsCategoryCreator = ({ open, onClose, edit, isInline }) => {
  const { isValid } = useFormUtils()
  const { id } = useParams()
  const { data } = useQueryLabelCategories()
  const [loading, setLoading] = useState(false)
  const { isDemo } = useDemonstration()

  const [updateLabelCategory] = useMutationUpdateLabelCategory()
  const [name, setName] = useState(initialState.name)
  const isEdit = edit != null

  const [groups, setGroups] = useState(initialState.groups)
  const [locations, setLocations] = useState(initialState.locations)

  const isLabelsDemo = isDemo(DEMO_PROFILE.LABELS)

  // When editing a category, filter the data to get the category to edit
  useEffect(() => {
    if (data && id && edit) {
      const result = data.labelCategories.find((c) => c.id === id)
      if (result) {
        setName(result.name)
        setGroups(result.groups.length ? mapToIds(result.groups) : ["all"])
        setLocations(result.locations.length ? mapToIds(result.locations) : ["all"])
      }
    }
  }, [data, id, edit])

  const handleOnClose = () => {
    handleResetState()
    if (onClose) onClose(true)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (formValid()) {
      setLoading(true)
      const variables = {
        input: {
          name,
          groups: groups.includes("all") ? [] : mapToIds(groups),
          locations: locations.includes("all") ? [] : mapToIds(locations),
        },
      }

      await updateLabelCategory({ variables: { id, ...variables } })
      setLoading(false)
      handleOnClose(event)
    }
  }

  const handleResetState = () => {
    setName(initialState.name)
  }

  const handleLocationsChanged = (event) => {
    setLocations([...multipleSelectChange(locations, event)])
  }

  const handleRegionChange = (regionLocations) => {
    setLocations([...mapToIds(regionLocations)])
  }

  const handleGroupsChanged = (event) => {
    setGroups([...multipleSelectChange(groups, event)])
  }

  const formValid = () => isValid(name)
  const isFormValid = formValid()

  const form = (
    <>
      <Box display="flex" flexDirection="row" mb={2} alignItems="center">
        <Box flexGrow={1}>
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
            required
            data-cy="TextField-name"
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <LocationOutlinedSelect
            value={locations}
            onChange={handleLocationsChanged}
            onRegionChange={handleRegionChange}
            multiple
            data-cy="LabelCategoryCreator-locations"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GroupOutlinedSelect
            value={groups}
            onChange={handleGroupsChanged}
            multiple
            data-cy="LabelCategoryCreator-groups"
          />
        </Grid>
      </Grid>
      <CreatorActions
        subject="Category"
        submitLoading={loading}
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid || isLabelsDemo}
      />
    </>
  )

  return (
    <CreatorMaster
      open={open}
      subject="Category"
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleOnClose}
    />
  )
}

export { LabelsCategoryCreator }
