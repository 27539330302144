import React, { useMemo } from "react"
import { Chart, BarSeries, ArgumentAxis, ValueAxis, Tooltip } from "@devexpress/dx-react-chart-material-ui"
import { ValueScale, Stack, EventTracker, HoverState } from "@devexpress/dx-react-chart"
import { useTheme, Box, makeStyles } from "@material-ui/core"
import { FiberManualRecord } from "@material-ui/icons"
import { NoItemsMessage, RowBox } from ".."

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: ({ containerHeight }) => containerHeight,
    overflowY: "auto",
  },
  fullscreenContainer: {
    height: "calc(100vh - 180px)",
    overflowY: "auto",
  },
  chartLegendLabel: {
    fontSize: 11,
  },
  chartLegendDot: {
    fontSize: 15,
  },
  barSeries: {
    cursor: "pointer",
  },
  completed: {
    color: theme.palette.success.main,
  },
  skipped: {
    color: theme.palette.primary.main,
  },
  late: {
    color: theme.palette.error.light,
  },
  uncompleted: {
    color: theme.palette.chart.background,
  },
}))

const format = (scale) => scale.tickFormat(null, "%")

const DayStepCompletionChart = ({
  data,
  argumentLabels = true,
  barWidth = 0.5,
  rotated = false,
  height,
  onClick,
  containerHeight = null,
}) => {
  const classes = useStyles({ containerHeight })
  const theme = useTheme()

  const dataConfig = {
    hasOverdue: data && data[0] && typeof data[0].late !== "undefined",
    hasUncompleted: data && data[0] && typeof data[0].uncompleted !== "undefined",
  }
  const { hasOverdue, hasUncompleted } = dataConfig

  const seriesConfig = useMemo(() => {
    const result = ["Completed", "Skipped"]
    if (hasOverdue) result.push("Late")
    if (hasUncompleted) result.push("Uncompleted")
    return result
  }, [hasOverdue, hasUncompleted])

  const HorizontalLegend = ({ children, mb = 1, mt, ...props }) => (
    <RowBox mb={mb} mt={mt} {...props} justifyContent="flex-end" mr={2}>
      {children}
    </RowBox>
  )

  const HorizontalLegendItem = ({ text, className }) => (
    <RowBox py={0.5} px={1}>
      <FiberManualRecord className={`${classes.chartLegendDot} ${className}`} />
      <Box ml={0.5} className={classes.chartLegendLabel}>
        {text}
      </Box>
    </RowBox>
  )

  const handleClick = (event) => {
    const index = event.targets[0].point
    onClick && onClick(data[index], event)
  }

  const TooltipContent = ({ targetItem: { point, series } }) => {
    const { completed, late, skipped, uncompleted } = data[point]
    let total = completed + skipped
    if (hasOverdue) total += late
    if (hasUncompleted) total += uncompleted
    const value = data[point][series.toLowerCase()]
    const percentage = Math.floor((value / total) * 100)
    return (
      <Box>
        <Box>
          <strong>{series}</strong>
        </Box>
        <Box>
          {percentage}% ({value} step{value > 1 ? "s" : ""})
        </Box>
      </Box>
    )
  }

  if (!data?.length) return <NoItemsMessage>No data</NoItemsMessage>

  return (
    <>
      <HorizontalLegend>
        <HorizontalLegendItem text="On-time" className={classes.completed} />
        {hasOverdue && <HorizontalLegendItem text="Late" className={classes.late} />}
        <HorizontalLegendItem text="Skipped" className={classes.skipped} />
        {hasUncompleted && <HorizontalLegendItem text="Uncompleted" className={classes.uncompleted} />}
      </HorizontalLegend>
      <Box className={containerHeight ? classes.container : classes.fullscreenContainer}>
        <Chart data={data} rotated={rotated} height={Math.max(height, 80)}>
          <ValueScale name="Total" />

          <ArgumentAxis showLabels={argumentLabels} />
          <ValueAxis tickFormat={format} />
          <BarSeries
            name="Completed"
            valueField="completed"
            argumentField="argument"
            scaleName="Total"
            color={theme.palette.success.main}
            className={classes.barSeries}
            barWidth={barWidth}
          />
          {hasOverdue && (
            <BarSeries
              name="Late"
              valueField="late"
              argumentField="argument"
              scaleName="Total"
              color={theme.palette.error.light}
              className={classes.barSeries}
              barWidth={barWidth}
            />
          )}
          <BarSeries
            name="Skipped"
            valueField="skipped"
            argumentField="argument"
            scaleName="Total"
            color={theme.palette.primary.main}
            className={classes.barSeries}
            barWidth={barWidth}
          />
          {hasUncompleted && (
            <BarSeries
              name="Uncompleted"
              valueField="uncompleted"
              argumentField="argument"
              scaleName="Total"
              color={theme.palette.chart.background}
              className={classes.barSeries}
              barWidth={barWidth}
            />
          )}
          <Stack stacks={[{ series: seriesConfig || [] }]} />
          <EventTracker onClick={handleClick} />
          <Tooltip contentComponent={TooltipContent} />
          <HoverState />
        </Chart>
      </Box>
    </>
  )
}

export { DayStepCompletionChart }
