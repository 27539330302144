import React, { useEffect } from "react"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import { Box, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { ReportSectionActionCard, FullscreenReportContainer, TimeAgo, Icon, RowBox, FormatDateTimeCompact } from ".."
import { mapToIds, toId } from "../../utils"
import { useLazyQueryReportActionsProcessStepsDetail } from "../../data/report/useQueryReportActionsProcessStepsDetail"
import { useAuth } from "../../services"
import { ActionStatusChip } from "../Chips/ActionStatusChip"
import { ACTION_PRIORITY, ACTION_PRIORITY_LABELS, ACTION_STATUS } from "../../data"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"

const useStyles = makeStyles((theme) => ({
  viewAction: {
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  },
}))

const ProcessStepActionsTableReport = ({
  gt,
  lt,
  process,
  step,
  locations,
  groups,
  tags,
  statuses,
  priorities,
  defaultFullscreen = false,
  onFullscreen,
}) => {
  const classes = useStyles()
  const [load, { data, loading }] = useLazyQueryReportActionsProcessStepsDetail()
  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    const filterLocations = locations.includes("all") ? userLocations : locations
    const filterStatuses = statuses.includes("all") ? null : statuses
    const filterPriorities = priorities.includes("all") ? null : priorities
    const filterGroups = groups.includes("all") ? null : groups
    const filterTags = tags.includes("all") ? null : tags

    load({
      variables: {
        input: {
          process: toId(process),
          processStep: toId(step),
          locations: mapToIds(filterLocations),
          groups: filterGroups,
          tags: filterTags,
          statuses: filterStatuses,
          priorities: filterPriorities,
          gt,
          lt,
        },
      },
    })
  }, [groups, gt, load, locations, lt, priorities, process, statuses, step, tags, userLocations])

  const { processName, processStepName, actions } = data?.report.actions.steps.detail || {}

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = [
      "Process",
      "Step",
      "RaisedAt",
      "RaisedBy",
      "Title",
      "Location",
      "Priority",
      "Due",
      "Status",
      "StatusAt",
      "StatusBy",
      "Note",
      "RaisedByID",
      "StatusByID",
      "ProcessID",
      "ProcessStepID",
    ]
    const rows = [...actions].map(
      ({ createdAt, createdBy, title, location, priority, dueAt, status, statusAt, statusBy, note }) =>
        [
          stringFormatter(processName),
          stringFormatter(processStepName),
          createdAt,
          createdBy?.fullName || "",
          stringFormatter(title),
          stringFormatter(location),
          stringFormatter(priority ? ACTION_PRIORITY_LABELS[priority] : ACTION_PRIORITY_LABELS[ACTION_PRIORITY.NORMAL]),
          dueAt,
          status,
          statusAt,
          statusBy?.fullName || "",
          note,
          toId(createdBy, true),
          toId(statusBy, true),
          toId(process),
          toId(step),
        ].join(",")
    )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "process-step-actions-detail.csv", "text/csv")
  }

  const handleFullscreen = (fullscreen) => {
    onFullscreen && onFullscreen(fullscreen)
  }

  return (
    <ReportSectionActionCard
      title="Actions raised by step"
      detail={loading ? "Loading..." : `${processName} - ${processStepName}`}
      defaultFullscreen={defaultFullscreen}
      onFullscreen={handleFullscreen}
      tools={
        <RowBox>
          <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
        </RowBox>
      }
      loading={loading || !data}
    >
      {({ fullscreen }) => {
        if (!actions) {
          return null
        }

        const display = (
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 130 }}>Raised</TableCell>
                  <TableCell>By</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Due</TableCell>
                  <TableCell style={{ width: 200, textAlign: "center" }}>Status</TableCell>
                  <TableCell>At</TableCell>
                  <TableCell>By</TableCell>
                  <TableCell>Note</TableCell>
                  <TableCell style={{ width: 100 }}>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actions.map((action) => (
                  <TableRow key={toId(action)}>
                    <TableCell>
                      <TimeAgo date={action.createdAt} />
                    </TableCell>
                    <TableCell>{action.createdBy?.fullName}</TableCell>
                    <TableCell>{action.title}</TableCell>
                    <TableCell>{action.location}</TableCell>
                    <TableCell>
                      <RowBox>
                        <Box mr={1}>{ACTION_PRIORITY_LABELS[action.priority || ACTION_PRIORITY.NORMAL]}</Box>
                        <Icon name={`priority-${action.priority || ACTION_PRIORITY.NORMAL}`} throwForUnmapped={false} />
                      </RowBox>
                    </TableCell>
                    <TableCell>
                      {action.dueAt ? (
                        <FormatDateTimeCompact
                          value={action.dueAt}
                          compactTodayTimeZone={action.location?.timeZone || null}
                        />
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <ActionStatusChip status={action.status || ACTION_STATUS.UNRESOLVED} />
                    </TableCell>
                    <TableCell>{action.statusAt ? <FormatDateTimeCompact value={action.statusAt} /> : null}</TableCell>
                    <TableCell>{action.statusBy?.fullName}</TableCell>
                    <TableCell>{action.note}</TableCell>
                    <TableCell>
                      <NavLink to={`/hub/action/${toId(action)}`} className={classes.viewAction}>
                        <RowBox>
                          <Box mr={0.5}>View</Box>
                          <Icon name="forward" fontSize="small" />
                        </RowBox>
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )

        if (fullscreen) {
          return <FullscreenReportContainer>{display}</FullscreenReportContainer>
        }

        return display
      }}
    </ReportSectionActionCard>
  )
}

export default ProcessStepActionsTableReport
