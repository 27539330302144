import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { offsetLimitPagination } from "@apollo/client/utilities"
import * as Sentry from "@sentry/browser"
import { toId } from "../../utils"
import { POST_FIELDS } from "./fragments/postFieldsFragment"

const POSTS_LIMIT = 5

const POSTS_QUERY = gql`
  query Posts($filter: PostsFilterInput, $offset: Int) {
    posts {
      list(filter: $filter, limit: ${POSTS_LIMIT}, offset: $offset) {
        ...PostFields
      } 
      count(filter: $filter)
    }
  }
  ${POST_FIELDS}
`

const postsTypePolicies = {
  scoped: {
    PostsQuery: {
      merge(_, next) {
        return { ...next }
      },
      fields: {
        list: offsetLimitPagination(["filter"]),
        count: {
          keyArgs: ["filter"],
        },
      },
    },
  },
}

const postTypePolicies = {
  Post: {
    fields: {
      confirmed: {
        merge(prev, next) {
          return [...next]
        },
      },
      unconfirmed: {
        merge(prev, next) {
          return [...next]
        },
      },
    },
  },
}

const POST_CREATED_SUBSCRIPTION = gql`
  subscription PostCreatedV2 {
    postCreated {
      ...PostFields
    }
  }
  ${POST_FIELDS}
`
const POST_UPDATED_SUBSCRIPTION = gql`
  subscription PostUpdatedV2 {
    postUpdated {
      ...PostFields
    }
  }
  ${POST_FIELDS}
`

const POST_DELETED_SUBSCRIPTION = gql`
  subscription PostDeleted {
    postDeleted
  }
`

const COMMENT_CREATED_SUBSCRIPTION = gql`
  subscription CommentCreated {
    commentCreated {
      id
      content
      uploads {
        id
        key
        fileName
        fileGroup
        fileSize
      }
      author {
        id
        firstName
        lastName
        avatar {
          key
        }
      }
      post
      createdAt
    }
  }
`

const loadMore = (result) => async () => {
  try {
    await result.fetchMore({
      variables: {
        ...result.variables,
        offset: result.data.posts.list.length,
        limit: POSTS_LIMIT,
      },
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

const postCreatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const newItem = subscriptionData.data.postCreated
  const next = JSON.parse(JSON.stringify(prev))
  next.posts.list = [newItem, ...next.posts.list]
  return next
}

const postUpdatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const updatedItem = subscriptionData.data.postUpdated
  const next = JSON.parse(JSON.stringify(prev))
  let post = next.posts.list.find((p) => toId(p) === toId(updatedItem))
  if (post) {
    post = { ...updatedItem }
  }
  return next
}

const postDeletedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const next = JSON.parse(JSON.stringify(prev))
  next.posts.list = [...prev.posts.list.filter((post) => toId(post) !== toId(subscriptionData.data.postDeleted))]
  return next
}

const postCommentCreatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const newComment = subscriptionData.data.commentCreated
  const next = JSON.parse(JSON.stringify(prev))
  // find the post
  const post = next.posts.list.find((p) => toId(p) === toId(newComment.post))
  if (post && !post.comments.find((c) => toId(c) === toId(newComment))) {
    post.comments.push(newComment)
  }
  return next
}

const subscribe =
  ({ subscribeToMore, variables }) =>
  () =>
    [
      subscribeToMore({
        document: POST_CREATED_SUBSCRIPTION,
        variables,
        updateQuery: postCreatedSubscriptionUpdateQuery,
        onError: (err) => console.log("[useQueryPosts][POST_CREATED_SUBSCRIPTION]", err),
      }),
      subscribeToMore({
        document: POST_UPDATED_SUBSCRIPTION,
        variables,
        updateQuery: postUpdatedSubscriptionUpdateQuery,
        onError: (err) => console.log("[useQueryPosts][POST_UPDATED_SUBSCRIPTION]", err),
      }),
      subscribeToMore({
        document: POST_DELETED_SUBSCRIPTION,
        variables,
        updateQuery: postDeletedSubscriptionUpdateQuery,
        onError: (err) => console.log("[useQueryPosts][POST_DELETED_SUBSCRIPTION]", err),
      }),
      subscribeToMore({
        document: COMMENT_CREATED_SUBSCRIPTION,
        variables,
        updateQuery: postCommentCreatedSubscriptionUpdateQuery,
        onError: (err) => console.log("[useQueryPosts][COMMENT_CREATED_SUBSCRIPTION]", err),
      }),
    ]

const useQueryPosts = (options = {}) => {
  const { variables, ...otherOptions } = options
  const result = useQuery(POSTS_QUERY, {
    variables: {
      ...variables,
      offset: 0,
      limit: POSTS_LIMIT,
    },
    notifyOnNetworkStatusChange: true,
    ...otherOptions,
  })

  return {
    ...result,
    loadMore: loadMore(result),
    subscribe: subscribe(result),
  }
}

const useLazyQueryPosts = () => {
  const [loadPosts, result] = useLazyQuery(POSTS_QUERY, {
    variables: {
      offset: 0,
      limit: POSTS_LIMIT,
    },
    notifyOnNetworkStatusChange: true,
  })

  return [
    loadPosts,
    {
      ...result,
      loadMore: loadMore(result),
      subscribe: subscribe(result),
    },
  ]
}

const postMatchesSet = (post, set) => {
  if (set === "all") {
    return true
  }
  if (set === "important" && post.hasConfirm) {
    return true
  }
  if (set === "jobs" && post.job) {
    return true
  }
  if (set === "knowledge" && post.knowledge) {
    return true
  }
  return false
}

export {
  useQueryPosts,
  useLazyQueryPosts,
  POSTS_QUERY,
  POST_CREATED_SUBSCRIPTION,
  POST_UPDATED_SUBSCRIPTION,
  POST_DELETED_SUBSCRIPTION,
  COMMENT_CREATED_SUBSCRIPTION,
  postTypePolicies,
  postsTypePolicies,
  postCreatedSubscriptionUpdateQuery,
  postUpdatedSubscriptionUpdateQuery,
  postDeletedSubscriptionUpdateQuery,
  postCommentCreatedSubscriptionUpdateQuery,
  postMatchesSet,
}
