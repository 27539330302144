import { Box, Divider, Grid, MenuItem, Tab, Tabs } from "@material-ui/core"
import React, { memo, useEffect, useState } from "react"
import {
  useMutationCreateOrUpdateUserTrainingAssessment,
  USER_ACCREDITATIONS_STATUS,
  USER_TRAINING_ASSESSMENT_STATUS,
  USER_TRAINING_ASSESSMENT_STATUS_LABELS,
} from "../../data"
import { toId } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import { DatePicker } from "../DateTimePickers"
import { Caption } from "../Headings"
import { OutlinedSelect } from "../OutlinedSelect"
import { PersonNotes } from "../PersonViewer/PersonNotes"

const { NONE, SCHEDULED, COMPETENT, NEEDS_TRAINING, TRAINED } = USER_TRAINING_ASSESSMENT_STATUS

const OutlinedStatusSelect = memo(function OutlinedStatusSelect({ value, onChange, ...props }) {
  const [selectedValue, setSelectedValue] = useState(value || "")

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
    onChange && onChange(event)
  }

  return (
    <OutlinedSelect native={false} value={selectedValue} onChange={handleChange} {...props}>
      <MenuItem value={NONE}>(None)</MenuItem>
      <MenuItem value={COMPETENT}>{USER_TRAINING_ASSESSMENT_STATUS_LABELS[COMPETENT]}</MenuItem>
      <MenuItem value={TRAINED}>{USER_TRAINING_ASSESSMENT_STATUS_LABELS[TRAINED]}</MenuItem>
      <MenuItem value={NEEDS_TRAINING}>{USER_TRAINING_ASSESSMENT_STATUS_LABELS[NEEDS_TRAINING]}</MenuItem>
      <MenuItem value={SCHEDULED}>{USER_TRAINING_ASSESSMENT_STATUS_LABELS[SCHEDULED]}</MenuItem>
    </OutlinedSelect>
  )
})

const UserTrainingAssessmentCreator = ({ assessment: edit, module, user, onClose }) => {
  const [createOrUpdateUserTrainingAssessment, { loading }] = useMutationCreateOrUpdateUserTrainingAssessment()
  const [tab, setTab] = useState("status")
  const [status, setStatus] = useState(USER_ACCREDITATIONS_STATUS.NONE)
  const [trainedAt, setTrainedAt] = useState(null)
  const [trainedBy, setTrainedBy] = useState("")

  useEffect(() => {
    if (edit) {
      setStatus(edit.status)
      setTrainedAt(edit.trainedAt)
      setTrainedBy(toId(edit.trainedBy))
    }
  }, [edit])

  const handleSubmit = async () => {
    const variables = {
      module: toId(module),
      user: toId(user),
      status,
      trainedAt,
      trainedBy: trainedBy || null,
    }

    await createOrUpdateUserTrainingAssessment({
      variables,
    })

    onClose && onClose(true)
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const handleStatusChange = async ({ target: { value } }) => {
    setStatus(value)
    if (value !== COMPETENT) {
      setTrainedAt(null)
      setTrainedBy(null)
    }
  }

  const handleTrainedAtChange = async (value) => {
    setTrainedAt(value.toDate())
  }

  const handleTrainedByChanged = async ({ target: { value } }) => {
    setTrainedBy(value)
  }

  const { users } = module

  const trainers = [...users]
  if (edit?.trainedBy?.fullName && !trainers.some((item) => toId(item) === toId(edit.trainedBy))) {
    trainers.push({ ...edit.trainedBy })
  }

  const form = (
    <>
      <Tabs
        variant="standard"
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={(event, newTab) => setTab(newTab)}
        aria-label="Assessment details tabs"
        data-cy="UserTrainingAssessmentCreator-tabs"
      >
        <Tab label="Status" value="status" data-cy="Tab-status" />
        <Tab label="Internal notes" value="notes" data-cy="Tab-notes" />
      </Tabs>

      <Divider />

      <Box pt={3} data-cy="UserTrainingAssessmentCreator-page">
        {tab === "status" && (
          <>
            <Box mb={2}>
              <OutlinedStatusSelect label="Status" value={status} onChange={handleStatusChange} />
            </Box>
            {status === COMPETENT && (
              <Box mb={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <DatePicker
                      label="Trained on"
                      value={trainedAt}
                      onChange={handleTrainedAtChange}
                      clearLabel="Clear"
                      clearable
                    />
                  </Grid>

                  {Boolean(trainers.length) && (
                    <Grid item xs={12} md={8}>
                      <OutlinedSelect
                        native={false}
                        label="Trained by"
                        value={trainedBy ? toId(trainedBy) : ""}
                        onChange={handleTrainedByChanged}
                      >
                        <MenuItem value="">(None)</MenuItem>
                        {trainers.map((trainer) => (
                          <MenuItem key={toId(trainer)} value={toId(trainer)}>
                            {trainer.fullName}
                          </MenuItem>
                        ))}
                      </OutlinedSelect>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}

            <CreatorActions
              id="UserTrainingAssessmentCreator-CreatorActions"
              subject=""
              onClose={handleClose}
              onSubmit={handleSubmit}
              disableSubmit={loading}
            />
          </>
        )}
        {tab === "notes" && (
          <>
            <Box mb={3}>
              <Caption mb={1.5}>
                Only users with Regional Manager permission level and above can view and leave notes.
              </Caption>
              <PersonNotes user={user} />
            </Box>
          </>
        )}
      </Box>
    </>
  )

  return (
    <CreatorMaster
      id="UserTrainingAssessmentCreator-dialog"
      open
      title={`${user.fullName}: ${module.name} Module`}
      form={form}
      isEdit={Boolean(edit)}
      isInline={false}
      onClose={handleClose}
    />
  )
}

export { UserTrainingAssessmentCreator }
