import React, { memo } from "react"
import moment from "moment"
import { Chart, ArgumentAxis, ValueAxis, LineSeries, Tooltip } from "@devexpress/dx-react-chart-material-ui"
import { Box, makeStyles, useTheme } from "@material-ui/core"
import { EventTracker, ValueScale, ArgumentScale } from "@devexpress/dx-react-chart"
import { scaleTime } from "d3-scale"
import { FormatDateTime, NoItemsMessage, RowBox } from ".."
import { HorizontalLegend, HorizontalLegendItem, NumberDisplay } from "."
import { LoadingSpinner } from "../LoadingSpinner"

const useStyles = makeStyles((theme) => ({
  temperature: {
    color: theme.palette.sensor.temperature,
  },
  humidity: {
    color: theme.palette.sensor.humidity,
  },
}))

const useTooltipStyles = makeStyles(() => ({
  number: {
    fontSize: 20,
  },
  scale: {
    opacity: 0.3,
  },
  at: {
    color: ({ overdue }) => (overdue ? "red" : "inherit"),
    fontWeight: ({ overdue }) => (overdue ? "600" : "inherit"),
  },
  indicators: {
    color: "#000000",
    opacity: 0.87,
  },
}))

const SensorSampleChart = memo(function SensorSampleChartMemo({
  data,
  height = 300,
  temperaturePadding = 1,
  humidityPadding = 5,
  loading,
}) {
  const theme = useTheme()
  const classes = useStyles()

  const temperatureMap = data.map(({ temperature }) => temperature).filter(Number)
  const temperatureMax = Math.max(...temperatureMap) + temperaturePadding
  const temperatureMin = Math.min(...temperatureMap) - temperaturePadding

  const humidityMap = data.map(({ humidity }) => humidity).filter(Number)
  const hasHumidity = Boolean(humidityMap.length)
  const humidityMax = Math.max(...humidityMap) + humidityPadding
  const humidityMin = Math.min(...humidityMap) - humidityPadding

  const atMap = data.map(({ at }) => moment(at))
  const atMax = moment.max(atMap)
  const atMin = moment.min(atMap)

  const TooltipContent = ({ targetItem: { point } }) => {
    const tooltipClasses = useTooltipStyles()
    const { temperature, humidity, at } = data[point]
    return (
      <NumberDisplay
        alignItems="flex-start"
        justifyContent="flex-end"
        value={
          <RowBox className={tooltipClasses.number}>
            {!!temperature && (
              <>
                {temperature}
                <small className={tooltipClasses.scale}>{"\u2103"}</small>
              </>
            )}
            {!!humidity && (
              <>
                {humidity}
                <small className={tooltipClasses.scale}>%RH</small>
              </>
            )}
          </RowBox>
        }
        subject={
          <Box className={classes.at}>
            <FormatDateTime value={at} />
          </Box>
        }
      />
    )
  }

  const ValueLabel = ({ suffix, ...props }) => {
    const { text } = props
    return <ValueAxis.Label {...props} text={`${text}${suffix}`} />
  }

  if (!data?.length) return <NoItemsMessage>No data</NoItemsMessage>

  return (
    <>
      {loading && (
        <RowBox style={{ height }} justifyContent="center">
          <LoadingSpinner size="60" delay={false} />
        </RowBox>
      )}
      {!loading && (
        <>
          <Chart
            data={data.map(({ temperature, humidity, at }) => ({ temperature, humidity, at: moment(at).toDate() }))}
            height={height}
          >
            <ValueScale name="temperature" modifyDomain={() => [temperatureMin, temperatureMax]} />
            <ValueScale name="humidity" modifyDomain={() => [humidityMin, humidityMax]} />
            <ArgumentScale factory={scaleTime} modifyDomain={() => [moment(atMin).toDate(), moment(atMax).toDate()]} />

            <ArgumentAxis />
            <ValueAxis
              scaleName="temperature"
              labelComponent={(props) => <ValueLabel suffix={"\u2103"} {...props} />}
            />
            <ValueAxis
              scaleName="humidity"
              position="right"
              labelComponent={(props) => <ValueLabel suffix="%" {...props} />}
              showGrid={false}
            />

            <LineSeries
              name="Humidity"
              scaleName="humidity"
              valueField="humidity"
              argumentField="at"
              color={theme.palette.sensor.humidity}
            />
            <LineSeries
              name="Temperature"
              scaleName="temperature"
              valueField="temperature"
              argumentField="at"
              color={theme.palette.sensor.temperature}
            />

            <EventTracker />
            <Tooltip contentComponent={TooltipContent} />
          </Chart>
          <HorizontalLegend>
            <HorizontalLegendItem text="Temperature" className={classes.temperature} />
            {hasHumidity && <HorizontalLegendItem text="Humidity" className={classes.humidity} />}
          </HorizontalLegend>
        </>
      )}
    </>
  )
})

export { SensorSampleChart }
