import React from "react"
import { makeStyles, MenuItem } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
}))

const ChipMenuItem = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <MenuItem {...rest} classes={classes}>
      {children}
    </MenuItem>
  )
}

export { ChipMenuItem }
