import React from "react"
import { FiltersChipMenuItem } from "./FiltersChipMenuItem"
import { UserChipMenuItem, UserValueText } from "../ChipMenu/UserChipMenu"

const UsersFiltersChipMenuItem = ({ menuItemRef, value, onChange, onExpanded, ...props }) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue)
  }

  return (
    <FiltersChipMenuItem
      menuItemRef={menuItemRef}
      name="People"
      description={<UserValueText value={value} />}
      onExpanded={onExpanded}
    >
      <UserChipMenuItem value={value} onChange={handleChange} {...props} />
    </FiltersChipMenuItem>
  )
}

export { UsersFiltersChipMenuItem }
