import React from "react"

import { TextField, makeStyles, Radio, FormControlLabel, RadioGroup } from "@material-ui/core"

import { KNOWLEDGE_CONTENT_NOTE_TYPE } from "../../../data"

const useStyles = makeStyles(() => ({
  contentField: {
    backgroundColor: "#ffffff",
  },
}))

const CreatorContentNote = ({ content, onUpdateContent }) => {
  const classes = useStyles()

  return (
    <>
      <RadioGroup value={content.type} onChange={(e) => onUpdateContent("type", e.target.value)} row>
        <FormControlLabel
          label="Information"
          value={KNOWLEDGE_CONTENT_NOTE_TYPE.INFORMATION}
          control={<Radio color="primary" />}
        />
        <FormControlLabel
          label="Important"
          value={KNOWLEDGE_CONTENT_NOTE_TYPE.IMPORTANT}
          control={<Radio color="primary" />}
        />
        <FormControlLabel
          label="Danger"
          value={KNOWLEDGE_CONTENT_NOTE_TYPE.DANGER}
          control={<Radio color="primary" />}
        />
      </RadioGroup>
      <TextField
        variant="outlined"
        value={content.text}
        fullWidth
        size="small"
        label="Note"
        multiline
        minRows={5}
        onChange={(e) => onUpdateContent("text", e.target.value)}
        className={classes.contentField}
      />
    </>
  )
}

export { CreatorContentNote }
