import React, { useState } from "react"
import {
  Box,
  Tabs,
  Tab,
  Divider,
  Hidden,
  Drawer,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { ModuleHeading, UsersGroupsAssigner, Switch } from ".."
import { useAuth } from "../../services"
import { toId, useMountAwareReactiveVar, useMountEffect } from "../../utils"
import { jobFiltersStateVar } from "../../data"

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 0,
    margin: "0 0 4px 0",
  },
  filter: {
    width: ({ fullWidth }) => (fullWidth ? "100%" : 322),
    backgroundColor: theme.palette.shaded.filter,
    borderRadius: "8px",
  },
  filterButton: {
    paddingTop: ({ xs }) => (xs ? 6 : "inherit"),
    paddingBottom: ({ xs }) => (xs ? 6 : "inherit"),
  },
  drawer: {
    maxWidth: "80vw",
  },
  tabsIndicator: {
    width: ({ fullWidth }) => (fullWidth ? "auto" : "145px !important"),
  },
  tabRoot: {
    minWidth: ({ fullWidth }) => (fullWidth ? "none" : 145),
    maxWidth: ({ fullWidth }) => (fullWidth ? "none" : 145),
  },
  assignedTo: {
    borderBottom: "none",
  },
  radioTabs: {
    backgroundColor: theme.palette.shaded.filter,
  },
}))

const handleSet = (prop, value) => {
  jobFiltersStateVar({
    ...jobFiltersStateVar(),
    [prop]: value,
  })
}

const Filters = ({ classes }) => {
  const store = useMountAwareReactiveVar(jobFiltersStateVar)
  const { tab, hideCompleted, hideFuture, hideHidden, isFuture } = store

  return (
    <Box mb={3}>
      {tab === "others" && <AssignedPickers store={store} classes={classes} />}
      <Box px={3} pb={3} pt={tab === "me" ? 2 : 0}>
        <Grid container direction="row">
          <Grid xs={12} sm={5} md={12} item>
            <Switch
              label="Show completed"
              checked={!hideCompleted}
              onChange={() => handleSet("hideCompleted", !hideCompleted)}
              disabled={isFuture}
            />
          </Grid>

          <Grid xs={12} sm={5} md={12} item>
            <Switch
              label="Show not yet available"
              checked={!hideFuture}
              onChange={() => handleSet("hideFuture", !hideFuture)}
              disabled={isFuture}
            />
          </Grid>

          <Grid xs={12} sm={5} md={12} item>
            <Switch
              label="Show hidden"
              checked={!hideHidden}
              onChange={() => handleSet("hideHidden", !hideHidden)}
              disabled={isFuture}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

// TODO: Use AssignedToPickers instead.
const AssignedPickers = ({ classes, store }) => {
  const { location } = useAuth()
  const [toInitialPicked, setToInitialPicked] = useState(null)
  const { toVariant, toOpenPickerInit, toUsers, toGroups, byVariant } = store

  useMountEffect(() => {
    setToInitialPicked([...toGroups, ...toUsers])
  })

  const handleToPickedChanged = (picked) => {
    jobFiltersStateVar({
      ...store,
      toHasEveryone: picked ? picked.some((p) => p.__typename === "Everyone") : false,
      toUsers: picked ? picked.filter((p) => p.__typename === "User") : [],
      toGroups: picked ? picked.filter((p) => p.__typename === "Group") : [],
    })
  }

  const handleSetVariant = (target, variant) => {
    handleSet(`${target}Variant`, variant)
    setTimeout(() => {
      handleSet(`${target}OpenPickerInit`, variant === "select")
    }, 1000)
  }

  return (
    <Box p={3}>
      {toInitialPicked && (
        <UsersGroupsAssigner
          variant={toVariant}
          title="Assigned to"
          locations={[toId(location)]}
          onPickedChanged={handleToPickedChanged}
          onVariantChange={(variant) => handleSetVariant("to", variant)}
          openPickerAfterInit={toOpenPickerInit}
          initialPicked={toInitialPicked}
          initialSelectValue={toVariant === "select" && toInitialPicked.length === 1 ? toId(toInitialPicked[0]) : null}
          selectAllowAdvanced
          className={toVariant === "advanced" && byVariant === "advanced" && classes.assignedTo}
          allowSelf
          assigneeFlow="none"
        />
      )}
    </Box>
  )
}

const JobsFilters = ({ open, onClose, fullWidth }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const store = useMountAwareReactiveVar(jobFiltersStateVar)
  const {
    settings: { deviceGroups },
  } = useAuth()
  const { tab } = store

  const classes = useStyles({ fullWidth, xs })

  const handleToggleFilterDrawer = () => {
    onClose && onClose()
  }

  const hasDeviceGroups = deviceGroups?.length

  return (
    <>
      <Hidden mdDown>
        <Box mr={5} className={classes.filter}>
          <Box px={3} pt={2} pb={0}>
            <ModuleHeading className={classes.title}>Filter jobs</ModuleHeading>
            {!hasDeviceGroups && (
              <Tabs
                variant="standard"
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, newTab) => handleSet("tab", newTab)}
                aria-label="Job filtering tabs"
                classes={{ indicator: classes.tabsIndicator }}
              >
                <Tab label="Assigned to me" value="me" classes={{ root: classes.tabRoot }} />
                <Tab label="Assigned to others" value="others" classes={{ root: classes.tabRoot }} />
              </Tabs>
            )}
          </Box>
          {!hasDeviceGroups && <Divider />}
          <Filters classes={classes} />
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Drawer open={open} onClose={handleToggleFilterDrawer}>
          <Box className={classes.drawer}>
            {!hasDeviceGroups && (
              <RadioGroup
                value={tab}
                onChange={(e) => handleSet("tab", e.target.value)}
                row
                className={classes.radioTabs}
              >
                <Box display="flex" flexDirection="column" p={3}>
                  <FormControlLabel label="Assigned to me" value="me" control={<Radio color="primary" />} />
                  <FormControlLabel label="Assigned to others" value="others" control={<Radio color="primary" />} />
                </Box>
              </RadioGroup>
            )}
            {!hasDeviceGroups && <Divider />}
            <Filters classes={classes} />
          </Box>
        </Drawer>
      </Hidden>
    </>
  )
}

export { JobsFilters }
