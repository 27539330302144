import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, Popover, Grid, Hidden } from "@material-ui/core"
import { FilterList as FilterListIcon } from "@material-ui/icons"
import PopupState, { anchorRef, bindTrigger, bindMenu } from "material-ui-popup-state"
import { GroupOutlinedSelect, ScheduleOutlinedSelect } from ".."
import { CalendarViewToggleButton } from "./CalendarViewToggleButton"

const useFiltersPopoverStyles = makeStyles((theme) => ({
  root: {
    width: 512,
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const CalendarFiltersButton = ({ view, groups, schedules, onGroupsChange, onRepeatsChange, onViewChange }) => {
  const classes = useFiltersPopoverStyles()

  const groupIds = useMemo(() => groups?.split("-") || ["all"], [groups])
  const repeatItems = useMemo(() => schedules?.split("-") || ["all"], [schedules])

  const monthView = view === "month"
  const allGroups = groups === "all"

  const handleGroupsChange = (...args) => {
    onGroupsChange && onGroupsChange(...args)
  }

  const handleRepeatsChange = (...args) => {
    onRepeatsChange && onRepeatsChange(...args)
  }

  const handleViewChange = (...args) => {
    onViewChange && onViewChange(...args)
  }

  return (
    <PopupState variant="popover" popupId="bulk-actions">
      {(popupState) => (
        <>
          <Button variant="contained" color="primary" {...bindTrigger(popupState)} ref={anchorRef(popupState)}>
            <FilterListIcon />
            <Hidden xsDown>
              <Box ml={1}>Filters</Box>
            </Hidden>
          </Button>
          <Popover
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={classes}
          >
            <Box p={2}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <GroupOutlinedSelect
                    label=""
                    allLabel={monthView && allGroups ? "Select areas of work" : "Any area of work"}
                    value={groupIds}
                    multiple
                    hasAll={!monthView || allGroups}
                    onChange={handleGroupsChange}
                  />
                </Grid>
                <Grid item>
                  <ScheduleOutlinedSelect
                    label=""
                    allLabel="Any schedule"
                    value={repeatItems}
                    multiple
                    hasAll
                    onChange={handleRepeatsChange}
                  />
                </Grid>
                <Grid item>
                  <CalendarViewToggleButton view={view} onViewChange={handleViewChange} size="large" ml={0} />
                </Grid>
              </Grid>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  )
}

export { CalendarFiltersButton }
