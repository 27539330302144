import { Box } from "@material-ui/core"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import React, { useEffect, useMemo, useState } from "react"
import { JobStepCompletionChart, ReportSectionActionCard, RowBox } from ".."
import { useLazyQueryReportUsersJobsOverview } from "../../data"
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.rank - b.rank)

const UsersJobStepCompletionReport = ({ gt, lt, locations, groups, defaultSort = "alpha", onUserClick }) => {
  const [sort, setSort] = useState(defaultSort)
  const [loadReportUsersJobsOverview, { data: jobsUsersOverviewData, loading }] = useLazyQueryReportUsersJobsOverview()

  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    if (!gt) {
      return
    }

    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterGroups = groups.includes("all") ? null : groups

    loadReportUsersJobsOverview({
      variables: { users: [], locations: filterLocations, groups: filterGroups, gt, lt },
    })
  }, [groups, gt, loadReportUsersJobsOverview, locations, lt, userLocations])

  const handleUserClick = ({ user }) => {
    onUserClick && onUserClick(user)
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const chartData = useMemo(
    () =>
      jobsUsersOverviewData?.reportUsersJobsOverview?.length
        ? jobsUsersOverviewData.reportUsersJobsOverview
            .map((item) =>
              item.jobs.reduce(
                (acc, job) => {
                  acc.completed += job.stepsCompleted
                  acc.late += job.stepsOverdue
                  acc.uncompleted += job.stepsUncompleted
                  acc.skipped += job.stepsSkipped
                  acc.rank += job.stepsCompleted + job.stepsOverdue + job.stepsSkipped
                  return acc
                },
                {
                  argument: `${item.firstName} ${item.lastName}`,
                  user: item.id,
                  completed: 0,
                  late: 0,
                  uncompleted: 0,
                  skipped: 0,
                  rank: 0,
                }
              )
            )
            .reverse()
            .sort((a, b) => (sort === "alpha" ? 0 : a.rank - b.rank))
        : [],
    [jobsUsersOverviewData, sort]
  )

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = ["Id", "Name", "On-time", "Late", "Skipped", "Uncompleted"]
    const rows = [...chartData]
      .reverse()
      .sort((a, b) => sortData(sort, a, b))
      .map(({ user, argument, completed, late, skipped, uncompleted }) =>
        [user, stringFormatter(argument), completed, late, skipped, uncompleted].join(",")
      )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "people-jobs-step-completion.csv", "text/csv")
  }

  return (
    <ReportSectionActionCard
      title="Jobs"
      detail="Step completion # by person"
      loading={loading}
      tools={
        <RowBox>
          <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
          <Box ml={1}>
            <ReportToolsIconButton
              title={sort === "alpha" ? "Sort by completions #" : "Sort alphabetically"}
              icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
              onClick={handleSort}
              disabled={loading}
            />
          </Box>
        </RowBox>
      }
    >
      {({ fullscreen }) => {
        if (!chartData) {
          return null
        }

        return (
          <JobStepCompletionChart
            data={chartData}
            rotated
            barWidth={0.75}
            argumentLabels
            height={chartData.length * 32}
            containerHeight={fullscreen ? null : 300}
            variant="stacked-bar"
            onClick={handleUserClick}
            truncateLabelAt={25}
          />
        )
      }}
    </ReportSectionActionCard>
  )
}

export default UsersJobStepCompletionReport
