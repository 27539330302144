import React, { useState } from "react"
import { Button, Menu, makeStyles, MenuItem, ListItemIcon, Box } from "@material-ui/core"
import { RequirePermissions, Icon } from ".."
import { useQueryTrainingModules } from "../../data"

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: 50,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
}))

const DesktopCreateTrainingButton = ({ onCreateClick }) => {
  const classes = useStyles()
  const { data } = useQueryTrainingModules()
  const [showCreateOptions, setShowCreateOptions] = useState(false)
  const [showCreateOptionsEl, setShowCreateOptionsEl] = useState(null)

  const handleCreateClick = (type) => {
    setShowCreateOptions(false)
    setShowCreateOptionsEl(null)
    onCreateClick && onCreateClick(type)
  }

  const handleToggleCreateMenu = ({ currentTarget }) => {
    setShowCreateOptionsEl(!showCreateOptions ? currentTarget : null)
    setShowCreateOptions(!showCreateOptions)
  }

  return (
    <RequirePermissions requires={["post_create", "action_create"]}>
      <Box display="flex" flexDirection="row">
        <Button onClick={handleToggleCreateMenu} variant="contained" color="primary" data-cy="Button-create-training">
          Create
        </Button>
        <Menu
          anchorEl={showCreateOptionsEl}
          keepMounted
          open={showCreateOptions}
          onClose={handleToggleCreateMenu}
          className={classes.menu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={() => handleCreateClick("modules")} data-cy="MenuItem-create-module">
            <ListItemIcon className={classes.menuListItemIcon}>
              <Icon name="training-module" />
            </ListItemIcon>
            <>Create a new module</>
          </MenuItem>
          <MenuItem
            onClick={() => handleCreateClick("courses")}
            data-cy="MenuItem-create-course"
            disabled={!data?.trainingModules.length}
          >
            <ListItemIcon className={classes.menuListItemIcon}>
              <Icon name="training" />
            </ListItemIcon>
            <>Create a new course</>
          </MenuItem>
        </Menu>
      </Box>
    </RequirePermissions>
  )
}

export { DesktopCreateTrainingButton }
