import { useMutation, gql } from "@apollo/client"
import moment from "moment"
import { makeOptimisticUserFromPrincipal } from "../makeOptimistic"
import { ACTION_FIELDS } from "./fragments/actionFieldsFragment"

const UPDATE_ACTION_MUTATION = gql`
  mutation UpdateAction($id: ID!, $input: ActionInput!) {
    action(id: $id) {
      update(input: $input) {
        ...ActionFields
      }
    }
  }
  ${ACTION_FIELDS}
`

const UPDATE_STATUS_ACTION_MUTATION = gql`
  mutation UpdateStatusAction($id: ID!, $input: ActionUpdateStatusInput!) {
    action(id: $id) {
      updateStatus(input: $input) {
        ...ActionFields
      }
    }
  }
  ${ACTION_FIELDS}
`

const DELETE_ACTION_MUTATION = gql`
  mutation DeleteAction($id: ID!) {
    action(id: $id) {
      delete
    }
  }
`

const makeUpdateActionStatusOptimisticResponse = ({ variables, action, principal }) => ({
  __typename: "Mutation",
  action: {
    updateStatus: {
      __typename: "Action",
      ...action,
      ...variables.input,
      statusAt: moment(),
      statusBy: makeOptimisticUserFromPrincipal(principal),
    },
    __typename: "ActionMutation",
  },
})

const useMutationUpdateAction = () => useMutation(UPDATE_ACTION_MUTATION)

const useMutationUpdateStatusAction = () => useMutation(UPDATE_STATUS_ACTION_MUTATION)

const useMutationDeleteAction = () => useMutation(DELETE_ACTION_MUTATION)

export {
  useMutationUpdateAction,
  useMutationDeleteAction,
  makeUpdateActionStatusOptimisticResponse,
  useMutationUpdateStatusAction,
}
