import React, { useEffect, useMemo, useState } from "react"
import { Box, makeStyles, Grid } from "@material-ui/core"
import moment from "moment"
import { useHistory, useParams } from "react-router"
import { mapToIds, multipleSelectChange, useDateUtils, useReportUtils } from "../../../utils"
import { useAuth } from "../../../services"
import LocationsActionsReport from "../../../components/Reports/LocationsActionsReport"
import ProcessStepActionsOverviewReport from "../../../components/Reports/ProcessStepActionsOverviewReport"
import { DateRangeChipMenu } from "../../../components/ChipMenu/DateRangeChipMenu"
import { LocationChipMenu } from "../../../components/ChipMenu/LocationChipMenu"
import { TagChipMenu } from "../../../components/ChipMenu/TagChipMenu"
import { ActionStatusChipMenu } from "../../../components/ChipMenu/ActionStatusChipMenu"
import { ActionPriorityChipMenu } from "../../../components/ChipMenu/ActionPriorityChipMenu"
import { ReportsFilters } from "../../../components/Reports/ReportsFilters"
import { ProcessChipMenu } from "../../../components/ChipMenu/ProcessChipMenu"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
    width: 300,
    flexShrink: 0,
  },
  filterSelect: {
    backgroundColor: "white",
    maxWidth: 300,
  },
  reports: {
    width: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  infiniteScrollWrapper: {
    "&&>div": {
      display: "contents",
    },
  },
  infiniteScroll: {
    display: "contents",
  },
}))

const Actions = () => {
  const classes = useStyles()
  const { days, locations, groups, process, tags } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const { getNavigateToActionsLink, getNavigateToLink } = useReportUtils()
  const [gt, setGt] = useState(null)
  const [lt, setLt] = useState(null)
  const [priorities, setPriorities] = useState(["all"])
  const [statuses, setStatuses] = useState(["all"])
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()

  const daysValue = days || 7
  const groupIds = groups?.split("-") || ["all"]
  const locationIds = useMemo(() => locations?.split("-") || ["all"], [locations])
  const processValue = process || "all"
  const tagIds = useMemo(() => tags?.split("-") || ["all"], [tags])

  useEffect(() => {
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      setGt(momentToScheduleDate(moment(daysParts[0])))
      setLt(momentToScheduleDate(moment(daysParts[1])))
    } else {
      setGt(momentToScheduleDate(moment().subtract(days, "days")))

      let newLt = null
      // If not "Today"
      if (days !== "1") {
        // Set end of yesterday (23h59)
        newLt = momentToScheduleDate(moment().subtract(1, "days").endOf("day"))
      }

      setLt(newLt)
    }
  }, [days, locations, momentToScheduleDate, process, userLocations])

  const handleFiltersMenuApply = (values) => {
    const {
      days: newDays,
      locations: newLocations,
      groups: newGroups,
      process: newProcess,
      tags: newTags,
      actionStatus: newStatuses,
      actionPriorities: newPriorities,
    } = values
    history.push(getNavigateToActionsLink(newDays, newLocations, newGroups, newProcess, newTags))
    setPriorities(newPriorities)
    setStatuses(newStatuses)
  }

  const handleDaysChange = (newDays) => {
    history.push(getNavigateToActionsLink(newDays, locationIds, groupIds, processValue, tagIds))
  }

  const handleLocationsChange = (event) => {
    history.push(
      getNavigateToActionsLink(daysValue, multipleSelectChange(locationIds, event), groupIds, processValue, tagIds)
    )
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToActionsLink(daysValue, mapToIds(regionLocations), groupIds, processValue, tagIds))
  }

  const handleJobChange = (newJob) => {
    history.push(getNavigateToActionsLink(daysValue, locationIds, groupIds, newJob, tagIds))
  }

  const handleTagsChange = (event) => {
    history.push(
      getNavigateToActionsLink(daysValue, locationIds, groupIds, processValue, multipleSelectChange(tagIds, event))
    )
  }

  // const handleJobClick = (newProcess) => {
  //   history.push(getNavigateToActionsLink(days, locationIds, groupIds, newProcess))
  // }

  const handleStatusesChange = (event) => {
    setStatuses([...multipleSelectChange(statuses, event)])
  }

  const handlePrioritieshange = (event) => {
    setPriorities([...multipleSelectChange(priorities, event)])
  }

  const handleReset = () => {
    history.push(getNavigateToLink("actions", 7, ["all"], ["all"]))
  }

  return (
    <>
      <ReportsFilters
        values={{
          days,
          locations: locationIds,
          groups: groupIds,
          process: processValue,
          actionStatus: statuses,
          actionPriorities: priorities,
          tags: tagIds,
        }}
        menu={{
          days: true,
          locations: true,
          process: true,
          actionStatus: true,
          actionPriorities: true,
          tags: true,
        }}
        onApply={handleFiltersMenuApply}
        onReset={handleReset}
      >
        <Box ml={0.5}>
          <DateRangeChipMenu value={days} onChange={handleDaysChange} onReset={handleReset} />
        </Box>
        <Box ml={0.5}>
          <LocationChipMenu
            value={locationIds}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        <Box ml={0.5}>
          <ProcessChipMenu value={processValue} onChange={handleJobChange} />
        </Box>
        <Box ml={0.5}>
          <ActionStatusChipMenu
            value={statuses}
            onChange={handleStatusesChange}
            onReset={handleReset}
            multiple
            hasAll
          />
        </Box>
        <Box ml={0.5}>
          <ActionPriorityChipMenu
            value={priorities}
            onChange={handlePrioritieshange}
            onReset={handleReset}
            multiple
            hasAll
          />
        </Box>
        <Box ml={0.5}>
          <TagChipMenu value={tagIds} onChange={handleTagsChange} onReset={handleReset} multiple />
        </Box>
      </ReportsFilters>

      <Grid container spacing={1} className={classes.reports}>
        <Grid item xs={12}>
          <LocationsActionsReport
            gt={gt}
            lt={lt}
            process={process}
            locations={locationIds}
            groups={groupIds}
            tags={tagIds}
            statuses={statuses}
            priorities={priorities}
          />
        </Grid>
        <Grid item xs={12}>
          <ProcessStepActionsOverviewReport
            gt={gt}
            lt={lt}
            process={process}
            locations={locationIds}
            groups={groups}
            tags={tagIds}
            statuses={statuses}
            priorities={priorities}
          />
        </Grid>
      </Grid>

      {/* <ActionsTableReport
        gt={gt}
        lt={lt}
        locations={locationIds}
        groups={groupIds}
        tags={tagIds}
        statuses={statuses}
        priorities={priorities}
      /> */}
    </>
  )
}

export default Actions
