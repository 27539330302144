import React, { useState } from "react"
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  makeStyles,
  useTheme,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core"
import fileDownload from "js-file-download"
import Config from "react-global-configuration"
import axios from "axios"
import { useSnackbar } from "../SnackbarProvider"
import { PaperBox } from "../Boxes"
import { Icon } from "../Icon"
import { LoadingSpinner } from "../LoadingSpinner"
import { useOffline } from "../Offline/useOffline"
import { useLazyQueryReportJobDetailPdf } from "../../data/report/useQueryReportJobDetailPdf"
import { toId, useDeviceUtils } from "../../utils"
import ExportJobsTablePdfButton from "../Reports/ExportJobsTablePdfButton"

const useStyles = makeStyles((theme) => ({
  fileBox: {
    cursor: "pointer",
    "&:hover": {
      boxShadow: theme.palette.hoverCard.boxShadow,
    },
    "&:focus": {
      boxShadow: theme.palette.hoverCard.boxShadow,
    },
  },
  fileTitle: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  fileDetail: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  menu: {
    marginTop: 50,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
}))

const DownloadExportJobButton = ({
  job,
  jobs,
  listAllJobsOptionProps = null,
  variant = "outlined",
  color = "primary",
  iconSize = "medium",
  iconName = "pdf",
  disabled,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { clientKiosk, clientDevice } = Config.get()
  const { canPostMessage, postDownloadMessage } = useDeviceUtils()
  const [load] = useLazyQueryReportJobDetailPdf()
  const { offline } = useOffline()
  const [exporting, setExporting] = useState(false)
  const snackbar = useSnackbar()

  const [showDownloadOptions, setShowDownloadOptions] = useState(false)
  const [showDownloadOptionsEl, setShowDownloadOptionsEl] = useState(null)

  const busyChipProps = { style: { backgroundColor: theme.palette.scheduled.main } }

  const handleExport = async (selectedJob) => {
    if (exporting) {
      return
    }

    try {
      snackbar.showMessage({
        message: "Exporting PDF, please wait",
        icon: <Icon name="pdf" />,
        chipProps: busyChipProps,
        autoHideDuration: 30000,
      })

      setExporting(true)

      const result = await load({
        variables: { id: toId(selectedJob) },
      })

      if (!result?.data) {
        snackbar.showMessage({ message: "Unknown error generating PDF" })
        setExporting(false)
        return
      }

      const data = result?.data?.report?.job?.detail?.pdf

      if (!data.exportUrl) {
        snackbar.showMessage({ message: data.message || "Unknown error generating PDF" })
        setExporting(false)
        return
      }

      const busySnackbarConfig = {
        message: "Downloading, please wait",
        icon: <Icon name="download" />,
        chipProps: busyChipProps,
        autoHideDuration: 60000 * 10,
      }

      snackbar.showMessage(busySnackbarConfig)

      if ((clientKiosk || clientDevice) && canPostMessage()) {
        postDownloadMessage({
          signedDownloadUrl: data.exportUrl,
          upload: {
            fileName: data.fileName,
            contentType: "application/pdf",
          },
        })
        setExporting(false)
        return
      }

      const response = await axios({
        url: data.exportUrl,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          snackbar.showMessage({
            ...busySnackbarConfig,
            message: `Downloading PDF (${percentCompleted}%)`,
          })
        },
      })

      fileDownload(response.data, data.fileName)

      snackbar.showMessage({ message: "Download ready", icon: <Icon name="download" />, autoHideDuration: 5000 })

      setExporting(false)
    } catch (error) {
      console.log(error)
      setExporting(false)
    }
  }

  const handleToggleDownloadMenu = ({ currentTarget }) => {
    if (exporting) {
      return
    }

    setShowDownloadOptionsEl(!showDownloadOptions ? currentTarget : null)
    setShowDownloadOptions(!showDownloadOptions)
  }

  const handleDownloadJob = async (selectedJob) => {
    if (exporting) {
      return
    }

    setShowDownloadOptionsEl(null)
    setShowDownloadOptions(false)

    await handleExport(selectedJob)
  }

  const handleAllJobsClick = () => {
    setShowDownloadOptionsEl(null)
    setShowDownloadOptions(false)

    setExporting(true)
  }

  const handleAllJobsDownloaded = () => {
    setExporting(false)
  }

  if (variant === "icon") {
    return (
      <Tooltip title="Export PDF">
        <IconButton size={iconSize} onClick={() => handleExport(job)} disabled={offline || disabled}>
          {!exporting && <Icon name={iconName} />}
          {exporting && <LoadingSpinner size="24px" delay={false} />}
        </IconButton>
      </Tooltip>
    )
  }

  if (variant === "file") {
    return (
      <PaperBox p={2} mb={1} onClick={() => handleExport(job)} className={classes.fileBox}>
        <Box display="flex" flexGrow={1} flexDirection="row">
          <Box mr={1}>
            <Icon color="secondary" name="upload-pdf" />
          </Box>
          <Box flexGrow={1} flexDirection="column" alignSelf="center" alignItems="center">
            <Box>
              <Typography variant="h2" className={classes.fileTitle}>
                Download the full report
              </Typography>
            </Box>
            <Box className={classes.fileDetail}>PDF Document</Box>
          </Box>
          <Box mt={1}>
            {!exporting && <Icon name="download" />}
            {exporting && <LoadingSpinner size="24px" delay={false} />}
          </Box>
        </Box>
      </PaperBox>
    )
  }

  if (variant === "list") {
    // Click to show a list of pdfs
    return (
      <>
        <Tooltip title="Export PDF">
          <IconButton size={iconSize} onClick={handleToggleDownloadMenu} disabled={offline || exporting || disabled}>
            {!exporting && <Icon name="pdf" />}
            {exporting && <LoadingSpinner size="24px" delay={false} />}
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={showDownloadOptionsEl}
          keepMounted
          open={showDownloadOptions}
          onClose={handleToggleDownloadMenu}
          className={classes.menu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {listAllJobsOptionProps && (
            <>
              <ExportJobsTablePdfButton
                {...listAllJobsOptionProps}
                variant="menuitem"
                onClick={handleAllJobsClick}
                onDownloaded={handleAllJobsDownloaded}
              />
              <Divider />
            </>
          )}
          {jobs
            ? jobs.reverse().map((j, i) => (
                <MenuItem key={i} onClick={() => handleDownloadJob(j)}>
                  <ListItemIcon>
                    <Icon name="download" />
                  </ListItemIcon>
                  <ListItemText primary={j.name} />
                </MenuItem>
              ))
            : null}
        </Menu>
      </>
    )
  }

  return (
    <Button
      variant="contained"
      fullWidth
      onClick={() => handleExport(job)}
      color={color}
      disabled={offline || exporting || disabled}
    >
      {!exporting && <>Export PDF</>}
      {exporting && (
        <>
          <LoadingSpinner size="24px" delay={false} />
          <Box ml={1}>Exporting</Box>
        </>
      )}
    </Button>
  )
}

export { DownloadExportJobButton }
