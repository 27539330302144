import { Box, makeStyles } from "@material-ui/core"
import React from "react"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: "16px",
    color: theme.palette.grey[400],
  },
}))

const AdminOnlyLabel = ({ label = "Managers only" }) => {
  const classes = useStyles()

  return (
    <RowBox className={classes.root}>
      <Icon name="view-alt" />
      <Box ml={1}>{label}</Box>
    </RowBox>
  )
}

export { AdminOnlyLabel }
