import queryString from "query-string"
import Config from "react-global-configuration"
import * as Sentry from "@sentry/browser"
import { useMutationCreateIntegration } from "../data"
import { toId } from "./data"

const useIntegrationProviderConnector = () => {
  const [createIntegration] = useMutationCreateIntegration()

  const startConnectionRequest = async (integrationProvider, reconnectingIntegration) => {
    if (!integrationProvider) {
      throw new Error("Integration provider argument is required")
    }
    if (!integrationProvider.connect) {
      throw new Error("Integration provider argument must be complete including connect details")
    }

    const clientBaseUri = Config.get("clientBaseUri")
    const { authoriseEndpoint, client: client_id, responseType: response_type, scope } = integrationProvider.connect

    const redirectExtraParams = reconnectingIntegration ? `?reconnect=${toId(reconnectingIntegration)}` : ""

    const parameters = {
      client_id,
      response_type,
      scope,
      redirect_uri: `${clientBaseUri}/account/integrations/provider/${toId(
        integrationProvider
      )}/connect${redirectExtraParams}`,
    }

    window.location = `${authoriseEndpoint}?${queryString.stringify(parameters)}`
  }

  const checkConnectionResult = () => {
    const qs = queryString.parse(window.location.search)
    return {
      code: qs.code,
      reconnect: qs.reconnect,
    }
  }

  const completeConnectionResult = async (integrationProviderId, onConnected, onError) => {
    const { code, reconnect: reconnectIntegration } = checkConnectionResult()
    if (code) {
      try {
        const integration = await createIntegration({
          variables: { integrationProvider: toId(integrationProviderId), code, reconnectIntegration },
        })
        if (integration?.data) {
          onConnected && onConnected(integration?.data.createIntegration || null)
          return
        }
        throw new Error("Integration not created")
      } catch (error) {
        Sentry.captureException(error)
        onError && onError(error)
      }
    }
  }

  return {
    startConnectionRequest,
    checkConnectionResult,
    completeConnectionResult,
  }
}

export { useIntegrationProviderConnector }
