import React, { useState } from "react"
import { Alarm } from "@material-ui/icons"
import { AssignerDisplay } from "../AssignerDisplay"
import { ProcessScheduleStatement } from "./ProcessScheduleStatement"
import ProcessScheduleDialog from "./ProcessScheduleDialog"

// const UntilTypeCaption = ({ untilType, untilPeriod }) => {
//   const untilPeriodNumber = Number(untilPeriod)

//   return (
//     <>
//       {untilType === PROCESS_SCHEDULE_UNTIL.NONE && (
//         <Typography variant="caption">This job will remain on the list until the end of the day.</Typography>
//       )}
//       {untilType === PROCESS_SCHEDULE_UNTIL.COMPLETED && (
//         <Typography variant="caption">This job will remain at the top of each day until completed.</Typography>
//       )}
//       {untilType === PROCESS_SCHEDULE_UNTIL.MINUTES && (
//         <Typography variant="caption">
//           This job will remain at the top for {untilPeriod || "several"} minutes after available from.
//         </Typography>
//       )}
//       {untilType === PROCESS_SCHEDULE_UNTIL.HOURS && (
//         <Typography variant="caption">
//           This job will remain at the top for {untilPeriod || "several"} hours after available from.
//         </Typography>
//       )}
//       {untilType === PROCESS_SCHEDULE_UNTIL.DAYS && (
//         <Typography variant="caption">
//           {!untilPeriod && (
//             <>This job will remain at the top for the specificed number of days after available from.</>
//           )}
//           {untilPeriodNumber === 1 && <>This job will remain at the top an additional day after available from.</>}
//           {untilPeriodNumber > 1 && (
//             <>This job will remain at the top an additional {untilPeriod} days after available from.</>
//           )}
//         </Typography>
//       )}
//       {untilType === PROCESS_SCHEDULE_UNTIL.NEXT_INSTANCE && (
//         <Typography variant="caption">
//           This job will remain at the top of each day until completed otherwise its next scheduled occurrence. Use this
//           to avoid multiple active pinned instances of this job.
//         </Typography>
//       )}
//     </>
//   )
// }

const ProcessScheduleAssigner = ({
  title = "Schedule",
  placeholder = "Not scheduled",
  schedule,
  subject,
  topBorder = true,
  onChange,
  disabled,
  cy,
}) => {
  const [open, setOpen] = useState(false)

  const handleDisplayClick = () => {
    setOpen(true)
  }

  const handleChange = (newValue) => {
    onChange && onChange(newValue)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {open && (
        <ProcessScheduleDialog
          open={open}
          value={schedule}
          subject={subject}
          onChange={handleChange}
          onClose={handleClose}
        />
      )}

      <AssignerDisplay
        icon={<Alarm />}
        title={title}
        value={schedule?.repeat ? <ProcessScheduleStatement schedule={schedule} subject={subject} /> : null}
        placeholder={placeholder}
        onClick={handleDisplayClick}
        topBorder={topBorder}
        disabled={disabled}
        cy={cy}
      />
    </>
  )
}

export { ProcessScheduleAssigner }
