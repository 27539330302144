import React, { useEffect } from "react"
import { Divider, makeStyles, MenuItem } from "@material-ui/core"
import { toId } from "../../utils"
import { OutlinedSelect } from "./OutlinedSelect"
import { useQueryTags } from "../../data/tags/useQueryTags"

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 125,
  },
}))

const TagOutlinedSelect = ({
  onDataLoaded,
  allLabel = "All tags",
  hasAll = true,
  disabled: disabledInput = false,
  multiple = false,
  ...rest
}) => {
  const { data, loading } = useQueryTags()
  const classes = useStyles()

  useEffect(() => {
    if (onDataLoaded && data) onDataLoaded(data)
  }, [data, onDataLoaded])

  const disabled = disabledInput || loading || !data?.tags.length

  return (
    <OutlinedSelect
      label="Tags"
      native={false}
      value="all"
      classes={classes}
      disabled={disabled}
      multiple={multiple}
      selectProps={{
        renderValue: (option) => {
          if (multiple) {
            return option
              .map((item) => {
                if (item === "all") {
                  return allLabel
                }

                return data?.tags.find((tag) => tag.id === item)?.name || item
              })
              .join(", ")
          }

          if (option === "all") {
            return allLabel
          }

          const selected = data?.tags.find((item) => item.id === option.value)
          return selected?.name || option
        },
      }}
      {...rest}
    >
      {hasAll && <MenuItem value="all">{allLabel}</MenuItem>}
      {hasAll && <Divider />}
      {data?.tags.map((tag) => (
        <MenuItem key={toId(tag)} value={toId(tag)}>
          {tag.name}
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { TagOutlinedSelect }
