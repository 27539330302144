import React, { useState } from "react"
import { Route, Switch } from "react-router-dom"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@material-ui/core"
import Config from "react-global-configuration"
import { AreaHeader, Content, DesktopSensorsReportsButton, Icon, RowBox } from "../../components"
import Sensors from "./Sensors"
import Sensor from "./Sensor"
import { useQuerySettings } from "../../data"
import { useAuth } from "../../services"

const MoreButton = () => {
  const [open, setOpen] = useState(false)
  const { location, settings } = useAuth()
  const configLinks = Config.get("links")

  const handleClose = () => {
    setOpen(false)
  }

  const sensorSupportLink = `mailto:${
    configLinks.emailSensorSupport
  }?subject=Operandio%20Sensor%20Support%20for%20${encodeURIComponent(settings.organisation.name)}${
    location && location.name ? `%20(${encodeURIComponent(location.name)})` : ""
  }`
  const sensorSalesLink = `mailto:${configLinks.emailSupport}?subject=Operandio%20Sensors%20for%20${encodeURIComponent(
    settings.organisation.name
  )}${location && location.name ? `%20(${encodeURIComponent(location.name)})` : ""}`
  const generalSupportLink = `mailto:${
    configLinks.emailSupport
  }?subject=User%20support%20request%20-%20${encodeURIComponent(settings.organisation.name)}${
    location && location.name ? `%20(${encodeURIComponent(location.name)})` : ""
  }`

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Alerts &amp; Support
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alerts &amp; Support</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              Our sensor team will be able to directly assist you in setting up your sensor rules (high / low
              temperature thresholds), power failure alerts and alert contact information.
              <br />
              <br />
              If you have not yet set these up, are experiencing issues, excessive alerts, or have any sensor-specific
              questions, please reach out to our sensor support team:
              <Box mt={2}>
                <strong>Sensor Support (Monday-Friday, 9am-5pm AEST)</strong>
              </Box>
              <Paper elevation={3}>
                <Box
                  mt={1}
                  p={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ fontSize: 14 }}
                >
                  <Box display="flex" flexDirection="column">
                    <strong>Email</strong>
                    <a href={sensorSupportLink}>temperature@operandio.com</a>
                  </Box>
                  <Button variant="contained" href={sensorSupportLink}>
                    <RowBox>
                      <Icon name="mail" fontSize="small" />
                      <Box ml={1}>Email support</Box>
                    </RowBox>
                  </Button>
                </Box>
              </Paper>
              <Paper elevation={3}>
                <Box
                  mt={1}
                  p={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ fontSize: 14 }}
                >
                  <Box display="flex" flexDirection="column">
                    <strong>Call</strong>
                    <a href={`tel:${configLinks.phoneSensorSupport.replaceAll(" ", "")}`}>
                      {configLinks.phoneSensorSupport}
                    </a>
                  </Box>
                  <Button variant="contained" href={`tel:${configLinks.phoneSensorSupport.replaceAll(" ", "")}`}>
                    <RowBox>
                      <Icon name="phone" fontSize="small" />
                      <Box ml={1}>Call support</Box>
                    </RowBox>
                  </Button>
                </Box>
              </Paper>
            </Box>
            <Box mt={3}>
              <strong>Sensor Sales</strong>
              <br />
              To order sensors, or manage your sensor subscription, please contact our sales team:
              <Paper elevation={3}>
                <Box
                  mt={1}
                  p={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ fontSize: 14 }}
                >
                  <Box display="flex" flexDirection="column">
                    <strong>Email</strong>
                    <a href={sensorSalesLink}>{configLinks.emailSupport}</a>
                  </Box>
                  <Button variant="contained" href={sensorSalesLink}>
                    <RowBox>
                      <Icon name="mail" fontSize="small" />
                      <Box ml={1}>Email sales</Box>
                    </RowBox>
                  </Button>
                </Box>
              </Paper>
            </Box>
            <Box mt={3}>
              <strong>Other support</strong>
              <br />
              For all other support enquiries not related to sensors, please
              <br />
              <a href={generalSupportLink}>contact general support</a>.
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const Index = () => {
  const { lang } = useQuerySettings()
  const { location } = useAuth()

  return (
    <>
      <AreaHeader
        title={lang.area.sensors}
        titleIcon="sensors-light"
        subTitle={location ? location.name : null}
        desktopButtons={
          <RowBox>
            <Box mr={1}>
              <MoreButton />
            </Box>
            <DesktopSensorsReportsButton />
          </RowBox>
        }
      />

      <Content full start mb={5}>
        <Switch>
          <Route path="/sensors/:id/:days" component={Sensor} />
          <Route path="/sensors" component={Sensors} />
        </Switch>
      </Content>
    </>
  )
}

export default Index
