import { v4 as uuidv4 } from "uuid"

export const mapToIds = (collection) => collection?.map((item) => toId(item)) || []

export const toId = (item, allowNull) => (!item && allowNull ? null : item?._id || item?.id || item)

export const toRef = (item, allowNull) => (!item && allowNull ? null : item?.__ref)

export const arraysMatch = (a, b) => a.every((item) => b.includes(item)) && b.every((item) => a.includes(item))

export const toIntArray = (collection) => collection.map((item) => Number.parseInt(item))

export const makeTemporaryId = () => `temp-${uuidv4()}`

export const isTemporaryId = (id) => id?.toString().startsWith("temp-")

export const mapRemoveTemporaryIds = (collection) =>
  collection.map(({ id, ...item }) => (isTemporaryId(toId(item, true)) ? { ...item } : { ...item, id }))

export const formatEnumKeyForDisplay = (key) => {
  return key.replace(/_/g, " ").toLowerCase()
}

export const joinStringsWithNaturalLanguage = (arrayOfStrings, conjunction = "and") =>
  arrayOfStrings.join(", ").replace(/, ([^,]*)$/, ` ${conjunction} $1`)

export const valueByDotNotation = (path, parent) => {
  if (path.includes(".")) {
    return path.split(".").reduce(
      (acc, propName) => {
        acc.value = acc.parent[propName]
        acc.parent = acc.value
        return acc
      },
      { parent, value: null }
    ).value
  }
  return parent[path]
}

// TODO: replace - apollo client 3.8.0 should have middleware to
// remove __typename for mutation inputs
export const deepRemoveTypenames = (obj) => deepOmit(obj, ["__typename"])

// TODO: replace - apollo client 3.8.0 should have middleware to
// remove __typename for mutation inputs
export const removeTypename = (prev) => {
  const value = prev ? { ...prev } : prev
  if (value?.__typename) {
    delete value.__typename
  }
  return value
}

// recursively iterates through an object or
// array and omits the specified keys
export const deepOmit = (obj, keysToOmit) => {
  const omitKeys = (value) => {
    if (Array.isArray(value)) {
      return value.map((item) => omitKeys(item))
    }
    if (typeof value === "object" && value !== null) {
      return Object.keys(value).reduce((acc, key) => {
        if (!keysToOmit.includes(key)) {
          acc[key] = omitKeys(value[key])
        }
        return acc
      }, {})
    }
    return value
  }
  return omitKeys(obj)
}

export const hasIterator = (obj) => {
  // checks for null and undefined
  if (obj == null) {
    return false
  }
  return typeof obj[Symbol.iterator] === "function"
}

export const isLastInArray = (array, index) => index === array.length - 1

export const isArrayOddLength = (array) => array.length % 2 > 0

export const listHasSomeById = (list1, list2) =>
  list1.some((item1) => list2.find((item2) => toId(item2) === toId(item1)))

export const chunkArray = (array, chunkSize) =>
  Array(Math.ceil(array.length / chunkSize))
    .fill()
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize))

export const multipleSelectChange = (prev, eventOrValues) => {
  const targetValues = eventOrValues.target?.value || eventOrValues
  if (prev.includes("all") && targetValues.length > 1) {
    return targetValues.filter((targetValue) => targetValue !== "all")
  }
  if ((!prev.includes("all") && targetValues.includes("all")) || !targetValues.length) {
    return ["all"]
  }
  return targetValues
}
