import { Box, Divider, makeStyles, TextField } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import arrayMove from "array-move"
import React, { forwardRef, useEffect, useState } from "react"
import { useMutationCreateTrainingModule, useMutationUpdateTrainingModule, useQuerySettings } from "../../data"
import { useAuth } from "../../services"
import { mapToIds, toId } from "../../utils"
import { KnowledgeSearch } from "../AreaSearch"
import { UsersGroupsAssigner } from "../Assigners"
import { ColumnBox, PaperBox, RowBox } from "../Boxes"
import { DeleteConfirmIconButton, ShowMore } from "../Buttons"
import { CreatorActions, CreatorMaster } from "../Creators"
import { FieldSectionHeading, Caption } from "../Headings"
import { AdornmentIcon } from "../Icon"
import { DragHandle, SortableList } from "../Sortable"

const useArticleStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: 8,
  },
  drag: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "500",
    color: theme.palette.text.primary,
  },
  caption: {
    fontSize: 12,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
  navigate: {
    cursor: "pointer",
  },
}))

const TrainingArticleListItem = forwardRef(function TrainingArticle({ itemIndex, item, onRemoveItem }, ref) {
  const classes = useArticleStyles()
  const { title, category } = item

  const handleRemove = () => {
    onRemoveItem && onRemoveItem(itemIndex)
  }

  return (
    <Box ref={ref} className={classes.root}>
      {itemIndex > 0 && <Divider />}
      <RowBox p={2} alignItems="center">
        <DragHandle icon="drag" className={classes.drag} />
        <AdornmentIcon name="document" className={classes.icon} />
        <ColumnBox ml={1} mr="auto">
          <Box className={classes.title}>{title}</Box>
          <Box className={classes.caption}>{category}</Box>
        </ColumnBox>
        <DeleteConfirmIconButton onDelete={handleRemove} />
      </RowBox>
    </Box>
  )
})

const TrainingModuleCreator = ({ edit, onClose }) => {
  const {
    settings: { locations: userLocations },
    hasFeature,
  } = useAuth()
  const [createTrainingModule] = useMutationCreateTrainingModule()
  const [updateTrainingModule] = useMutationUpdateTrainingModule()
  const [name, setName] = useState("")
  const [knowledges, setKnowledges] = useState([])
  const [users, setUsers] = useState([])
  const [groups, setGroups] = useState([])
  const { lang } = useQuerySettings()
  const [initialPicked, setInitialPicked] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (edit) {
      const noUsersOrGroups = !edit.users.length && !edit.groups.length

      setName(edit.name)
      setKnowledges(
        edit.knowledges.map((knowledge) => ({
          id: toId(knowledge),
          title: knowledge.title,
          category: knowledge.category.name,
        }))
      )
      setUsers(mapToIds(edit.users))
      setGroups(mapToIds(edit.groups))

      setInitialPicked(noUsersOrGroups ? [] : [...edit.users, ...edit.groups])
    } else {
      setInitialPicked([])
    }
  }, [edit])

  const hasTrainingAssessment = hasFeature(["training", "training_assessment"])

  const handleSubmit = async () => {
    setLoading(true)
    const variables = { name, knowledges: mapToIds(knowledges), users, groups }
    if (edit) {
      await updateTrainingModule({
        variables: {
          id: toId(edit),
          ...variables,
        },
      })
    } else {
      await createTrainingModule({
        variables,
      })
    }
    setLoading(false)
    onClose && onClose()
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleAddArticle = (item) => {
    if (knowledges.some((knowledge) => toId(knowledge) === toId(item))) {
      return
    }
    setKnowledges((prev) => [...prev, { ...item }])
  }

  const handleRemoveArticle = (index) => {
    setKnowledges((state) => {
      state.splice(index, 1)
      return [...state]
    })
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setKnowledges((state) => {
      const next = arrayMove(state, oldIndex, newIndex)
      return [...next]
    })
  }

  const handleUsersGroupsPickedChanged = (picked) => {
    setUsers([...mapToIds(picked.filter((p) => p.__typename === "User"))])
    setGroups([...mapToIds(picked.filter((p) => p.__typename === "Group"))])
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const formValid = () => Boolean(name)

  const isFormValid = formValid()

  const form = (
    <>
      <Box mb={3}>
        <Alert severity="info">
          <AlertTitle>What are training modules?</AlertTitle>
          <>
            {!hasTrainingAssessment && (
              <>
                <Box mb={2}>
                  Modules are areas of training focus for your business. They link articles from your Knowledge Base.
                </Box>
                <Box>
                  Courses then combine several modules, and are what you assign to your staff to complete as part of
                  their online training.
                </Box>
              </>
            )}
            {hasTrainingAssessment && (
              <>
                <Box>
                  Modules are areas of training focus for your business. They can reference articles from your Knowledge
                  Base. Modules can also be associated with trainers, your staff members capable of training other staff
                  on these areas of training focus.
                </Box>
                <ShowMore text="More Information">
                  <Box my={2}>
                    Courses then combine several modules, and are what you assign to your staff to complete as part of
                    their online training.
                  </Box>
                  <Box>
                    Modules can also be used in Assessment to record staff competencies, and plan any further training.
                  </Box>
                </ShowMore>
              </>
            )}
          </>
        </Alert>
      </Box>

      <Box mb={3}>
        <TextField
          label="Module name"
          value={name}
          variant="outlined"
          fullWidth
          required
          inputProps={{ "data-cy": "TextField-name" }}
          onChange={handleNameChange}
        />
      </Box>

      <Divider />

      <FieldSectionHeading mt={2}>Add {lang.area.knowledge} articles to your module</FieldSectionHeading>

      <Box mb={3}>
        <KnowledgeSearch placeholder="Start typing an article name..." onItemSelected={handleAddArticle} />
      </Box>

      {Boolean(knowledges.length) && (
        <>
          <FieldSectionHeading mb={0.5}>Articles</FieldSectionHeading>
          <Caption mb={1.5}>List your articles in the order you want them to be completed</Caption>
          <PaperBox mb={3}>
            <SortableList
              items={knowledges}
              itemComponent={TrainingArticleListItem}
              itemProps={{
                onRemoveItem: handleRemoveArticle,
              }}
              onSortEnd={handleSortEnd}
              useDragHandle
              disablePadding
            />
          </PaperBox>
        </>
      )}

      {hasTrainingAssessment && (
        <>
          <Divider />

          <FieldSectionHeading mt={2} mb={0.5}>
            Add trainers
          </FieldSectionHeading>
          <Caption mb={1.5}>
            If relevant, associate this module with staff members capable of training other staff on this module's
            content.
          </Caption>

          {initialPicked && (
            <Box mb={3}>
              <UsersGroupsAssigner
                title="Trainers for this module"
                placeholder="Nobody selected"
                allowAll={false}
                allowSelf
                allowGroups={false}
                locations={mapToIds(userLocations)}
                initialPicked={initialPicked}
                onPickedChanged={handleUsersGroupsPickedChanged}
                cy="TrainingModuleCreator-trainers"
              />
            </Box>
          )}
        </>
      )}

      <CreatorActions
        id="TrainingModuleCreator-CreatorActions"
        submitLoading={loading}
        subject="Module"
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid}
      />
    </>
  )

  return (
    <CreatorMaster
      id="training-module-creator-dialog"
      open
      subject="Module"
      form={form}
      isEdit={Boolean(edit)}
      isInline={false}
      onClose={handleClose}
    />
  )
}

export { TrainingModuleCreator }
