import React from "react"
import { MenuItem, makeStyles } from "@material-ui/core"
import { JOB_STATUS, JOB_STATUS_LABELS } from "../../data"
import { OutlinedSelect } from "./OutlinedSelect"

const useStyles = makeStyles((theme) => {
  const styles = {
    root: {
      minWidth: 125,
    },
  }

  Object.values(JOB_STATUS).forEach((value) => {
    styles[`icon_${value}`] = {
      color: theme.palette.status[value] || "inherit",
    }
  })

  return styles
})

const JobStatusOutlinedSelect = ({ onDataLoaded, disabled, ...rest }) => {
  const classes = useStyles()

  return (
    <OutlinedSelect
      label="Status"
      native={false}
      classes={{ root: classes.root }}
      disabled={disabled}
      selectProps={{
        renderValue: (selected) => {
          if (Array.isArray(selected)) {
            return selected.map((value) => JOB_STATUS_LABELS[value]).join(", ")
          }

          return JOB_STATUS_LABELS[selected]
        },
      }}
      {...rest}
    >
      {Object.values(JOB_STATUS).map((value) => (
        <MenuItem key={value} value={value}>
          {JOB_STATUS_LABELS[value]}
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { JobStatusOutlinedSelect }
