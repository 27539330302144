import { useLazyQuery, gql } from "@apollo/client"

const BILLINGPORTALSESSION_QUERY = gql`
  query BillingPortalSession {
    billingPortalSession {
      access_url
      expires_at
    }
  }
`

const useLazyQueryBillingPortalSession = (options) =>
  useLazyQuery(BILLINGPORTALSESSION_QUERY, {
    fetchPolicy: "network-only",
    ...options,
  })

export { useLazyQueryBillingPortalSession }
