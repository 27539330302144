import React, { useMemo, useState } from "react"
import { Switch, useHistory, useParams } from "react-router-dom"
import { Box, Button, ButtonGroup, makeStyles, useMediaQuery } from "@material-ui/core"
import { Add as AddIcon } from "@material-ui/icons"
import { useTheme } from "@material-ui/styles"
import {
  AreaHeader,
  PrivateRoute,
  KnowledgeArticleCreator,
  FloatingActionButton,
  DesktopTrainingButton,
  RowBox,
  ScrollToTop,
  RequirePermissions,
} from "../../components"
import { useMutationDeleteCategory, useMutationDeleteKnowledge, useMutationCopyKnowledge } from "../../data"
import Categories from "./Categories"
import Category from "./Category"
import Article from "./Article"
import { mapToIds, multipleSelectChange, toId, useKnowledgeUtils } from "../../utils"
import { KnowledgeFiltersButton } from "../../components/Knowledge/KnowledgeFiltersButton"
import { useAuth } from "../../services"

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
  },
  areaMobileFilters: {
    position: "absolute",
    right: 16,
    top: 6,
  },
}))

const Knowledge = () => {
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { id } = useParams()
  const { hasFeature } = useAuth()
  const history = useHistory()
  const [groups, setGroups] = useState(["all"])
  const [locations, setLocations] = useState(["all"])
  const [tags, setTags] = useState(["all"])

  const [deleteKnowledge] = useMutationDeleteKnowledge()
  const [copyKnowledge] = useMutationCopyKnowledge()

  const [deleteCategory] = useMutationDeleteCategory()
  const { getNavigateToCategoryForID, getNavigateToArticleLinkForID } = useKnowledgeUtils()

  const groupIds = useMemo(() => {
    const filteredGroups = groups.filter((g) => g !== "all")
    return filteredGroups.length ? mapToIds(filteredGroups) : null
  }, [groups])

  const locationIds = useMemo(() => {
    const filteredLocations = locations.filter((l) => l !== "all")
    return filteredLocations.length ? mapToIds(filteredLocations) : null
  }, [locations])

  const tagIds = useMemo(() => {
    const filteredTags = tags.filter((l) => l !== "all")
    return filteredTags.length ? mapToIds(filteredTags) : null
  }, [tags])

  const handleGroupsChange = (event) => {
    setGroups([...multipleSelectChange(groups, event)])
  }
  const handleLocationsChange = (event) => {
    setLocations([...multipleSelectChange(locations, event)])
  }
  const handleRegionChange = (regionLocations) => {
    setLocations([...multipleSelectChange(locations, regionLocations)])
  }

  const handleTagsChange = (event) => {
    setTags([...multipleSelectChange(tags, event)])
  }

  const handleCreateArticleClick = () => {
    history.push("/knowledge/article/new")
  }
  const handleCreateArticleClose = () => {
    history.push("/knowledge")
  }
  const handleCreateArticleCreated = (item) => {
    history.push(getNavigateToArticleLinkForID(item.id))
  }
  const handleEditArticle = (knowledge) => {
    history.push(`/knowledge/article/${toId(knowledge)}/edit`)
  }
  const handleDeleteArticle = async ({ id: deleteId, category }) => {
    const result = await deleteKnowledge({
      variables: {
        id: deleteId,
      },
    })

    if (result?.data?.deleteKnowledge) {
      history.push(getNavigateToCategoryForID(toId(category)))
    }
  }
  const handleCopyArticle = async (knowledge) => {
    const result = await copyKnowledge({
      variables: {
        id: knowledge.id,
      },
    })

    if (result?.data) {
      history.push(`/knowledge/article/${result?.data?.knowledge.copy.id}/edit`)
    }
  }

  const handleEditCategory = (category) => {
    history.push(`/knowledge/category/${toId(category)}/edit`)
  }
  const handleDeleteCategory = async ({ id: deleteId }) => {
    await deleteCategory({
      variables: {
        id: deleteId,
      },
    })
  }

  const isNew = id === "new"

  const hasLocationsAndGroups = hasFeature("knowledge_access")

  return (
    <>
      <ScrollToTop />
      {isNew && (
        <KnowledgeArticleCreator
          open
          onClose={handleCreateArticleClose}
          onCreated={handleCreateArticleCreated}
          isInline={xs}
        />
      )}

      {(!xs || (xs && !isNew)) && (
        <Box className={classes.container}>
          <AreaHeader
            title="Knowledge Base"
            titleIcon="knowledge-light"
            desktopButtons={
              <RowBox>
                <Box mr={1}>
                  <DesktopTrainingButton />
                </Box>

                <RequirePermissions requires="knowledge_create">
                  <Box mr={hasLocationsAndGroups ? 1 : 0}>
                    <ButtonGroup variant="contained" color="primary">
                      <Button onClick={handleCreateArticleClick} data-cy="Button-create">
                        Create article
                      </Button>
                    </ButtonGroup>
                  </Box>
                </RequirePermissions>

                {hasLocationsAndGroups && (
                  <KnowledgeFiltersButton
                    groups={groups}
                    locations={locations}
                    tags={tags}
                    onGroupsChange={handleGroupsChange}
                    onLocationsChange={handleLocationsChange}
                    onRegionChange={handleRegionChange}
                    onTagsChange={handleTagsChange}
                    options={{
                      hasGroups: true,
                      hasLocations: true,
                    }}
                  />
                )}
              </RowBox>
            }
            mobileButtons={
              <>
                {hasLocationsAndGroups && (
                  <Box className={classes.areaMobileFilters}>
                    <KnowledgeFiltersButton
                      groups={groups}
                      locations={locations}
                      onGroupsChange={handleGroupsChange}
                      onLocationsChange={handleLocationsChange}
                      onRegionChange={handleRegionChange}
                      onTagsChange={handleTagsChange}
                      options={{
                        hasGroups: true,
                        hasLocations: true,
                      }}
                    />
                  </Box>
                )}
                <RequirePermissions requires="knowledge_create">
                  <FloatingActionButton
                    color="primary"
                    aria-label="Create article"
                    onClick={handleCreateArticleClick}
                    data-cy="FloatingActionButton-create"
                  >
                    <AddIcon />
                  </FloatingActionButton>
                </RequirePermissions>
              </>
            }
          />

          <Switch>
            <PrivateRoute
              exact
              path="/knowledge/article/new"
              component={() => (
                <Categories
                  groups={groupIds}
                  locations={locationIds}
                  tags={tagIds}
                  onEditCategory={handleEditCategory}
                  onDeleteCategory={handleDeleteCategory}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/knowledge"
              component={() => (
                <Categories
                  groups={groupIds}
                  locations={locationIds}
                  tags={tagIds}
                  onEditCategory={handleEditCategory}
                  onDeleteCategory={handleDeleteCategory}
                />
              )}
            />
            <PrivateRoute
              path="/knowledge/training/:id"
              component={() => (
                <Categories
                  groups={groupIds}
                  locations={locationIds}
                  onEditCategory={handleEditCategory}
                  onDeleteCategory={handleDeleteCategory}
                  showingTraining
                />
              )}
            />
            <PrivateRoute
              path="/knowledge/training"
              component={() => (
                <Categories
                  groups={groupIds}
                  locations={locationIds}
                  onEditCategory={handleEditCategory}
                  onDeleteCategory={handleDeleteCategory}
                  showingTraining
                />
              )}
            />
            <PrivateRoute
              exact
              path="/knowledge/category/:id/:action"
              component={() => (
                <Category
                  groups={groupIds}
                  locations={locationIds}
                  tags={tagIds}
                  onEditArticle={handleEditArticle}
                  onDeleteArticle={handleDeleteArticle}
                  onEditCategory={handleEditCategory}
                  onDeleteCategory={handleDeleteCategory}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/knowledge/category/:id"
              component={() => (
                <Category
                  groups={groupIds}
                  locations={locationIds}
                  tags={tagIds}
                  onEditArticle={handleEditArticle}
                  onDeleteArticle={handleDeleteArticle}
                  onCopyArticle={handleCopyArticle}
                  onEditCategory={handleEditCategory}
                  onDeleteCategory={handleDeleteCategory}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/knowledge/article/:id/:action"
              component={() => (
                <Article
                  onEditArticle={handleEditArticle}
                  onDeleteArticle={handleDeleteArticle}
                  onCopyArticle={handleCopyArticle}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/knowledge/article/:id"
              component={() => (
                <Article
                  onEditArticle={handleEditArticle}
                  onDeleteArticle={handleDeleteArticle}
                  onCopyArticle={handleCopyArticle}
                />
              )}
            />
          </Switch>
        </Box>
      )}
    </>
  )
}

export default Knowledge
