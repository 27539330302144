import { Box, Chip, Divider, Grid, makeStyles, MenuItem, Tooltip } from "@material-ui/core"
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { NavLink, useHistory, useParams } from "react-router-dom"
import pluralize from "pluralize"
import {
  ActionCard,
  AuthorTools,
  Icon,
  IconMessage,
  RowBox,
  TrainingModuleCreator,
  LoadingSpinner,
  SearchInput,
  OutlinedSelect,
} from "../../components"
import { useMutationDeleteTrainingModule, useQueryTrainingCourses, useQueryTrainingModules } from "../../data"
import { mapToIds, toId } from "../../utils"
import { useAuth } from "../../services"
import { ListWindowVirtualizer } from "../../components/Lists/ListWindowVirtualizer"

const useStyles = makeStyles((theme) => ({
  info: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  rightChildren: {
    height: 40,
    overflow: "visible",
  },
}))

const useModuleCoursesIndicatorStyles = makeStyles((theme) => ({
  prefix: {
    whiteSpace: "nowrap",
  },
  location: {
    fontWeight: "600",
  },
  locations: {
    fontWeight: "600",
    color: theme.palette.primary.main,
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
}))

const ModuleCoursesIndicator = ({ module, courses, loading, ...rest }) => {
  const classes = useModuleCoursesIndicatorStyles()
  const moduleCourses = courses.filter((item) => mapToIds(item.modules).includes(toId(module)))

  const title = moduleCourses.map((item) => item.name).join(", ") || ""

  if (loading) {
    return <LoadingSpinner size={32} />
  }

  if (!moduleCourses.length) {
    return null
  }

  if (moduleCourses.length === 1) {
    return (
      <Box mr={0.5} className={classes.prefix} {...rest}>
        Active in <span className={classes.location}>{title}</span>
      </Box>
    )
  }

  return (
    <RowBox {...rest}>
      <Box mr={0.5} className={classes.prefix}>
        Active in
      </Box>
      <Tooltip title={title}>
        <Box className={classes.locations}>{pluralize("course", moduleCourses.length, true)}</Box>
      </Tooltip>
    </RowBox>
  )
}

const Modules = () => {
  const classes = useStyles()
  const history = useHistory()
  const { hasFeature } = useAuth()
  const { id } = useParams()
  const { data, loading, refetch } = useQueryTrainingModules()
  const { data: coursesData, loading: coursesLoading } = useQueryTrainingCourses()
  const [deleteTrainingModule] = useMutationDeleteTrainingModule()

  const [edit, setEdit] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [course, setCourse] = useState("any")

  const containerRef = useRef(null)
  const offsetRef = useRef(0)

  useEffect(() => {
    if (data && id && id !== "new") {
      setEdit(data.trainingModules.find((item) => toId(item) === id))
    } else if (!id && edit) {
      setEdit(null)
    }
  }, [data, edit, id])

  useLayoutEffect(() => {
    offsetRef.current = containerRef.current?.offsetTop ?? 0
  }, [containerRef])

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleEdit = (item) => {
    history.push(`/training/modules/${toId(item)}`)
  }
  const handleDelete = async (item) => {
    await deleteTrainingModule({ variables: { id: toId(item) } })
    refetch()
  }
  const handleClose = () => {
    history.push("/training/modules")
    refetch()
  }

  const handleCourseChange = (event) => {
    setCourse(event.target.value)
  }

  const items = useMemo(() => {
    const courseModules =
      course !== "any"
        ? mapToIds(coursesData?.trainingCourses.filter((item) => toId(item) === course)?.[0]?.modules || [])
        : []
    return (
      data?.trainingModules.filter(
        (item) =>
          (!searchText || item.name.toLowerCase().includes(searchText)) &&
          (course === "any" || courseModules.includes(toId(item)))
      ) || []
    )
  }, [course, coursesData, data, searchText])

  const isNew = id === "new"

  const hasCourses = coursesData?.trainingCourses.length > 0

  const hasFilter = data?.trainingModules.length > 5 || hasCourses

  const hasTrainingAssessment = hasFeature("training_assessment")

  return (
    <>
      {(isNew || Boolean(edit)) && <TrainingModuleCreator edit={edit} onClose={handleClose} />}

      {hasFilter && (
        <Box mb={4}>
          <Grid container>
            <Grid item xs={hasCourses ? 6 : 12}>
              <SearchInput placeholder="Filter modules" boxProps={{ pl: 0, pr: 1, mb: 0 }} onChange={handleFilter} />
            </Grid>
            {hasCourses && (
              <Grid item xs={6}>
                <OutlinedSelect placeholder="Any course..." native={false} value={course} onChange={handleCourseChange}>
                  <MenuItem value="any">Any course...</MenuItem>
                  <Divider />
                  {coursesData?.trainingCourses.map((item) => (
                    <MenuItem key={toId(item)} value={toId(item)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </OutlinedSelect>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      <div ref={containerRef}>
        {data && (
          <ListWindowVirtualizer
            items={items}
            itemContent={(_, item) => (
              <div key={toId(item)}>
                <ActionCard
                  title={item.name}
                  cursor="default"
                  detail={item.knowledges.length ? pluralize("article", item.knowledges.length, true) : null}
                  rightChildren={
                    <Box
                      mr="auto"
                      display="flex"
                      flexDirection="row"
                      alignSelf="center"
                      alignItems="center"
                      className={classes.rightChildren}
                    >
                      <AuthorTools
                        item={item}
                        subject="module"
                        admins={{ edit: "training_update_all", delete: "training_delete_all" }}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                      />
                    </Box>
                  }
                  footer={
                    <>
                      <Box mr="auto">
                        <RowBox flexWrap="wrap" style={{ rowGap: 10 }}>
                          {item.knowledges.map((knowledge, knowledgeIndex) => (
                            <RowBox key={toId(knowledge)} alignItems="center">
                              <Tooltip title="Go to article">
                                <Chip
                                  component={NavLink}
                                  to={`/knowledge/article/${toId(knowledge)}`}
                                  label={knowledge.title}
                                  clickable
                                />
                              </Tooltip>
                              {knowledgeIndex < item.knowledges.length - 1 && <Icon name="right" />}
                            </RowBox>
                          ))}
                        </RowBox>
                      </Box>
                      <Box ml="auto">
                        <ModuleCoursesIndicator
                          module={item}
                          courses={coursesData?.trainingCourses || []}
                          loading={coursesLoading}
                          ml={1}
                        />
                      </Box>
                    </>
                  }
                />
              </div>
            )}
          />
        )}

        {!data && loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}

        {data?.trainingModules.length === 0 && (
          <IconMessage
            name="empty-trainingmodules"
            maxWidth={500}
            text={
              <>
                {!hasTrainingAssessment && (
                  <>
                    <Box mb={2}>
                      Modules are areas of training focus for your business. They link articles from your Knowledge
                      Base.
                    </Box>
                    <Box mb={2}>
                      Courses then combine several modules, and are what you assign to your employees to complete as
                      part of their online training.
                    </Box>
                    <Box mb={2}>
                      Create your first module using the Create button above to get started with training.
                    </Box>
                  </>
                )}
                {hasTrainingAssessment && (
                  <>
                    <Box mb={2}>
                      Modules are areas of training focus for your business. They can reference articles from your
                      Knowledge Base. Modules can also be associated with trainers, your employees capable of training
                      other staff on these areas of training focus.
                    </Box>
                    <Box mb={2}>
                      Courses then combine several modules, and are what you assign to your employees to complete as
                      part of their online training.
                    </Box>
                    <Box mb={2}>
                      Modules can also be used in Assessment to record your employees competencies, and plan any further
                      training.
                    </Box>
                    <Box mb={2}>
                      Create your first module using the Create button above to get started with training.
                    </Box>
                  </>
                )}
              </>
            }
          />
        )}
      </div>
    </>
  )
}

export { Modules }
