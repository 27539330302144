import React, { Suspense } from "react"
import moment from "moment-timezone"

const SplitHumanReadableCron = React.lazy(() => import("./SplitHumanReadableCron"))

const HumanReadableCron = ({ expression, timeZone }) => {
  if (!expression) {
    return null
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SplitHumanReadableCron expression={expression} /> {moment.tz(timeZone).format("z")}
    </Suspense>
  )
}

export default HumanReadableCron
