import React, { useState, useEffect, Suspense } from "react"
import { Grid, Hidden, Box, Tabs, Tab, Divider } from "@material-ui/core"
import { useParams } from "react-router-dom"

import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { AreaHeader, Content, BackToTop, LoadingSpinner, RowBox, ColumnBox } from "../../components"
import { useQueryArea } from "../../data/areas/useQueryArea"
import { useAuth } from "../../services"
import AreaTagLeftSidebar from "./AreaTagLeftSidebar"
import AreaTagBreadcrumb from "./AreaTagBreadcrumb"

const AreaTagJobsTab = React.lazy(() => import("./AreaTagJobsTab"))
const AreaTagRaisedActionsTab = React.lazy(() => import("./AreaTagRaisedActionsTab"))
const AreaTagArticlesTab = React.lazy(() => import("./AreaTagArticlesTab"))
const AreaTagDocumentsTab = React.lazy(() => import("./AreaTagDocumentsTab"))
const AreaTagSuppliersTab = React.lazy(() => import("./AreaTagSuppliersTab"))

const AreaTag = () => {
  const { slug } = useParams()
  const history = useHistory()
  const { loading: queryAreaLoading, data: areaData } = useQueryArea({ variables: { id: slug } })
  const { location: userLocation } = useAuth()
  const [area, setArea] = useState()
  const [openSubNav, setOpenSubNav] = useState(false)
  const [tab, setTab] = useState("jobs")

  const handleToggleSubNav = () => {
    setOpenSubNav(!openSubNav)
  }

  useEffect(() => {
    if (areaData && Object.entries(areaData).length > 0) {
      setArea(areaData?.area)
    }
  }, [areaData])

  useEffect(() => {
    if (!queryAreaLoading && !areaData) {
      history.push("/")
    }
  }, [areaData, history, queryAreaLoading])

  const loadingSpinnerOverlay = () => {
    return (
      <ColumnBox justifyContent="center" alignItems="center">
        <Box>
          <LoadingSpinner size={70} />
        </Box>
      </ColumnBox>
    )
  }

  const handleTabChange = (_, newValue) => {
    setTab(newValue)
  }

  if (queryAreaLoading) {
    return loadingSpinnerOverlay()
  }

  const singleTag = area?.tags?.length === 1

  return (
    <>
      <AreaHeader
        title={area?.name ?? ""}
        titleIcon={`${area?.icon ?? "templates"}-light`}
        subTitle={userLocation?.name ?? ""}
        beta
      />
      <Content start>
        <Grid container direction="row">
          {!singleTag && (
            <Hidden mdDown>
              <Grid item xs={12} lg={3}>
                <AreaTagLeftSidebar tags={area?.tags ?? []} open={openSubNav} onClose={handleToggleSubNav} />
                <BackToTop from={300} />
              </Grid>
            </Hidden>
          )}
          {!singleTag && (
            <Hidden lgUp>
              <AreaTagLeftSidebar tags={area?.tags ?? []} open={openSubNav} onClose={handleToggleSubNav} />
            </Hidden>
          )}
          <Grid item xs={12} lg={singleTag ? 12 : 9}>
            {!singleTag && (
              <RowBox flexGrow={1}>
                <Box flexGrow={1}>
                  <AreaTagBreadcrumb area={area} />
                </Box>
                {/* <Box alignSelf="start" display={{ sm: "flex", lg: "none" }}>
                <IconButton onClick={handleToggleSubNav} className={classes.filterButton}>
                  <FilterListIcon />
                </IconButton>
              </Box> */}
              </RowBox>
            )}
            <Box>
              <Tabs
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                value={tab}
                onChange={handleTabChange}
                aria-label="Tag view section tabs"
                data-cy="Tabs"
              >
                <Tab label="Jobs" value="jobs" />
                <Tab label="Raised Actions" value="actions" />
                <Tab label="Articles" value="knowledges" />
                <Tab label="Documents" value="documents" />
                <Tab label="Suppliers" value="suppliers" />
              </Tabs>
              <Divider />
              <Suspense fallback={loadingSpinnerOverlay()}>
                {tab === "jobs" && <AreaTagJobsTab tags={area?.tags ?? []} />}
                {tab === "actions" && <AreaTagRaisedActionsTab tags={area?.tags ?? []} />}
                {tab === "knowledges" && (
                  <Box sx={{ py: 3 }}>
                    <AreaTagArticlesTab tags={area?.tags ?? []} />
                  </Box>
                )}
                {tab === "documents" && (
                  <Box sx={{ py: 3 }}>
                    <AreaTagDocumentsTab tags={area?.tags ?? []} />
                  </Box>
                )}
                {tab === "suppliers" && (
                  <Box sx={{ py: 3 }}>
                    <AreaTagSuppliersTab tags={area?.tags ?? []} />
                  </Box>
                )}
              </Suspense>
            </Box>
          </Grid>
        </Grid>
      </Content>
    </>
  )
}

export default AreaTag
