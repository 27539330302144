import { useQuery, gql } from "@apollo/client"

const REGION_FIELDS = gql`
  fragment RegionFields on Region {
    id
    name
  }
`

const REGIONS_QUERY = gql`
  ${REGION_FIELDS}
  query Regions {
    regions {
      ...RegionFields
    }
  }
`

const useQueryRegions = (options) => useQuery(REGIONS_QUERY, options)

export { useQueryRegions, REGION_FIELDS }
