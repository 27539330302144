import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_JOB_STEP_COMPLETIONS_OVERVIEW_FIELDS = gql`
  fragment ReportJobStepOverviewFields on ReportJobStepOverview {
    id
    name
    job
    process
    completed
    overdue
    skipped
    uncompleted
  }
`

const REPORT_JOB_STEPS_OVERVIEW_QUERY = gql`
  ${REPORT_JOB_STEP_COMPLETIONS_OVERVIEW_FIELDS}
  query ReportJobStepsOverview($locations: [ID!]!, $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportJobStepsOverview(locations: $locations, gt: $gt, lt: $lt) {
      ...ReportJobStepOverviewFields
    }
  }
`

const useQueryReportJobStepsOverview = (options) =>
  useQuery(REPORT_JOB_STEPS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportJobStepsOverview = (options) =>
  useLazyQuery(REPORT_JOB_STEPS_OVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJobStepsOverview, useLazyQueryReportJobStepsOverview }
