import React from "react"
import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Button } from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { InlineTitleCloser } from "../Creators"
import { ErrorBoundary, InlineTitle, NoItemsMessage, RowBox } from ".."
import { SupplierDetails } from "./SupplierDetails"
import { SupplierPanel } from "./SupplierPanel"
import { useAuth } from "../../services"
import { toId } from "../../utils"
import { SupplierContactPanel } from "./SupplierContactPanel"

const SupplierViewer = ({ supplier, variant, onClose, isInline }) => {
  const { hasPermission } = useAuth()

  const handleOnClose = (event, isCancel) => {
    onClose(isCancel)
  }

  if (!supplier) {
    return null
  }

  const hasEditDetails = hasPermission("supplier_update")

  const display = (
    <>
      <Box px={isInline ? 2 : 0} py={isInline ? 2 : 0} data-cy="SupplierViewer-display">
        <SupplierPanel supplier={supplier} layout="landscape" mb={2} />
        <ErrorBoundary>
          <Box data-cy="SupplierViewer-page">
            <SupplierDetails supplier={supplier} />
          </Box>
        </ErrorBoundary>
      </Box>
    </>
  )

  if (isInline) {
    return (
      <>
        <InlineTitle>
          Supplier details
          <InlineTitleCloser onClose={handleOnClose} />
        </InlineTitle>
        {display}

        <RowBox pr={2} pb={2} justifyContent="flex-end">
          <Button
            component={NavLink}
            variant="contained"
            color="primary"
            to={`/suppliers/${toId(supplier)}/edit`}
            disabled={!hasEditDetails}
          >
            Edit details
          </Button>
        </RowBox>
      </>
    )
  }

  if (variant === "peek") {
    return (
      <Box>
        <SupplierPanel supplier={supplier} layout="landscape" mb={2} variant={variant} />
        <Box>
          {supplier.contacts?.length === 0 ? (
            <NoItemsMessage>No contacts added</NoItemsMessage>
          ) : (
            <>
              {supplier.contacts?.map((contact) => (
                <SupplierContactPanel key={toId(contact)} contact={contact} mb={1} />
              ))}
            </>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Dialog
      open={supplier !== null}
      onClose={handleOnClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        Supplier details
        <DialogTitleCloser onClose={handleOnClose} />
      </DialogTitle>
      <DialogContent>{display}</DialogContent>
      {hasEditDetails && (
        <DialogActions>
          <Box pt={1} pr={2} pb={2}>
            <Button component={NavLink} variant="contained" color="primary" to={`/suppliers/${toId(supplier)}/edit`}>
              Edit details
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}

export { SupplierViewer }
