import React, { useEffect, useState } from "react"
import { Box, Grid, Radio, Divider, TextField, RadioGroup, FormControlLabel, useMediaQuery } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useHistory, useParams } from "react-router-dom"
import { useTheme } from "@material-ui/styles"
import { Icon } from ".."
import { useFormUtils, useAnalytics } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import { PROCESS_TYPE, useLazyQueryProcess, useMutationCopyProcess } from "../../data"
import { NoItemsMessage } from "../Lists"

const initialState = {
  name: "",
  schedules: [],
}

const TemplateCopyCreator = ({ template: id = null, onClose, displayInline }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const [load, { data, loading: dataLoading }] = useLazyQueryProcess()
  const { isValid, isError, getErrorMessage, debounceTouched } = useFormUtils()
  const [copyProcess] = useMutationCopyProcess()
  const analytics = useAnalytics()
  const { set } = useParams()
  const history = useHistory()

  const [nameTouched, setNameTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rename, setRename] = useState(false)
  const [name, setName] = useState(initialState.name)
  const [schedules, setSchedules] = useState(initialState.schedules)

  useEffect(() => {
    if (id) {
      load({ variables: { id } })
    }
  }, [id, load])

  useEffect(() => {
    if (data) {
      setName(`${data.process.name} (copy)`)
      setSchedules([
        ...data.process.schedules.map((schedule) => ({
          ...schedule,
        })),
      ])
    }
  }, [data, id])

  const handleClose = (event, isCancel) => {
    handleResetState()
    onClose(isCancel)
  }

  const handleResetState = () => {
    setNameTouched(false)
    setLoading(false)
    setRename(false)
    setName(initialState.name)
    setSchedules(initialState.schedules)
  }

  const template = data?.process || null

  if (!id) {
    return null
  }

  const formValid = () => isValid(name) && schedules.length > 0
  const handleRename = () => {
    setRename(!rename)
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleScheduleUpdate = (scheduleId, value) => {
    const selectedSchedule = schedules.find((schedule) => schedule.id === scheduleId)
    selectedSchedule.name = value
    setSchedules([...schedules])
  }

  const handleScheduleReset = (scheduleId, value) => {
    const selectedSchedule = schedules.find((schedule) => schedule.id === scheduleId)

    if (value !== "") {
      return
    }

    const originalName = data.process.schedules.find((schedule) => schedule.id === scheduleId)
    selectedSchedule.name = originalName.name
    setSchedules([...schedules])
  }

  const isFormValid = formValid()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (formValid()) {
      setLoading(true)

      // If rename toggle is not active when submitted, revert to original schedule names
      let finalSchedules = schedules
      if (!rename) {
        finalSchedules = data.process.schedules
      }

      const inputSchedules = finalSchedules.map(({ id: scheduleId, name: scheduleName }) => ({
        id: scheduleId,
        name: scheduleName,
      }))
      const variables = {
        input: {
          name,
          schedules: inputSchedules,
        },
      }
      const item = await copyProcess({
        variables: {
          id,
          ...variables,
        },
      })
      handleResetState()
      analytics.track("Copied Process")
      if (item.data) {
        history.push(`/templates/${set.startsWith("active") ? `in${set}` : set}/${item.data.process.copy.id}/edit`)
      } else {
        handleClose()
      }
    }
  }

  let form
  let subject

  if (template) {
    subject = template.type === PROCESS_TYPE.AUDIT ? "Audit" : "Process"
    const subjectLowerCase = subject.toLowerCase()
    form = (
      <>
        {!template.inactive && (
          <Box mb={3}>
            <Alert severity="info">
              <AlertTitle>You're copying an active {subjectLowerCase}</AlertTitle>
              The copied {subjectLowerCase} will be made inactive to prevent unintended jobs from running. You can make
              the {subjectLowerCase} active once you have finished editing it.
            </Alert>
          </Box>
        )}

        <Box>
          <TextField
            label={`${subject} name`}
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            fullWidth
            required
            onBlur={() => debounceTouched(setNameTouched)}
            error={isError(name, nameTouched)}
            helperText={getErrorMessage(name, nameTouched, `Please enter a name for the ${subjectLowerCase}`)}
            inputProps={{ "data-cy": "TextField-title" }}
          />
        </Box>

        <RadioGroup value={rename} onChange={handleRename} row>
          <Box display="flex" flexDirection="column" mt={2} mb={2}>
            <FormControlLabel label="Keep existing schedule names" value={false} control={<Radio color="primary" />} />
            <FormControlLabel label="Rename schedules" value control={<Radio color="primary" />} />
          </Box>
        </RadioGroup>

        {rename && (
          <>
            {data.process.schedules.map((schedule) => (
              <Box key={schedule.id} mb={2}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} sm>
                    <TextField label="Existing name" variant="outlined" value={schedule.name} fullWidth readOnly />
                  </Grid>
                  {!xs && (
                    <Grid item>
                      <Icon name="forward" />
                    </Grid>
                  )}

                  {schedules
                    .filter((newSchedule) => newSchedule.id === schedule.id)
                    .map((newSchedule) => (
                      <Grid item xs={12} sm key={newSchedule.id}>
                        <TextField
                          label="New name"
                          onChange={(event) => handleScheduleUpdate(newSchedule.id, event.target.value)}
                          onBlur={(event) => handleScheduleReset(newSchedule.id, event.target.value)}
                          variant="outlined"
                          fullWidth
                          value={newSchedule.name}
                        />
                      </Grid>
                    ))}
                </Grid>

                {xs && (
                  <Box mb={3} mt={3}>
                    <Divider />
                  </Box>
                )}
              </Box>
            ))}
          </>
        )}

        <CreatorActions
          id="TemplateCopyCreator-CreatorActions"
          subject={subject}
          submitLabel={`Copy ${subjectLowerCase}`}
          onClose={handleClose}
          onSubmit={handleSubmit}
          disableSubmit={!isFormValid || loading}
          submitLoading={loading}
        />
      </>
    )
  } else if (!dataLoading) {
    form = <NoItemsMessage>Template not found</NoItemsMessage>
  }

  return (
    <CreatorMaster
      open={!!id}
      subject={subject}
      form={form}
      isCopy
      loading={dataLoading}
      isInline={displayInline}
      onClose={handleClose}
    />
  )
}

export { TemplateCopyCreator }
