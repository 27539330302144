import React, { useEffect, useState } from "react"
import { Box, Button, Checkbox, Divider, Grid, makeStyles } from "@material-ui/core"
import { MailOutline } from "@material-ui/icons"
import { ActionCard, LoadingSpinner, useSnackbar } from "../../components"
import { useMutationUpdateSelf, useQueryAdminableUser } from "../../data"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  info: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    display: "flex",
    flexGrow: 1,
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  stub: {
    marginLeft: -10,
  },
  completed: {
    color: theme.palette.success.main,
  },
}))

const SimpleNotifications = () => {
  const classes = useStyles()
  const { principal, hasPermission } = useAuth()
  const { data } = useQueryAdminableUser({ variables: { id: principal.userID, organisation: principal.organisation } })
  const [updateSelf] = useMutationUpdateSelf()
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [touched, setTouched] = useState(false)
  const [midday, setMidday] = useState(false)
  const [evening, setEvening] = useState(false)
  const [action, setAction] = useState(false)
  const [reminder, setReminder] = useState(false)
  const [overdue, setOverdue] = useState(false)
  const [training, setTraining] = useState(false)
  const [posts, setPosts] = useState(false)

  useEffect(() => {
    if (data) {
      setLoading(false)
      const { adminableUser: user } = data
      setMidday(user.settings.notifications.simple.midday)
      setEvening(user.settings.notifications.simple.evening)
      setAction(user.settings.notifications.simple.action)
      setReminder(user.settings.notifications.simple.reminder)
      setOverdue(user.settings.notifications.simple.overdue)
      setTraining(user.settings.notifications.simple.training)
      setPosts(user.settings.notifications.simple.posts)
    }
  }, [data])

  const handleSave = async () => {
    try {
      setLoading(true)
      const variables = {
        simpleNotifications: { midday, evening, action, reminder, overdue, training, posts },
      }
      await updateSelf({ variables })
      setLoading(false)
      setTouched(false)
      snackbar.showMessage({ message: "Notification preferences updated", icon: <MailOutline /> })
    } catch (error) {
      snackbar.showMessage({ message: error.message })
    }
  }

  const NotificationActionCard = ({ title, description, value, setter }) => {
    const handleChange = () => {
      setTouched(true)
      setter(!value)
    }

    return (
      <ActionCard
        title={title}
        detail={description}
        stub={
          <Grid item className={classes.stub}>
            <Checkbox color="primary" checked={value} onClick={handleChange} />
          </Grid>
        }
        rightChildren={<MailOutline />}
      />
    )
  }

  const hasJobReadAllPermission = hasPermission("jobprocess_read_all")

  return (
    <>
      <Box mb={5}>
        {!data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {data && (
          <>
            {hasJobReadAllPermission && (
              <NotificationActionCard
                title="Midday status update"
                description="One email sent per business location around midday, showing current status for the location's active jobs"
                setter={setMidday}
                value={midday}
              />
            )}
            {hasJobReadAllPermission && (
              <NotificationActionCard
                title="Evening status update"
                description="One email sent per business location around 6PM, showing current status for the location's active jobs"
                setter={setEvening}
                value={evening}
              />
            )}
            <NotificationActionCard
              title="Action status notifications"
              description="Notifications sent when an action you are linked to is created, updated, commented on, or marked resolved/unresolved"
              setter={setAction}
              value={action}
            />
            <NotificationActionCard
              title="Job reminder notifications"
              description="Notifications sent when a job is nearing its due time"
              setter={setReminder}
              value={reminder}
            />
            {hasJobReadAllPermission && (
              <NotificationActionCard
                title="Job overdue notifications"
                description="Notifications sent when an uncompleted job, with a set due time, becomes overdue"
                setter={setOverdue}
                value={overdue}
              />
            )}
            <NotificationActionCard
              title="Training notifications"
              description="Notifications sent when you are assigned a training course, or are reminded of an upcoming or overdue course"
              setter={setTraining}
              value={training}
            />
            <NotificationActionCard
              title="Post notifications"
              description="Notifications sent when a post you are assigned to in the Communications Hub is created, updated or commented on"
              setter={setPosts}
              value={posts}
            />
          </>
        )}
      </Box>

      <Divider />

      <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mt={2}>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={!touched || loading}>
          <Box px={2}>Save</Box>
        </Button>
      </Box>
    </>
  )
}

export default SimpleNotifications
