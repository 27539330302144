import { useQuery, gql } from "@apollo/client"
import { ALL_JOBS_LIST_FIELDS } from "./fragments/allJobsFieldsFragment"

const ALL_JOBS_QUERY = gql`
  query JobsListAll($filter: JobsQueryAllFilterInput!, $limit: Int, $offset: Int) {
    jobs: jobsV2 {
      listAll(filter: $filter, limit: $limit, offset: $offset) {
        ...AllJobsListFields
      }
    }
  }
  ${ALL_JOBS_LIST_FIELDS}
`

const ALL_JOBS_COUNT_QUERY = gql`
  query JobsCountAll($filter: JobsQueryAllFilterInput!, $limit: Int, $offset: Int) {
    jobs: jobsV2 {
      countAll(filter: $filter)
    }
  }
  ${ALL_JOBS_LIST_FIELDS}
`

const useQueryAllJobs = (options) => useQuery(ALL_JOBS_QUERY, options)

const useQueryAllJobsCount = (options) => useQuery(ALL_JOBS_COUNT_QUERY, options)

export { useQueryAllJobs, useQueryAllJobsCount }
