import { useMutation, gql } from "@apollo/client"
import { SCHEDULE_TRIGGER_FIELDS, EVENT_TRIGGER_FIELDS } from "./useQueryTriggers"

const TEST_TRIGGER_MUTATION = gql`
  mutation TestTrigger($id: ID!) {
    trigger(id: $id) {
      test
    }
  }
`

const PAUSE_TRIGGER_MUTATION = gql`
  ${SCHEDULE_TRIGGER_FIELDS}
  ${EVENT_TRIGGER_FIELDS}
  mutation PauseTrigger($id: ID!) {
    trigger(id: $id) {
      pause {
        ... on ScheduleTrigger {
          ...ScheduleTriggerFields
        }
        ... on EventTrigger {
          ...EventTriggerFields
        }
      }
    }
  }
`

const RESUME_TRIGGER_MUTATION = gql`
  ${SCHEDULE_TRIGGER_FIELDS}
  ${EVENT_TRIGGER_FIELDS}
  mutation ResumeTrigger($id: ID!) {
    trigger(id: $id) {
      resume {
        ... on ScheduleTrigger {
          ...ScheduleTriggerFields
        }
        ... on EventTrigger {
          ...EventTriggerFields
        }
      }
    }
  }
`

const DELETE_TRIGGER_MUTATION = gql`
  mutation DeleteTrigger($id: ID!) {
    trigger(id: $id) {
      delete
    }
  }
`

const useMutationTestTrigger = () => useMutation(TEST_TRIGGER_MUTATION)

const useMutationPauseTrigger = () => useMutation(PAUSE_TRIGGER_MUTATION)

const useMutationResumeTrigger = () => useMutation(RESUME_TRIGGER_MUTATION)

const useMutationDeleteTrigger = (type) =>
  useMutation(DELETE_TRIGGER_MUTATION, {
    update(cache, { errors }, { variables: { id } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id, __typename: type })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

export { useMutationTestTrigger, useMutationPauseTrigger, useMutationResumeTrigger, useMutationDeleteTrigger }
