import React, { useState } from "react"
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Box,
  makeStyles,
} from "@material-ui/core"
import { useFormUtils } from "../../utils"
import { ACTION_STATUS_VERBS, ACTION_STATUS_LABELS } from "../../data"

const useStyles = makeStyles((theme) => ({
  paper: {
    width: theme.dimensions.dialogs.alerts.width,
    top: "inherit",
  },
  title: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  description: {
    color: theme.palette.text.primary,
  },
  content: {
    padding: theme.spacing(1, 2, 0),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}))

const DialogActionStatus = ({ open, onClose, onSubmit, status }) => {
  const classes = useStyles()
  const { isValid } = useFormUtils()

  const [note, setNote] = useState("")

  const handleClose = () => {
    onClose && onClose()
  }

  const handleSubmit = () => {
    onSubmit && onSubmit({ note })
    handleClose()
  }

  const isFormValid = isValid(note.trim())

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <Box pb={1}>
        <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
          {ACTION_STATUS_LABELS[status]} action
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <DialogContentText id="alert-dialog-description" className={classes.description}>
            Please note down how this action is {ACTION_STATUS_LABELS[status]?.toLowerCase()}
          </DialogContentText>
          <Box mb={2}>
            <TextField
              variant="outlined"
              multiline
              fullWidth
              label="Add note"
              name="note"
              minRows={6}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              inputProps={{ "data-cy": "DialogActionStatus-note" }}
              required
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isFormValid}>
              {ACTION_STATUS_VERBS[status]} action
            </Button>
            <Button onClick={handleClose} className={classes.button}>
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default DialogActionStatus
