import React, { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/styles"
import { Grid, Hidden, Box, useMediaQuery, IconButton } from "@material-ui/core"
import { Switch, Route, useParams, Redirect, useHistory } from "react-router-dom"
import { FilterList as FilterListIcon } from "@material-ui/icons"
import {
  AreaHeader,
  Content,
  MainHeading,
  ModuleHeading,
  RowBox,
  HelpBanner,
  BackToTop,
  MobileCreateProcessButton,
  DesktopCreateProcessButton,
} from "../../components"
import { useQueryTemplateCategories, useQuerySettings } from "../../data"

import Library from "./Library"
import Organisation from "./Organisation"
import { useProcessUtils } from "../../utils"
import { TemplatesSubNav } from "./TemplatesSubNav"

const useStyles = makeStyles((theme) => ({
  filterButton: {
    paddingTop: ({ isInline }) => (isInline ? 6 : "inherit"),
    paddingBottom: ({ isInline }) => (isInline ? 6 : "inherit"),
  },
  subNav: {
    paddingTop: 0,
    borderRadius: "0 0 8px 8px",
    "&&:first-of-type": {
      marginBottom: 0,
      borderRadius: "8px 8px 0 0",
      paddingBottom: 0,
    },
  },
  subNavTitle: {
    paddingTop: 0,
  },
  subNavList: {
    maxWidth: 240 - theme.spacing(1),
  },
  featuredIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const TemplateRoutes = () => (
  <Switch>
    <Route path="/templates/library/:category/:id/:action" component={Library} />
    <Route path="/templates/library/:category" component={Library} />
    <Route path="/templates/:set/:id/:action" component={Organisation} />
    <Route path="/templates/:set" component={Organisation} />
    <Redirect to="/templates/active" />
  </Switch>
)

const Templates = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isInline = useMediaQuery(theme.breakpoints.down("xs"))
  const { getFriendlySetName } = useProcessUtils()
  const { set, id, action, category } = useParams()
  const history = useHistory()

  const [openSubNav, setOpenSubNav] = useState(false)
  const { data: templateCategoriesData } = useQueryTemplateCategories()

  const { lang } = useQuerySettings()

  const baseUri = `/templates/${set || "active"}`

  const handleToggleSubNav = () => {
    setOpenSubNav(!openSubNav)
  }

  const handleCreate = (type) => {
    history.push(`${baseUri}/new/${type}`)
  }

  let heading
  if (category) {
    if (templateCategoriesData) {
      const templateCategory = templateCategoriesData.templateCategories.find((item) => item.id === category)
      heading = templateCategory ? templateCategory.name : "Category"
    }
  } else {
    heading = getFriendlySetName(set)
  }

  const open = id && action

  if (isInline && open) {
    return <TemplateRoutes />
  }

  return (
    <>
      <AreaHeader
        title={lang.area.templates}
        titleIcon="templates-light"
        desktopButtons={<DesktopCreateProcessButton onCreateClick={handleCreate} />}
        mobileButtons={<MobileCreateProcessButton onCreateClick={handleCreate} />}
      />

      <Content full start>
        <HelpBanner name="templates" />

        <Grid container direction="row">
          <Hidden mdDown>
            <Grid item xs={12} lg={3}>
              <TemplatesSubNav open={openSubNav} onClose={handleToggleSubNav} />
              <BackToTop from={300} />
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <TemplatesSubNav open={openSubNav} onClose={handleToggleSubNav} />
          </Hidden>
          <Grid item xs={12} lg={9}>
            <RowBox flexGrow={1}>
              <Box flexGrow={1}>
                <Hidden mdDown>
                  <MainHeading mb={3.75}>{heading}</MainHeading>
                </Hidden>
                <Hidden lgUp>
                  <Box pb={1}>
                    <ModuleHeading noMargin>{heading}</ModuleHeading>
                  </Box>
                </Hidden>
              </Box>
              <Box alignSelf="center" display={{ sm: "flex", lg: "none" }}>
                <IconButton onClick={handleToggleSubNav} className={classes.filterButton}>
                  <FilterListIcon />
                </IconButton>
              </Box>
            </RowBox>

            <TemplateRoutes />
          </Grid>
        </Grid>
      </Content>
    </>
  )
}

export default Templates
