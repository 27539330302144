import React, { useState, useEffect, Suspense } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, useMediaQuery } from "@material-ui/core"
import moment from "moment"
import { ActionCard } from "./ActionCard"
import {
  Checkbox,
  Icon,
  DownloadList,
  ImagesViewer,
  AddPhotoStep,
  AddFileStep,
  OutlinedInputStep,
  MultipleChoiceButtonsStep,
  NumberRatingStep,
} from ".."
import { MentionOutput } from "../Mentions"
import {
  PROCESS_STEP_LOGIC_OUTCOME,
  PROCESS_STEP_RESPONSE_TYPE,
  PROCESS_STEP_SELECTION_SOURCES,
  useMakeJobStepOptimistic,
  useMutationJobStepComplete,
} from "../../data"
import { mapToIds, preventDefault, toId, useFocus } from "../../utils"
import { ColumnBox, RowBox } from "../Boxes"
import { CompletedByAvatar } from "../Avatar"
import FooterButton from "../Buttons/ActionCardFooterButton"
import AddNoteFooterButton from "../JobViewer/AddNoteFooterButton"
import RaiseActionFooterButton from "../JobViewer/RaiseActionFooterButton"
import { DateTimeStep } from "../JobViewer/DateTimeStep"
import { NestedProcessStep } from "../JobViewer/NestedProcessStep"
import SelectionStep from "./SelectionStep"
import { NumberStepWithProbes } from "../JobViewer/NumberStepWithProbes"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "500",
    lineHeight: "20px",
    color: theme.palette.text.primary,
    marginTop: 19,
  },
  description: {
    fontSize: 14,
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  circle: {
    backgroundColor: ({ completed }) => (completed ? "transparent" : "#f5fffc"),
    borderRadius: 21,
    width: 42,
    height: 42,
  },
  divider: {
    marginLeft: ({ completed, skip }) => (completed || skip ? 0 : -2),
    marginRight: ({ completed, skip }) => (completed || skip ? 0 : -2),
    width: "100%",
    backgroundColor: ({ completed, skip }) => theme.palette.cards.divide[completed || skip ? "darker" : "normal"],
  },
  icon: {
    color: ({ completed }) => theme.palette.cards.icons[completed ? "complete" : "incomplete"],
    padding: 8,
    fontSize: 42,
  },
  svgIcon: {
    opacity: 0.6,
    padding: 8,
  },
  resizedIcon: {
    color: ({ completed }) => theme.palette.cards.icons[completed ? "complete" : "incomplete"],
    marginLeft: 10,
    marginTop: 9,
  },
  promptArea: {
    minHeight: 64,
  },
  button: {
    lineHeight: "14px",
    height: 36,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: "auto",
    "&:not(.MuiButton-containedPrimary):not(.Mui-disabled)": {
      backgroundColor: theme.palette.buttons.light.background,
    },
    "&:hover:not(.MuiButton-containedPrimary):not(.Mui-disabled)": {
      backgroundColor: theme.palette.buttons.light.hover,
    },
  },
  completed: {
    color: theme.palette.success.main,
  },
  completedText: {
    color: "rgba(0,0,0,0.87)",
    "&.Mui-disabled": {
      color: "rgba(0,0,0,0.87)",
    },
  },
  adornments: {
    "& p": {
      color: "rgba(0,0,0,0.87)",
    },
  },
  completedButton: {
    backgroundColor: theme.palette.primary.background,
  },
  incompletedButton: {
    backgroundColor: theme.palette.primary.background,
  },
  footer: {
    padding: ({ xs }) => (xs ? theme.spacing(1, 1) : theme.spacing(1, 1.5)),
  },
}))

const SignatureStep = React.lazy(() => import("../JobViewer/SignatureStep"))

const {
  CHECKBOX,
  NUMBER,
  TEXT,
  LONG_TEXT,
  DATE_TIME,
  PHOTOS,
  MULTIPLE_CHOICE,
  NUMBER_RATING_5,
  NUMBER_RATING_10,
  PROCESS,
  SIGNATURE,
  FILES,
  SELECTION,
} = PROCESS_STEP_RESPONSE_TYPE

const ProcessStepActionCard = ({
  job,
  step,
  index,
  isSubmitted,
  isScheduled,
  isPreview,
  onCreateAction,
  cy = "ProcessStep",
}) => {
  const jobID = toId(job)
  const {
    id,
    name,
    description,
    uploads,
    skip,
    completed,
    completedAt,
    completedBy,
    responseType,
    responseMandatory,
    response,
    responseUploads,
    selection,
    compactResponseSelections,
    responseCustomSelections,
  } = step
  const xs = useMediaQuery((theme) => theme.breakpoints.down("xs"))
  const { hasFeature } = useAuth()
  const [completeJobStep] = useMutationJobStepComplete(job, step)
  const { makeJobStepCompleteOptimisticResponse } = useMakeJobStepOptimistic()
  const [requireAction, setRequireAction] = useState(false)
  const [requireNote, setRequireNote] = useState(false)
  const [inputRef, setInputFocus] = useFocus()
  const [newResponse, setNewResponse] = useState(response)
  const [newResponseSelections, setNewResponseSelections] = useState(selection?.allowMultiple ? [] : null)
  const classes = useStyles({ completed, skip, xs })
  const isFromCustomItemsSource = selection?.selectFrom === PROCESS_STEP_SELECTION_SOURCES.CUSTOM_ITEMS

  const hasProbes = hasFeature("probes")

  useEffect(() => {
    const isComplete = completedAt != null
    if (isComplete) {
      setNewResponse(response || "")
    }
  }, [completedAt, skip, response, completedBy])

  const makeOptimisticResponse = (variables) =>
    makeJobStepCompleteOptimisticResponse({
      ...variables,
      job,
    })

  const handleCreateAction = () => {
    onCreateAction && onCreateAction(step)
  }

  const handleCompletedChange = async (event, skipping) => {
    const state = !completed
    preventDefault(event, async () => {
      if (!isPreview) {
        const variables = {
          job: jobID,
          step: id,
          skip: skipping,
          complete: !completed,
          response: newResponse,
          ...(responseType !== PROCESS_STEP_RESPONSE_TYPE.SELECTION && { response: newResponse }),
          ...(!isFromCustomItemsSource &&
            responseType === PROCESS_STEP_RESPONSE_TYPE.SELECTION && {
              responseSelections: {
                ids: mapToIds(newResponseSelections),
                selectedFrom: selection?.selectFrom?.slice(0, -1),
              },
            }),
          ...(isFromCustomItemsSource &&
            responseType === PROCESS_STEP_RESPONSE_TYPE.SELECTION && {
              responseCustomSelections: newResponseSelections?.map((newResponseSelection) => newResponseSelection),
            }),
        }

        await completeJobStep({
          variables,
          optimisticResponse: makeOptimisticResponse({
            ...variables,
            ...(responseType === PROCESS_STEP_RESPONSE_TYPE.SELECTION && {
              compactResponseSelections: newResponseSelections,
            }),
          }),
        })
      }
      if (!state && [NUMBER, TEXT, LONG_TEXT].includes(responseType)) {
        setInputFocus()
      }
    })
  }

  const handleCompletedWithResponse = async (value) => {
    setNewResponse(value)

    if (!isPreview) {
      const variables = {
        job: jobID,
        step: id,
        skip: false,
        complete: true,
        response: value,
      }

      await completeJobStep({
        variables,
        optimisticResponse: makeOptimisticResponse(variables),
      })
    }
    if ([NUMBER, TEXT, LONG_TEXT].includes(responseType)) {
      setInputFocus()
    }
  }

  const handleResponseChange = (value) => {
    setNewResponse(value)
  }

  const handleLogicResult = (logicResult) => {
    setRequireAction(logicResult.types.includes(PROCESS_STEP_LOGIC_OUTCOME.ACTION))
    setRequireNote(
      logicResult.types.some((type) =>
        [
          PROCESS_STEP_LOGIC_OUTCOME.NOTE,
          PROCESS_STEP_LOGIC_OUTCOME.NOTE_FILE,
          PROCESS_STEP_LOGIC_OUTCOME.NOTE_IMAGE,
          PROCESS_STEP_LOGIC_OUTCOME.NOTE_TEXT,
        ].includes(type)
      )
    )
  }

  const handleSignatureChange = async (value) => {
    const clearing = !value
    setNewResponse(moment(value).format())

    if (!isPreview) {
      const variables = {
        job: jobID,
        step: id,
        skip: false,
        complete: !clearing,
        response: clearing ? "" : value,
      }

      await completeJobStep({
        variables,
        optimisticResponse: makeOptimisticResponse(variables),
      })
    }
  }

  const handleDateTimeChange = async (newDate) => {
    const value = newDate ? moment(newDate).format() : null

    const clearing = !value
    setNewResponse(value)

    if (!isPreview) {
      const variables = {
        job: jobID,
        step: id,
        skip: false,
        complete: !clearing,
        response: clearing ? "" : value,
      }

      await completeJobStep({
        variables,
        optimisticResponse: makeOptimisticResponse(variables),
      })
    }
  }

  const handleButtonResponseClick = async (event, value) => {
    const clearing = !!value && value === newResponse
    setNewResponse(clearing ? "" : value)
    preventDefault(event, async () => {
      if (!isPreview) {
        const variables = {
          job: jobID,
          step: id,
          skip: false,
          complete: !clearing,
          response: clearing ? "" : value,
        }

        await completeJobStep({
          variables,
          optimisticResponse: makeOptimisticResponse(variables),
        })
      }
    })
  }

  const handleUploadsChange = async (event, value) => {
    preventDefault(event, async () => {
      if (!isPreview) {
        await completeJobStep({
          variables: {
            job: jobID,
            step: id,
            skip: false,
            complete: !!value.length,
            responseUploads: value ? [...mapToIds(value)] : [],
          },
        })
      }
    })
  }

  const handleUploadsRemove = async (value) => {
    if (!isPreview) {
      await completeJobStep({
        variables: {
          job: jobID,
          step: id,
          skip: false,
          complete: false,
          responseUploads: value ? [...mapToIds(value)] : [],
        },
      })
    }
  }

  const handleUploadsEdit = async (event) => {
    preventDefault(event, async () => {
      if (!isPreview) {
        await completeJobStep({
          variables: {
            job: jobID,
            step: id,
            skip: false,
            complete: false,
          },
        })
      }
    })
  }

  const handleSelectionChange = async (options) => {
    const clearing = !options
    const state = !clearing

    setNewResponseSelections(options)

    if (!isPreview) {
      if (!selection?.allowMultiple) {
        const optionIds = mapToIds([options])

        const variables = {
          job: jobID,
          step: id,
          skip: false,
          complete: state,
          ...(!isFromCustomItemsSource && {
            responseSelections: clearing
              ? null
              : {
                  ids: optionIds,
                  selectedFrom: selection?.selectFrom?.slice(0, -1),
                },
          }),
          ...(isFromCustomItemsSource && {
            responseCustomSelections: clearing
              ? null
              : selection?.allowMultiple
              ? options.map((option) => option)
              : [options],
          }),
        }

        await completeJobStep({
          variables,
          optimisticResponse: makeOptimisticResponse({
            ...variables,
            compactResponseSelections: options,
          }),
        })
      }
    }
  }

  const collapseTimeout = !completedAt || moment().diff(moment(completedAt), "seconds") < 2 ? "auto" : 0

  useEffect(() => {
    const isComplete = completedAt != null
    if (isComplete) {
      if (step.responseType !== PROCESS_STEP_RESPONSE_TYPE.SELECTION) {
        setNewResponse(response || "")
      }
    }
  }, [step, completedAt, skip, response, completedBy])

  useEffect(() => {
    if (responseType === PROCESS_STEP_RESPONSE_TYPE.SELECTION) {
      if (completedAt !== null) {
        let selectionValue
        if (!isFromCustomItemsSource) {
          selectionValue = selection?.allowMultiple
            ? compactResponseSelections ?? []
            : compactResponseSelections?.[0] ?? null
        } else {
          selectionValue = selection?.allowMultiple
            ? responseCustomSelections ?? []
            : responseCustomSelections?.[0] ?? null
        }

        setNewResponseSelections(selectionValue)
      } else {
        setNewResponseSelections(selection?.allowMultiple ? [] : null)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ActionCard
      title={
        <ColumnBox>
          <Box className={classes.title} mb={description || uploads?.length ? 0 : 2}>
            {name}
            {responseMandatory && "*"}
          </Box>
          {description && (
            <Box mt={1} mb={uploads?.length ? 0 : 2} mr={2} className={classes.description}>
              <MentionOutput content={description} />
            </Box>
          )}
          {!!uploads?.length && (
            <Box mb={2}>
              <DownloadList uploads={uploads} />
              <ImagesViewer uploads={uploads} />
            </Box>
          )}
        </ColumnBox>
      }
      titleProps={{
        alignSelf: "flex-start",
      }}
      cursor="default"
      contentMinHeight="auto"
      completed={completed}
      skipped={skip}
      isScheduled={isScheduled}
      classes={{ footer: classes.footer }}
      stub={
        <Box p={1} alignSelf="flex-start">
          <Box className={classes.circle}>
            {responseType === CHECKBOX && !skip && (
              <Checkbox
                type="complete"
                checked={completed}
                onClick={handleCompletedChange}
                disabled={isScheduled}
                data-cy={`ProcessStep-field-${responseType}`}
              />
            )}
            {responseType === CHECKBOX && skip && (
              <Checkbox
                type="skipped"
                checked
                onClick={handleCompletedChange}
                disabled
                data-cy={`ProcessStep-field-${responseType}-skip`}
              />
            )}
            {responseType === PHOTOS && <Icon name="camera" className={classes.icon} />}
            {responseType === FILES && <Icon name="upload-file" className={classes.resizedIcon} />}
            {responseType === DATE_TIME && <Icon name="date" className={classes.icon} />}
            {[NUMBER_RATING_5, NUMBER_RATING_10].includes(responseType) && (
              <Icon name="rating" className={classes.icon} />
            )}
            {responseType === MULTIPLE_CHOICE && (
              <Icon
                name="custom-outlined"
                customName={step.responseSet?.icon || "rule"}
                throwForUnmapped={false}
                className={classes.resizedIcon}
              />
            )}
            {responseType === SIGNATURE && <Icon name="signature" className={classes.resizedIcon} />}
            {responseType === PROCESS && <Icon name="steps" className={classes.icon} />}
            {responseType === LONG_TEXT && <Icon name="long-text" className={classes.icon} />}
            {responseType === SELECTION && <Icon name="selection" className={classes.icon} />}
            {![
              CHECKBOX,
              LONG_TEXT,
              PHOTOS,
              FILES,
              DATE_TIME,
              NUMBER_RATING_5,
              NUMBER_RATING_10,
              MULTIPLE_CHOICE,
              SIGNATURE,
              PROCESS,
              SELECTION,
            ].includes(responseType) && <Icon name="text" className={classes.icon} />}
          </Box>
        </Box>
      }
      rightChildren={
        completed && (
          <CompletedByAvatar at={completedAt} by={completedBy} ml="auto" pt={2} pr={2} pl={1} alignSelf="flex-start" />
        )
      }
      footer={
        <RowBox flexGrow={1}>
          {!responseMandatory && (
            <Box mr={xs ? 1 : 1.5}>
              {!skip && (
                <FooterButton
                  icon="skip"
                  label={xs ? "Skip" : "Skip step"}
                  onClick={(event) => handleCompletedChange(event, true)}
                  disabled={completed || isSubmitted || isPreview || isScheduled}
                  preview={isPreview}
                />
              )}
              {skip && (
                <FooterButton
                  icon="unskip"
                  label={xs ? "Unskip" : "Unskip step"}
                  disabled={isSubmitted || isPreview}
                  preview={isPreview}
                  onClick={handleCompletedChange}
                />
              )}
            </Box>
          )}
          <RaiseActionFooterButton
            job={toId(job)}
            step={step}
            isPreview={isPreview}
            isScheduled={isScheduled}
            onCreateAction={handleCreateAction}
            required={requireAction}
          />
          <AddNoteFooterButton
            job={toId(job)}
            step={step}
            isPreview={isPreview}
            isScheduled={isScheduled}
            required={requireNote}
          />
        </RowBox>
      }
      p="auto"
      cy={cy}
    >
      {([TEXT, LONG_TEXT].includes(responseType) || (responseType === NUMBER && !hasProbes)) && (
        <OutlinedInputStep
          expand={!skip}
          inputRef={inputRef}
          complete={completed}
          step={step}
          onResponseChange={handleResponseChange}
          onLogicResult={handleLogicResult}
          onCompletedChange={handleCompletedChange}
          value={newResponse}
          collapseTimeout={collapseTimeout}
          disabled={isSubmitted}
          classes={{
            divider: classes.divider,
            completedText: classes.completedText,
            incompletedButton: classes.incompletedButton,
            completedButton: classes.completedButton,
            adornments: classes.adornments,
          }}
        />
      )}
      {responseType === NUMBER && hasProbes && (
        <NumberStepWithProbes
          expand={!skip}
          inputRef={inputRef}
          complete={completed}
          step={step}
          onResponseChange={handleResponseChange}
          onLogicResult={handleLogicResult}
          onCompletedWithResponse={handleCompletedWithResponse}
          onCompletedChange={handleCompletedChange}
          value={newResponse}
          collapseTimeout={collapseTimeout}
          disabled={isSubmitted}
          classes={classes}
        />
      )}
      {DATE_TIME === responseType && (
        <DateTimeStep
          expand={!skip}
          complete={completed}
          value={newResponse}
          onChange={handleDateTimeChange}
          classes={{
            divider: classes.divider,
            completedButton: classes.completedButton,
          }}
          disabled={isSubmitted || isScheduled}
        />
      )}
      {PHOTOS === responseType && (
        <AddPhotoStep
          expand={!skip}
          jobId={toId(job, true)}
          stepId={toId(step, true)}
          reference={id}
          responseUploads={responseUploads}
          onChange={handleUploadsChange}
          onRemove={handleUploadsRemove}
          onEdit={handleUploadsEdit}
          classes={{
            button: classes.button,
            divider: classes.divider,
            incompletedButton: classes.incompletedButton,
            completedButton: classes.completedButton,
            promptArea: classes.promptArea,
          }}
          complete={completed}
          collapseTimeout={collapseTimeout}
          disabled={isSubmitted || isScheduled || isPreview}
        />
      )}
      {FILES === responseType && (
        <AddFileStep
          expand={!skip}
          reference={id}
          responseUploads={responseUploads}
          onChange={handleUploadsChange}
          onRemove={handleUploadsRemove}
          onEdit={handleUploadsEdit}
          classes={{
            button: classes.button,
            divider: classes.divider,
            incompletedButton: classes.incompletedButton,
            completedButton: classes.completedButton,
            promptArea: classes.promptArea,
          }}
          complete={completed}
          collapseTimeout={collapseTimeout}
          disabled={isSubmitted || isScheduled || isPreview}
        />
      )}
      {MULTIPLE_CHOICE === responseType && (
        <MultipleChoiceButtonsStep
          expand={!skip}
          value={newResponse}
          step={step}
          responses={step.responseSet?.responses || []}
          onClick={handleButtonResponseClick}
          buttonClass={classes.button}
          disabled={isSubmitted || isScheduled || isPreview}
          collapseTimeout={collapseTimeout}
          classes={{
            divider: classes.divider,
          }}
        />
      )}
      {[NUMBER_RATING_5, NUMBER_RATING_10].includes(responseType) && (
        <NumberRatingStep
          expand={!skip}
          value={newResponse}
          count={responseType === NUMBER_RATING_5 ? 5 : 10}
          onClick={handleButtonResponseClick}
          buttonClass={classes.button}
          disabled={isSubmitted || isScheduled || isPreview}
          collapseTimeout={collapseTimeout}
          classes={{
            divider: classes.divider,
          }}
        />
      )}
      {PROCESS === responseType && (
        <NestedProcessStep
          key={toId(step)}
          expand
          skipped={skip}
          complete={completed}
          job={job}
          step={step}
          index={index}
          disabled={isScheduled || isPreview}
          collapseTimeout={collapseTimeout}
          isSubmitted={isSubmitted}
          isPreview={isPreview}
          classes={{
            divider: classes.divider,
          }}
        />
      )}
      {SIGNATURE === responseType && (
        <Suspense fallback={<Box>Loading...</Box>}>
          <SignatureStep
            expand={!skip}
            value={newResponse}
            complete={completed}
            disabled={isSubmitted || isScheduled || isPreview}
            collapseTimeout={collapseTimeout}
            onChange={handleSignatureChange}
            buttonClass={classes.button}
            isSubmitted={isSubmitted}
            isPreview={isPreview}
            classes={{
              divider: classes.divider,
            }}
          />
        </Suspense>
      )}
      {SELECTION === responseType && (
        <SelectionStep
          step={step}
          expand={!skip}
          collapseTimeout={collapseTimeout}
          isPreview={isPreview}
          completed={completed}
          value={newResponseSelections}
          onChange={(_, options) => handleSelectionChange(options)}
          onComplete={(event) => handleCompletedChange(event)}
          classes={{
            divider: classes.divider,
          }}
          location={job?.location ?? null}
        />
      )}
    </ActionCard>
  )
}

export { ProcessStepActionCard }
