import React, { useState } from "react"
import { Box, Collapse, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core"
import { SelectorMaster, LoadingSpinner, Icon } from ".."
import { toId, useMountEffect } from "../../utils"
import { PRINTER_TYPE, PRINTER_TYPE_LABELS } from "../../data"
import { SelectionBadge } from "../Badges/SelectionBadge"
import { useProbes } from "../../utils/useProbes"

const ProbeSelectorListItem = ({ probe, selected, onSelect }) => {
  const handleSelect = () => {
    onSelect && onSelect(probe)
  }

  const name = probe.name !== probe.address ? probe.name : PRINTER_TYPE_LABELS[probe.type]

  return (
    <Collapse in>
      <ListItem key={toId(probe)} button onClick={handleSelect}>
        <ListItemAvatar>
          <SelectionBadge status={selected ? "selected" : "none"}>
            <Icon name="probe" fontSize="large" />
          </SelectionBadge>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {name || "Unknown Probe"}
              {selected ? " (active)" : ""}
            </>
          }
          secondary={probe.address}
          onClick={handleSelect}
          style={{ cursor: "pointer" }}
        />
      </ListItem>
    </Collapse>
  )
}

const ProbeSelector = ({ open, onClose }) => {
  const { probe, probes, findProbes, finding, selectProbe } = useProbes()
  const [mode, setMode] = useState("list")
  // const { isValid } = useFormUtils()
  const [newProbe, setNewProbe] = useState(null)
  const [
    newProbeValid, // setNewProbeValid
  ] = useState(false)

  useMountEffect(() => {
    findProbes()
  })

  const handleChange = (newValue) => {
    if (newValue.type === PRINTER_TYPE.UNKNOWN_BLUETOOTH) {
      setNewProbe({ ...newValue })
      setMode("manual")
      return
    }
    selectProbe(newValue)
    onClose && onClose()
  }

  const handleSelect = async () => {
    if (mode === "manual") {
      if (newProbe.name && newProbe.address && newProbe.type) {
        selectProbe(newProbe)
        onClose && onClose()
      }
    } else {
      findProbes()
    }
  }

  const handleCancel = () => {
    if (mode === "manual") {
      setMode("list")
    } else {
      onClose && onClose()
    }
  }

  const hasItems = Boolean(probes.length)

  const form = (
    <Box mb={2}>
      <Collapse in={mode === "list" && finding}>
        <List disablePadding>
          <ListItem>
            <ListItemAvatar>
              <LoadingSpinner size={40} />
            </ListItemAvatar>
            <ListItemText
              primary="Finding probes"
              secondary="Ensure your probe is on and in discovery mode if required"
            />
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={mode === "list" && !finding}>
        <List disablePadding>
          {probes.map((item) => (
            <ProbeSelectorListItem
              key={toId(item)}
              probe={item}
              selected={item.address === probe?.address}
              onSelect={handleChange}
            />
          ))}
          {!hasItems && !finding && (
            <ListItem>
              <ListItemAvatar>
                <Icon name="probe-disabled" fontSize="large" />
              </ListItemAvatar>
              <ListItemText
                primary="No probes could be found"
                secondary="Ensure you have paired the probe through your Bluetooth settings, that it is on, and in discovery mode if required"
              />
            </ListItem>
          )}
        </List>
      </Collapse>
    </Box>
  )

  return (
    <SelectorMaster
      open={open}
      title="Probes"
      maxWidth="sm"
      form={form}
      selectText={mode === "manual" ? "Add probe" : "Refresh"}
      selectVariant={mode === "manual" ? "button" : "link"}
      onSelect={handleSelect}
      selectDisabled={(mode === "manual" && !newProbeValid) || finding}
      cancelText={mode === "manual" ? "Back" : "Cancel"}
      onCancel={handleCancel}
    />
  )
}

export { ProbeSelector }
