import { Box, Grid, makeStyles } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { PaperBox, UserPanel } from "../../../components"
import UsersDaysOverviewReport from "../../../components/Reports/UsersDaysOverviewReport"
import UsersJobsOverviewReport from "../../../components/Reports/UsersJobsOverviewReport"
import JobsStepCompletionStatisticsReport from "../../../components/Reports/JobsStepCompletionStatisticsReport"
import UsersJobStepCompletionReport from "../../../components/Reports/UsersJobStepCompletionReport"
import { useLazyQueryAdminableUser, useQueryGroups } from "../../../data"
import { useAuth } from "../../../services"
import { mapToIds, multipleSelectChange, useDateUtils, useReportUtils } from "../../../utils"
import { ReportsFilters } from "../../../components/Reports/ReportsFilters"
import { DateRangeChipMenu } from "../../../components/ChipMenu/DateRangeChipMenu"
import { LocationChipMenu } from "../../../components/ChipMenu/LocationChipMenu"
import { GroupChipMenu } from "../../../components/ChipMenu/GroupChipMenu"
import { UserChipMenu } from "../../../components/ChipMenu/UserChipMenu"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
    width: 300,
    flexShrink: 0,
  },
  filterSelect: {
    backgroundColor: "white",
    maxWidth: 300,
  },
  reports: {
    marginTop: theme.spacing(3),
  },
  userPanelContainer: {
    backgroundColor: theme.palette.shaded.filter,
  },
}))

const People = () => {
  const classes = useStyles()
  const { days, locations, groups, user } = useParams()
  const { getNavigateToJobsLink } = useReportUtils()
  const { momentToScheduleDate } = useDateUtils()
  const [loadUser, { data: userData }] = useLazyQueryAdminableUser()
  const { getNavigateToPeopleLink, getNavigateToLink } = useReportUtils()
  const [gt, setGt] = useState(null)
  const [lt, setLt] = useState(null)
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data: groupsData } = useQueryGroups()

  const groupIds = groups?.split("-") || ["all"]
  const locationIds = locations?.split("-") || ["all"]
  const userValue = user || ""

  useEffect(() => {
    // // do here?
    // const filterLocations = locations === "all" ? mapToIds(userLocations) : locations.split("-")
    // const filterGroups = groups === "all" ? null : groups.split("-")
    let newGt = null
    let newLt = null
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      newGt = momentToScheduleDate(moment(daysParts[0]))
      newLt = momentToScheduleDate(moment(daysParts[1]))
    } else {
      newGt = momentToScheduleDate(moment().subtract(days, "days"))

      // If not "Today"
      if (days !== "1") {
        // Set end of yesterday (23h59)
        newLt = momentToScheduleDate(moment().subtract(1, "days").endOf("day"))
      }
    }
    setGt(newGt)
    setLt(newLt)
  }, [days, groups, locations, momentToScheduleDate, userLocations])

  useEffect(() => {
    if (user) {
      loadUser({ variables: { id: user } })
    }
  }, [loadUser, user])

  const handleFiltersMenuApply = (values) => {
    const { days: newDays, locations: newLocations, groups: newGroups, users: newUsers } = values
    history.push(getNavigateToPeopleLink(newDays, newLocations, newGroups, newUsers))
  }

  const handleDaysChange = (newDays) => {
    history.push(getNavigateToPeopleLink(newDays, locationIds, groupIds, user))
  }

  const handleLocationsChange = (event) => {
    history.push(getNavigateToPeopleLink(days, multipleSelectChange(locationIds, event), groupIds, user))
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToPeopleLink(days, mapToIds(regionLocations), groupIds, user))
  }

  const handleGroupsChange = (event) => {
    history.push(getNavigateToPeopleLink(days, locationIds, multipleSelectChange(groupIds, event), user))
  }

  const handleUserChange = (newUser) => {
    history.push(getNavigateToPeopleLink(days, locationIds, groupIds, newUser))
  }

  const handleUserClick = (userId) => {
    history.push(getNavigateToPeopleLink(days, locationIds, groupIds, userId))
  }

  const handleDayClick = (date) => {
    const newDays = `${moment(date).format("YYYY-MM-DD")}-to-${moment(date).format("YYYY-MM-DD")}`
    history.push(getNavigateToPeopleLink(newDays, locationIds, groupIds, user))
  }

  const handleJobClick = (process) => {
    history.push(getNavigateToJobsLink(days, locationIds, groupIds, process))
  }

  const handleClear = () => {
    history.push(getNavigateToLink("people", 7, ["all"], ["all"]))
  }

  const hasGroups = !!groupsData?.groups.length

  return (
    <>
      <ReportsFilters
        values={{
          days,
          users: userValue,
          locations: locationIds,
          groups: groupIds,
        }}
        menu={{
          days: true,
          users: true,
          locations: true,
          groups: hasGroups,
        }}
        onApply={handleFiltersMenuApply}
        onClear={handleClear}
      >
        <Box ml={0.5}>
          <DateRangeChipMenu value={days} onChange={handleDaysChange} />
        </Box>
        <Box ml={0.5}>
          <UserChipMenu value={user} onChange={handleUserChange} />
        </Box>
        <Box ml={0.5}>
          <LocationChipMenu
            value={locationIds}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        {hasGroups && (
          <Box ml={0.5}>
            <GroupChipMenu value={groupIds} onChange={handleGroupsChange} multiple />
          </Box>
        )}
      </ReportsFilters>

      {/* <Box p={3} className={classes.filter} mt={3} mb={3}>
        <Box mb={2}>
          <UserAutocomplete
            label="Person"
            value={user}
            onChange={handleUserChange}
            inputProps={{ className: classes.filterSelect }}
            key={user}
          />
        </Box>
        <Box mb={2}>
          <DateRangeOutlinedSelect
            label="Date range"
            value={days}
            onChange={handleDaysChange}
            className={classes.filterSelect}
          />
        </Box>
        <Box>
          <LocationOutlinedSelect
            value={locationIds}
            className={classes.filterSelect}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        {hasGroups && (
          <Box mt={2}>
            <GroupOutlinedSelect
              value={groupIds}
              className={classes.filterSelect}
              onChange={handleGroupsChange}
              multiple
            />
          </Box>
        )}
        <RowBox mt={2} justifyContent="flex-end">
          <Button variant="contained" onClick={handleReset}>
            Reset
          </Button>
        </RowBox>
      </Box> */}
      {!user && (
        <Grid container spacing={1} className={classes.reports}>
          <Grid item xs={12}>
            <UsersJobStepCompletionReport
              gt={gt}
              lt={lt}
              locations={locationIds}
              groups={groupIds}
              onUserClick={handleUserClick}
              defaultSort="rank"
            />
          </Grid>
          <Grid item xs={12}>
            <JobsStepCompletionStatisticsReport gt={gt} lt={lt} locations={locationIds} groups={groupIds} />
          </Grid>
        </Grid>
      )}
      {user && (
        <Grid container spacing={1} className={classes.reports}>
          <Grid item xs={10}>
            {userData && (
              <PaperBox fullHeight paperProps={{ className: classes.userPanelContainer }}>
                <UserPanel layout="landscape" user={userData.adminableUser} />
              </PaperBox>
            )}
          </Grid>
          <JobsStepCompletionStatisticsReport
            gt={gt}
            lt={lt}
            locations={locationIds}
            groups={groupIds}
            users={user ? [user] : []}
          />
          <Grid item xs={12} lg={6}>
            <UsersDaysOverviewReport
              gt={gt}
              lt={lt}
              locations={locationIds}
              groups={groupIds}
              users={user ? [user] : []}
              onDayClick={handleDayClick}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <UsersJobsOverviewReport
              gt={gt}
              lt={lt}
              locations={locationIds}
              groups={groupIds}
              users={user ? [user] : []}
              onJobClick={handleJobClick}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default People
