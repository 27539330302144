import React from "react"
import { Box, Collapse } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { makeStyles } from "@material-ui/styles"
import { Icon, LinkButton } from ".."

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: "normal",
    fontFamily: theme.typography.fontFamily,
    margin: 0,
    padding: 0,
    lineHeight: "inherit",
    verticalAlign: "inherit",
  },
}))

const DraftBlockquote = ({ show = false, subject, onDiscard }) => {
  const classes = useStyles()

  const handleDiscard = () => {
    onDiscard && onDiscard()
  }

  return (
    <Collapse in={show}>
      <Box mb={3}>
        <Alert icon={<Icon name="draft" />} severity="info">
          <AlertTitle>{subject} restored from draft</AlertTitle>
          This {subject.toLowerCase()} was restored from an unsaved draft, to start fresh{" "}
          <LinkButton onClick={handleDiscard} className={classes.button}>
            discard the draft
          </LinkButton>
        </Alert>
      </Box>
    </Collapse>
  )
}

export { DraftBlockquote }
