import React from "react"
import { Box } from "@material-ui/core"
import { ReportSectionActionCard, FullscreenReportContainer } from ".."
import { mapToIds } from "../../utils"
import { useQueryReportTrainingUsersAndCount } from "../../data/report/useQueryReportTrainingUsers"
import { useAuth } from "../../services"
import { UserTrainingCoursesDataTable } from "../Charts/UserTrainingCoursesDataTable"

const TrainingUserTableReport = ({ locations, groups, defaultFullscreen = false, onFullscreen }) => {
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
  const filter = {
    locations: filterLocations,
    groups,
  }
  const {
    data: trainingUsersData,
    loading: trainingUsersLoading,
    fetchMore,
  } = useQueryReportTrainingUsersAndCount({
    variables: {
      filter,
      offset: 0,
      limit: 20,
    },
    skip: !userLocations,
  })

  const handleFullscreen = (fullscreen) => {
    onFullscreen && onFullscreen(fullscreen)
  }

  const renderTable = (fullscreen) => (
    <Box style={{ width: fullscreen ? "100%" : "auto", overflowX: "auto" }}>
      <UserTrainingCoursesDataTable
        data={trainingUsersData?.report.trainingV2 || {}}
        fullscreen={fullscreen}
        fetchMore={fetchMore}
        filter={filter}
      />
    </Box>
  )

  return (
    <ReportSectionActionCard
      title="Users"
      detail="Training status"
      defaultFullscreen={defaultFullscreen}
      onFullscreen={handleFullscreen}
      loading={trainingUsersLoading || !trainingUsersData}
    >
      {({ fullscreen }) => {
        if (trainingUsersLoading || !trainingUsersData) {
          return null
        }

        if (fullscreen) {
          return <FullscreenReportContainer>{renderTable(fullscreen)}</FullscreenReportContainer>
        }

        return renderTable(fullscreen)
      }}
    </ReportSectionActionCard>
  )
}

export { TrainingUserTableReport }
