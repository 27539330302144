import React, { useState, useEffect, useMemo } from "react"
import { Box, MenuItem, TextField } from "@material-ui/core"
import moment from "moment-timezone"
import cronParser from "cron-parser"
import { CreatorActions } from "../Creators"
import { LocationOutlinedSelect, OutlinedSelect } from "../OutlinedSelect"
import {
  TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE,
  TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE_LABELS,
  TRIGGER_TARGET_TYPE,
} from "../../data"
import { UsersGroupsAssigner } from "../Assigners"
import { useAuth } from "../../services"
import { TextDivider } from "../TextDivider"
import HumanIntervalScheduler from "../Scheduling/HumanIntervalScheduler"
import TagsTextField from "../TextField/TagsTextField"
import {
  useMutationCreateScheduleTrigger,
  useMutationUpdateScheduleTrigger,
} from "../../data/triggers/useMutationScheduleTrigger"
import TriggerTargetTypeOutlinedSelect from "./TriggerTargetTypeOutlinedSelect"
import { mapToIds, multipleSelectChange, removeTypename, toId, useFormUtils } from "../../utils"
import TriggerNotifyMethodOutlinedSelect from "./TriggerNotifyMethodOutlinedSelect"

const { NOTIFY_USERS, NOTIFY_EMAILS, INTEGRATION } = TRIGGER_TARGET_TYPE

const ScheduledNotificationContentOutlinedSelect = ({ label = "What communication content?", ...props }) => (
  <OutlinedSelect label={label} native={false} {...props}>
    {Object.values(TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE).map((value) => (
      <MenuItem key={value} value={value}>
        {TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE_LABELS[value]}
      </MenuItem>
    ))}
  </OutlinedSelect>
)

const initial = {
  name: "",
  scheduleSource: {
    schedules: [""],
    timeZone: moment.tz.guess(),
    locations: [],
  },
  targetType: "",
  notifyUsersTarget: {
    methods: [],
    users: [],
    groups: [],
    output: "",
  },
  notifyEmailsTarget: {
    emails: [],
    output: "",
  },
  integrationTarget: "",
}

const minimumFrequencySeconds = 60 * 60

const scheduleTooFrequent = (schedule) => {
  try {
    const interval = cronParser.parseExpression(schedule, { currentDate: moment().toDate() })
    const one = interval.next().toDate()
    const two = interval.next().toDate()

    return moment(two).diff(moment(one), "seconds") < minimumFrequencySeconds
  } catch {
    return false // invalid
  }
}

const scheduleValid = (schedule) => {
  try {
    cronParser.parseExpression(schedule)
    return true
  } catch {
    return false
  }
}

const ScheduleSourceTrigger = ({ edit, onClose, cy }) => {
  const [createTrigger, { loading: createLoading }] = useMutationCreateScheduleTrigger()
  const [updateTrigger, { loading: updateLoading }] = useMutationUpdateScheduleTrigger()
  const {
    settings: { locations },
  } = useAuth()
  const { isValid, validate } = useFormUtils()
  const [init, setInit] = useState(false)
  const [name, setName] = useState(initial.name)
  const [scheduleSource, setScheduleSource] = useState(initial.scheduleSource)
  const [targetType, setTargetType] = useState(initial.targetType)
  const [notifyUsersTarget, setNotifyUsersTarget] = useState(initial.notifyUsersTarget)
  const [notifyEmailsTarget, setNotifyEmailsTarget] = useState(initial.notifyEmailsTarget)
  const [integrationTarget, setIntegrationTarget] = useState(initial.integrationTarget)

  const [tooFrequent, setTooFrequent] = useState(false)
  const [initialPicked, setInitialPicked] = useState(null)

  useEffect(() => {
    if (edit) {
      setName(edit.name)
      setScheduleSource(() => ({
        ...removeTypename(edit.scheduleSource),
        locations: !edit.scheduleSource?.locations?.length ? ["all"] : mapToIds(edit.scheduleSource.locations),
      }))
      setTargetType(edit.targetType)
      if (edit.targetType === NOTIFY_USERS) {
        setNotifyUsersTarget(() => ({
          ...removeTypename(edit.notifyUsersTarget),
          users: mapToIds(edit.notifyUsersTarget.users),
          groups: mapToIds(edit.notifyUsersTarget.groups),
        }))
      }
      if (edit.targetType === NOTIFY_EMAILS) {
        setNotifyEmailsTarget(removeTypename(edit.notifyEmailsTarget))
      }
      setIntegrationTarget(edit.integrationTarget)

      if (edit.targetType === NOTIFY_USERS) {
        setInitialPicked([...edit.notifyUsersTarget.users, ...edit.notifyUsersTarget.groups])
      }

      setTooFrequent(scheduleTooFrequent(edit.scheduleSource?.schedules?.[0]))
    } else {
      setInitialPicked([])
    }
    setInit(true)
  }, [edit])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleSchedulesChange = (value) => {
    setScheduleSource((prev) => ({
      ...prev,
      ...value,
    }))
  }

  const handleValidateScheduleExpression = (expression) => {
    if (!expression) {
      return { error: false, message: "" }
    }
    if (!scheduleValid(expression)) {
      return { error: true, message: "Invalid schedule expression" }
    }
    if (scheduleTooFrequent(expression)) {
      setTooFrequent(true)
      return { error: true, message: `Too frequent, maximum every ${minimumFrequencySeconds / 60} minutes` }
    }
    setTooFrequent(false)
    return { error: false, message: "" }
  }

  const handleTargetTypeChange = (event) => {
    setTargetType(event.target.value)
  }

  const handleLocationsChange = (event) => {
    setScheduleSource((prev) => ({
      ...prev,
      locations: [...multipleSelectChange(scheduleSource.locations, event)],
    }))
  }

  const handleRegionChange = (newLocations) => {
    setScheduleSource((prev) => ({
      ...prev,
      locations: [...mapToIds(newLocations)],
    }))
  }

  const handleOutputChange = (event) => {
    const destination = targetType === NOTIFY_USERS ? setNotifyUsersTarget : setNotifyEmailsTarget
    destination((prev) => ({
      ...prev,
      output: event.target.value,
    }))
  }

  const handleNotifyMethodsChange = (event) => {
    setNotifyUsersTarget((prev) => ({
      ...prev,
      methods: [...multipleSelectChange(notifyUsersTarget.methods, event)],
    }))
  }

  const handleNotifyUsersPickedChange = (picked) => {
    const newHasEveryone = picked.find((p) => p.__typename === "Everyone")
    setNotifyUsersTarget((prev) => ({
      ...prev,
      users: newHasEveryone ? [] : [...mapToIds(picked.filter((p) => p.__typename === "User"))],
      groups: newHasEveryone ? [] : [...mapToIds(picked.filter((p) => p.__typename === "Group"))],
    }))
  }

  const handleEmailsChange = (event, options) => {
    setNotifyEmailsTarget((prev) => ({
      ...prev,
      emails: options,
    }))
  }

  const handleSubmit = async () => {
    const variables = {
      input: {
        name,
        scheduleSource: {
          ...scheduleSource,
          locations: scheduleSource.locations.includes("all") ? [] : scheduleSource.locations,
        },
        targetType,
      },
    }

    switch (targetType) {
      case NOTIFY_USERS:
        variables.input.notifyUsersTarget = { ...notifyUsersTarget }
        break
      case NOTIFY_EMAILS:
        variables.input.notifyEmailsTarget = { ...notifyEmailsTarget }
        break
      case INTEGRATION:
        variables.input.integrationTarget = { ...integrationTarget }
        break
      default:
        throw Error("No target type selected")
    }

    if (edit) {
      await updateTrigger({
        variables: {
          id: toId(edit),
          ...variables,
        },
      })
    } else {
      await createTrigger({
        variables,
      })
    }

    onClose && onClose()
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const formValid = useMemo(
    () =>
      Boolean(scheduleSource?.schedules?.length) &&
      scheduleValid(scheduleSource?.schedules?.[0]) &&
      !tooFrequent &&
      isValid(scheduleSource?.timeZone) &&
      Boolean(scheduleSource?.locations?.length) &&
      isValid(targetType) &&
      (targetType !== NOTIFY_USERS ||
        Boolean(
          notifyUsersTarget?.methods?.length && (notifyUsersTarget?.users?.length || notifyUsersTarget?.groups?.length)
        )) &&
      (targetType !== NOTIFY_EMAILS ||
        (notifyEmailsTarget?.emails?.length &&
          notifyEmailsTarget.emails.every((email) => validate.requiredEmail(email)))),
    [
      scheduleSource?.schedules,
      scheduleSource?.timeZone,
      scheduleSource?.locations?.length,
      tooFrequent,
      isValid,
      targetType,
      notifyUsersTarget?.methods?.length,
      notifyUsersTarget?.users?.length,
      notifyUsersTarget?.groups?.length,
      notifyEmailsTarget.emails,
      validate,
    ]
  )

  const hasNotification = targetType.startsWith("notify_")
  const loading = createLoading || updateLoading

  return (
    <>
      <Box mb={1}>
        <Box mb={2}>
          <TextField
            label="Trigger name"
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            fullWidth
            inputProps={{ "data-cy": "TextField-name" }}
          />
        </Box>

        <TextDivider mb={2} />

        {init && (
          <HumanIntervalScheduler
            value={scheduleSource}
            inputProps={{ label: "When should the trigger run?", required: true }}
            onChange={handleSchedulesChange}
            onValidateExpression={handleValidateScheduleExpression}
          />
        )}

        <TextDivider mb={2} />

        <Box mb={2}>
          <TriggerTargetTypeOutlinedSelect value={targetType} onChange={handleTargetTypeChange} required />
        </Box>

        {hasNotification && (
          <>
            <Box my={2}>
              <ScheduledNotificationContentOutlinedSelect
                value={targetType === NOTIFY_EMAILS ? notifyEmailsTarget.output : notifyUsersTarget.output}
                onChange={handleOutputChange}
              />
            </Box>
            {targetType === NOTIFY_USERS && (
              <Box mb={2}>
                <TriggerNotifyMethodOutlinedSelect
                  value={notifyUsersTarget.methods || []}
                  allowInApp={false}
                  allowPush={false}
                  onChange={handleNotifyMethodsChange}
                  multiple
                />
              </Box>
            )}
            <Box mb={2}>
              <LocationOutlinedSelect
                mode="admin"
                label="For which locations?"
                value={scheduleSource?.locations || []}
                onChange={handleLocationsChange}
                onRegionChange={handleRegionChange}
                multiple
              />
            </Box>
          </>
        )}

        {targetType === NOTIFY_USERS && Boolean(initialPicked) && (
          <UsersGroupsAssigner
            title="Who should be notified?"
            allowSelf
            allowAll={false}
            locations={mapToIds(locations)}
            initialPicked={initialPicked}
            onPickedChanged={handleNotifyUsersPickedChange}
            cy="TriggerCreator-notification-to"
          />
        )}

        {targetType === NOTIFY_EMAILS && (
          <Box my={2}>
            <TagsTextField
              value={notifyEmailsTarget.emails || []}
              label="Email Address(es)"
              placeholder="Type address and press enter..."
              inputProps={{ type: "email" }}
              onChange={handleEmailsChange}
            />
          </Box>
        )}
      </Box>

      <CreatorActions
        id={`${cy}-CreatorActions`}
        subject="Trigger"
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitLoading={loading}
        disableSubmit={!formValid}
      />
    </>
  )
}

export default ScheduleSourceTrigger
