import React, { useState, useEffect, useMemo } from "react"
import { TextField, Box, Divider, MenuItem } from "@material-ui/core"
import moment from "moment-timezone"
import { EditLocation, ImageOutlined } from "@material-ui/icons"
import { useApolloClient } from "@apollo/client"
import { Autocomplete } from "@material-ui/lab"
import { useMutationCreateLocation, useMutationUpdateLocation, useQueryIntegrations } from "../../data"
import { toId, useFormUtils } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import { useAuth } from "../../services"
import { useSnackbar } from "../SnackbarProvider"
import { CreateLocationBillingNoticeBlockquote } from "../Blockquotes/CreateLocationBillingNoticeBlockquote"
import { ColumnBox, PaperBox } from "../Boxes"
import { InfoSection } from "../Viewers"
import { ExpansionPanel } from "../ExpansionPanel"
import { OutlinedSelect } from "../OutlinedSelect"
import { useQueryRegions } from "../../data/locations/useQueryRegions"
import Checkbox from "../Checkbox"
import { UploaderProfileImage, Icon } from ".."

const LocationCreator = ({ open, onClose, edit, isInline, cy = "LocationCreator" }) => {
  const { isValid } = useFormUtils()
  const client = useApolloClient()
  const { refresh, hasFeature } = useAuth(client)
  const snackbar = useSnackbar()
  const { data: integrationsData } = useQueryIntegrations()
  const { data: regionsData } = useQueryRegions()
  const [createLocation, { loading: createLoading }] = useMutationCreateLocation()
  const [updateLocation, { loading: updateLoading }] = useMutationUpdateLocation()

  const dataLoading = createLoading || updateLoading

  const [name, setName] = useState("")
  const [timeZone, setTimeZone] = useState(moment.tz.guess())
  const [region, setRegion] = useState("")
  const [inactive, setInactive] = useState(false)
  const [logo, setLogo] = useState(null)

  const isEdit = edit != null

  useEffect(() => {
    if (edit) {
      setName(edit.name)
      setTimeZone(edit.timeZone)
      setRegion(toId(edit.region, true) || "")
      setInactive(edit.inactive)
      setLogo(edit.logo)
    }
  }, [edit])

  const handleOnClose = () => {
    handleResetState()
    if (onClose) onClose(true)
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleTimeZoneChange = async (_, newValue) => {
    setTimeZone(newValue?.key || "")
  }

  const handleRegionChange = async (event) => {
    setRegion(event.target.value)
  }

  const handleSubmit = async (event) => {
    try {
      const input = { name, timeZone, region: region || null, inactive, logo: logo && logo.id }
      let response
      if (isEdit) {
        response = await updateLocation({ variables: { id: toId(edit), input } })
      } else {
        response = await createLocation({ variables: { input } })
      }
      if (response.errors) {
        return
      }
      refresh(client, false)
      handleOnClose(event)
      snackbar.showMessage({ message: isEdit ? "Location updated" : "Location created", icon: <EditLocation /> })
    } catch (error) {
      snackbar.showMessage({ message: error.message })
    }
  }

  const handleResetState = () => {
    setName("")
    setTimeZone(moment.tz.guess())
    setInactive(false)
    setLogo(null)
  }

  const formValid = isValid(name)

  const sortByZone = (a, b) => {
    const [ahh, amm] = a.display.split("UTC")[1].split(")")[0].split(":")
    const [bhh, bmm] = b.display.split("UTC")[1].split(")")[0].split(":")
    return +ahh * 60 + amm - (+bhh * 60 + bmm)
  }
  const timeZoneList = useMemo(
    () =>
      moment.tz
        .names()
        .map((tzName) => ({ key: tzName, display: `(UTC${moment.tz(tzName).format("Z")}) ${tzName}` }))
        .sort(sortByZone),
    []
  )

  const hasAttributes = useMemo(
    () =>
      Boolean(edit) &&
      integrationsData?.integrations.some(({ integrationProvider: { attributes } }) => attributes?.location.length),
    [edit, integrationsData?.integrations]
  )

  const optionValue = useMemo(
    () => timeZoneList.find((item) => item.key === timeZone) || null,
    [timeZone, timeZoneList]
  )

  const hasRegions = regionsData?.regions?.length > 0 && hasFeature("regions")

  const handleLogoChange = (file) => {
    if (file) {
      setLogo(file.upload)
    } else {
      setLogo(null)
    }
  }

  const form = (
    <>
      {!edit && <CreateLocationBillingNoticeBlockquote />}

      <Box mb={3}>
        <Box flexGrow={1} mb={2}>
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            label="Business location name"
            name="name"
            value={name}
            onChange={handleNameChange}
            autoFocus
            required
            inputProps={{ "data-cy": `${cy}-TextField-name` }}
          />
        </Box>
        <Box flexGrow={1}>
          <Autocomplete
            freeSolo
            value={optionValue}
            getOptionLabel={(item) => item?.display || ""}
            getOptionSelected={(option) => option.key === optionValue.key}
            options={timeZoneList || []}
            onChange={handleTimeZoneChange}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Time zone" fullWidth />}
          />
        </Box>
        {hasRegions && (
          <Box mt={2}>
            <OutlinedSelect
              value={toId(region, true) || ""}
              label="Region"
              native={false}
              onChange={handleRegionChange}
            >
              <MenuItem value="">None</MenuItem>
              <Divider />
              {regionsData.regions.map((item) => (
                <MenuItem key={toId(item)} value={toId(item)}>
                  {item.name}
                </MenuItem>
              ))}
            </OutlinedSelect>
          </Box>
        )}
      </Box>

      <UploaderProfileImage
        avatarProps={{ avatar: logo, fullName: name, icon: <Icon name="image" /> }}
        subject="logo"
        badgeIcon={<ImageOutlined style={{ fontSize: 16 }} />}
        onChange={handleLogoChange}
      />

      {hasAttributes && (
        <Box mb={3}>
          <ExpansionPanel title="Integration attributes">
            <ColumnBox width="100%">
              <PaperBox fullWidth>
                {integrationsData.integrations.map((integration) =>
                  integration.integrationProvider.attributes?.location.map((attribute) => (
                    <React.Fragment key={toId(attribute)}>
                      <InfoSection
                        title={attribute.name}
                        value={
                          edit.attributes.find(
                            (ua) => toId(ua.integration) === toId(integration) && ua.key === attribute.key
                          )?.value || "(not set)"
                        }
                      />
                      <Divider />
                    </React.Fragment>
                  ))
                )}
              </PaperBox>
            </ColumnBox>
          </ExpansionPanel>
        </Box>
      )}

      <Box mb={1}>
        <Checkbox
          color="primary"
          type="label"
          label="Make location inactive"
          checked={inactive}
          onChange={() => setInactive(!inactive)}
        />
      </Box>

      <Box mb={1}>
        <Divider />
      </Box>

      <CreatorActions
        id={`${cy}-CreatorActions`}
        subject="Location"
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        submitLoading={dataLoading}
        disableSubmit={!formValid}
      />
    </>
  )

  return (
    <CreatorMaster
      open={open}
      subject="Location"
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleOnClose}
    />
  )
}

export { LocationCreator }
