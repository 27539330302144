import React, { useEffect } from "react"
import { Box } from "@material-ui/core"
import moment from "moment"
import { formatters } from "json2csv"
import fileDownload from "js-file-download"
import Config from "react-global-configuration"
import {
  ReportSectionActionCard,
  JobStepDataTable,
  getImageSource,
  FullscreenReportContainer,
  DownloadExportJobButton,
} from ".."
import { useLazyQueryReportJobDetail, PROCESS_STEP_RESPONSE_TYPE } from "../../data"
import { formatJobTitle, toId } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ExportJobAttachmentsButton from "./ExportJobAttachmentsButton"

const reportJobsHaveResponseUploads = (jobs) => {
  if (!jobs || jobs.length === 0) {
    return false
  }

  return jobs.some((job) => {
    if (!job?.steps) {
      return false
    }

    return job.steps.some((step) => step.responseUploads.length > 0)
  })
}

const JobStepTableReport = ({ gt, lt, process, location, defaultFullscreen = false, onFullscreen, multiplePerDay }) => {
  const [load, { data, loading }] = useLazyQueryReportJobDetail()

  useEffect(() => {
    load({ variables: { process: toId(process), location: toId(location), gt, lt } })
  }, [gt, load, location, lt, process])

  const handleFullscreen = (fullscreen) => {
    onFullscreen && onFullscreen(fullscreen)
  }

  const handleDownloadData = () => {
    const { imageViewerBaseUri, imageViewerBucketName } = Config.get("uploader")
    const { process: downloadProcess, jobs } = data.reportJobDetail
    const stringFormatter = formatters.string()
    const header = [
      "",
      ...jobs.map(({ availableFrom }) =>
        stringFormatter(moment(availableFrom).tz(location.timeZone).format("D MMM YY"))
      ),
    ]

    const titles = ["", ...jobs.map((job) => stringFormatter(formatJobTitle(job)))]

    const rows = []

    downloadProcess.steps.forEach(({ id: stepId, name }) => {
      let row = [stringFormatter(name)]

      row = row.concat(
        jobs.map(({ steps: jobSteps }) => {
          const jobStep = jobSteps.find(({ step: jobStepId }) => jobStepId === stepId)
          if (!jobStep) {
            return ""
          }

          const { skip, response, responseType, responseUploads, completedAt } = jobStep
          if (skip || !completedAt) {
            return ""
          }
          if (responseType === PROCESS_STEP_RESPONSE_TYPE.PHOTOS && responseUploads) {
            return stringFormatter(
              responseUploads
                .map((upload) =>
                  getImageSource(imageViewerBaseUri, imageViewerBucketName, upload, "inside", 1280, 1024, false)
                )
                .join(" ")
            )
          }
          if (response) {
            return stringFormatter(response)
          }
          return completedAt
        })
      )

      rows.push(`${row.join(",")}`)
    })

    const csv = `${header.join(",")}\n${titles.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "job-step-responses.csv", "text/csv")
  }

  const hasAttachments = reportJobsHaveResponseUploads(data?.reportJobDetail.jobs)

  return (
    <ReportSectionActionCard
      title={location.name}
      detail={`${!loading && data ? `${data.reportJobDetail?.process?.name || "Unknown process"} - ` : ""}Job data`}
      tools={
        <>
          {hasAttachments && (
            <ExportJobAttachmentsButton
              variant="icon"
              gt={gt}
              lt={lt}
              process={process}
              locations={[location]}
              disabled={loading}
            />
          )}
          <Box ml={1}>
            <DownloadExportJobButton
              jobs={
                data?.reportJobDetail?.jobs?.map(({ id, availableFrom, title }) => ({
                  id,
                  name: `${moment(availableFrom).format("D MMM YY")}${multiplePerDay ? ` - ${title}` : ""}`,
                })) || []
              }
              variant="list"
              iconSize="small"
              listAllJobsOptionProps={{ gt, lt, process, location }}
              disabled={loading}
            />
          </Box>
          <Box ml={1}>
            <ReportToolsDownloadDataIconButton onClick={() => handleDownloadData(location)} disabled={loading} />
          </Box>
        </>
      }
      defaultFullscreen={defaultFullscreen}
      onFullscreen={handleFullscreen}
      loading={loading || !data}
    >
      {({ fullscreen }) => {
        if (!data) {
          return null
        }

        const display = (
          <Box style={{ maxWidth: "100%", overflowX: "auto" }}>
            <JobStepDataTable data={data.reportJobDetail} location={location} fullscreen={fullscreen} />
          </Box>
        )

        if (fullscreen) {
          return <FullscreenReportContainer>{display}</FullscreenReportContainer>
        }

        return display
      }}
    </ReportSectionActionCard>
  )
}

export default JobStepTableReport
